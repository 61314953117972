import React, { useEffect, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js';
import _ from 'lodash';

import '../../styles/widget.css';
import '../../styles/clientHorizBarChart.css';

import { getColorFromIndex } from '../../utilities/AdvisorVue/getColorFromIndex';
import { Investment } from '../../types/Investment';

interface ClientHorizBarChartProps {
    investments: Investment[] | null;
    blank: boolean;
}

const ClientHorizBarChart: React.FC<ClientHorizBarChartProps> = ({ investments, blank }) => {
    const [data, setData] = useState<ChartData<'bar', number[], string> | null>(null);

    useEffect(() => {
        loadData();
    }, [investments, blank]);

    const loadData = () => {
        if (!investments || !Array.isArray(investments) || investments.length === 0) {
            setData({
                datasets: [
                    {
                        type: 'bar' as const,
                        label: 'empty',
                        backgroundColor: '#E0E0E0',
                        barThickness: 20,
                        data: [1],
                    },
                ],
                labels: ['Allocations'],
            });
            return;
        }

        // Filter out invalid investments
        const validInvestments = investments.filter((investment) => typeof investment.amount === 'number' && !isNaN(investment.amount));

        // Aggregate committed values into grouped types
        const sum = validInvestments.reduce((total, { amount }) => total + amount, 0);

        const datasets =
            blank || validInvestments.length === 0 || sum === 0
                ? null
                : validInvestments.map(({ name, amount }, tIdx) => ({
                      type: 'bar' as const,
                      label: name,
                      backgroundColor: getColorFromIndex(tIdx),
                      barThickness: 20,
                      data: [amount / sum],
                  }));

        const labels = ['Allocations'];
        const chartData = datasets
            ? { datasets, labels }
            : {
                  datasets: [
                      {
                          type: 'bar' as const,
                          label: 'empty',
                          backgroundColor: '#E0E0E0',
                          barThickness: 20,
                          data: [1],
                      },
                  ],
                  labels: ['Allocations'],
              };
        setData(chartData);
    };

    const getChartOptions = (): ChartOptions<'bar'> => ({
        indexAxis: 'y',
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                display: false,
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                display: false,
                grid: {
                    display: false,
                },
                stacked: true,
                ticks: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    });

    return (
        <div className="clientBar-component">
            {data && <Chart type="bar" data={data} options={getChartOptions()} style={{ position: 'relative', backgroundColor: 'transparent' }} />}
        </div>
    );
};

export { ClientHorizBarChart };
