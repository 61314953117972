// saves zip file with multiple files, accounting for duplicate filenames

// params
// content: [{
//     blob, // the blob to save
//     filename, // the name of the file to save
// }]
// filename: the name of the zip file to save
//
// returns boolean success

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const saveZip = async (content, zipFilename) => {
    try {
        const zip = new JSZip();
        const filenamesWithoutExtension = {};
        content.forEach((item) => {
            if (!item.blob || !item.filename) {
                throw new Error('Invalid content item', item);
            }
            const extension = item.filename.split('.').pop();
            const nameWithoutExtension = item.filename.split('.').slice(0, -1).join('.');
            if (filenamesWithoutExtension[nameWithoutExtension]) {
                const newName = `${nameWithoutExtension}_${filenamesWithoutExtension[nameWithoutExtension]}.${extension}`;
                zip.file(newName, item.blob);
                filenamesWithoutExtension[nameWithoutExtension] += 1;
            } else {
                filenamesWithoutExtension[nameWithoutExtension] = 1;
                zip.file(item.filename, item.blob);
            }
        });
        const blob = await zip.generateAsync({ type: 'blob' });
        saveAs(blob, zipFilename);
        return true;
    } catch (err) {
        console.error('Error saving zip', err);
        return false;
    }
};

export default saveZip;
