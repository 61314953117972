import { Currency, CurrencySymbol } from '../types/Currency';

export const CURRENCIES: { [key in Currency]: Currency } = { ...Currency };

export const CURRENCY_SYMBOLS: { [key in Currency]: CurrencySymbol } = { ...CurrencySymbol };

export const getCurrencyString = (currency: Currency): string => {
    const symbol = CURRENCY_SYMBOLS[currency] || CURRENCY_SYMBOLS.USD;
    return `${currency} (${symbol})`;
};
