import validator from 'validator';

import { DocumentType } from '../../pages/DocumentsPage/documentDrawer/documentFieldsConstants';
import { DocumentOwnershipType, TaxDocumentSubtypes } from '../../constants/documentConstants';

// IMPORTANT!!!!!!!!!!!!
// Update the api copy too when making changes here

const notEmpty = (value) => {
    return value !== undefined && value !== null && value !== '';
};

const checkCapitalCallError = (metadata) => {
    const hasAmount = metadata.some((m) => notEmpty(m.value) && m.key === 'capital called' && validator.isNumeric(String(m.value)));
    const hasEffectiveDate = metadata.some((m) => notEmpty(m.value) && m.key === 'effective date' && validator.isDate(new Date(m.value)));
    if (!hasAmount && !hasEffectiveDate) {
        return 'Both amount and effective date are missing or invalid.';
    }
    if (!hasAmount) {
        return 'Amount is missing or invalid.';
    }
    if (!hasEffectiveDate) {
        return 'Effective date is missing or invalid.';
    }
    return null;
};

const checkNetCapitalCallError = (metadata) => {
    const hasCapitalCalled = metadata.some((m) => notEmpty(m.value) && m.key === 'capital called' && validator.isNumeric(String(m.value)));
    const hasProfitDistribution = metadata.some((m) => notEmpty(m.value) && m.key === 'profit distribution' && validator.isNumeric(String(m.value)));
    const hasPrincipalDistribution = metadata.some((m) => notEmpty(m.value) && m.key === 'principal distribution' && validator.isNumeric(String(m.value)));
    const hasRecallableProfitDistribution = metadata.some((m) => notEmpty(m.value) && m.key === 'recallable profit distribution' && validator.isNumeric(String(m.value)));
    const hasRecallablePrincipalDistribution = metadata.some((m) => notEmpty(m.value) && m.key === 'recallable principal distribution' && validator.isNumeric(String(m.value)));
    const hasEffectiveDate = metadata.some((m) => notEmpty(m.value) && m.key === 'effective date' && validator.isDate(new Date(m.value)));
    const hasAnyDistribution = hasProfitDistribution || hasPrincipalDistribution || hasRecallableProfitDistribution || hasRecallablePrincipalDistribution;

    if (!hasCapitalCalled) return 'Capital called validation failed or is missing.';
    if (!hasAnyDistribution) return 'No valid distribution detected.';
    if (!hasEffectiveDate) return 'Effective date is missing or invalid.';

    return null;
};

const checkDistributionNoticeError = (metadata) => {
    const hasEffectiveDate = metadata.some((m) => notEmpty(m.value) && m.key === 'effective date' && validator.isDate(new Date(m.value)));
    if (!hasEffectiveDate) return 'Missing or invalid effective date.';
    const hasProfitDistribution = metadata.some((m) => notEmpty(m.value) && m.key === 'profit distribution' && validator.isNumeric(String(m.value)));
    const hasPrincipalDistribution = metadata.some((m) => notEmpty(m.value) && m.key === 'principal distribution' && validator.isNumeric(String(m.value)));
    const hasRecallableProfitDistribution = metadata.some((m) => notEmpty(m.value) && m.key === 'recallable profit distribution' && validator.isNumeric(String(m.value)));
    const hasRecallablePrincipalDistribution = metadata.some((m) => notEmpty(m.value) && m.key === 'recallable principal distribution' && validator.isNumeric(String(m.value)));
    const hasAnyDistribution = hasProfitDistribution || hasPrincipalDistribution || hasRecallableProfitDistribution || hasRecallablePrincipalDistribution;
    if (!hasAnyDistribution) return 'There are no valid distributions present.';
    return null; // No issues found
};

const checkNetDistributionNoticeError = (metadata) => {
    const hasEffectiveDate = metadata.some((m) => notEmpty(m.value) && m.key === 'effective date' && validator.isDate(new Date(m.value)));
    if (!hasEffectiveDate) return "Missing or invalid 'effective date'.";
    const hasProfitDistribution = metadata.some((m) => notEmpty(m.value) && m.key === 'profit distribution' && validator.isNumeric(String(m.value)));
    const hasPrincipalDistribution = metadata.some((m) => notEmpty(m.value) && m.key === 'principal distribution' && validator.isNumeric(String(m.value)));
    const hasRecallableProfitDistribution = metadata.some((m) => notEmpty(m.value) && m.key === 'recallable profit distribution' && validator.isNumeric(String(m.value)));
    const hasRecallablePrincipalDistribution = metadata.some((m) => notEmpty(m.value) && m.key === 'recallable principal distribution' && validator.isNumeric(String(m.value)));
    const hasCapitalCalled = metadata.some((m) => notEmpty(m.value) && m.key === 'capital called' && validator.isNumeric(String(m.value)));
    if (!hasCapitalCalled) return "Missing or invalid 'capital called'.";
    const hasAnyDistribution = hasProfitDistribution || hasPrincipalDistribution || hasRecallableProfitDistribution || hasRecallablePrincipalDistribution;
    if (!hasAnyDistribution) return 'At least one distribution type is required.';
    return null; // all checks passed
};

const checkCapitalAccountStatementError = (metadata) => {
    const hasPeriodStart = metadata.some((m) => notEmpty(m.value) && m.key === 'period start' && validator.isDate(new Date(m.value)));
    const hasPeriodEnd = metadata.some((m) => notEmpty(m.value) && m.key === 'period end' && validator.isDate(new Date(m.value)));
    const hasTimeframe = metadata.some((m) => notEmpty(m.value) && m.key === 'timeframe' && ['QTD', 'YTD', 'MTD', 'ITD'].includes(m.value));
    const hasEndingBalance = metadata.some((m) => notEmpty(m.value) && m.key === 'ending balance' && validator.isNumeric(String(m.value)));
    const hasPeriodStartOrTimeframe = hasPeriodStart || hasTimeframe;
    if (!hasPeriodEnd) return 'Missing or invalid period end';
    if (!hasEndingBalance) return 'Missing or invalid ending balance';
    if (!hasPeriodStartOrTimeframe) return 'Either period start or timeframe must be provided and valid';
    return null;
};

const checkHedgeFundStatementError = (metadata) => {
    const hasPeriodStart = metadata.some((m) => notEmpty(m.value) && m.key === 'period start' && validator.isDate(new Date(m.value)));
    const hasPeriodEnd = metadata.some((m) => notEmpty(m.value) && m.key === 'period end' && validator.isDate(new Date(m.value)));
    const hasTimeframe = metadata.some((m) => notEmpty(m.value) && m.key === 'timeframe' && ['QTD', 'YTD', 'MTD', 'ITD'].includes(m.value));
    const hasEndingBalance = metadata.some((m) => notEmpty(m.value) && m.key === 'ending balance' && validator.isNumeric(String(m.value)));
    const hasContributions = metadata.some((m) => notEmpty(m.value) && m.key === 'contributions' && validator.isNumeric(String(m.value)));
    const hasWithdrawals = metadata.some((m) => notEmpty(m.value) && m.key === 'withdrawals' && validator.isNumeric(String(m.value)));
    const hasPeriodStartOrTimeframe = hasPeriodStart || hasTimeframe;
    const hasContributionsOrWithdrawals = hasContributions || hasWithdrawals;
    if (!hasPeriodEnd) return 'Missing or invalid period end.';
    if (!hasPeriodStartOrTimeframe) return 'Missing or invalid period start and timeframe.';
    if (!hasEndingBalance) return 'Missing or invalid ending balance.';
    return null; // No errors found
};

const checkTaxDocumentError = (metadata) => {
    const formType = metadata.find((m) => notEmpty(m.value) && m.key === 'form type')?.value;
    const hasTaxYear = metadata.some((m) => notEmpty(m.value) && m.key === 'tax year');

    const valid_form_types = Object.values(TaxDocumentSubtypes); // ['K-1', 'Estimate', '1099', 'W-9', 'Other'];
    if (!valid_form_types.includes(formType)) {
        return 'Error: Invalid form type.';
    }
    if (!hasTaxYear) {
        return 'Error: Missing or invalid tax year.';
    }
};

export const checkMetadataError = (document) => {
    const metadata = document.get ? document.get('metadata').toObject() : document['metadata'];
    const _ = DocumentType.CapitalCall;
    if (!metadata || document.ownership_type !== DocumentOwnershipType.investment) return false;
    try {
        switch (document.type) {
            case DocumentType.CapitalCall:
                return checkCapitalCallError(metadata);
            case DocumentType.NetCapitalCall:
                return checkNetCapitalCallError(metadata);
            case DocumentType.Distribution:
                return checkDistributionNoticeError(metadata);
            case DocumentType.NetDistribution:
                return checkNetDistributionNoticeError(metadata);
            case DocumentType.Statement:
                return checkCapitalAccountStatementError(metadata);
            case DocumentType.HedgeFund:
                return checkHedgeFundStatementError(metadata);
            case DocumentType.TaxDocument:
                return checkTaxDocumentError(metadata);
            case DocumentType.Other:
                return null;
            case DocumentType.Disclosure:
                return null;
            case DocumentType.InvestmentUpdate:
                return null;
            case DocumentType.LegalAgreement:
                return null;
            case DocumentType.PPMOM:
                return null;
            case DocumentType.PrivacyNotice:
                return null;
            case DocumentType.ReportingAuthorizationLetter:
                return null;
            case DocumentType.WireInstructionConfirmation:
                return null;
            case null:
                return null;
            default:
                break;
        }
    } catch (error) {
        console.log(`Error validating document metadata for document ${document._id}: ${error}`);
        return `Unknown error validating document metadata for document ${document._id}: ${error}`;
    }
};
