import { faTwitter, faFacebook, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

export const EDIT_MODES = {
    NORMAL: 'normal',
    SORT: 'sort',
    LABEL: 'label',
};

export const SOCIAL_MEDIA_TYPES = {
    twitter: {
        name: 'Twitter',
        icon: faTwitter,
    },
    facebook: {
        name: 'Facebook',
        icon: faFacebook,
    },
    linkedin: {
        name: 'Linked In',
        icon: faLinkedin,
    },
    instagram: {
        name: 'Instagram',
        icon: faInstagram,
    },
};

export enum DocumentOwnershipType {
    account = 'Account',
    investment = 'Investment',
}

export enum TaxDocumentSubtypes {
    K1 = 'K-1',
    Estimate = 'Estimate',
    Form1099 = '1099',
    W9 = 'W-9',
    Other = 'Other',
}
