export const STAGING_DATA_FEATURES = {
    AUTO_IMPORT: 'Auto Import',
    AUTO_IMPORT_DOCUMENTS: 'Auto Import Documents',
};

export const IGNORED_FILTER_OPTIONS = {
    all: 'All',
    ignore: 'Ignored',
    not_ignore: 'Not Ignored',
};

export const MAPPED_FILTER_OPTIONS = {
    all: 'All',
    mapped: 'Mapped',
    not_mapped: 'Not Mapped',
};

export const STAGING_TYPES = {
    USER: 'user',
    ACCOUNT: 'account',
    INVESTMENT: 'investment',
    DOCUMENT: 'document',
    TRANSACTION: 'transaction',
    COMMITMENT: 'commitment',
    VALUATION: 'valuation',
};
