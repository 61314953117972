import { calcAmountInvested } from '../../utilities/calculate/financeCalculations';

//Stats found on investment object
export const getInvestmentStats = (investment) => {
    const performance = investment?.performance?.itd;
    return {
        ...investment,
        _id: investment._id,
        name: investment.name,
        type: investment.type,
        incomplete: investment.incomplete,
        inactive: investment.inactive,
        committed: performance?.committed ?? 0,
        amountCalled: performance?.contributions ?? 0,
        value: investment?.valuation ?? 0,
        distributions: performance?.distributions ?? 0,
        gain: performance?.gain ?? 0,
        other: performance?.other ?? 0,
        fees: performance?.fees ?? 0,
        irr: (performance?.irr ?? 0) * 100,
        invested: calcAmountInvested(investment),
        totalValue: investment.valuation + (performance?.distributions ?? 0),
        currentValue: investment.valuation,
    };
};
