const OWNERSHIP_TYPE_OPTIONS = {
    all: 'All',
    user: 'User',
    account: 'Account',
    investment: 'Investment',
};

export enum OwnershipType {
    all = 'All',
    user = 'User',
    account = 'Account',
    investment = 'Investment',
}
