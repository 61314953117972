import React, { useState, useEffect, useRef, FC } from 'react';
import _ from 'lodash';
import inputKey from '../../utilities/inputKey';

interface SearchProps {
    value?: string;
    defaultValue?: string;
    callback: (value: string) => void;
    placeholder?: string;
    style?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
}

const Search: FC<SearchProps> = ({ value, defaultValue, callback, placeholder = 'Search', style, inputStyle }) => {
    const searchInput = useRef<HTMLInputElement>(null);
    const [inputValue, setInputValue] = useState<string>(value ?? defaultValue ?? '');
    const [focused, setFocused] = useState<boolean>(false);

    useEffect(() => {
        const handleSearch = (e: KeyboardEvent) => {
            inputKey(e, {
                Enter: () => {
                    if (focused) {
                        callback(inputValue);
                    }
                },
                Escape: () => {
                    searchInput.current?.blur();
                },
            });
        };

        if (focused) {
            document.addEventListener('keydown', handleSearch);
        }

        return () => {
            document.removeEventListener('keydown', handleSearch);
        };
    }, [focused, inputValue, callback]);

    useEffect(() => {
        if (!_.isEqual(value, inputValue)) {
            setInputValue(value ?? '');
        }
    }, [value]);

    return (
        <div className="searchContainer" style={style}>
            <input
                ref={searchInput}
                style={inputStyle}
                type="text"
                className="search"
                value={inputValue}
                placeholder={placeholder}
                onChange={(e) => setInputValue(e.target.value)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
            />
            <img src="/images/icons/circle-arrow.png" alt="search" onClick={() => callback(inputValue)} />
        </div>
    );
};

export default Search;
