import React, { useEffect, useState, useRef } from 'react';
import * as OTPAuth from 'otpauth';
import '../styles/totpDisplay.css';

interface TOTPDisplayProps {
    totp_key: string;
}

const TOTPDisplay: React.FC<TOTPDisplayProps> = ({ totp_key }) => {
    const [token, setToken] = useState<string>('');
    const initialDelayRef = useRef<NodeJS.Timeout>();
    const intervalRef = useRef<NodeJS.Timeout>();

    const getPeriodFromKey = (key: string): number => {
        if (!key) {
            return 30;
        }
        const periodMatch = key.match(/&period=(\d+)/i);
        return periodMatch ? parseInt(periodMatch[1], 10) : 30;
    };

    const getSecretFromKey = (key: string): string => {
        if (!key) {
            return key;
        }
        const secretMatch = key.match(/^otpauth:\/\/totp\/.*secret=([^&]+)/i);
        return secretMatch ? secretMatch[1] : key;
    };

    const generateToken = () => {
        try {
            const urlFormatRegex = /^otpauth:\/\/totp\/.*$/i;
            if (urlFormatRegex.test(totp_key)) {
                const totp = OTPAuth.URI.parse(totp_key);
                const generatedToken = totp.generate();
                setToken(generatedToken);
                return;
            }

            const formattedKey = getSecretFromKey(totp_key);
            const period = getPeriodFromKey(totp_key);
            const totp = new OTPAuth.TOTP({
                algorithm: 'SHA1',
                digits: 6,
                period: period,
                secret: formattedKey,
            });
            const generatedToken = totp.generate();
            setToken(generatedToken);
        } catch (error) {
            console.error('Failed to generate TOTP token:', error);
        }
    };

    const setupTokenGeneration = () => {
        // Clear existing timers
        if (initialDelayRef.current) clearTimeout(initialDelayRef.current);
        if (intervalRef.current) clearInterval(intervalRef.current);

        generateToken(); // Generate token immediately

        const period = getPeriodFromKey(totp_key);
        const currentTime = new Date();
        const secondsUntilNextInterval = (period - (currentTime.getSeconds() % period)) * 1000;

        // Initial delay to align with the period
        initialDelayRef.current = setTimeout(() => generateToken(), secondsUntilNextInterval);

        // Refresh based on the period
        intervalRef.current = setInterval(() => generateToken(), period * 1000);
    };

    useEffect(() => {
        setupTokenGeneration();

        // Cleanup function
        return () => {
            if (initialDelayRef.current) clearTimeout(initialDelayRef.current);
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [totp_key]); // Re-run effect when totp_key changes

    return (
        <div className="totp-display-container">
            <div className="totp-token">{token}</div>
        </div>
    );
};

export default TOTPDisplay;
