import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SOCIAL_MEDIA_TYPES } from '../../constants/documentConstants';

import '../../styles/socialIcon.css';

const SocialIcon = ({ type, link, size }) => {
    const social = SOCIAL_MEDIA_TYPES[type];

    if (!social || !link) return null;

    if (!link.startsWith('http')) {
        link = `https://${link}`;
    }

    return (
        <a href={link} target="_blank" rel="noopener noreferrer" title={link}>
            <FontAwesomeIcon key={`social_${type}`} icon={social.icon} size={size} className={`social_${type}`} />
        </a>
    );
};

export default SocialIcon;
