// add commas to number
// if not a number, just returns the given value
export const addCommasToNumber = (num, isDollars = false, decimals = null) => {
    if (num && !isNaN(num)) {
        let negative = false;
        if (parseFloat(num) < 0) {
            num *= -1;
            negative = true;
        }

        if (decimals && decimals >= 0) num = parseFloat(num).toFixed(decimals);

        const decimalSplit = num.toString().split('.');

        // split into char array
        let numSplit = decimalSplit[0].toString().split('');

        // add commas every three chars, except the last one
        numSplit = numSplit.map((char, idx) => {
            const isEveryThird = (numSplit.length - 1 - idx) % 3 === 0;
            const isLast = idx !== numSplit.length - 1;
            const needsComma = isEveryThird && isLast && numSplit[idx + 1] !== '.';
            return char + (needsComma ? ',' : '');
        });

        let res = numSplit.join('');

        if (decimalSplit.length > 1) {
            res += '.' + decimalSplit[1];
        }

        if (isDollars) res = `$${res}`;

        if (negative) res = `-${res}`;

        return res;
    } else return num;
};
