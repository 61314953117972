import React from 'react';

import '../styles/notificationBell.css';

const DEFAULT_HEIGHT = 30;

interface NotificationBellProps {
    count?: number;
    height?: number;
    onClick?: () => void;
    imgStyle?: React.CSSProperties;
}

export const NotificationBell: React.FC<NotificationBellProps> = ({ count = 0, height = DEFAULT_HEIGHT, onClick, imgStyle }) => {
    const width = (height * 7) / 8;

    return (
        <button
            data-testid="notification-bell"
            onClick={onClick}
            className="notificationBellComponent"
            aria-label="Notification Bell"
            style={{ padding: 0, border: 'none', background: 'transparent', cursor: 'pointer' }}
        >
            <img
                src="/images/icons/bell.png"
                alt="Notification Bell"
                className="notificationBellImg"
                style={{
                    ...imgStyle,
                    height: height + 'px',
                    width: width + 'px',
                }}
            />
            {count > 0 && (
                <div
                    className="notificationBellCount"
                    style={{
                        fontSize: ((count < 100 ? 16 : count < 1000 ? 12 : count < 10000 ? 10 : 8) * height) / DEFAULT_HEIGHT + 'px',
                        left: -(width / 4) + 'px',
                        bottom: -(height / 4) + 'px',
                        width: (height * 2) / 3 + 'px',
                        height: (height * 2) / 3 + 'px',
                    }}
                >
                    {count}
                </div>
            )}
        </button>
    );
};

export default NotificationBell;
