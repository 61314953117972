// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

const stats = {
    emptyText: 'Track and manage tax documents',
    textStyle: {
        fontSize: '16px',
    },

    // documents collected widget
    stats: {
        received: 7,
        expected: 9,
    },

    // distributions widget
    distributions: {
        dollarByTypeByYearList: [
            {
                _id: 2023,
                types: [
                    {
                        type: 'Bond / Loan',
                        distributions: 0,
                    },
                    {
                        type: 'Diverse Investments',
                        distributions: 59994,
                    },
                    {
                        type: 'Hedge Fund',
                        distributions: 33330,
                    },
                    {
                        type: 'Real Estate',
                        distributions: 0,
                    },
                    {
                        type: 'Venture Capital Fund',
                        distributions: 11110,
                    },
                ],
                total: 104434,
            },
            {
                _id: 2022,
                types: [
                    {
                        type: 'Bond / Loan',
                        distributions: 13804,
                    },
                    {
                        type: 'Diverse Investments',
                        distributions: 20000,
                    },
                    {
                        type: 'Hedge Fund',
                        distributions: 0,
                    },
                    {
                        type: 'Real Estate',
                        distributions: 50123,
                    },
                    {
                        type: 'Venture Capital Fund',
                        distributions: 6500,
                    },
                ],
                total: 14727,
            },
        ],
        maxTotal: 104434,
        relevantTypes: ['Bond / Loan', 'Diverse Investments', 'Hedge Fund', 'Real Estate', 'Venture Capital Fund'],
    },
};

export default stats;
