// net negative amount means it's a net capital call, net positive amount means it's a net distribution
export const calculateNetAmount = (
    callAmount = 0,
    profitDistAmount = 0,
    principalDistAmount = 0,
    recallableProfitDistAmount = 0,
    recallablePrincipalDistAmount = 0,
    pre = false
) => {
    callAmount = Math.abs(callAmount);
    profitDistAmount = Math.abs(profitDistAmount);
    principalDistAmount = Math.abs(principalDistAmount);
    recallableProfitDistAmount = Math.abs(recallableProfitDistAmount);
    recallablePrincipalDistAmount = Math.abs(recallablePrincipalDistAmount);
    if (pre) {
        if (profitDistAmount > 0 && recallableProfitDistAmount > 0) {
            profitDistAmount = profitDistAmount - recallableProfitDistAmount;
        }
        if (principalDistAmount > 0 && recallablePrincipalDistAmount > 0) {
            principalDistAmount = principalDistAmount - recallablePrincipalDistAmount;
        }
    }
    const distTotal = profitDistAmount + principalDistAmount + recallablePrincipalDistAmount + recallableProfitDistAmount;
    const callTotal = callAmount;
    // const netAmount = Math.abs(distTotal - callTotal);
    const netAmount = distTotal - callTotal;
    return netAmount;
};

export const calculateNetAmountFromTransaction = (transaction) => {
    return calculateNetAmount(
        transaction.joint_transactions?.find((t) => t.type === 'capital_call')?.amount || 0,
        transaction.joint_transactions?.find((t) => t.type === 'return_on_capital')?.amount || 0,
        transaction.joint_transactions?.find((t) => t.type === 'return_of_capital')?.amount || 0,
        transaction.joint_transactions?.find((t) => t.type === 'recallable_return_on_capital')?.amount || 0,
        transaction.joint_transactions?.find((t) => t.type === 'recallable_return_of_capital')?.amount || 0
    );
};
