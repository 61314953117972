// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

const isMobile = window.innerWidth <= 768;

export default {
    emptyText: (addInvestmentFunc) => (
        <div style={{ width: '400px', color: 'white' }}>
            Get a consolidated view of all your investments.
            <button
                style={{
                    fontSize: '18px',
                    width: '265px',
                    height: '46px',
                    marginTop: !isMobile ? '30px' : '',
                }}
                onClick={addInvestmentFunc}
            >
                Add First Investment
            </button>
        </div>
    ),
    textStyle: {
        fontSize: '24px',
    },
    containerStyle: {
        left: 0,
        width: '100%',
        height: '100%',
        top: '-40px',
    },
    props: {
        quarterly: [
            {
                quarter: 1,
                year: 2017,
                // "snapshot": { "irr": -0.04159404839371871 }
            },
            {
                quarter: 1,
                year: 2018,
                // "snapshot": { "irr": -0.17748698579438504 }
            },
            {
                quarter: 1,
                year: 2019,
                snapshot: { irr: 0.025 },
            },
            {
                quarter: 2,
                year: 2019,
                snapshot: { irr: 0.06 },
            },
            {
                quarter: 3,
                year: 2019,
                snapshot: { irr: 0.01 },
            },
            {
                quarter: 4,
                year: 2019,
                snapshot: { irr: -0.08 },
            },
            {
                quarter: 1,
                year: 2020,
                snapshot: { irr: 0.04 },
            },
            {
                quarter: 2,
                year: 2020,
                snapshot: { irr: 0.08 },
            },
            {
                quarter: 3,
                year: 2020,
                snapshot: { irr: -0.02 },
            },
            {
                quarter: 4,
                year: 2020,
                snapshot: { irr: 0.01 },
            },
            {
                quarter: 1,
                year: 2021,
                snapshot: { irr: 0.03 },
            },
        ],
        cashflow: [
            { month: 3, year: 2021, contributions: 4500, distributions: 7000, other: 800 },
            { month: 4, year: 2021, contributions: 7500, distributions: 0, other: 800 },
            { month: 5, year: 2021, contributions: 8000, distributions: 0, other: 0 },
            { month: 6, year: 2021, contributions: 2000, distributions: 14000, other: 1000 },
            { month: 7, year: 2021, contributions: 5000, distributions: 3000, other: 2000 },
            { month: 8, year: 2021, contributions: 3500, distributions: 0, other: 400 },
            { month: 9, year: 2021, contributions: 2500, distributions: 7000, other: 2000 },
            { month: 10, year: 2021, contributions: 0, distributions: 7500, other: 400 },
            { month: 11, year: 2021, contributions: 7500, distributions: 0, other: 1000 },
            { month: 12, year: 2021, contributions: 2000, distributions: 3000, other: 400 },
            { month: 1, year: 2022, contributions: 0, distributions: 7000, other: 0 },
            { month: 2, year: 2022, contributions: 2500, distributions: 9500, other: 0 },
        ],
        performance: {
            itd: {
                appreciation: 10000000,
                distributions: 700000,
            },
            ytd: {
                appreciation: 1500000,
                distributions: 500000,
            },
        },
    },
};
