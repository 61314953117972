import api from '../../../../api';
import api2 from '../../../../api2';
import { FileParseResult, FileTextResult } from '../../../../constants/file';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
import sharedFields, { isObjectId } from '../types/sharedFields';
import { accountUploadHandlerColumns, AccountUploadHandlerCreateType } from './AccountUploadHandler';
import BulkUploadHandler from './BulkUploadHandler';
import UserUploadHandler, { UserUploadHandlerConfig, UserUploadHandlerCreateType } from './UserUploadHandler';

// build the account columns
const ACCOUNT_COLUMN_KEYS: string[] = ['name', 'account_type', 'id_type', 'id_number', 'streetLine1', 'streetLine2', 'city', 'state', 'zipCode'];

const ACCOUNT_FIELD_MAP: { [key: string]: { key: string; fields: { fieldName: string } } } = {
    name: {
        key: 'account_name',
        fields: {
            fieldName: 'account_name',
        },
    },
};

const ACCOUNT_COLUMNS: { [key: string]: BulkUploadColumn } = Object.entries(accountUploadHandlerColumns)
    .filter(([key, col]) => ACCOUNT_COLUMN_KEYS.includes(key as string))
    .reduce((acc, [key, col]) => {
        if (Object.keys(ACCOUNT_FIELD_MAP).includes(key as string)) {
            const field = ACCOUNT_FIELD_MAP[key as keyof typeof ACCOUNT_FIELD_MAP];
            return {
                ...acc,
                [field.key]: {
                    ...col,
                    ...field.fields,
                    required: false,
                },
            };
        }
        return {
            ...acc,
            [key]: {
                ...col,
                required: false,
            },
        };
    }, {});

const ACCOUNT_COLUMN_NAMES = Object.values(ACCOUNT_COLUMNS).map((col) => col.fieldName);

export interface InvestorUploadHandlerConfig extends Omit<UserUploadHandlerConfig, 'access'>, Omit<AccountUploadHandlerCreateType, 'user'> {
    advisors?: string;
}

export interface InvestorUploadHandlerCreateType extends Omit<UserUploadHandlerCreateType, 'access'> {
    advisors?: string;
}

class InvestorUploadHandler extends BulkUploadHandler<InvestorUploadHandlerCreateType, InvestorUploadHandlerConfig> {
    // The type of the bulk upload, eg. 'user' or 'valuation'
    type = BulkUploadType.investor;

    // instance of UserUploadHandler to use base functions
    userUploadHandlerInstance = new UserUploadHandler({
        access: 'investor',
    });

    // The base columns that are required for the bulk upload
    base_columns = {
        name: this.userUploadHandlerInstance.base_columns.name,
        email: this.userUploadHandlerInstance.base_columns.email,
        password: this.userUploadHandlerInstance.base_columns.password,
        advisors: {
            ...sharedFields.string,
            displayName: 'Advisors',
            fieldName: 'advisors',
            isValid: (val: string) => {
                const split = val
                    .split('/')
                    .map((v) => v.trim())
                    .filter((v) => v);
                for (let i = 0; i < split.length; i++) {
                    if (!isObjectId(split[i])) {
                        return false;
                    }
                }
                return true;
            },
            format: (val: string) => {
                return val
                    .split('/')
                    .map((v) => v.trim())
                    .filter((v) => v)
                    .join('/');
            },
            style: { width: '250px' },
            required: false,
        },
        ...ACCOUNT_COLUMNS,
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof InvestorUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (columnOrder: (keyof InvestorUploadHandlerCreateType)[] = this.columnOrder): { [key in keyof InvestorUploadHandlerCreateType]: BulkUploadColumn } => {
        // Sort the columns based on the given column order or the default order.
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);
        delete sortedColumns.access;
        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof InvestorUploadHandlerCreateType]: string }, columnOrder?: (keyof InvestorUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        // any additional validation goes here
        return isDataValid;
    };

    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @returns {Object} An object with the correct fields for the line.
     */
    parseSingleCsvLine = (line: string, columnOrder?: (keyof InvestorUploadHandlerCreateType)[]): InvestorUploadHandlerCreateType => {
        // if not enough commas are included, the fields will be empty strings
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        const accountData = Object.entries(parsedValues)
            .filter(([key, value]) => ACCOUNT_COLUMN_NAMES.includes(key) && value)
            .reduce(
                (acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                },
                {} as { [key: string]: any }
            );

        // set up special conditions based on the config and data type
        return {
            name: parsedValues.name,
            email: parsedValues.email,
            password: parsedValues.password,

            // Only include the advisors if it's provided and valid.
            ...(parsedValues.advisors
                ? {
                      advisors: expectedColumns.advisors && expectedColumns.advisors.isValid(parsedValues.advisors) ? expectedColumns.advisors.format(parsedValues.advisors) : '',
                  }
                : {}),

            ...accountData,
        };
    };

    // Parse the text file results into a FileParseResult
    parseTextFileResult = async (
        textFileResult: FileTextResult,
        columnOrder?: (keyof InvestorUploadHandlerCreateType)[]
    ): Promise<FileParseResult<InvestorUploadHandlerCreateType>> => {
        try {
            const data = textFileResult.lines.map((line) => this.parseSingleCsvLine(line, columnOrder));
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<InvestorUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<InvestorUploadHandlerCreateType>;
        }
    };

    // Get the notes for the bulk upload type
    getNotes = (): string[] => {
        let notes: string[] = ['The advisors column is a list of advisor IDs separated by slashes. For example, "5f7b1b7b4b3e4b001f4b3e4b/5f7b1b7b4b3e4b001f4b3e4b"'];
        return notes;
    };

    // function to create the object in the database from the parsed data
    create = async (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof InvestorUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        // Ensure the data is valid
        if (!this.isDataValid(data, columnOrder)) {
            return { success: false, message: 'Invalid data' };
        }

        let access = 'investor';

        let advisors = [];
        if (data.advisors) {
            advisors = data.advisors
                .split('/')
                .map((advisorId: string) => advisorId.trim())
                .filter((advisorId: string) => advisorId);
        }

        // gather the account data
        const addressData = {
            ...(data.streetLine1 ? { streetLine1: data.streetLine1 } : {}),
            ...(data.streetLine2 ? { streetLine2: data.streetLine2 } : {}),
            ...(data.city ? { city: data.city } : {}),
            ...(data.state ? { state: data.state } : {}),
            ...(data.zipCode ? { zipCode: data.zipCode } : {}),
        };

        const hasAddressData = Object.values(addressData).some((value) => value);

        const accountBody: { [key: string]: any } = {
            ...(data.account_name ? { name: data.account_name } : {}),
            ...(data.account_type ? { account_type: data.account_type } : {}),
            ...(data.id_type ? { id_type: data.id_type } : {}),
            ...(data.id_number ? { id_number: data.id_number } : {}),
            ...(hasAddressData ? { address: addressData } : {}),
        };

        const hasAccountData = Object.values(accountBody).some((value) => value);

        const userBody = {
            name: data.name,
            email: data.email,
            password: data.password,
            access,
            advisors,
            ...(hasAccountData ? { disable_initial_account_creation: true } : {}),
        };

        try {
            // Attempt to create a single user from the current object.
            // if account data is provided, the account will not be created automatically
            const createRes = await api.post('/users', userBody, {}, true);

            const created_user_id = createRes.user?._id;

            if (createRes.success && created_user_id) {
                try {
                    if (hasAccountData) {
                        const account_create_res = await api2.client.AccountApi.createAccount({
                            CreateAccountRequest: {
                                user: created_user_id,
                                name: accountBody.name || userBody.name,
                                ...accountBody,
                            },
                        });

                        if (!account_create_res?.data.success) {
                            throw new Error('Error creating account');
                        }
                    }
                } catch (err) {
                    // delete the user if the account update fails
                    await this.delete(created_user_id);
                    return {
                        success: false,
                        message: `Error creating Investor Account.`,
                    };
                }
            } else {
                return {
                    success: false,
                    message: `Error creating Investor.`,
                };
            }

            return {
                success: true,
                message: 'Investor created successfully',
                id: createRes.user?._id,
            };
        } catch (err) {
            // Log and return null in case of an error.
            console.log('error creating user:', err);
            return {
                success: false,
                message: `Error creating user: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            await api.delete(`/users/${id}`, {}, true);
            return {
                success: true,
                message: 'User deleted successfully',
            };
        } catch (err) {
            console.log('error deleting user:', err);
            return {
                success: false,
                message: `Error deleting user: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the InvestorUploadHandler
export const getInvestorUploadHandler: (config: InvestorUploadHandlerConfig) => InvestorUploadHandler = (config: InvestorUploadHandlerConfig) => new InvestorUploadHandler(config);

export default InvestorUploadHandler;
