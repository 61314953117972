import React, { useEffect, useState, useRef } from 'react';
import '../../styles/DocumentViewerMenu.css';

const MENU_WIDTH = 200;
const ITEM_HEIGHT = 40;

const DocumentViewerMenu = (props) => {
    const [menuItems2, setmenuItems2] = useState({});
    const [menuItems3, setMenuItems3] = useState({});

    let containerRef = useRef(null);

    const { top, left, items, onHide } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                onHide?.();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMouseEnterItem = (item, index, e, menuIdx, verticalOffsetCount) => {
        if (item.children) {
            if (menuIdx === 0) {
                setmenuItems2({
                    items: item.children,
                    top: top,
                    left: left,
                    menuIdx: menuIdx + 1,
                    verticalOffsetCount: index + verticalOffsetCount,
                    parentIdx: index,
                });
            } else if (menuIdx === 1) {
                setMenuItems3({
                    items: item.children,
                    top: top,
                    left: left,
                    menuIdx: menuIdx + 2,
                    verticalOffsetCount: index + verticalOffsetCount,
                    parentIdx: index,
                });
            }
        } else {
            if (menuIdx === 0) {
                setmenuItems2({});
            } else if (menuIdx === 1) {
                setMenuItems3({});
            }
        }
    };

    const handleMouseLeaveContainer = () => {
        setmenuItems2({});
        setMenuItems3({});
    };

    const renderSingleMenu = (itemsList, top = 0, left = 0, menuIdx = 0, verticalOffsetCount = 0, parentIdx = -1) => {
        const padding = 0;
        let adjTop = top + verticalOffsetCount * (ITEM_HEIGHT + padding * 2);

        let verticalOffset = 0;

        // get container top
        const containerRect = containerRef?.current?.getBoundingClientRect();
        if (containerRect) {
            // get menu height from item count
            const menuHeight = itemsList.length * ITEM_HEIGHT;
            // get menu bottom
            const menuBottom = adjTop + menuHeight + containerRect.top;
            // get container bottom
            const containerBottom = containerRect.bottom;
            // if menu bottom is greater than container bottom, adjust top
            if (menuBottom > containerBottom) {
                verticalOffset = containerBottom - menuBottom;
            }
        }

        return (
            <div
                style={{
                    position: 'absolute',
                    top: adjTop + verticalOffset,
                    left: left + menuIdx * MENU_WIDTH,
                    backgroundColor: 'white',
                    border: '1px solid gray',
                    zIndex: 1000,
                    width: MENU_WIDTH,
                }}
                className="docViewMenu-menu"
            >
                {itemsList?.map((item, index) => (
                    <div
                        key={index}
                        onMouseEnter={(e) => handleMouseEnterItem(item, index, e, menuIdx, verticalOffsetCount)}
                        onClick={(e) => {
                            if (!item.children) {
                                item.callback?.(items, index, e);
                                onHide?.();
                            }
                        }}
                        style={{ cursor: 'pointer', height: ITEM_HEIGHT }}
                        className={`docViewMenu-item ${index === parentIdx ? 'docViewMenu-item-selected' : ''}`}
                    >
                        {item.name}
                        {item.children && <span style={{ float: 'right' }}>{'>'}</span>}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="docViewMenu-container" onMouseLeave={handleMouseLeaveContainer} ref={containerRef}>
            {renderSingleMenu(items, top, left, 0, 0, menuItems2?.parentIdx)}
            {menuItems2?.items?.length > 0 && renderSingleMenu(menuItems2.items, menuItems2.top, menuItems2.left, 1, menuItems2.verticalOffsetCount, menuItems3?.parentIdx)}
            {menuItems3?.items?.length > 0 && renderSingleMenu(menuItems3.items, menuItems3.top, menuItems3.left, 2, menuItems3.verticalOffsetCount, -1)}
        </div>
    );
};

export default DocumentViewerMenu;
