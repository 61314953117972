const delayMillis = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const waitFor = async (condition, timeout = 5000, pollTime = 100, msg = 'waitFor() Timeout Error') => {
    const start = Date.now();
    while (!condition() && Date.now() - start < timeout) {
        await delayMillis(pollTime);
    }
    if (Date.now() - start >= timeout) {
        throw new Error(msg);
    }
    return condition();
};

export default delayMillis;
