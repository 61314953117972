import React, { Component } from 'react';

import { DEFAULT_HIGHLIGHT_COLOR } from './helpers';

import '../../styles/DocumentCanvas.css';

class Canvas extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    componentDidMount() {
        this.updateCanvas();
    }

    componentDidUpdate(prevProps) {
        this.updateCanvas(prevProps);
    }

    updateCanvas(prevProps) {
        const docContainer = document.querySelector('.docContainer'); // get document container to scroll to focused search result
        const page = document.getElementById(`pageImage_${this.props.pageNumber}`);
        const canvas = this.canvasRef.current;
        // set canvas size
        canvas.style.width = `${page.getBoundingClientRect().width}px`;
        canvas.style.height = `${page.getBoundingClientRect().height}px`;
        canvas.width = page.getBoundingClientRect().width;
        canvas.height = page.getBoundingClientRect().height;
        canvas.style.backgroundColor = 'rgba(0,0,0,0.0)';

        const context = canvas.getContext('2d');

        // clear canvas
        context.fillStyle = 'rgba(0,193,239,0.4)';
        context.clearRect(0, 0, canvas.width, canvas.height);

        // selected words
        this.props.words?.forEach((word) => {
            if (!word.selected) return;
            context.fillRect(canvas.width * word.x, canvas.height * word.y, canvas.width * word.width, canvas.height * word.height);
        });

        // highlighted words
        this.props.words?.forEach((word) => {
            if (!word.highlighted) return;
            context.fillStyle = word.highlightedColor ?? DEFAULT_HIGHLIGHT_COLOR;
            const pad = 5;
            context.fillRect(canvas.width * word.x - pad, canvas.height * word.y - pad, canvas.width * word.width + pad * 2, canvas.height * word.height + pad * 2);
        });

        // highlight search results
        this.props.searchResults[this.props.pageNumber]?.forEach((result, resultIndex) => {
            for (let i = result.matchStartIndex; i <= result.matchEndIndex; i++) {
                const prevResult = prevProps?.searchResults[this.props.pageNumber]?.[resultIndex];
                const word = this.props.words[i];
                context.fillStyle = result.focus ? 'rgba(255,139,72,0.4)' : 'rgba(0,193,239,0.4)';
                context.fillRect(canvas.width * word.x, canvas.height * word.y, canvas.width * word.width, canvas.height * word.height);

                // scroll to focused search result if it is newly focused
                if (result.focus && result.focus && prevResult && !prevResult?.focus) {
                    // window.scrollTo(0, document.getElementById(this.props.pageNumber).offsetTop + canvas.height * word.y / 2);
                    docContainer.scrollTo(0, document.getElementById(`docViewer_page${this.props.pageNumber}`).offsetTop + (canvas.height * word.y) / 2);
                }
            }
        });
    }

    render() {
        return (
            <canvas
                className="Canvas"
                ref={this.canvasRef}
                onContextMenu={(e) => {
                    e.preventDefault();
                    // e.stopPropagation()
                    this.props.onContextMenu?.(
                        e,
                        'canvas',
                        this.props.pageNumber,
                        this.props.words,
                        this.props.searchResults[this.props.pageNumber],
                        this.props.document,
                        this.props.documentSearchParams,
                        this.props.documentSearchResults,
                        this.props.documentSearchFocus
                    );
                }}
            />
        );
    }
}

export default Canvas;
