// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import formatCurrency from '../../../utilities/format/formatCurrency';

import '../../../styles/widget.css';
import '../../../styles/widget-InvestmentDetailsData.css';
import { getFieldsByType, getInvestmentSubtypeFields, getInvestmentSubtypes } from '../../../pages/Account/AddManualInvestmentStages/investmentTypeConstants';
import formatDate, { formatUTCDate } from '../../../utilities/format/formatDate';
import _ from 'lodash';
import Dropdown from '../../Dropdowns/Dropdown';
import { DrawerInputObject } from '../../DrawerComponents/DrawerInputObject';
import api from '../../../api';
import { setDismissableAlert } from '../../../utilities/alert/setDismissableAlert';
import dateToUTCMidnight from '../../../utilities/date/dateToUTCMidnight';
import { UserPermissions } from '../../../utilities/AdvisorVue/permissions';
import { getInvestmentDate } from '../../../common/investments/getInvestmentDate';
import api2 from '../../../api2';
import MostRecentValuation from '../MostRecentValuation';

const DEFAULT_OPTION_STRING = 'Add a field';
const SELECT_SUBTYPE_MSG = 'Select subtype to add fields';

class InvestmentDetailsData extends Component {
    state = {
        investment_data: null,
        editMode: false,
        dataUpdate: null,
    };

    componentDidMount = async () => {
        const investment_data = this.props.investment?.investment_data;
        this.setState({ investment_data });
    };

    componentDidUpdate = async (prev) => {
        if (!_.isEqual(this.props.investment, prev.investment)) {
            const investment_data = this.props.investment?.investment_data;
            this.setState({ investment_data });
        }
    };

    toggleEditMode = () => {
        const newState = {
            editMode: !this.state.editMode,
        };

        newState.dataUpdate = newState.editMode
            ? {
                  ...this.state.investment_data,
              }
            : null;

        this.setState(newState, () => {
            const _ = this.props.setEditMode ? this.props.setEditMode(this.state.editMode) : null;
        });
    };

    setDataUpdateField = (fieldName, value) => {
        this.setState({ dataUpdate: { ...this.state.dataUpdate, [fieldName]: value } });
    };

    renderTable = (data) => {
        return (
            <table style={this.props.singleColumn ? { width: '100%' } : { width: '45%' }}>
                <tbody>
                    {data &&
                        data.map((datum, dataIdx) => {
                            return (
                                <tr key={'perfSummary' + dataIdx}>
                                    <td style={{ display: 'flex', alignItems: 'center' }}>
                                        {datum.label}
                                        {datum.icon && <img className="investment-investment-details-icon" src={datum.icon} alt="table icon" onClick={datum.onIconClick ?? null} />}
                                    </td>
                                    <td style={{ fontSize: '18px', fontWeight: 600 }}>
                                        {!this.state.editMode || !datum.editable ? (
                                            datum.displayValue
                                        ) : (
                                            <DrawerInputObject
                                                object={datum}
                                                onChangeCallback={(value) => {
                                                    this.setDataUpdateField(datum.fieldName, value);
                                                }}
                                                value={this.state.dataUpdate[datum.fieldName]}
                                                // errors={errors}
                                                // notateRequired={notateRequired}
                                                // style={{height: '20px'}}
                                            />
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
        );
    };

    getFields = () => {
        return getFieldsByType(this.props.investment.type, this.state.dataUpdate?.type ?? this.state.investment_data?.type);
    };

    getData = () => {
        const p = this.props;
        let data = this.state.dataUpdate ?? this.state.investment_data ?? {};
        let res = {};
        if (!data) return res;
        const excludeList = ['type', 'website', 'location'];
        let fields = this.getFields();
        data = Object.keys(data)
            .filter((k) => data[k] !== null && !excludeList.includes(k))
            .concat(this.state.editMode && this.state.investment_data ? ['website', 'location'] : [])
            .forEach((k) => {
                const field = fields[k];
                if (field) {
                    res[k] = {
                        ...field,
                        label: !field ? k : field.label,
                        displayValue: !field
                            ? data[k]
                            : field.type === 'date'
                              ? formatUTCDate(data[k])
                              : field.type === 'currency'
                                ? formatCurrency(data[k], p.investment?.currency)
                                : field.type === 'percent'
                                  ? `${data[k]}%`
                                  : data[k],
                        value: data[k],
                        editable: true,
                        hideLabel: true,
                        // editable: true,
                        // type: field ? (field.type === 'date' ? 'dateCalendar' : field.type) : "text",
                        type: field.type === 'date' ? 'dateCalendar' : field.type,
                    };
                }
            });
        // remove
        if (this.state.editMode) {
        }
        return res;
    };

    getUnusedFields = () => {
        const EXCLUDED_FIELDS = [
            'name',
            'amountInvested',
            'amountInvestedDate',
            'currentValue',
            'currentValueDate',
            'distributions',
            'distributionsDate',
            'managementFees',
            'managementFeesDate',
        ];
        if (!this.state.investment_data) return [];
        const usedFieldKeys = Object.keys(this.state.investment_data).filter((k) => this.state.investment_data[k] !== null);
        let fields = this.getFields();
        Object.keys(fields).forEach((k) => {
            if (usedFieldKeys.includes(k) || EXCLUDED_FIELDS.includes(k)) {
                delete fields[k];
            }
        });
        delete fields['type'];
        delete fields['committed'];
        delete fields['committedDate'];
        return fields;
    };

    getSingleColumnData = (data) => {
        const p = this.props;
        const exclude = p.excludeFields ?? [];
        const res = Object.values(data);
        const investmentDate = getInvestmentDate(p.investment);
        if (!exclude.includes('fees') && p.investment.performance?.itd?.fees) {
            res.unshift({
                label: 'Fees',
                value: Math.abs(p.investment.performance?.itd?.fees),
                displayValue: formatCurrency(Math.abs(p.investment.performance?.itd?.fees), p.investment?.currency),
            });
        }
        if (!exclude.includes('distributions')) {
            res.unshift({
                label: 'Distributions (Realized)',
                value: p.investment.performance?.itd?.distributions,
                displayValue: formatCurrency(p.investment.performance?.itd?.distributions, p.investment?.currency),
            });
        }
        if (!exclude.includes('currentValue')) {
            res.unshift({
                label: 'Current Value (Unrealized)',
                value: p.investment.valuation,
                displayValue: formatCurrency(p.investment.valuation, p.investment?.currency),
                icon: '/images/icons/edit.png',
                onIconClick: () => this.props.openAddEditUnrealizedValueDrawer(p.investment, p.investment?.currency),
            });
        }
        if (!exclude.includes('mostRecentValuation')) {
            res.unshift({
                label: 'Last Reported Valuation',
                value: null,
                displayValue: <MostRecentValuation investmentId={p.investment._id} currency={p.investment?.currency} />,
            });
        }
        if (!exclude.includes('amountInvested')) {
            res.unshift({
                label: 'Amount Invested',
                value: p.investment.performance?.itd?.contributions,
                displayValue: formatCurrency(p.investment.performance?.itd?.contributions, p.investment?.currency),
            });
        }
        if (!exclude.includes('investmentDate')) {
            res.unshift({
                label: 'Investment Date',
                value: dateToUTCMidnight(investmentDate),
                displayValue: formatUTCDate(investmentDate),
                fieldName: 'started_at',
            });
        }
        return res;
    };

    getSplitData = (data) => {
        const p = this.props;
        const exclude = p.excludeFields ?? [];
        const leftData = Object.values(data).filter((_, i) => i % 2 === 1); //.reduce((res, [k, v]) => (res[k] = v, res), {})
        const investmentDate = getInvestmentDate(p.investment);
        // add fees, current value, and amount invested
        if (!exclude.includes('fees') && p.investment.performance?.itd?.fees) {
            leftData.unshift({
                label: 'Fees',
                value: Math.abs(p.investment.performance?.itd?.fees),
                displayValue: formatCurrency(Math.abs(p.investment.performance?.itd?.fees), p.investment?.currency),
            });
        }
        if (!exclude.includes('currentValue')) {
            leftData.unshift({
                label: 'Current Value (Unrealized)',
                value: p.investment.valuation,
                displayValue: formatCurrency(p.investment.valuation, p.investment?.currency),
                icon: !UserPermissions().canUpdateAnyInvestments ? null : '/images/icons/edit.png',
                onIconClick: !UserPermissions().canUpdateAnyInvestments ? null : () => this.props.openAddEditUnrealizedValueDrawer(p.investment),
            });
        }
        if (!exclude.includes('mostRecentValuation')) {
            leftData.unshift({
                label: 'Last Reported Valuation',
                value: null,
                displayValue: <MostRecentValuation investmentId={p.investment._id} currency={p.investment?.currency} />,
            });
        }
        if (!exclude.includes('amountInvested')) {
            leftData.unshift({
                label: 'Amount Invested',
                value: p.investment.performance?.itd?.contributions,
                displayValue: formatCurrency(p.investment.performance?.itd?.contributions, p.investment?.currency),
            });
        }

        const rightData = Object.values(data).filter((_, i) => i % 2 === 0); //.reduce((res, [k, v]) => (res[k] = v, res), {})
        // add distributions and investment date
        if (!exclude.includes('distributions')) {
            rightData.unshift({
                label: 'Distributions (Realized)',
                value: p.investment.performance?.itd?.distributions,
                displayValue: formatCurrency(p.investment.performance?.itd?.distributions, p.investment?.currency),
            });
        }
        if (!exclude.includes('investmentDate')) {
            rightData.unshift({
                label: 'Investment Date',
                value: dateToUTCMidnight(investmentDate),
                displayValue: formatDate(investmentDate),
                fieldName: 'started_at',
            });
        }
        return [leftData, rightData];
    };

    addField = (field) => {
        if (field === DEFAULT_OPTION_STRING) return;
        const unusedFields = this.getUnusedFields();
        const fieldToAdd = unusedFields[field.fieldName];
        this.setDataUpdateField(field.fieldName, fieldToAdd.type === 'date' ? dateToUTCMidnight() : fieldToAdd.type === 'option' ? fieldToAdd.options[0] : '');
    };

    renderUnusedFieldsDropdown = () => {
        const unusedFields = Object.values(this.getUnusedFields());
        if (unusedFields.length === 0) return null;
        return (
            <Dropdown
                options={unusedFields}
                optionField="label"
                select={(field) => this.addField(field)}
                style={{ width: '200px' }}
                defaultOptionString={DEFAULT_OPTION_STRING}
                selection={{ label: DEFAULT_OPTION_STRING }}
            />
        );
    };

    renderSubtype = () => {
        const subtype = this.state.investment_data?.type ?? this.state.dataUpdate?.type;
        if (!this.state.editMode && !subtype) return null;

        const subtypeList = getInvestmentSubtypes(this.props.investment.type);

        if (!this.state.editMode) {
            return <span className="investment-details-header-subtype">&nbsp;&nbsp;|&nbsp;&nbsp;{subtype}</span>;
        } else if (subtypeList.length > 0) {
            return (
                <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                    <Dropdown
                        options={subtypeList}
                        select={(newSubtype) => {
                            const oldSubtypeFields = getInvestmentSubtypeFields(this.props.investment.type, subtype);
                            const newSubtypeFields = getInvestmentSubtypeFields(this.props.investment.type, newSubtype);
                            const dataUpdate = { ...this.state.dataUpdate };
                            // delete old subtype fields
                            oldSubtypeFields.forEach((f) => {
                                if (!newSubtypeFields.map((x) => x.fieldName).includes(f.fieldName)) {
                                    delete dataUpdate[f.fieldName];
                                }
                            });
                            // dataUpdate.type = newSubtype
                            this.setState({ dataUpdate, investment_data: dataUpdate }, () => this.setDataUpdateField('type', newSubtype));
                        }}
                        style={{ width: '180px' }}
                        defaultOptionString={subtype}
                        selection={this.state.dataUpdate.type}
                    />
                    {!this.state.dataUpdate.type && !this.props.singleColumn && <div style={{ marginLeft: '10px', color: 'red' }}>{SELECT_SUBTYPE_MSG}</div>}
                </div>
            );
        }
    };

    saveChanges = async () => {
        let update = {};
        let investment_data = { ...this.state.dataUpdate };

        // remove empty values
        Object.keys(investment_data).forEach((k) => {
            if (!investment_data[k]) {
                delete investment_data[k];
            }
        });

        update.investment_data = investment_data;

        const investmentId = this.props.investment._id;

        this.props.loading(320, 'saveChanges');
        try {
            await api2.client.InvestmentApi.updateInvestment({
                investment_id: investmentId,
                UpdateInvestmentRequest: update,
            });
            const investment = (
                await api2.client.InvestmentApi.getInvestment({
                    investment_id: investmentId,
                })
            ).data.investment;
            this.setState({ editMode: false, investment_data: { ...investment.investment_data }, dataUpdate: { ...investment.investment_data } });
            this.props.reloadData();
            setDismissableAlert(this.props.setAlert, 'Changes saved');
        } catch (err) {
            setDismissableAlert(this.props.setAlert, 'Error saving changes', true);
        }
        this.props.loaded('saveChanges');
    };

    renderEditControls = () => {
        const s = this.state;
        const p = this.props;
        if (this.props.singleColumn) {
            if (!s.editMode) {
                return (
                    <div style={{ position: 'absolute', right: '20px', display: 'flex', alignItems: 'center' }}>
                        <div
                            className="edit-icon"
                            style={{ marginLeft: '20px', padding: '8px 11px' }}
                            onClick={this.toggleEditMode}
                            title={s.editMode ? 'Turn Edit Off' : 'Edit'}
                        />
                    </div>
                );
            } else {
                return (
                    <>
                        <div style={{ clear: 'both', position: 'relative', height: '50px' }} />
                        <div
                            style={{
                                position: 'absolute',
                                left: '0px',
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '140px',
                                justifyContent: 'center',
                                width: '100%',
                                paddingTop: '30px',
                                paddingBottom: '30px',
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ display: 'flex' }}>
                                    <div className="a" onClick={this.toggleEditMode} style={{ color: 'var(--color-dark-red)', marginLeft: '20px' }}>
                                        Cancel
                                    </div>
                                    <div className="a" onClick={() => this.saveChanges()} style={{ marginLeft: '20px' }}>
                                        Save Changes
                                    </div>
                                </div>
                                <div style={{ display: 'flex', marginLeft: '20px' }}>{this.renderUnusedFieldsDropdown()}</div>
                            </div>
                            {!this.state.dataUpdate.type && <div style={{ marginLeft: '10px', color: 'red' }}>{SELECT_SUBTYPE_MSG}</div>}
                        </div>
                    </>
                );
            }
        } else {
            return (
                <div style={{ position: 'absolute', right: '20px', display: 'flex', alignItems: 'center' }}>
                    {s.editMode && <div style={{ display: 'flex' }}>{this.renderUnusedFieldsDropdown()}</div>}
                    {!s.editMode ? (
                        <div
                            className="edit-icon"
                            style={{ marginLeft: '20px', padding: '8px 11px' }}
                            onClick={this.toggleEditMode}
                            title={s.editMode ? 'Turn Edit Off' : 'Edit'}
                        />
                    ) : (
                        <div style={{ display: 'flex' }}>
                            <div className="a" onClick={() => this.saveChanges()} style={{ marginLeft: '20px' }}>
                                Save Changes
                            </div>
                            <div className="a" onClick={this.toggleEditMode} style={{ color: 'var(--color-dark-red)', marginLeft: '20px' }}>
                                Cancel
                            </div>
                        </div>
                    )}
                </div>
            );
        }
    };

    getEditControlsStyle = () => ({ marginTop: this.props.singleColumn ? '90px' : null });

    render = () => {
        // if (!this.state.investment_data) return <></>
        const s = this.state;
        const p = this.props;
        const data = this.getData();
        if (!data) return <>No Data</>;
        let leftData = [];
        let rightData = [];

        if (!p.singleColumn) {
            [leftData, rightData] = this.getSplitData(data);
        } else {
            leftData = this.getSingleColumnData(data);
        }

        return (
            <div className="widget investment-details-component">
                <div className="investment-details-header">
                    <div className="investment-details-header-title">Investment Details</div>
                    {this.renderSubtype()}
                    {UserPermissions().canUpdateAnyInvestments && this.renderEditControls()}
                </div>
                <div className={'investment-investment-details-tables'} style={{ marginTop: p.singleColumn && s.editMode ? '80px' : null }}>
                    {this.renderTable(leftData)}
                    {!p.singleColumn && this.renderTable(rightData)}
                </div>
            </div>
        );
    };
}

export default withRouter(InvestmentDetailsData);
