import { DocumentSigningRemoteOptions, RALCommonStatus } from '../openApiClient';

export interface RALRequestUser {
    _id: string;
    name: string;
}

export interface RALRequestOwnership {
    _id: string;
    name: string;
    type: string;
    currency: string;
    user: string | RALRequestUser | null;
    account: string | { _id: string; name: string } | null;
}

export interface RALRequestSignedRal {
    _id: string;
    name: string;
    status: string;
}

export interface RALRequestSignatory {
    first_name: string;
    last_name: string;
    role: string;
    email: string;
}

export interface RALRequestTokens {
    [key: string]: string;
}

export interface RALRequestRalTemplate {
    _id: string;
    name: string;
}

export const PANDA_RAL_STATUSES_WITH_URL = [RALCommonStatus.Sent, RALCommonStatus.Viewed, RALCommonStatus.Completed, RALCommonStatus.Declined];

export interface RALRequest {
    _id: string;
    user: string | RALRequestUser | null;
    ownership_ids: (string | RALRequestOwnership | null)[];
    ownership_type: string;
    signed_ral: string | RALRequestSignedRal | null;
    signatories: RALRequestSignatory[] | null;
    tokens: RALRequestTokens;
    ral_template: string | RALRequestRalTemplate | null;
    remote_id: string;
    remote_status: RALCommonStatus;
    remote_type?: DocumentSigningRemoteOptions;
    sent_at: string | null; // Assuming date-time string
    signed_at: string | null; // Assuming date-time string
    created_at: string; // Assuming date-time string
    updated_at: string; // Assuming date-time string
    deleted_at: string | null; // Assuming date-time string
}
