import React from 'react';

export const setDismissableAlert = (setAlertFn: any, message: string | any = '', isError: boolean = false, timeout: number | null = null, alertId: string | null = null) => {
    if (!setAlertFn) return;
    const dismiss = () => setAlertFn(null);
    if (message === null) {
        dismiss();
        return;
    }
    setAlertFn(
        <>
            <span>{message}</span>
            <button style={{ color: isError ? 'var(--color-dark-red)' : '' }} className="alert-button" onClick={() => dismiss()}>
                Dismiss
            </button>
        </>,
        alertId,
        isError
    );
    setTimeout(() => {
        dismiss();
    }, timeout ?? 5000);
};
