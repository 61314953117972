import { AccountSearchFilter, InvestmentSearchFilter } from '../../../components/Filters/GenericSearchFilter';
import { DocumentOwnershipType } from '../../../constants/documentConstants';
import { Account } from '../../../types/Account';
import { Investment } from '../../../types/Investment';
import { capitalizeFirst } from '../../../utilities/format/capitalizeFirst';

export const getDocumentOwnershipFields = ({
    userId,
    ownershipType,
    onChangeOwnershipType,
    ownershipIdObjects,
    onChangeOwnershipIdObjects,
    includeDisplayFields,
}: any & {
    includeDisplayFields?: {
        investment?: (keyof Investment)[];
        account?: (keyof Account)[];
    };
}) => {
    const fields: any = {
        OwnershipType: {
            placeholder: 'Select Ownership Type',
            label: 'Ownership Type',
            fieldName: 'ownership_type',
            type: 'select',
            defaultOptionString: 'User',
            options: Object.values(DocumentOwnershipType),
            optionClearable: true,
            callback: (value: DocumentOwnershipType) => {
                if (value === ownershipType) return;
                onChangeOwnershipType(value);
                // clear ownership ids when ownership type changes
                onChangeOwnershipIdObjects(null);
            },
        },
    };

    if (ownershipType) {
        if (ownershipType === DocumentOwnershipType.account) {
        } else if (ownershipType === DocumentOwnershipType.investment) {
            delete fields.Entity;
        }
        const label = `${capitalizeFirst(ownershipType)}s`;

        const ownershipFilter: any = {};
        if (userId) {
            ownershipFilter.users = [userId];
        }

        const ownership_ids: string[] = ownershipIdObjects?.map((idObj: any) => idObj._id) ?? [];

        fields['OwnershipObjects'] = {
            placeholder: `Select ${label}`,
            label: `${label}`,
            fieldName: 'ownership_ids',
            type: 'custom',
            component: () => {
                return ownershipType === DocumentOwnershipType.account ? (
                    <AccountSearchFilter
                        filter={ownershipFilter}
                        selected={ownershipIdObjects ?? []}
                        onChange={(newOwnershipIdObjects: any) => {
                            onChangeOwnershipIdObjects(newOwnershipIdObjects);
                        }}
                        defaultOptions={!!userId}
                        width={'100%'}
                        isMulti={true}
                        postProcessData={async (results: any) => results.filter((result: any) => !ownership_ids.includes(result._id))}
                    />
                ) : (
                    <InvestmentSearchFilter
                        filter={ownershipFilter}
                        selected={ownershipIdObjects ?? []}
                        onChange={(newOwnershipIdObjects: any) => {
                            onChangeOwnershipIdObjects(newOwnershipIdObjects);
                        }}
                        showInvestmentLink={true}
                        defaultOptions={!!userId}
                        width={'100%'}
                        isMulti={true}
                        includeDisplayFields={includeDisplayFields?.investment}
                        postProcessData={async (results: any) => results.filter((result: any) => !ownership_ids.includes(result._id))}
                    />
                );
            },
        };
    }

    return fields;
};
