const ALL = 'ALL';

// constant common strings so we don't have to type them out every time
const S = {
    name: 'name',
    committed: 'committed',
    committedDate: 'committedDate',
    amountInvested: 'amountInvested',
    amountInvestedDate: 'amountInvestedDate',
    currentValue: 'currentValue',
    currentValueDate: 'currentValueDate',
    distributions: 'distributions',
    distributionsDate: 'distributionsDate',
    managementFees: 'managementFees',
    managementFeesDate: 'managementFeesDate',

    percentOwnership: 'percentOwnership',
    sharePrice: 'sharePrice',
    sharesUnitsTokens: 'sharesUnitsTokens',
    options: 'options',
    optionsPrice: 'optionsPrice',
    website: 'website',
    location: 'location',
    interestFrequency: 'interestFrequency',
    valuationCap: 'valuationCap',
    discountRate: 'discountRate',
    annualInterestRate: 'annualInterestRate',
    termOfLoan: 'termOfLoan',
    maturityDate: 'maturityDate',

    contraAccountNumber: 'contraAccountNumber',

    date: 'date',
    currency: 'currency',
    number: 'number',
    percent: 'percent',
    text: 'text',
    option: 'option',

    // investment types
    privateCompanyStartup: 'Private Company / Startup',
    realEstate: 'Real Estate',
    realAssetsDirectOwned: 'Real Assets (Direct Owned)',
    collectible: 'Collectible',
    crypto: 'Crypto',
    bondLoan: 'Bond / Loan',

    hedgeFund: 'Hedge Fund',

    // fund types
    privateEquityFund: 'Private Equity Fund',
    privateCreditFund: 'Private Credit Fund',
    ventureCapitalFund: 'Venture Capital Fund',
    fundOfFunds: 'Fund of Funds',
    secondaryFund: 'Secondary Fund',
    realEstateFund: 'Real Estate Fund',
    coinvestmentFund: 'Coinvestment Fund',
    realAssetsFund: 'Real Assets Fund',
};

// investment groups like "fund" and "hedgeFund", used to decide which page to show
export const INVESTMENT_TYPE_GROUPINGS = {
    fund: [S.privateEquityFund, S.privateCreditFund, S.ventureCapitalFund, S.fundOfFunds, S.secondaryFund, S.realEstateFund, S.coinvestmentFund, S.realAssetsFund],
    hedgeFund: [S.hedgeFund],
};

// represents display order on manual investment type choosing page
export const INVESTMENT_TYPES_DISPLAY_ARRANGEMENT = [
    // // 3 per row (3 wide)
    [S.privateEquityFund, S.privateCreditFund, S.ventureCapitalFund],
    [S.fundOfFunds, S.secondaryFund, S.realEstateFund],
    [S.coinvestmentFund, null, S.realAssetsFund],
    [],
    [null, S.hedgeFund, null],
    [],
    [S.privateCompanyStartup, S.bondLoan, S.collectible],
    [S.crypto, S.realEstate, S.realAssetsDirectOwned],
];

// const NON_EDITABLE_FIELDS = [
//     S.name,
//     S.committed,
//     S.committedDate,
//     S.amountInvested,
//     S.amountInvestedDate,
//     S.currentValue,
//     S.currentValueDate,
//     S.distributions,
//     S.distributionsDate,
//     S.managementFees,
//     S.managementFeesDate,
// ]

export const COMMON_INVESTMENT_FIELDS = {
    [S.name]: {
        placeholder: 'Enter Name',
        label: 'Name',
        type: S.text,
        fieldName: S.name,
        required: true,
    },
    [S.committed]: {
        placeholder: 'Enter Committed Capital ($)',
        label: 'Committed Capital ($)',
        type: S.currency,
        fieldName: S.committed,
    },
    [S.committedDate]: {
        placeholder: 'Enter Committed Capital Date',
        label: 'Committed Capital Date',
        type: S.date,
        fieldName: S.committedDate,
        requiredCondition: {
            fieldName: S.committed,
        },
    },
    [S.amountInvested]: {
        placeholder: 'Enter Amount Invested (capital called) ($)',
        label: 'Amount Invested (capital called) ($)',
        type: S.currency,
        fieldName: S.amountInvested,
        required: true,
    },
    [S.amountInvestedDate]: {
        placeholder: 'Enter Date Invested',
        label: 'Date Invested',
        type: S.date,
        fieldName: S.amountInvestedDate,
        required: true,
    },
    [S.percentOwnership]: {
        editable: true,
        placeholder: 'Enter % Ownership (%)',
        label: '% Ownership (%)',
        type: S.percent,
        fieldName: S.percentOwnership,
    },
    [S.sharePrice]: {
        editable: true,
        placeholder: 'Enter Share Price ($)',
        label: 'Share Price ($)',
        type: S.currency,
        fieldName: S.sharePrice,
    },
    [S.sharesUnitsTokens]: {
        editable: true,
        placeholder: 'Enter Shares / Units / Tokens',
        label: 'Shares / Units / Tokens',
        type: S.number,
        fieldName: S.sharesUnitsTokens,
    },
    [S.options]: {
        editable: true,
        placeholder: 'Enter Options',
        label: 'Options',
        type: S.number, // likely a different type, waiting on Kareem
        fieldName: S.options,
    },
    [S.optionsPrice]: {
        editable: true,
        placeholder: 'Enter Options Price ($)',
        label: 'Options Price ($)',
        type: S.currency, // likely a different type, waiting on Kareem
        fieldName: S.optionsPrice,
        requiredCondition: {
            fieldName: S.options,
        },
    },
    [S.currentValue]: {
        placeholder: `Enter Current Value (Unrealized)`,
        label: `Current Value (Unrealized)`,
        type: S.currency,
        fieldName: S.currentValue,
        required: true,
    },
    [S.currentValueDate]: {
        placeholder: `Enter Current Value as of date)`,
        label: `Current Value as of date`,
        type: S.date,
        fieldName: S.currentValueDate,
        required: true,
    },
    [S.distributions]: {
        placeholder: `Enter Distributions (Profit)`,
        label: `Distributions (Profit)`,
        type: S.currency,
        fieldName: S.distributions,
        // correction: amt => -Math.abs(amt)
    },
    [S.distributionsDate]: {
        placeholder: `Enter Distributions (Profit) as of date`,
        label: `Distributions (Profit) as of date`,
        type: S.date,
        fieldName: S.distributionsDate,
        requiredCondition: {
            fieldName: S.distributions,
        },
    },
    [S.website]: {
        editable: true,
        placeholder: 'Enter Website',
        label: 'Website',
        type: S.text,
        fieldName: S.website,
    },
    [S.managementFees]: {
        placeholder: 'Enter Management Fees',
        label: 'Management Fees',
        type: S.currency,
        fieldName: S.managementFees,
        // correction: amt => Math.abs(amt)
    },
    [S.managementFeesDate]: {
        placeholder: `Enter Management Fees as of date`,
        label: `Management Fees as of date`,
        type: S.date,
        fieldName: S.managementFeesDate,
        requiredCondition: {
            fieldName: S.managementFees,
        },
    },
    [S.location]: {
        editable: true,
        placeholder: 'Enter Location',
        label: 'Location',
        type: S.text,
        fieldName: S.location,
    },
    [S.interestFrequency]: {
        editable: true,
        placeholder: 'Enter Interest Frequency',
        label: 'Interest Frequency',
        type: S.option,
        fieldName: S.interestFrequency,
        options: ['daily', 'weekly', 'monthly', 'quarterly', 'annually'],
    },
    [S.valuationCap]: {
        editable: true,
        placeholder: 'Enter Valuation Cap ($ millions)',
        label: 'Valuation Cap ($ millions)',
        type: S.currency,
        fieldName: S.valuationCap,
    },
    [S.discountRate]: {
        editable: true,
        placeholder: 'Enter Discount Rate (%)',
        label: 'Discount Rate (%)',
        type: S.percent,
        fieldName: S.discountRate,
    },
    [S.annualInterestRate]: {
        editable: true,
        placeholder: 'Enter Annual Interest Rate (%)',
        label: 'Annual Interest Rate (%)',
        type: S.percent,
        fieldName: S.annualInterestRate,
    },
    [S.termOfLoan]: {
        editable: true,
        placeholder: 'Enter Term of Loan (# of months)',
        label: 'Term of Loan (# of months)',
        type: S.number,
        fieldName: 'termOfLoan',
    },
    [S.maturityDate]: {
        editable: true,
        placeholder: 'Enter Maturity Date',
        label: 'Maturity Date',
        type: S.date,
        fieldName: S.maturityDate,
    },
    [S.contraAccountNumber]: {
        editable: true,
        placeholder: 'Enter Contra Account Number',
        label: 'Contra Account Number',
        type: S.text,
        fieldName: S.contraAccountNumber,
    },
};

// get investment types for investment
export const getInvestmentTypes = () => {
    return Object.values(INVESTMENT_TYPES)
        .filter((group) => group.name !== 'default')
        .sort((a, b) => a.name.localeCompare(b.name));
};

// get investment subtypes for investment type
export const getInvestmentSubtypes = (investmentType) => {
    if (!investmentType) {
        return Object.values(INVESTMENT_TYPES).flatMap((group) => group.types);
    }
    const typeKey = Object.keys(INVESTMENT_TYPES).find((key) => INVESTMENT_TYPES[key].name.toLowerCase() === investmentType.toLowerCase());
    let group = INVESTMENT_TYPES[typeKey];
    return group?.types ?? [];
};

// get fields that apply to investment subtype
export const getInvestmentSubtypeFields = (investmentType, subtype) => {
    let group = INVESTMENT_TYPES[investmentType];
    return group.typeFields?.filter((tf) => tf.appliedTypes.includes(subtype) || tf.appliedTypes.includes(ALL))?.[0]?.fields ?? [];
};

// get fields that apply to investment type
export const getFieldsByType = (investmentTypeName, subtype = null, editableOnly = false, requiredFieldsOnly = false) => {
    let investmentType = INVESTMENT_TYPES[investmentTypeName] ?? INVESTMENT_TYPES['default'];

    // get fields to include
    let fieldsToInclude = requiredFieldsOnly ? investmentType.requiredFields : investmentType.fields;
    let allFields = Object.entries(COMMON_INVESTMENT_FIELDS).reduce((res, [fieldKey, fieldValue]) => {
        if (editableOnly && !fieldValue.editable) return res; // skip non-editable fields
        if (fieldsToInclude.includes(fieldKey)) {
            // only include fields that apply to this investment type
            res[fieldKey] = fieldValue;
        }
        return res;
    }, {});

    // add group types as option list
    allFields.type = {
        placeholder: 'Select Subtype',
        label: 'Investment Subtype',
        type: S.option,
        options: investmentType.types,
        fieldName: 'type',
        required: true,
    };

    // add fields based on selected type, or if applies to ALL types
    const shouldIncludeTypeFields = (subtype || investmentType.typeFields?.[0]?.appliedTypes?.includes(ALL)) && investmentType.typeFields?.length;
    if (shouldIncludeTypeFields) {
        investmentType.typeFields.forEach((typeField) => {
            const shouldIncludeThisTypeField = typeField.appliedTypes.includes(ALL) || typeField.appliedTypes.includes(subtype);
            if (shouldIncludeThisTypeField) {
                typeField.fields.forEach((field) => {
                    allFields[field.fieldName] = field;
                });
            }
        });
    }

    return allFields;
};

const DEFAULT_REQUIRED_FIELDS = [S.name, S.amountInvested, S.amountInvestedDate];

const FUND_REQUIRED_FIELDS = [S.name, S.committed, S.committedDate, S.amountInvested, S.amountInvestedDate];

const ALL_FUND_FIELDS = [
    S.name,
    S.committed,
    S.committedDate,
    S.amountInvested,
    S.amountInvestedDate,
    S.percentOwnership,
    S.sharePrice,
    S.sharesUnitsTokens,
    // S.options,
    // S.optionsPrice,
    S.currentValue,
    S.currentValueDate,
    S.distributions,
    S.distributionsDate,
    S.managementFees,
    S.managementFeesDate,
    S.website,
    S.location,
    S.contraAccountNumber,
];

// data for investment types.
// requiredFields are required fields for investment type
// fields are all fields for investment type
// types are the options for the type field (which is like the investment subtype)
export const INVESTMENT_TYPES = {
    [S.privateCompanyStartup]: {
        name: S.privateCompanyStartup,
        requiredFields: [...DEFAULT_REQUIRED_FIELDS],
        fields: [
            S.name,
            // S.committed,
            // S.committedDate,
            S.amountInvested,
            S.amountInvestedDate,
            S.percentOwnership,
            S.sharePrice,
            S.sharesUnitsTokens,
            S.options,
            S.optionsPrice,
            S.currentValue,
            S.currentValueDate,
            S.distributions,
            S.distributionsDate,
            S.managementFees,
            S.managementFeesDate,
            S.website,
            S.location,
            S.contraAccountNumber,
        ],
        types: ['SAFE', 'Convertible Note (Credit)', 'Preferred Stock', 'Common Stock'],
        typeFields: [
            {
                appliedTypes: ['SAFE'],
                fields: [COMMON_INVESTMENT_FIELDS[S.discountRate], COMMON_INVESTMENT_FIELDS[S.valuationCap]],
            },
            {
                appliedTypes: ['Convertible Note (Credit)'],
                fields: [
                    COMMON_INVESTMENT_FIELDS[S.annualInterestRate],
                    COMMON_INVESTMENT_FIELDS[S.interestFrequency],
                    COMMON_INVESTMENT_FIELDS[S.valuationCap],
                    COMMON_INVESTMENT_FIELDS[S.maturityDate],
                ],
            },
        ],
    },
    [S.realEstate]: {
        name: S.realEstate,
        requiredFields: [...DEFAULT_REQUIRED_FIELDS],
        fields: [
            S.name,
            // S.committed,
            // S.committedDate,
            S.amountInvested,
            S.amountInvestedDate,
            S.percentOwnership,
            // S.sharePrice,
            // S.sharesUnitsTokens,
            // S.options,
            // S.optionsPrice,
            S.currentValue,
            S.currentValueDate,
            S.distributions,
            S.distributionsDate,
            S.managementFees,
            S.managementFeesDate,
            S.website,
            S.location,
            S.contraAccountNumber,
        ],
        types: [
            'House - single family',
            'Apartment',
            'Multi-family',
            'Office building',
            'Hotel / Motel',
            'Student housing',
            'Industrial',
            'Hospital / Nursing Home',
            'Storage / Warehouse',
            'Land',
            'Retail',
        ],
    },
    [S.realAssetsDirectOwned]: {
        name: S.realAssetsDirectOwned,
        requiredFields: [...DEFAULT_REQUIRED_FIELDS],
        fields: [
            S.name,
            // S.committed,
            // S.committedDate,
            S.amountInvested,
            S.amountInvestedDate,
            S.percentOwnership,
            // S.sharePrice,
            // S.sharesUnitsTokens,
            // S.options,
            // S.optionsPrice,
            S.currentValue,
            S.currentValueDate,
            S.distributions,
            S.distributionsDate,
            S.managementFees,
            S.managementFeesDate,
            S.website,
            S.location,
            S.contraAccountNumber,
        ],
        types: ['Infrastructure', 'Commodities', 'Energy', 'Mines', 'Farmland', 'Timberland', 'Shipping', 'Royalties'],
    },
    [S.collectible]: {
        name: S.collectible,
        requiredFields: [...DEFAULT_REQUIRED_FIELDS],
        fields: [
            S.name,
            // S.committed,
            // S.committedDate,
            S.amountInvested,
            S.amountInvestedDate,
            S.percentOwnership,
            // S.sharePrice,
            // S.sharesUnitsTokens,
            // S.options,
            // S.optionsPrice,
            S.currentValue,
            S.currentValueDate,
            // S.distributions,
            // S.distributionsDate,
            S.managementFees,
            S.managementFeesDate,
            S.website,
            S.location,
            S.contraAccountNumber,
        ],
        types: ['Coins', 'Stamps', 'Artwork', 'Automobiles', 'Watches', 'Wine', 'Toys', 'Musical Instruments', 'Jewelry', 'Trading Cards', 'Vintage Furniture'],
    },
    [S.crypto]: {
        name: S.crypto,
        requiredFields: [...DEFAULT_REQUIRED_FIELDS],
        fields: [
            S.name,
            // S.committed,
            // S.committedDate,
            S.amountInvested,
            S.amountInvestedDate,
            // S.percentOwnership,
            S.sharePrice,
            S.sharesUnitsTokens,
            // S.options,
            // S.optionsPrice,
            S.currentValue,
            S.currentValueDate,
            S.distributions,
            S.distributionsDate,
            S.managementFees,
            S.managementFeesDate,
            S.website,
            S.location,
            S.contraAccountNumber,
        ],
        types: ['Coin', 'NFT', 'Stablecoin', 'DeFi Token'],
    },
    [S.bondLoan]: {
        name: S.bondLoan,
        requiredFields: [...DEFAULT_REQUIRED_FIELDS],
        fields: [
            S.name,
            // S.committed,
            // S.committedDate,
            S.amountInvested,
            S.amountInvestedDate,
            // S.percentOwnership,
            // S.sharePrice,
            // S.sharesUnitsTokens,
            // S.options,
            // S.optionsPrice,
            S.currentValue,
            S.currentValueDate,
            S.distributions,
            S.distributionsDate,
            S.managementFees,
            S.managementFeesDate,
            S.website,
            S.location,
            S.contraAccountNumber,
        ],
        types: ['Bond', 'Loan', 'Convertible Note', 'Mortgage'],
        typeFields: [
            {
                appliedTypes: [ALL],
                fields: [
                    COMMON_INVESTMENT_FIELDS[S.annualInterestRate],
                    COMMON_INVESTMENT_FIELDS[S.interestFrequency],
                    COMMON_INVESTMENT_FIELDS[S.termOfLoan],
                    COMMON_INVESTMENT_FIELDS[S.maturityDate],
                ],
            },
        ],
    },
    [S.hedgeFund]: {
        name: S.hedgeFund,
        requiredFields: [...FUND_REQUIRED_FIELDS],
        fields: [...ALL_FUND_FIELDS],
        types: ['Long / Short', 'Market Neutral', 'Event Driven', 'Credit', 'Macro', 'Quant'],
    },

    [S.privateEquityFund]: {
        name: S.privateEquityFund,
        requiredFields: [...FUND_REQUIRED_FIELDS],
        fields: [...ALL_FUND_FIELDS],
        types: ['Small Cap', 'Mid Cap', 'Large Cap'],
    },
    [S.privateCreditFund]: {
        name: S.privateCreditFund,
        requiredFields: [...FUND_REQUIRED_FIELDS],
        fields: [...ALL_FUND_FIELDS],
        types: ['Mezzanine', 'Distressed', 'Special Opportunity', 'Mixed', 'Loans'],
    },
    [S.ventureCapitalFund]: {
        name: S.ventureCapitalFund,
        requiredFields: [...FUND_REQUIRED_FIELDS],
        fields: [...ALL_FUND_FIELDS],
        types: ['Seed / Angel', 'Early Stage', 'Late Stage / Growth', 'Multi-Stage', 'Venture Debt'],
    },
    [S.fundOfFunds]: {
        name: S.fundOfFunds,
        requiredFields: [...FUND_REQUIRED_FIELDS],
        fields: [...ALL_FUND_FIELDS],
        types: ['Buyout', 'Debt', 'Venture / Growth', 'Real Estate', 'Real Assets'],
    },
    [S.secondaryFund]: {
        name: S.secondaryFund,
        requiredFields: [...FUND_REQUIRED_FIELDS],
        fields: [...ALL_FUND_FIELDS],
        types: ['Funds', 'Direct Investments', 'Both Funds & Direct Investments'],
    },
    [S.realEstateFund]: {
        name: S.realEstateFund,
        requiredFields: [...FUND_REQUIRED_FIELDS],
        fields: [...ALL_FUND_FIELDS],
        types: ['Core', 'Core Plus', 'Value Added', 'Opportunistic', 'Credit'],
    },
    [S.coinvestmentFund]: {
        name: S.coinvestmentFund,
        requiredFields: [...FUND_REQUIRED_FIELDS],
        fields: [...ALL_FUND_FIELDS],
        types: ['Buyout', 'Debt', 'Venture / Growth', 'Real Estate', 'Real Assets'],
    },
    [S.realAssetsFund]: {
        name: S.realAssetsFund,
        requiredFields: [...FUND_REQUIRED_FIELDS],
        fields: [...ALL_FUND_FIELDS],
        types: ['Infrastructure', 'Commodities', 'Energy', 'Mines', 'Farmland', 'Timberland', 'Shipping', 'Royalties'],
    },

    default: {
        name: 'default',
        fields: [
            S.name,
            // S.committed,
            // S.committedDate,
            S.amountInvested,
            S.amountInvestedDate,
            S.percentOwnership,
            // S.sharePrice,
            // S.sharesUnitsTokens,
            // S.options,
            // S.optionsPrice,
            S.currentValue,
            S.currentValueDate,
            // S.distributions,
            // S.distributionsDate,
            S.managementFees,
            S.managementFeesDate,
            S.website,
            S.location,
        ],
        types: [],
    },
};
