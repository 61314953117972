import React, { useState, useEffect, useCallback } from 'react';

interface DelayedInputProps {
    value: string; // The initial value for the input
    onChange: (value: string) => void; // Function to call when the input changes after the specified delay
    type?: string; // Type of the input, defaults to 'text'
    delay?: number; // Delay in milliseconds before the onChange event is triggered, defaults to 700ms
    containerStyle?: React.CSSProperties; // Custom styles for the container
    inputStyle?: React.CSSProperties; // Custom styles for the input
    isValid?: (value: string) => boolean; // Function to determine if the current input value is valid
    invalidMessage?: string; // Message to display when the input value is invalid
    className?: string; // Additional class name for the container
}

const DelayedInput: React.FC<DelayedInputProps> = ({
    value,
    onChange,
    type = 'text',
    delay = 700,
    containerStyle = {},
    inputStyle = {},
    isValid = () => true,
    invalidMessage = 'Invalid input',
    className,
}) => {
    const [inputValue, setInputValue] = useState<string>(value); // State to hold the current input value
    const [lastValidValue, setLastValidValue] = useState<string>(value); // State to hold the last valid input value

    useEffect(() => {
        setInputValue(value);
        setLastValidValue(value);
    }, [value]);

    const handleDelayedChange = useCallback(() => {
        if (inputValue === lastValidValue) {
            return;
        }
        if (isValid(inputValue)) {
            // If the input value is valid and has changed, trigger the onChange event
            onChange(inputValue);
            setLastValidValue(inputValue);
        }
    }, [inputValue, lastValidValue, onChange, isValid]);

    useEffect(() => {
        const timer = setTimeout(handleDelayedChange, delay);
        // Cleanup function to clear the timer when the component unmounts or before setting a new timer
        return () => clearTimeout(timer);
    }, [handleDelayedChange, delay]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    return (
        <div
            className={className}
            style={{
                ...containerStyle,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <input
                type={type}
                value={inputValue}
                onChange={handleInputChange}
                onClick={(e) => e.currentTarget.select()}
                style={{
                    ...inputStyle,
                    borderColor: isValid(inputValue) ? undefined : 'red', // Change border color if input is invalid
                }}
            />
            {!isValid(inputValue) && <span style={{ color: 'red', fontSize: '12px' }}>{invalidMessage}</span>}
        </div>
    );
};

export default DelayedInput;
