import React, { useState, useEffect, useRef } from 'react';
import clickOutsideHandler from '../../utilities/clickOutsideHandler';
import '../../styles/hamburgerMenu.css';

interface Option {
    label: string;
    action?: () => void;
    disabled?: boolean;
    subOptions?: Option[];
}

interface HamburgerMenuProps {
    numDots?: number;
    containerStyle?: React.CSSProperties;
    dotStyle?: React.CSSProperties;
    optionStyle?: React.CSSProperties;
    options: Option[];
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ numDots = 3, containerStyle, dotStyle, optionStyle, options }) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState(false);
    const [outclickSet, setOutclickSet] = useState(false);
    const [activeOption, setActiveOption] = useState<number | null>(null);

    useEffect(() => {
        setOutclick();
        return () => {
            setOutclickSet(false);
            clickOutsideHandler.remove(containerRef.current, () => setOpen(false));
        };
    }, []);

    useEffect(() => {
        setOutclick();
    });

    const setOutclick = () => {
        if (containerRef.current && !outclickSet) {
            clickOutsideHandler.add(containerRef.current, () => setOpen(false));
            setOutclickSet(true);
        }
    };

    const handleMouseOutFromOption = (event: React.MouseEvent, index: number) => {
        if (containerRef.current) {
            const optionDiv = containerRef.current.querySelector(`[data-option-index="${index}"]`);
            const subOptionsDiv = containerRef.current.querySelector(`[data-suboptions-index="${index}"]`);

            if (optionDiv && !optionDiv.contains(event.target as Node) && subOptionsDiv && !subOptionsDiv.contains(event.target as Node)) {
                setActiveOption(null);
            }
        }
    };

    return (
        <div ref={containerRef} className={'ham_container'} style={{ ...containerStyle, '--num-dots': numDots } as React.CSSProperties}>
            <div className="ham_menu" role="button" onClick={() => setOpen(!open)}>
                {[...Array(numDots)].map((_, i) => (
                    <div className={'ham_dot'} style={{ ...dotStyle }} key={i} />
                ))}
            </div>
            {open && (
                <div className="ham_options">
                    {options.map((opt, i) => (
                        <div
                            key={'opt' + i}
                            data-option-index={i}
                            onMouseOver={() => setActiveOption(i)}
                            onMouseOut={(e) => handleMouseOutFromOption(e, i)}
                            style={{ position: 'relative' }}
                        >
                            <div
                                className={'ham_option' + (opt.disabled ? ' ham_option_disabled' : '')}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', ...optionStyle }}
                                onClick={
                                    !opt.disabled && opt.action
                                        ? (e) => {
                                              e.stopPropagation();
                                              opt.action && opt.action();
                                              setOpen(false);
                                          }
                                        : undefined
                                }
                            >
                                {opt.label} {opt.subOptions && opt.subOptions.length > 0 && <div className="ham_option_arrow1">&#9658;</div>}
                            </div>
                            {opt.subOptions && opt.subOptions.length > 0 && activeOption === i && (
                                <div className="ham_suboptions" data-suboptions-index={i}>
                                    {opt.subOptions.map((subOpt, j) => (
                                        <div
                                            key={'subopt' + j}
                                            className={'ham_suboption' + (subOpt.disabled ? ' ham_option_disabled' : '')}
                                            onClick={
                                                !subOpt.disabled && subOpt.action
                                                    ? (e) => {
                                                          e.stopPropagation();
                                                          subOpt.action && subOpt.action();
                                                          setOpen(false);
                                                      }
                                                    : undefined
                                            }
                                        >
                                            {subOpt.label}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default HamburgerMenu;
