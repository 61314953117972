import React, { useEffect, useState } from 'react';
import api2 from '../../api2';
import { Currency } from '../../types/Currency';
import formatCurrency from '../../utilities/format/formatCurrency';
import { formatUTCDate } from '../../utilities/format/formatDate';

interface MostRecentValuationProps {
    investmentId: string;
    currency: Currency;
    shouldDisplayDate?: boolean;
    onValuationFetched?: (valuation: { amount: number; date: string }) => void;
}

const MostRecentValuation: React.FC<MostRecentValuationProps> = ({ investmentId, currency, shouldDisplayDate = true, onValuationFetched }) => {
    const [valuation, setValuation] = useState<{ amount: number; date: string } | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchMostRecentValuation = async () => {
            setLoading(true);
            try {
                const mostRecent = await api2.client.ValuationApi.listValuations({
                    investments: [investmentId],
                    sort_field: 'date',
                    sort_direction: 'desc',
                    limit: 1,
                });

                if (mostRecent?.data?.valuations?.length && mostRecent.data.valuations[0].amount !== undefined && mostRecent.data.valuations[0].date) {
                    setValuation({
                        amount: mostRecent.data.valuations[0].amount,
                        date: mostRecent.data.valuations[0].date,
                    });
                    if (onValuationFetched) {
                        onValuationFetched({
                            amount: mostRecent.data.valuations[0].amount,
                            date: mostRecent.data.valuations[0].date,
                        });
                    }
                }
            } catch (err) {
                console.error('Error fetching most recent valuation', err);
            }
            setLoading(false);
        };

        fetchMostRecentValuation();
    }, [investmentId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!valuation) {
        return <div>No Valuation Data Available</div>;
    }

    return (
        <div>
            {formatCurrency(valuation.amount, currency)}
            {shouldDisplayDate ? ` (as of ${formatUTCDate(valuation.date)})` : ''}
        </div>
    );
};

export default MostRecentValuation;
