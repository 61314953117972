import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import NotesWidget from './NotesWidget';

import '../../../styles/widget.css';
import '../../../styles/widget-notes.css';

interface Tab {
    name: string;
    type: string;
    typeId: string;
    breadcrumbs: Record<string, string>;
}

interface NoteTabsWidgetProps extends RouteComponentProps {
    tabs: Tab[];
    loading: (duration: number, key?: string) => void;
    loaded: (key?: string) => void;
    containerStyle?: React.CSSProperties;
    title: string;
}

const NoteTabsWidget: React.FC<NoteTabsWidgetProps> = (props) => {
    const [activeTab, setActiveTab] = useState<number>(0);

    const renderTabs = () => {
        const p = props;
        return (
            <div
                className="tabs11"
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    height: 'min-content',
                    fontSize: '12px',
                }}
            >
                {p.tabs?.map((tab, i) => (
                    <span
                        style={{
                            fontSize: '16px',
                            width: 'min-content',
                            textAlign: 'center',
                            border: '1px solid black',
                            cursor: 'pointer',
                            padding: '5px 10px',
                            whiteSpace: 'nowrap',
                            backgroundColor: activeTab === i ? 'white' : 'lightgray',
                        }}
                        key={'notes_tab' + i}
                        onClick={() => setActiveTab(i)}
                    >
                        {tab.name}
                    </span>
                ))}
            </div>
        );
    };

    const renderNotes = (tab: Tab) => {
        const p = props;
        return (
            <div style={{ width: 'calc(100% - 0px)', minHeight: '100px' }}>
                <NotesWidget
                    containerStyle={{
                        margin: '0px',
                    }}
                    loading={p.loading}
                    loaded={p.loaded}
                    type={tab.type}
                    typeId={tab.typeId}
                    breadcrumbs={tab.breadcrumbs}
                />
            </div>
        );
    };

    const p = props;

    return (
        <div
            id="note_tabs_widget"
            className="widget pdfIgnore"
            style={{
                position: 'relative',
                margin: 0,
                height: 'fit-content',
                ...p.containerStyle,
            }}
        >
            <h1
                style={{
                    fontSize: '24px',
                    margin: '0px 0px 10px 0px',
                    padding: '0px 0px 10px 0px',
                }}
            >
                {p.title}
            </h1>
            {renderTabs()}
            {p.tabs?.length > 0 && renderNotes(p.tabs[activeTab])}
        </div>
    );
};

export default withRouter(NoteTabsWidget);
