// this is the same code that is in the backend in the same named file

import { TaxDocumentSubtypes } from '../../../constants/documentConstants';
import { DocumentType as DocumentTypeEnum } from '../../../types/Document';

// export const DocumentType = {
//     Statement: 'Capital Account Statement',
//     CapitalCall: 'Capital Call Notice',
//     Distribution: 'Distribution Notice',
//     TaxDocument: 'Tax Document',
//     Other: 'Other',
//     NetCapitalCall: 'Net Capital Call',
//     NetDistribution: 'Net Distribution',
//     HedgeFund: 'Hedge Fund',
//     PPMOM: 'PPM/OM',
//     PrivacyNotice: 'Privacy Notice',
//     Disclosure: 'Disclosure / ADV',
//     InvestmentUpdate: 'Investment Update',
//     LegalAgreement: 'Legal Agreement',
//     WireInstructionConfirmation: 'Wire Instruction / Confirmation',
//     ReportingAuthorizationLetter: 'Reporting Authorization Letter',
// };

export const DocumentType = { ...DocumentTypeEnum };

export const TimeframeOptions = {
    MTD: `Monthly`,
    QTD: `Quarterly`,
    YTD: `Annually`,
};

export const parseTaxFormType = (input) => {
    const normalized = input.toLowerCase().replace(/\W/g, '');
    if (normalized.includes('k1')) {
        return 'K-1';
    } else if (normalized.includes('estimate')) {
        return 'Estimate';
    } else if (normalized.includes('1099')) {
        return '1099';
    } else if (normalized.includes('w9')) {
        return 'W-9';
    } else if (normalized.includes('other')) {
        return 'Other';
    }
    return null;
};

export const timeframeParse = (timeframe_str) => {
    const normalizedStr = timeframe_str.toLowerCase().replace(/\W/g, ''); // remove non-alphanumeric characters and convert to lowercase
    if ([`month`, `mtd`].some((str) => normalizedStr.includes(str))) {
        return 'MTD';
    } else if ([`quarter`, `qtd`, `q1`, `q2`, `q3`, `q4`].some((str) => normalizedStr.includes(str))) {
        return 'QTD';
    } else if ([`year`, `ytd`, 'annual'].some((str) => normalizedStr.includes(str))) {
        return 'YTD';
    }
    return null; // return null if no match found
};
const Str = {
    Timeframe: `Timeframe`,
    PeriodStart: `Period Start`,
    PeriodEnd: `Period End`,
    BeginningBalance: `Beginning Balance`,
    EndingBalance: `Ending Balance`,
    TotalCommitted: `Total Committed`,
    ManagementFee: `Management Fee`,
    ManagementFeeRebate: `Management Fee Rebate`,
    Carry: `Carry`,
    NetCashFlow: `Net Cash Flow`,
    Contributions: `Contributions`,
    ContributionsDate: `Contributions Date`,
    PrincipalDistribution: `Principal Distribution`,
    ProfitDistribution: `Profit Distribution`,
    ProfitDistributionDate: `Profit Distribution Date`,
    RecallablePrincipalDistribution: `Recallable Principal Distribution`,
    RecallableProfitDistribution: `Recallable Profit Distribution`,
    EffectiveDate: `Effective Date`,
    CapitalCallAmount: `Capital Call Amount`,
    AmountTowardInvestment: `Amount toward investment`,
    FeeExpenseAmount: `Fee/Expense Amount`,
    InterestAmount: `Interest Amount`,
    DividendIncome: `Dividend/Income`,
    DividendIncomeAmount: `Dividend/Income Amount`,
    DividendIncomeDate: `Dividend/Income Date`,
    OtherAmount: `Other Amount`,
    CalculatedTotal: `Calculated Total`,
    Withdrawals: `Withdrawals`,
    WithdrawalsDate: `Withdrawals Date`,
    AmountCommitted: `Amount Committed`,
    DocumentTaxYear: `Document Tax Year`,
    TaxFormType: `Tax Form Type`,

    currency: `currency`,
    number: `number`,
    text: `text`,
    password: `password`,
    percent: `percent`,

    eg$XXXXXXX: `$X,XXX,XXX`,
};

let DOCUMENT_TYPES_LIST = {
    [DocumentType.Statement]: {
        name: DocumentType.Statement,
        canGenerateTransactions: true,
        meta: {
            [Str.Timeframe]: {
                label: Str.Timeframe,
                fieldName: Str.Timeframe.toLowerCase(),
                type: `select`,
                required: true,
                or: [Str.PeriodStart],
                options: Object.keys(TimeframeOptions),
                optionClearable: true,
                getLabel: (value) => TimeframeOptions[value],
                parse: timeframeParse,
            },
            [Str.PeriodStart]: {
                label: Str.PeriodStart,
                fieldName: Str.PeriodStart.toLowerCase(),
                type: `date`,
                required: true,
                or: [Str.Timeframe],
            },
            [Str.PeriodEnd]: {
                label: Str.PeriodEnd,
                fieldName: Str.PeriodEnd.toLowerCase(),
                type: `date`,
                required: true,
            },
            [Str.BeginningBalance]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.BeginningBalance,
                fieldName: Str.BeginningBalance.toLowerCase(),
                type: Str.currency,
                required: false,
            },
            [Str.EndingBalance]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.EndingBalance,
                fieldName: Str.EndingBalance.toLowerCase(),
                type: Str.currency,
                required: true,
            },
            [Str.TotalCommitted]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.TotalCommitted,
                fieldName: Str.TotalCommitted.toLowerCase(),
                type: Str.currency,
            },
            [Str.ManagementFee]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.ManagementFee,
                fieldName: Str.ManagementFee.toLowerCase(),
                type: Str.currency,
            },
            [Str.Carry]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.Carry,
                fieldName: Str.Carry.toLowerCase(),
                type: Str.currency,
            },
            [Str.ManagementFeeRebate]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.ManagementFeeRebate,
                fieldName: `fee rebate`,
                type: Str.currency,
            },
            [Str.Contributions]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.Contributions,
                fieldName: Str.Contributions.toLowerCase(),
                type: Str.currency,
                showShouldGenerateTransactionsToggle: true,
                shouldGenerateTransactionsDefault: false,
            },
            [Str.PrincipalDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.PrincipalDistribution,
                fieldName: Str.PrincipalDistribution.toLowerCase(),
                type: Str.currency,
                showShouldGenerateTransactionsToggle: true,
                shouldGenerateTransactionsDefault: false,
            },
            [Str.ProfitDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.ProfitDistribution,
                fieldName: Str.ProfitDistribution.toLowerCase(),
                type: Str.currency,
                showShouldGenerateTransactionsToggle: true,
                shouldGenerateTransactionsDefault: false,
            },
            [Str.DividendIncomeAmount]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.DividendIncomeAmount,
                fieldName: `dividend`,
                type: Str.currency,
                required: false,
                showShouldGenerateTransactionsToggle: true,
                shouldGenerateTransactionsDefault: false,
            },
            [Str.RecallablePrincipalDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.RecallablePrincipalDistribution,
                fieldName: Str.RecallablePrincipalDistribution.toLowerCase(),
                type: Str.currency,
            },
            [Str.RecallableProfitDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.RecallableProfitDistribution,
                fieldName: Str.RecallableProfitDistribution.toLowerCase(),
                type: Str.currency,
            },
        },
    },
    [DocumentType.CapitalCall]: {
        name: DocumentType.CapitalCall,
        canGenerateTransactions: true,
        meta: {
            [Str.EffectiveDate]: {
                label: Str.EffectiveDate,
                fieldName: `effective date`,
                type: `date`,
                required: true,
            },
            [Str.CapitalCallAmount]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.CapitalCallAmount,
                fieldName: `capital called`,
                type: Str.currency,
                required: true,
            },
            [Str.AmountTowardInvestment]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.AmountTowardInvestment,
                fieldName: `capital called investment`,
                type: Str.currency,
                required: false,
            },
            [Str.FeeExpenseAmount]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.FeeExpenseAmount,
                fieldName: `capital called fee`,
                type: Str.currency,
                required: false,
            },
            [Str.InterestAmount]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.InterestAmount,
                fieldName: `capital called interest`,
                type: Str.currency,
                required: false,
            },
            [Str.OtherAmount]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.OtherAmount,
                fieldName: `capital called other`,
                type: Str.currency,
                required: false,
            },
            [Str.CalculatedTotal]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.CalculatedTotal,
                fieldName: `calculated total`,
                type: Str.currency,
                required: false,
                disabled: true,
                calculateFromData: (data) => {
                    let {
                        [`capital called investment`]: capitalCalledInvestment,
                        [`capital called fee`]: capitalCalledFee,
                        [`capital called interest`]: capitalCalledInterest,
                        [`capital called other`]: capitalCalledOther,
                    } = data;

                    if (isNaN(capitalCalledInvestment) && isNaN(capitalCalledFee) && isNaN(capitalCalledInterest) && isNaN(capitalCalledOther)) {
                        return null;
                    }

                    // make values 0 if they are null
                    capitalCalledInvestment = !isNaN(capitalCalledInvestment) ? Number(capitalCalledInvestment) : 0;
                    capitalCalledFee = !isNaN(capitalCalledFee) ? Number(capitalCalledFee) : 0;
                    capitalCalledInterest = !isNaN(capitalCalledInterest) ? Number(capitalCalledInterest) : 0;
                    capitalCalledOther = !isNaN(capitalCalledOther) ? Number(capitalCalledOther) : 0;

                    const sum = capitalCalledInvestment + capitalCalledFee + capitalCalledInterest + capitalCalledOther;

                    return sum;
                },
            },
            [Str.TotalCommitted]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.TotalCommitted,
                fieldName: Str.TotalCommitted.toLowerCase(),
                type: Str.currency,
            },
        },
    },
    [DocumentType.Distribution]: {
        name: DocumentType.Distribution,
        canGenerateTransactions: true,
        meta: {
            [Str.EffectiveDate]: {
                label: Str.EffectiveDate,
                fieldName: `effective date`,
                type: `date`,
                required: true,
            },
            [Str.ProfitDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.ProfitDistribution,
                fieldName: `profit distribution`,
                type: Str.currency,
                required: false,
                or: [Str.ProfitDistribution, Str.PrincipalDistribution, Str.RecallableProfitDistribution, Str.RecallablePrincipalDistribution, Str.ManagementFeeRebate],
            },
            [Str.PrincipalDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.PrincipalDistribution,
                fieldName: `principal distribution`,
                type: Str.currency,
                required: false,
                or: [Str.ProfitDistribution, Str.PrincipalDistribution, Str.RecallableProfitDistribution, Str.RecallablePrincipalDistribution, Str.ManagementFeeRebate],
            },
            [Str.RecallableProfitDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.RecallableProfitDistribution,
                fieldName: `recallable profit distribution`,
                type: Str.currency,
                required: false,
                or: [Str.ProfitDistribution, Str.PrincipalDistribution, Str.RecallableProfitDistribution, Str.RecallablePrincipalDistribution, Str.ManagementFeeRebate],
            },
            [Str.RecallablePrincipalDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.RecallablePrincipalDistribution,
                fieldName: `recallable principal distribution`,
                type: Str.currency,
                required: false,
                or: [Str.ProfitDistribution, Str.PrincipalDistribution, Str.RecallableProfitDistribution, Str.RecallablePrincipalDistribution, Str.ManagementFeeRebate],
            },
            [Str.ManagementFeeRebate]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.ManagementFeeRebate,
                fieldName: `fee rebate`,
                type: Str.currency,
                or: [Str.ProfitDistribution, Str.PrincipalDistribution, Str.RecallableProfitDistribution, Str.RecallablePrincipalDistribution, Str.ManagementFeeRebate],
            },
            [Str.FeeExpenseAmount]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.FeeExpenseAmount,
                fieldName: `distribution fee`,
                type: Str.currency,
                required: false,
            },
            [Str.InterestAmount]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.InterestAmount,
                fieldName: `distribution interest`,
                type: Str.currency,
                required: false,
            },
            [Str.DividendIncomeAmount]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.DividendIncomeAmount,
                fieldName: `dividend`,
                type: Str.currency,
                required: false,
            },
            [Str.OtherAmount]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.OtherAmount,
                fieldName: `distribution other`,
                type: Str.currency,
                required: false,
            },
        },
    },
    [DocumentType.TaxDocument]: {
        canGenerateTransactions: false,
        name: DocumentType.TaxDocument,
        meta: {
            [Str.DocumentTaxYear]: {
                doesNotGenerateTransactions: true,
                placeholder: `Select Document Tax Year`,
                label: Str.DocumentTaxYear,
                fieldName: `tax year`,
                type: `select`,
                dataType: `number`,
                options: [...Array(21)].map((_, i) => new Date().getFullYear() - i),
                optionClearable: true,
            },
            [Str.TaxFormType]: {
                doesNotGenerateTransactions: true,
                placeholder: `Select Type`,
                label: Str.TaxFormType,
                fieldName: `form type`,
                type: `select`,
                options: Object.values(TaxDocumentSubtypes),
                parse: parseTaxFormType,
                optionClearable: true,
            },
        },
    },
    [DocumentType.Other]: {
        canGenerateTransactions: false,
        name: DocumentType.Other,
        meta: {},
    },
    [DocumentType.PPMOM]: {
        name: DocumentType.PPMOM,
        canGenerateTransactions: false,
        meta: {},
    },
    [DocumentType.PrivacyNotice]: {
        name: DocumentType.PrivacyNotice,
        canGenerateTransactions: false,
        meta: {},
    },
    [DocumentType.Disclosure]: {
        name: DocumentType.Disclosure,
        canGenerateTransactions: false,
        meta: {},
    },
    [DocumentType.InvestmentUpdate]: {
        name: DocumentType.InvestmentUpdate,
        canGenerateTransactions: false,
        meta: {},
    },
    [DocumentType.LegalAgreement]: {
        name: DocumentType.LegalAgreement,
        canGenerateTransactions: false,
        meta: {},
    },
    [DocumentType.WireInstructionConfirmation]: {
        name: DocumentType.WireInstructionConfirmation,
        canGenerateTransactions: false,
        meta: {},
    },
    [DocumentType.ReportingAuthorizationLetter]: {
        name: DocumentType.ReportingAuthorizationLetter,
        canGenerateTransactions: false,
        meta: {},
    },
    [DocumentType.NetCapitalCall]: {
        name: DocumentType.NetCapitalCall,
        canGenerateTransactions: true,
        meta: {
            [Str.EffectiveDate]: {
                label: Str.EffectiveDate,
                fieldName: `effective date`,
                type: `date`,
                required: true,
            },
            [Str.CapitalCallAmount]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.CapitalCallAmount,
                fieldName: `capital called`,
                type: Str.currency,
                required: true,
            },
            [Str.ProfitDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.ProfitDistribution,
                fieldName: `profit distribution`,
                type: Str.currency,
                required: false,
                or: [Str.ProfitDistribution, Str.PrincipalDistribution, Str.RecallableProfitDistribution, Str.RecallablePrincipalDistribution],
            },
            [Str.PrincipalDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.PrincipalDistribution,
                fieldName: `principal distribution`,
                type: Str.currency,
                required: false,
                or: [Str.ProfitDistribution, Str.PrincipalDistribution, Str.RecallableProfitDistribution, Str.RecallablePrincipalDistribution],
            },
            [Str.RecallableProfitDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.RecallableProfitDistribution,
                fieldName: `recallable profit distribution`,
                type: Str.currency,
                required: false,
                or: [Str.ProfitDistribution, Str.PrincipalDistribution, Str.RecallableProfitDistribution, Str.RecallablePrincipalDistribution],
            },
            [Str.RecallablePrincipalDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.RecallablePrincipalDistribution,
                fieldName: `recallable principal distribution`,
                type: Str.currency,
                required: false,
                or: [Str.ProfitDistribution, Str.PrincipalDistribution, Str.RecallableProfitDistribution, Str.RecallablePrincipalDistribution],
            },
            [Str.ManagementFee]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.ManagementFee,
                fieldName: Str.ManagementFee.toLowerCase(),
                type: Str.currency,
            },
        },
    },
    [DocumentType.NetDistribution]: {
        name: DocumentType.NetDistribution,
        canGenerateTransactions: true,
        meta: {
            [Str.EffectiveDate]: {
                label: Str.EffectiveDate,
                fieldName: `effective date`,
                type: `date`,
                required: true,
            },
            [Str.CapitalCallAmount]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.CapitalCallAmount,
                fieldName: `capital called`,
                type: Str.currency,
                required: true,
            },
            [Str.ProfitDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.ProfitDistribution,
                fieldName: `profit distribution`,
                type: Str.currency,
                required: false,
                or: [Str.ProfitDistribution, Str.PrincipalDistribution, Str.RecallableProfitDistribution, Str.RecallablePrincipalDistribution],
            },
            [Str.PrincipalDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.PrincipalDistribution,
                fieldName: `principal distribution`,
                type: Str.currency,
                required: false,
                or: [Str.ProfitDistribution, Str.PrincipalDistribution, Str.RecallableProfitDistribution, Str.RecallablePrincipalDistribution],
            },
            [Str.RecallableProfitDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.RecallableProfitDistribution,
                fieldName: `recallable profit distribution`,
                type: Str.currency,
                required: false,
                or: [Str.ProfitDistribution, Str.PrincipalDistribution, Str.RecallableProfitDistribution, Str.RecallablePrincipalDistribution],
            },
            [Str.RecallablePrincipalDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.RecallablePrincipalDistribution,
                fieldName: `recallable principal distribution`,
                type: Str.currency,
                required: false,
                or: [Str.ProfitDistribution, Str.PrincipalDistribution, Str.RecallableProfitDistribution, Str.RecallablePrincipalDistribution],
            },
            [Str.ManagementFee]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.ManagementFee,
                fieldName: Str.ManagementFee.toLowerCase(),
                type: Str.currency,
            },
        },
    },
    [DocumentType.HedgeFund]: {
        name: DocumentType.HedgeFund,
        canGenerateTransactions: true,
        meta: {
            [Str.Timeframe]: {
                label: Str.Timeframe,
                fieldName: Str.Timeframe.toLowerCase(),
                type: `select`,
                required: true,
                or: [Str.PeriodStart],
                options: Object.keys(TimeframeOptions),
                optionClearable: true,
                getLabel: (value) => TimeframeOptions[value],
                parse: timeframeParse,
            },
            [Str.PeriodStart]: {
                label: Str.PeriodStart,
                fieldName: Str.PeriodStart.toLowerCase(),
                type: `date`,
                required: true,
                or: [Str.Timeframe],
            },
            [Str.PeriodEnd]: {
                label: Str.PeriodEnd,
                fieldName: Str.PeriodEnd.toLowerCase(),
                type: `date`,
                required: true,
            },
            [Str.BeginningBalance]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.BeginningBalance,
                fieldName: Str.BeginningBalance.toLowerCase(),
                type: Str.currency,
                required: false,
            },
            [Str.EndingBalance]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.EndingBalance,
                fieldName: Str.EndingBalance.toLowerCase(),
                type: Str.currency,
                required: true,
            },
            [Str.Contributions]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.Contributions,
                fieldName: Str.Contributions.toLowerCase(),
                type: Str.currency,
                showShouldGenerateTransactionsToggle: true,
                shouldGenerateTransactionsDefault: true,
            },
            [Str.ContributionsDate]: {
                label: Str.ContributionsDate,
                fieldName: Str.ContributionsDate.toLowerCase(),
                type: `date`,
            },
            [Str.Withdrawals]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.Withdrawals,
                fieldName: Str.Withdrawals.toLowerCase(),
                type: Str.currency,
            },
            [Str.WithdrawalsDate]: {
                label: Str.WithdrawalsDate,
                fieldName: Str.WithdrawalsDate.toLowerCase(),
                type: `date`,
            },
            [Str.ProfitDistribution]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.ProfitDistribution,
                fieldName: Str.ProfitDistribution.toLowerCase(),
                type: Str.currency,
                showShouldGenerateTransactionsToggle: true,
                shouldGenerateTransactionsDefault: false,
            },
            [Str.ProfitDistributionDate]: {
                label: Str.ProfitDistributionDate,
                fieldName: Str.ProfitDistributionDate.toLowerCase(),
                type: `date`,
            },
            [Str.DividendIncomeAmount]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.DividendIncomeAmount,
                fieldName: `dividend`,
                type: Str.currency,
                required: false,
                showShouldGenerateTransactionsToggle: true,
                shouldGenerateTransactionsDefault: false,
            },
            [Str.DividendIncomeDate]: {
                label: Str.DividendIncomeDate,
                fieldName: 'dividend date',
                type: `date`,
            },
            [Str.AmountCommitted]: {
                placeholder: Str.eg$XXXXXXX,
                label: Str.AmountCommitted,
                fieldName: 'committed',
                type: Str.currency,
            },
        },
    },
};
export const DOCUMENT_TYPES = Object.fromEntries(
    Object.entries(DOCUMENT_TYPES_LIST).sort((a, b) => {
        return a[1].name.localeCompare(b[1].name);
    })
);

// console.log("DOCUMENT_TYPES", DOCUMENT_TYPES, DOCUMENT_TYPES_LIST);
