// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import '../../styles/widget.css';
import '../../styles/widget-MonthlyReturns.css';
import { calcROI } from '../../utilities/calculate/financeCalculations';
import { isFiniteNonzero } from '../../utilities/validation/numericalValidation';
import { EM_DASH } from '../../constants/constantStrings';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';

//All right, this one can stay...
const COLUMNS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

class MonthlyReturnsWidget extends Component {
    monthlyReturnsCell = (month, year, monthNum) => {
        let monthlyData = this.props.investment?.performance?.monthly ?? [];
        let data = monthlyData.find((md) => md.year === year && md.month === monthNum);
        let cssClass = 'widget-table-data-null';
        data = data?.snapshot?.roi;

        let widgetText = Number.isFinite(data)
            ? (100 * data).toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                  minimumFractionDigits: 1,
              }) + '%'
            : '—';
        if (data > 0) {
            cssClass = 'widget-table-data-positive';
        } else if (data < 0) {
            cssClass = 'widget-table-data-negative';
        }
        return (
            <td className={cssClass} key={monthNum.toString()}>
                {widgetText}
            </td>
        );
    };

    getTotalCell = (year) => {
        const yearROI = calcROI(this.props.investment, year);
        let cssClass = 'widget-table-data-null';
        let widgetText = yearROI
            ? (100 * yearROI).toLocaleString(undefined, {
                  maximumFractionDigits: 1,
                  minimumFractionDigits: 1,
              }) + '%'
            : EM_DASH;
        if (yearROI > 0) {
            cssClass = 'widget-table-data-positive';
        } else if (yearROI < 0) {
            cssClass = 'widget-table-data-negative';
        }
        return (
            <td className={cssClass} key={year.toString()}>
                {widgetText}
            </td>
        );
    };

    render = () => {
        let monthlyData = this.props.data ?? [];
        let latestYear = dateToUTCMidnight().getUTCFullYear();

        if (monthlyData) {
            let allYears = new Set();
            monthlyData.map((qd) => allYears.add(qd.year));
            let yearlyMax = Math.max(...allYears);
            latestYear = isFiniteNonzero(yearlyMax) ? yearlyMax : latestYear;
        }

        let displayYears = [];
        for (let i = latestYear; i > latestYear - 4; i--) {
            displayYears.push(i);
        }

        return (
            <div className="widget">
                <div className="widget-header">Monthly Returns</div>
                <table className="widget-table monthly-returns-table">
                    <thead>
                        <tr key="tableheader">
                            <th key="h_null" />
                            {COLUMNS.map((month) => (
                                <th key={'mthyear' + month}>{month}</th>
                            ))}
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayYears.map((year) => (
                            <tr key={'row_' + year}>
                                <th key="year_col">{year}</th>
                                {COLUMNS.map((month, idx) => this.monthlyReturnsCell(month, year, idx))}
                                {this.getTotalCell(year)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };
}

export default withRouter(MonthlyReturnsWidget);
