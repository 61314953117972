import React from 'react';

export type ErrorRecord = Record<string, string | undefined> | string | undefined;

interface RenderInputErrorProps {
    errorObjOrStr: ErrorRecord;
    field: string;
    alwaysFullSize?: boolean;
}

// Helper function implementation
function renderInputError(errorObjOrStr: ErrorRecord, field: string, alwaysFullSize: boolean = true): JSX.Element | null {
    if (!errorObjOrStr || !field) return null;

    let errStr: string = '';
    if (typeof errorObjOrStr === 'string') {
        errStr = errorObjOrStr;
    } else if (typeof errorObjOrStr === 'object') {
        errStr = errorObjOrStr[field] ?? '';
    }

    const THRESHOLD = 50;
    const style = {
        height: alwaysFullSize || errStr ? `${Math.max(1, Math.ceil(errStr.length / THRESHOLD)) * 16}px` : '0px',
    };

    return (
        <div className={`error${errStr ? ' error-visible' : ''}`} style={style} data-testid="error-message">
            {errStr}
        </div>
    );
}

// React component implementation
const RenderInputError: React.FC<RenderInputErrorProps> = ({ errorObjOrStr, field, alwaysFullSize = true }) => renderInputError(errorObjOrStr, field, alwaysFullSize);

export { renderInputError, RenderInputError };
export default renderInputError;
