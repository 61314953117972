export const hashBuffer = async (buffer) => {
    const hash = await crypto.subtle.digest('SHA-256', buffer);
    // Convert hash to hex string
    const hashHex = Array.from(new Uint8Array(hash))
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');
    return hashHex;
};

export const hashString = async (str) => {
    const buffer = new TextEncoder().encode(str);
    return hashBuffer(buffer);
};

export const hashFile = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async () => {
            const buffer = reader.result;
            const hashHex = hashBuffer(buffer);
            resolve(hashHex);
        };
        reader.readAsArrayBuffer(file);
    });
};
