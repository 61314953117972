import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import api from '../../api';
import api2 from '../../api2';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import { DOCUMENT_TYPES } from '../../pages/DocumentsPage/documentDrawer/documentFieldsConstants';
import saveZip from '../../utilities/file/saveZip';
import downloadZip from '../../utilities/file/downloadZip';
import moment from 'moment';
import { downloadDocumentZip, getFullDocFilename } from '../../utilities/documents/downloadDocument';

const failedCount = (results) => results.filter((r) => !r?.success).length;

export async function deleteDocuments(documentIds) {
    try {
        await Promise.all(
            documentIds.map(async (id) => {
                await api2.client.DocumentApi.deleteDocument({
                    document_id: id,
                });
            })
        );
        return true;
    } catch (error) {
        console.error('Error deleting documents', error);
        return false;
    }
}

export async function changeDocumentTypes(documents, documentType) {
    try {
        await Promise.all(
            documents.map(async (d) => {
                await api2.client.DocumentApi.updateDocument({
                    document_id: d._id,
                    UpdateDocumentRequest: {
                        type: documentType,
                    },
                });
            })
        );
        return true;
    } catch (error) {
        console.error('Error changing document types', error);
        return false;
    }
}

export async function setGenerateTransactions(documents, shouldGenerateTransactions) {
    try {
        await Promise.all(
            documents.map(async (d) => {
                await api2.client.DocumentApi.updateDocument({
                    document_id: d._id,
                    UpdateDocumentRequest: {
                        shouldGenerateTransactions,
                    },
                });
            })
        );
        return true;
    } catch (error) {
        console.error('Error setting generate transactions', error);
        return false;
    }
}

async function sendDocumentsToJob(documents, job_list) {
    const job_send_requests = documents.map((d) => api.post('/documents/' + d._id + '/jobs', { job_list }));
    const results = await Promise.all(job_send_requests);
    return results.filter((r) => !r).length === 0;
}

export async function sendDocumentsToExtraction(documents) {
    return await sendDocumentsToJob(documents, ['extract']);
}

export async function sendDocumentsToSort(documents) {
    return await sendDocumentsToJob(documents, ['sort']);
}

export async function sendDocumentsToPreprocess(documents) {
    return await sendDocumentsToJob(documents, ['preprocess']);
}

export async function regenerateTransactionsForDocuments(documents) {
    const results = await Promise.all(
        documents.map(async (d) => {
            return (
                await api2.client.DocumentApi.reprocessMetadata({
                    document_id: d._id,
                })
            ).data.success;
        })
    );
    // true if all successful, false if any failed
    return results.filter((r) => !r).length === 0;
}

function getTypeActionFunction(props, type_name) {
    return async (documentList, callables) => {
        const confirm = window.confirm('Are you sure you want to change the type of these documents?');
        if (!confirm) return;
        await props?.loading?.(320, 'documentBulkActions-changeType');
        const change_res = await changeDocumentTypes(documentList, type_name);
        await callables.reload();
        await props?.loaded?.('documentBulkActions-changeType');
        if (!change_res) {
            setDismissableAlert(props.setAlert, 'Error changing document types', true);
        }
    };
}

function getSetGenerateTransactionsFunction(props, generate_transactions_setting) {
    return async (documentList, callables) => {
        const confirm = window.confirm('Are you sure you want to change the transaction generation setting of these documents?');
        if (!confirm) return;
        await props?.loading?.(320, 'documentBulkActions-setGenerateTransactions');
        const change_res = await setGenerateTransactions(documentList, generate_transactions_setting);
        await callables.reload();
        await props?.loaded?.('documentBulkActions-setGenerateTransactions');
        if (!change_res) {
            setDismissableAlert(props.setAlert, 'Error changing document transaction generation settings', true);
        }
    };
}

export default function getBulkActions(props) {
    let bulkActions = [
        {
            label: 'Delete selected',
            investor: true,
            action: async (documentList, callables) => {
                const confirm = window.confirm('Are you sure you want to delete these documents?');
                if (!confirm) return;
                await props?.loading?.(320, 'documentBulkActions-delete');
                const delete_res = await deleteDocuments(documentList?.map((d) => d._id));
                await callables.reload();
                await props?.loaded?.('documentBulkActions-delete');
                if (!delete_res) {
                    return setDismissableAlert(props.setAlert, 'Error deleting documents', true);
                }
            },
        },
        {
            label: 'Change type',
            investor: true,
            action: async (documentList, callables) => {},
            subOptions: [
                {
                    label: 'Unsorted',
                    action: getTypeActionFunction(props, null),
                },
                ...Object.keys(DOCUMENT_TYPES).map((type) => {
                    return {
                        label: type,
                        action: getTypeActionFunction(props, type),
                    };
                }),
            ],
        },
        {
            label: 'Set Generate Transactions',
            investor: true,
            action: async (documentList, callables) => {},
            subOptions: [
                {
                    label: 'Yes',
                    action: getSetGenerateTransactionsFunction(props, true),
                },
                {
                    label: 'No',
                    action: getSetGenerateTransactionsFunction(props, false),
                },
            ],
        },
        {
            label: 'Send to Sort',
            action: async (documentList, callables) => {
                const confirm = window.confirm('Are you sure you want to send these documents to Sort?');
                if (!confirm) return;
                await props?.loading?.(320, 'documentBulkActions-sendToSort');
                const sort_res = await sendDocumentsToSort(documentList);
                await props?.loaded?.('documentBulkActions-sendToSort');
                if (!sort_res) {
                    setDismissableAlert(props.setAlert, 'Error sending documents to Sort', true);
                }
            },
        },
        {
            label: 'Send to Extraction',
            action: async (documentList, callables) => {
                const confirm = window.confirm('Are you sure you want to send these documents to Extraction?');
                if (!confirm) return;
                await props?.loading?.(320, 'documentBulkActions-sendToExtraction');
                const extraction_res = await sendDocumentsToExtraction(documentList);
                await props?.loaded?.('documentBulkActions-sendToExtraction');
                if (!extraction_res) {
                    setDismissableAlert(props.setAlert, 'Error sending documents to Extraction', true);
                }
            },
        },
        {
            label: 'Send to Preprocess',
            action: async (documentList, callables) => {
                const confirm = window.confirm('Are you sure you want to send these documents to Preprocess?');
                if (!confirm) return;
                await props?.loading?.(320, 'documentBulkActions-sendToPreprocess');
                const preprocess_res = await sendDocumentsToPreprocess(documentList);
                await props?.loaded?.('documentBulkActions-sendToPreprocess');
                if (!preprocess_res) {
                    setDismissableAlert(props.setAlert, 'Error sending documents to Preprocess', true);
                }
            },
        },
        {
            label: 'Regenerate transactions from metadata',
            action: async (documentList, callables) => {
                const confirm = window.confirm('Are you sure you want to regenerate transactions for these documents?');
                if (!confirm) return;
                await props?.loading?.(320, 'documentBulkActions-regenerateTransactions');
                const regenerate_res = await regenerateTransactionsForDocuments(documentList);
                await props?.loaded?.('documentBulkActions-regenerateTransactions');
                if (!regenerate_res) {
                    setDismissableAlert(props.setAlert, 'Error regenerating transactions', true);
                }
            },
        },
        {
            label: 'Download selected',
            investor: true,
            action: async (documentList, callables) => {
                // prompt to verify download
                const confirm = window.confirm('Are you sure you want to download these documents?');
                if (!confirm) return;
                // download the documents as a zip
                await props?.loading?.(320, 'documentBulkActions-download');

                try {
                    await downloadDocumentZip(
                        documentList.map((d) => d._id),
                        getFullDocFilename
                    );
                    setDismissableAlert(props.setAlert, 'Documents downloaded successfully');
                } catch (error) {
                    setDismissableAlert(props.setAlert, 'Error downloading documents', true);
                }

                await props?.loaded?.('documentBulkActions-download');
            },
        },
    ];
    // If not admin vue, just allow a few of the actions
    if (props?.vue !== 'admin') {
        bulkActions = bulkActions.filter((b) => b.investor);
    }
    // console.log("bulkActions", bulkActions)
    return bulkActions;
}
