import { FileParseResult, FileTextResult } from '../../../../constants/file';
import BulkUploadHandler, { BulkUploadHandlerCreateType, BulkUploadHandlerConfig } from './BulkUploadHandler';
import sharedFields from '../types/sharedFields';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
import api2 from '../../../../api2';

export interface CommitmentUploadHandlerCreateType extends BulkUploadHandlerCreateType {
    amount?: number;
    date?: Date | string;
    document?: string;
    investment?: string;
}

export interface CommitmentUploadHandlerConfig extends BulkUploadHandlerConfig {
    investment?: string;
}

class CommitmentUploadHandler extends BulkUploadHandler<CommitmentUploadHandlerCreateType, CommitmentUploadHandlerConfig> {
    // The type of the bulk upload, eg. 'commitment' or 'valuation'
    type = BulkUploadType.commitment;

    // The base columns that are required for the bulk upload
    base_columns = {
        amount: {
            ...sharedFields.number,
            displayName: 'Amount',
            fieldName: 'amount',
            required: true, // Indicates that this field is required.
        },
        date: {
            ...sharedFields.date,
            displayName: 'Date',
            fieldName: 'date',
            required: true, // Indicates that this field is required.
        },
        document: {
            ...sharedFields.object_id,
            displayName: 'Document',
            fieldName: 'document',
            required: false,
        },
        investment: {
            ...sharedFields.object_id,
            displayName: 'Investment',
            fieldName: 'investment',
        },
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof CommitmentUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (columnOrder: (keyof CommitmentUploadHandlerCreateType)[] = this.columnOrder): { [key in keyof CommitmentUploadHandlerCreateType]: BulkUploadColumn } => {
        // Sort the columns based on the given column order or the default order.
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);

        // set up special conditions based on the config
        if (this.config.investment) {
            // remove investment column if investment is provided
            delete sortedColumns.investment;
        } else if (sortedColumns.investment) {
            // set investment as required if not provided
            sortedColumns.investment.required = true;
        }

        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof CommitmentUploadHandlerCreateType]: string }, columnOrder?: (keyof CommitmentUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        return isDataValid;
    };

    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @returns {Object} An object with the correct fields for the line.
     */
    parseSingleCsvLine = (line: string, columnOrder?: (keyof CommitmentUploadHandlerCreateType)[]): CommitmentUploadHandlerCreateType => {
        // if not enough commas are included, the fields will be empty strings
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        // replaces extra characters in the amount field
        if (parsedValues.amount) {
            parsedValues.amount = expectedColumns.amount?.format(parsedValues.amount).toString();
        }

        // set up special conditions based on the config and data type
        return {
            amount:
                expectedColumns.amount && expectedColumns.amount.isValid(parsedValues.amount)
                    ? expectedColumns.amount.format(parsedValues.amount)
                    : expectedColumns.amount?.defaultValue || 0,

            date: expectedColumns.date && expectedColumns.date.isValid(parsedValues.date) ? new Date(parsedValues.date).toISOString().split('T')[0] : '',

            document: expectedColumns.date && expectedColumns.date.isValid(parsedValues.date) ? parsedValues.document : '',

            // Only include the investment if it's provided and valid.
            ...(parsedValues.investment
                ? {
                      investment: expectedColumns.investment && expectedColumns.investment.isValid(parsedValues.investment) ? parsedValues.investment : '',
                  }
                : {}),
        };
    };

    // Parse the text file results into a FileParseResult
    parseTextFileResult = async (
        textFileResult: FileTextResult,
        columnOrder?: (keyof CommitmentUploadHandlerCreateType)[]
    ): Promise<FileParseResult<CommitmentUploadHandlerCreateType>> => {
        try {
            const data = textFileResult.lines.map((line) => this.parseSingleCsvLine(line, columnOrder));
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<CommitmentUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<CommitmentUploadHandlerCreateType>;
        }
    };

    // Get the notes for the bulk upload type
    getNotes = (): string[] => {
        let notes: string[] = [];
        if (this.config.investment) {
            notes = [...notes];
        }
        return notes;
    };

    // function to create the object in the database from the parsed data
    create = async (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof CommitmentUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        // Ensure the data is valid
        if (!this.isDataValid(data, columnOrder)) {
            return { success: false, message: 'Invalid data' };
        }

        // require investment from config or in data
        if (!this.config.investment && !data.investment) {
            return { success: false, message: 'Investment ID is required' };
        }

        try {
            // Attempt to create a single commitment from the current object.
            const commitment_id = (
                await api2.client.CommitmentApi.createCommitment({
                    CreateCommitmentRequest: {
                        amount: data.amount,
                        ...(data.document && { documents: [data.document] }),
                        investment: this.config.investment || data.investment,
                        // Ensure the date is in ISO format if it's a Date object; otherwise, use it as is.
                        date: new Date(data.date).toISOString().split('T')[0],
                    },
                })
            ).data.commitment_id;

            // Return the new commitment ID.
            return {
                success: true,
                message: 'Commitment created successfully',
                id: commitment_id,
            };
        } catch (err) {
            // Log and return null in case of an error.
            console.log('error creating commitment:', err);
            return {
                success: false,
                message: `Error creating commitment: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            await api2.client.CommitmentApi.deleteCommitment({
                commitment_id: id,
            });
            return {
                success: true,
                message: 'Commitment deleted successfully',
            };
        } catch (err) {
            console.log('error deleting commitment:', err);
            return {
                success: false,
                message: `Error deleting commitment: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the CommitmentUploadHandler
export const getCommitmentUploadHandler: (config: CommitmentUploadHandlerConfig) => CommitmentUploadHandler = (config: CommitmentUploadHandlerConfig) =>
    new CommitmentUploadHandler(config);

export default CommitmentUploadHandler;
