import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';
import config from './config';

const container = document.getElementById('root');
ReactDOM.render(
    <GoogleOAuthProvider clientId={config.googleClientId}>
        <App />
    </GoogleOAuthProvider>,
    container
);
