// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory
import React, { Component } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import EmptyOverlay from '../../EmptyStates/EmptyOverlay';
import emptyStateData from '../../EmptyStates/emptyStateFakeData';
import '../../../styles/widget.css';
import '../../../styles/widget-AccountPerformance.css';
import routes from '../../../routes';

interface Investment {
    _id: string;
    name: string;
    performance: {
        itd: {
            irr: number;
        };
    };
}

interface AccountPerformanceProps extends RouteComponentProps {
    showEmptyState: boolean;
    investments: Investment[];
    account?: {
        _id: string;
    };
}

class AccountPerformance extends Component<AccountPerformanceProps> {
    renderAmount = (amount: number, row: number) => {
        amount = amount ? amount * 100 : 0;
        let className = 'performance-summary-zero-amount';
        if (amount < 0) {
            className = 'performance-summary-negative-amount';
        } else if (amount) {
            className = 'performance-summary-positive-amount';
        }

        let digits = 0;
        if (amount > -100 && amount < 100) {
            digits = 1;
        }

        let displayVal = amount.toLocaleString('en-US', { maximumFractionDigits: digits, minimumFractionDigits: digits });
        if (amount > 0) {
            displayVal = '+ ' + displayVal;
        } else if (amount < 0) {
            displayVal = '- ' + displayVal.slice(1);
        }
        displayVal = displayVal + '%';
        return (
            <td className={className + ' performance-summary-amount'} key={'accountperformance_' + row + '_stat'}>
                {displayVal}
            </td>
        );
    };

    calculateGainLoss = (gain: number, contributions: number) => {
        return gain;
    };

    renderInvestment = (inv: Investment, rowIdx: number) => {
        if (!inv) return null;
        const invDetailPath = routes.investor.investmentDetails(this.props?.account?._id ?? '', inv?._id ?? '');
        return (
            <tr key={'accountPerformanceRow' + rowIdx}>
                <td key={'accountPerformanceRow' + rowIdx + '_name'} style={{ cursor: 'pointer' }} className="performance-summary-name" title={inv?.name}>
                    <a href={invDetailPath}>{inv?.name ?? ''}</a>
                </td>
                {this.renderAmount(inv?.performance?.itd?.irr, rowIdx)}
            </tr>
        );
    };

    renderSplitTable = (investments: Investment[]) => {
        return (
            <tbody>
                <tr>
                    <th>Top Performers</th>
                </tr>
                {investments.slice(0, 3).map(this.renderInvestment)}
                <tr>
                    <th>Under Achievers</th>
                </tr>
                {investments.reverse().slice(0, 3).reverse().map(this.renderInvestment)}
            </tbody>
        );
    };

    renderSolidTable = (investments: Investment[]) => {
        return <tbody>{investments.length && investments.map(this.renderInvestment)}</tbody>;
    };

    render = () => {
        // get investments from the fake data if props.showEmptyState is true
        let investments = this.props.showEmptyState
            ? emptyStateData.AccountPerformanceWidget.props.investments.map((inv, idx) => ({ ...inv, _id: inv._id || `fake-id-${idx}` }))
            : this.props.investments;

        if (investments.length) {
            investments =
                investments
                    ?.slice()
                    .filter((i) => i?.performance?.itd?.irr || i?.performance?.itd?.irr === 0)
                    .sort((i1, i2) => {
                        return i2?.performance?.itd?.irr === i1?.performance?.itd?.irr ? 0 : i2?.performance?.itd?.irr > i1?.performance?.itd?.irr ? 1 : -1;
                    }) ?? [];
        }

        let splitTable = false;

        if (investments.length > 8) {
            splitTable = true;
        }

        return (
            <EmptyOverlay
                isEmpty={this.props.showEmptyState}
                emptyText={emptyStateData.AccountPerformanceWidget.emptyText}
                textStyle={emptyStateData.AccountPerformanceWidget.textStyle}
            >
                <div className="widget">
                    <div className="account-performance-header">
                        <h1 className="account-performance-header">Performance</h1>
                        <div>
                            <Link className="performance-see-all" to={`/accounts/${this.props?.account?._id ?? 'all'}/details`}>
                                see all investments
                            </Link>
                        </div>
                    </div>

                    <table className="account-performance-summary-table">{splitTable ? this.renderSplitTable(investments) : this.renderSolidTable(investments)}</table>
                </div>
            </EmptyOverlay>
        );
    };
}

export default withRouter(AccountPerformance);
