import { ListDocumentsOwnershipTypeEnum } from '../openApiClient';

export enum DocumentType {
    Statement = 'Capital Account Statement',
    CapitalCall = 'Capital Call Notice',
    Distribution = 'Distribution Notice',
    TaxDocument = 'Tax Document',
    Other = 'Other',
    NetCapitalCall = 'Net Capital Call',
    NetDistribution = 'Net Distribution',
    HedgeFund = 'Hedge Fund',
    PPMOM = 'PPM/OM',
    PrivacyNotice = 'Privacy Notice',
    Disclosure = 'Disclosure / ADV',
    InvestmentUpdate = 'Investment Update',
    LegalAgreement = 'Legal Agreement',
    WireInstructionConfirmation = 'Wire Instruction / Confirmation',
    ReportingAuthorizationLetter = 'Reporting Authorization Letter',
}

export interface DocumentUser {
    _id: string;
    name: string;
}

export interface DocumentOwnership {
    _id: string;
    name: string;
    type: string;
    currency: string;
    user?: DocumentUser;
    account?: {
        _id: string;
        name: string;
    };
}

export interface DocumentMetadata {
    key: string;
    value: any;
    investment?: string; // Assuming ObjectId is represented as a string
    tags: {
        page: number;
        line: number;
        word: number;
        text: string;
    }[];
    shouldGenerateTransactions: boolean;
}

export interface DocumentFile {
    words: string;
    doc: string;
    stax_doc: string;
    ocr_result: string;
    pages: string[];
}

export interface DocumentTraining {
    sort: boolean;
    extraction: boolean;
}

export interface AuditLogSharedPopulateJsonSchemaOutputFields {
    action: string;
    target: string;
    target_type: string;
    initiator: any; // Type not specified, assuming any
    initiator_type: string;
    timestamp: string; // Assuming ISO 8601 format date-time string
    action_details: {
        login_success?: boolean;
        field_modifications?: {
            field: string;
            old_value: any; // Type not specified, assuming any
            new_value: any; // Type not specified, assuming any
        }[];
    };
}

export interface Document {
    _id: string;
    user?: DocumentUser;
    ownership_ids: DocumentOwnership[];
    ownership_type: ListDocumentsOwnershipTypeEnum;
    connection?: {
        _id: string;
        name: string;
    };
    metadata: DocumentMetadata[];
    name: string;
    type?: string;
    hasValidMetadata: boolean;
    shouldGenerateTransactions: boolean;
    date: string;
    hash: string;
    file_type: string;
    files: DocumentFile;
    training: DocumentTraining;
    post_job_review_required: DocumentTraining;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
    file_download_urls?: {
        words?: string;
        doc?: string;
        stax_doc?: string;
        ocr_result?: string;
        pages: string[];
    };
    audit_log?: AuditLogSharedPopulateJsonSchemaOutputFields[];
}
