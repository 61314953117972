import validator from 'validator';
import api from '../../../../api';
import api2 from '../../../../api2';
import { FileParseResult, FileTextResult } from '../../../../constants/file';
import { getInvestmentSubtypes, getInvestmentTypes } from '../../../../pages/Account/AddManualInvestmentStages/investmentTypeConstants';
import { Currency } from '../../../../types/Currency';
import { InvestmentStatuses } from '../../../../types/Investment';
import { generateInvestmentMasterTicker } from '../../../../utilities/string/generateInvestmentMasterTicker';
import isExtendedAscii from '../../../../utilities/string/isExtendedAscii';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
import sharedFields, { isObjectId, sharedFieldEnum } from '../types/sharedFields';
import BulkUploadHandler, { BulkUploadHandlerConfig, BulkUploadHandlerCreateType } from './BulkUploadHandler';

export interface InvestmentMasterUploadHandlerConfig extends BulkUploadHandlerConfig {}

export interface InvestmentMasterUploadHandlerCreateType extends BulkUploadHandlerCreateType {
    ticker?: string;
    name?: string;
    asset_manager?: string;
    description?: string;
    type?: string;
    sub_type?: string;
    website?: string;

    currency?: string;
    price?: string;
    exchange?: string;
    vintage_year?: string;
    status?: string;

    twitter?: string;
    facebook?: string;
    linkedin?: string;
    instagram?: string;

    contacts?: string; // slash separated list of contact ids
}

export interface InvestmentMasterUploadHandlerState {
    exchanges: any[];
    altx_exchange?: any;
}

const investmentTypes = getInvestmentTypes();
const investmentTypeNames = investmentTypes.map((type) => type.name);
const investmentSubTypeNames = investmentTypes.map((type) => type.types || []).flat();

// enum fields
const investmentTypeField = sharedFieldEnum(investmentTypeNames);
const investmentSubTypeField = sharedFieldEnum(investmentSubTypeNames);
const currencyField = sharedFieldEnum(Object.values(Currency));
const statusField = sharedFieldEnum(Object.values(InvestmentStatuses));

export const getExchangeField = (exchanges: any[]) => {
    return {
        ...sharedFieldEnum(exchanges.map((plan) => plan.name)),
        displayName: 'Exchange',
        fieldName: 'exchange',
        isValid: (val: string) => {
            return (
                exchanges.map((plan) => plan.name.toLowerCase()).includes(val.toLowerCase()) ||
                exchanges.map((plan) => plan._id).includes(val) ||
                exchanges.map((plan) => plan.ticker_prefix.toLowerCase()).includes(val.toLowerCase())
            );
        },
        format: (val: string) => {
            return exchanges.find((plan) => [plan.ticker_prefix.toLowerCase(), plan._id, plan.name.toLowerCase()].includes(val.toLowerCase()))?.name || '';
        },
        required: true,
        style: { width: '250px' },
    };
};

export const onLoadHandler = async (
    thisHandler: BulkUploadHandler<InvestmentMasterUploadHandlerCreateType, InvestmentMasterUploadHandlerConfig, InvestmentMasterUploadHandlerState>
) => {
    try {
        const response = await api2.client.ExchangeApi.listExchanges({});
        const exchanges = response.data.exchanges || [];
        const altx_exchange = exchanges.find((ex) => ex.ticker_prefix === 'ALTX');

        // set the state for the handler
        thisHandler.state = {
            exchanges,
            altx_exchange,
        };

        // set "exchange" base_columns field, needs to be done here because it requires the exchanges data
        thisHandler.base_columns.exchange = getExchangeField(exchanges);
    } catch (error) {
        console.error('Error loading exchanges:', error);
        thisHandler.state = {
            exchanges: [],
        };
    }
};

class InvestmentMasterUploadHandler extends BulkUploadHandler<InvestmentMasterUploadHandlerCreateType, InvestmentMasterUploadHandlerConfig, InvestmentMasterUploadHandlerState> {
    // The type of the bulk upload, eg. 'user' or 'valuation'
    type = BulkUploadType.investment_master;

    // The base columns that are required for the bulk upload
    base_columns = {
        ticker: {
            ...sharedFields.string,
            displayName: 'Ticker',
            fieldName: 'ticker',
            isValid: (ticker: string) => {
                const isRightLength =
                    validator.isLength(ticker + '', { min: 3, max: 12 }) && validator.isAlphanumeric(ticker + '', 'en-US', { ignore: '-' }) && validator.isUppercase(ticker + '');
                const split = ticker.split('-');
                if (split.length !== 2) return false;
                return isRightLength;
            },
            invalidMessage:
                'The ticker must be between 3 and 12 characters, contain only Uppercase alphanumeric characters, and must be in the format <exchange_ticker_prefix>-<investment_ticker>',
            required: false, // ticker is generated from name if not provided
        },
        exchange: getExchangeField([]),
        type: {
            ...investmentTypeField,
            displayName: 'Type',
            fieldName: 'type',
            isValid: (type: string) => investmentTypeNames.map((t) => t.toLowerCase()).includes(type.toLowerCase()),
            format: (type: string) => investmentTypes.find((t) => t.name.toLowerCase() === type.toLowerCase())?.name || '',
            required: true,
        },
        sub_type: {
            ...investmentSubTypeField,
            displayName: 'Sub Type',
            fieldName: 'sub_type',
            isValid: (sub_type: string, data: InvestmentMasterUploadHandlerCreateType) => {
                if (!sub_type) return true;
                const subtypes = data?.type ? getInvestmentSubtypes(data.type) : [];
                return subtypes.map((s: string) => s.toLowerCase()).includes(sub_type.toLowerCase());
            },
            format: (sub_type: string) => investmentSubTypeNames.find((t) => t.toLowerCase() === sub_type.toLowerCase()) || '',
            invalidMessage: 'The sub type must be a valid sub type for the selected investment type',
            getEnumOptions: (data: any) => {
                return data.type ? getInvestmentSubtypes(data.type) || investmentSubTypeField.options || [] : investmentSubTypeField.options || [];
            },
            required: false,
        },
        name: {
            ...sharedFields.string,
            displayName: 'Name',
            fieldName: 'name',
            isValid: (name: string) => !name || (validator.isLength(name + '', { min: 2, max: 140 }) && isExtendedAscii(name + '')),
            invalidMessage: 'The name must be between 2 and 140 characters and contain only ascii characters',
            required: true,
        },
        asset_manager: {
            ...sharedFields.object_id,
            displayName: 'Asset Manager',
            fieldName: 'asset_manager',
            required: false,
        },
        description: {
            ...sharedFields.string,
            displayName: 'Description',
            fieldName: 'description',
            required: false,
        },
        website: {
            ...sharedFields.string,
            displayName: 'Website',
            fieldName: 'website',
            isValid: (website: string) => !website || validator.isURL(website + '', { require_protocol: true }),
            invalidMessage: 'The website must be a valid URL',
            required: false,
        },
        currency: {
            ...currencyField,
            displayName: 'Currency',
            fieldName: 'currency',
            isValid: (currency: string) =>
                Object.values(Currency)
                    .map((value) => value.toLowerCase())
                    .includes(currency.toLowerCase()),
            format: (currency: string) => Object.keys(Currency).find((key) => Currency[key as keyof typeof Currency].toLowerCase() === currency.toLowerCase()) || '',
            required: false,
        },
        price: {
            ...sharedFields.string,
            displayName: 'Price',
            fieldName: 'price',
            required: false,
        },
        vintage_year: {
            ...sharedFields.string,
            displayName: 'Vintage Year',
            fieldName: 'vintage_year',
            isValid: (val: string) =>
                !val ||
                validator.isInt(val + '', {
                    min: 1900,
                    max: new Date().getFullYear(),
                }),
            required: false,
        },
        status: {
            ...statusField,
            displayName: 'Status',
            fieldName: 'status',
            isValid: (status: string) =>
                Object.values(InvestmentStatuses)
                    .map((value) => value.toLowerCase())
                    .includes(status.toLowerCase() as InvestmentStatuses),
            format: (status: string) =>
                Object.keys(InvestmentStatuses).find((key) => InvestmentStatuses[key as keyof typeof InvestmentStatuses].toLowerCase() === status.toLowerCase()) || '',
            required: false,
        },
        twitter: {
            ...sharedFields.string,
            displayName: 'Twitter',
            fieldName: 'twitter',
            // isValid: (val: string) => !val || validator.isURL(val + '', { require_protocol: true }),
            // invalidMessage: 'The twitter must be a valid URL',
            required: false,
        },
        facebook: {
            ...sharedFields.string,
            displayName: 'Facebook',
            fieldName: 'facebook',
            // isValid: (val: string) => !val || validator.isURL(val + '', { require_protocol: true }),
            // invalidMessage: 'The facebook must be a valid URL',
            required: false,
        },
        linkedin: {
            ...sharedFields.string,
            displayName: 'Linkedin',
            fieldName: 'linkedin',
            // isValid: (val: string) => !val || validator.isURL(val + '', { require_protocol: true }),
            // invalidMessage: 'The linkedin must be a valid URL',
            required: false,
        },
        instagram: {
            ...sharedFields.string,
            displayName: 'Instagram',
            fieldName: 'instagram',
            // isValid: (val: string) => !val || validator.isURL(val + '', { require_protocol: true }),
            // invalidMessage: 'The instagram must be a valid URL',
            required: false,
        },
        contacts: {
            ...sharedFields.string,
            displayName: 'Contacts',
            fieldName: 'contacts',
            isValid: (val: string) =>
                !val ||
                val
                    .split('/')
                    .map((x) => x.trim())
                    .filter((x) => x)
                    .every((id) => isObjectId(id)),
            invalidMessage: 'The contacts must be a slash separated list of valid ObjectId strings',
            required: false,
        },
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof InvestmentMasterUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (
        columnOrder: (keyof InvestmentMasterUploadHandlerCreateType)[] = this.columnOrder
    ): { [key in keyof InvestmentMasterUploadHandlerCreateType]: BulkUploadColumn } => {
        // Sort the columns based on the given column order or the default order.
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);
        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof InvestmentMasterUploadHandlerCreateType]: string }, columnOrder?: (keyof InvestmentMasterUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        // any additional validation goes here
        return isDataValid;
    };
    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @param {Array} [columnOrder] Optional array specifying the order of columns.
     * @returns {InvestmentMasterUploadHandlerCreateType} An object with the correct fields for the line.
     */
    parseSingleCsvLine(line: string, columnOrder?: (keyof InvestmentMasterUploadHandlerCreateType)[]) {
        // if not enough commas are included, the fields will be empty strings
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        // set up special conditions based on the config and data type
        const singleLineData: InvestmentMasterUploadHandlerCreateType = {
            ticker: expectedColumns.ticker && expectedColumns.ticker.isValid(parsedValues.ticker) ? parsedValues.ticker : '',
            name: expectedColumns.name && expectedColumns.name.isValid(parsedValues.name) ? parsedValues.name : '',
            asset_manager: expectedColumns.asset_manager && expectedColumns.asset_manager.isValid(parsedValues.asset_manager) ? parsedValues.asset_manager : '',
            description: expectedColumns.description && expectedColumns.description.isValid(parsedValues.description) ? parsedValues.description : '',
            type: expectedColumns.type && expectedColumns.type.isValid(parsedValues.type) ? expectedColumns.type.format(parsedValues.type) : '',
            sub_type:
                expectedColumns.sub_type && expectedColumns.sub_type.isValid(parsedValues.sub_type, parsedValues) ? expectedColumns.sub_type.format(parsedValues.sub_type) : '',
            website: expectedColumns.website && expectedColumns.website.isValid(parsedValues.website) ? parsedValues.website : '',
            currency: expectedColumns.currency && expectedColumns.currency.isValid(parsedValues.currency) ? expectedColumns.currency.format(parsedValues.currency) : '',
            price: expectedColumns.price && expectedColumns.price.isValid(parsedValues.price) ? parsedValues.price : '',
            exchange: expectedColumns.exchange && expectedColumns.exchange.isValid(parsedValues.exchange) ? expectedColumns.exchange.format(parsedValues.exchange) : '',
            vintage_year: expectedColumns.vintage_year && expectedColumns.vintage_year.isValid(parsedValues.vintage_year) ? parsedValues.vintage_year : '',
            status: expectedColumns.status && expectedColumns.status.isValid(parsedValues.status) ? expectedColumns.status.format(parsedValues.status) : '',
            twitter: expectedColumns.twitter && expectedColumns.twitter.isValid(parsedValues.twitter) ? parsedValues.twitter : '',
            facebook: expectedColumns.facebook && expectedColumns.facebook.isValid(parsedValues.facebook) ? parsedValues.facebook : '',
            linkedin: expectedColumns.linkedin && expectedColumns.linkedin.isValid(parsedValues.linkedin) ? parsedValues.linkedin : '',
            instagram: expectedColumns.instagram && expectedColumns.instagram.isValid(parsedValues.instagram) ? parsedValues.instagram : '',
            contacts: expectedColumns.contacts && expectedColumns.contacts.isValid(parsedValues.contacts) ? parsedValues.contacts : '',
        };

        const hasData = Object.values(singleLineData).some((v) => v);
        if (!hasData) {
            return null;
        }

        const exchange = this.state?.exchanges.find((e) => e._id === singleLineData.exchange);

        // generate the ticker if not provided, but name is provided
        if (singleLineData.name && !singleLineData.ticker) {
            // use the exchange ticker prefix if exchange is provided, otherwise use ALTX
            const ticker_prefix = exchange?.ticker_prefix ?? 'ALTX';
            singleLineData.ticker = `${ticker_prefix}-` + generateInvestmentMasterTicker(singleLineData.name);
        }
        if (!exchange) {
            // if no exchange is provided, if the provided ticker has a known prefix, set the exchange to the known exchange
            const ticker_prefix = singleLineData.ticker?.split('-')[0];
            if (ticker_prefix) {
                singleLineData.exchange = this.state?.exchanges.find((e) => e.ticker_prefix === ticker_prefix)?.name ?? '';
            } else {
                // otherwise, set the exchange to ALTX if not provided
                singleLineData.exchange = this.state?.altx_exchange?.name ?? '';
            }
        } else {
            singleLineData.exchange = exchange.name;
        }

        return singleLineData;
    }

    /**
     * Parses the text file results into a FileParseResult.
     * @param {FileTextResult} textFileResult The result of reading the text file.
     * @param {Array} [columnOrder] Optional array specifying the order of columns.
     * @returns {Promise<FileParseResult<InvestmentMasterUploadHandlerCreateType>>} The parsed file result.
     */
    parseTextFileResult = async (
        textFileResult: FileTextResult,
        columnOrder?: (keyof InvestmentMasterUploadHandlerCreateType)[]
    ): Promise<FileParseResult<InvestmentMasterUploadHandlerCreateType>> => {
        try {
            let data = textFileResult.lines.map((line) => this.parseSingleCsvLine(line, columnOrder)).filter((d) => d) as InvestmentMasterUploadHandlerCreateType[];
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<InvestmentMasterUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<InvestmentMasterUploadHandlerCreateType>;
        }
    };

    /**
     * Gets the notes for the bulk upload type.
     * @returns {string[]} An array of notes.
     */
    getNotes = (): string[] => {
        let notes: string[] = [
            "The ticker prefix must match the exchange' ticker prefix, if both are provided.",
            'The ticker will be generated from the name if not provided.',
            'If not provided, the Exchange field will be generate from the ticker, or default to AltExchange if no ticker is provided.',
            'The ticker prefix will be generated from the given Exchange if no ticker is provided, or default to ALTX if no exchange is provided.',
        ];
        if (this.config.investment) {
            notes = [...notes];
        }
        return notes;
    };

    /**
     * Creates the object in the database from the parsed data.
     * @param {Object} columnObj The column object.
     * @param {Object} data The data to create.
     * @param {Array} [columnOrder] Optional array specifying the order of columns.
     * @returns {Promise<BulkUploadRequestResult>} The result of the creation request.
     */
    create = async (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof InvestmentMasterUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        // Ensure the data is valid
        if (!this.isDataValid(data, columnOrder)) {
            return { success: false, message: 'Invalid data' };
        }

        const exchange_id = this.state?.exchanges.find((e) => e.name === data.exchange)?._id;

        try {
            const createBody: InvestmentMasterUploadHandlerCreateType = {
                ticker: data.ticker,
                name: data.name || undefined,
                asset_manager: data.asset_manager || undefined,
                description: data.description || undefined,
                type: data.type,
                sub_type: data.sub_type || undefined,
                website: data.website || undefined,
                currency: data.currency || Currency.USD,
                price: data.price || undefined,
                exchange: exchange_id,
                vintage_year: data.vintage_year || undefined,
                status: data.status || undefined,
            };

            // Add contacts if they exist, from a slash separated list of contact ids.
            if (data.contacts) {
                const contacts = data.contacts
                    .split('/')
                    .map((x: string) => x.trim())
                    .filter((x: string) => x);
                createBody.contacts = contacts;
            }

            // Add socials if they exist
            if (data.twitter || data.facebook || data.linkedin || data.instagram) {
                createBody.socials = {
                    twitter: data.twitter || undefined,
                    facebook: data.facebook || undefined,
                    linkedin: data.linkedin || undefined,
                    instagram: data.instagram || undefined,
                };
            }

            // Attempt to create a single investment_master from the current object.
            // const createRes = await apiClient.investment_masters.create(createBody);
            const createRes = await api.post('/investmentmasters', createBody, {}, true);

            if (createRes.success && createRes.investment?._id) {
                return {
                    success: true,
                    message: 'InvestmentMaster created successfully',
                    id: createRes.investment?._id,
                };
            } else {
                return {
                    success: false,
                    message: `Error creating investment master.`,
                };
            }
        } catch (err) {
            // Log and return null in case of an error.
            console.log('error creating investment master:', err);
            let errorMsg = (err as any)?.response?.data?.message || (err as any).message;
            // if (errorMsg.toLowerCase().includes('duplicate key')) {
            //     errorMsg = 'The ticker must be unique';
            // }
            return {
                success: false,
                message: `Error: ${errorMsg}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            await api.post(
                `/investmentmasters/delete`,
                {
                    _id: id,
                },
                {},
                true
            );
            return {
                success: true,
                message: 'InvestmentMaster deleted successfully',
            };
        } catch (err) {
            console.log('error deleting investment_master:', err);
            return {
                success: false,
                message: `Error deleting investment_master: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the InvestmentMasterUploadHandler
export const getInvestmentMasterUploadHandler: (config: InvestmentMasterUploadHandlerConfig) => InvestmentMasterUploadHandler = (config: InvestmentMasterUploadHandlerConfig) =>
    new InvestmentMasterUploadHandler(config, onLoadHandler);

export default InvestmentMasterUploadHandler;
