import _ from 'lodash';
import React, { Component } from 'react';

import Canvas from './Canvas';
import { getHoveredWord } from './helpers';

import '../../styles/DocumentPage.css';

class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cursorStyle: 'default',
        };
        this.pageRef = React.createRef();
    }

    componentDidMount() {
        // When search params change, search the page and find matches if any
        this.updateDocumentSearchResults();
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.documentSearchParams, this.props.documentSearchParams)) {
            this.updateDocumentSearchResults();
        }
    }

    mouseMoveHandler = ({ nativeEvent: event }) => {
        // set hovered word
        const hoveredWord = getHoveredWord(event, this.props.words, this.props.pageNumber);
        if (!_.isEqual(hoveredWord, this.props.hoveredWord)) {
            this.props.setHoveredWord(hoveredWord);
        }

        // set cursor style based on whether a word is hovered
        this.setState({ cursorStyle: hoveredWord ? 'text' : 'default' });

        // if mouse button is down and a word is hovered
        // set ending selected word to hovered word
        if (event.buttons !== 1 || !hoveredWord) return;
        this.props.setUserTextSelection((prevState) => {
            return {
                ...prevState,
                end: {
                    pageNumber: this.props.pageNumber,
                    wordIndex: hoveredWord.wordIndex,
                },
            };
        });
    };

    // on mouse button down, set as the starting selected word
    selectionHandler = ({ nativeEvent: event }) => {
        if (event.buttons !== 1) return;

        // if a word is hovered, set as the starting selected word
        // otherwise, deselect all words
        const hoveredWord = getHoveredWord(event, this.props.words, this.props.pageNumber);
        if (!hoveredWord) {
            this.props.deselectAllWords();
            return;
        }
        this.props.setUserTextSelection(() => {
            return {
                start: {
                    pageNumber: this.props.pageNumber,
                    wordIndex: hoveredWord.wordIndex,
                },
                end: {
                    pageNumber: this.props.pageNumber,
                    wordIndex: hoveredWord.wordIndex,
                },
            };
        });
    };

    updateDocumentSearchResults = () => {
        // if search params are empty, set results to an empty array and return
        if (this.props.documentSearchParams.length === 0) {
            this.props.setDocumentSearchResults((prevResults) => {
                return { ...prevResults, [this.props.pageNumber]: [] };
            });
            return;
        }

        // Search the page for matches
        const searchResults = []; // {matchStartIndex, matchEndIndex}

        // Loop through each word on the page to find matches
        // count and join the words in the search
        // then join each same sized group of words in the page, and find a match
        // so it matches from the beginning of a word,
        // but the end of the search param can be in the middle of a word
        for (let i = 0; i < this.props?.words?.length; i++) {
            const numWordsInParam = this.props.documentSearchParams.trim().split(' ').length;
            const searchParam = this.props.documentSearchParams.replaceAll(' ', '').toLowerCase();
            const pageSubString = this.props.words
                .slice(i, i + numWordsInParam)
                .map((word) => word.text)
                .join('')
                .substring(0, searchParam.length)
                .toLowerCase();

            if (pageSubString === searchParam) {
                searchResults.push({
                    matchStartIndex: i,
                    matchEndIndex: i + numWordsInParam - 1,
                    focus: false,
                });
            }
        }
        // set search results
        this.props.setDocumentSearchResults((prevResults) => {
            return { ...prevResults, [this.props.pageNumber]: searchResults };
        });
    };

    render = () => {
        return (
            <div
                className="page"
                id={`docViewer_page${this.props.pageNumber}`}
                onMouseMove={this.mouseMoveHandler}
                onMouseDown={this.selectionHandler}
                onContextMenu={(e) => {
                    this.props.onContextMenu?.(e, 'page');
                }}
                style={{ ...this.props.style, cursor: this.state.cursorStyle }}
                ref={this.pageRef}
                key={`page${this.props.pageNumber}`}
            >
                {/* Displayed page image */}
                <img className="pageImage" id={`pageImage_${this.props.pageNumber}`} src={this.props.page} alt="page" />
                {/* canvas for drawing */}
                <Canvas words={this.props.words} searchResults={this.props.documentSearchResults} pageNumber={this.props.pageNumber} selectedWords={this.props.userTextSelection} />
            </div>
        );
    };
}

export default Page;
