import React, { Component } from 'react';
import Select from 'react-select';
import _ from 'lodash';

import BulkUploaderDropzone from '../BulkUploaderDropzone/BulkUploaderDropzone';
import { BulkUploadHeaderComponent, BulkUploadType } from '../types/bulkUploadTypes';
import BulkUploadHandler, { BulkUploadHandlerConfig } from '../bulkUploadHandlers/BulkUploadHandler';
import CommitmentUploadHandler, { CommitmentUploadHandlerCreateType, CommitmentUploadHandlerConfig } from '../bulkUploadHandlers/CommitmentUploadHandler';

import styles from './BulkUploaderManager.module.scss';
import { capitalizeFirstEveryWord } from '../../../../utilities/format/capitalizeFirst';
import IconButton from '../../../Buttons/IconButton';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

import bulkUploadHandlers from '../bulkUploadHandlers';
import { ValuationUploadHandlerConfig, ValuationUploadHandlerCreateType } from '../bulkUploadHandlers/ValuationUploadHandler';

interface BulkUploaderManagerProps {
    close: () => void;
    bulkUploadType?: BulkUploadType;
    handlerProps: BulkUploadHandlerConfig;
    containerStyle?: React.CSSProperties;
    finishCallback?: () => void;
}

interface BulkUploaderManagerState {
    handler: BulkUploadHandler<any, any> | null;
    bulkUploadType?: BulkUploadType | null;
}

// Define the defaultProps separately to help TypeScript recognize it
const defaultProps: Partial<BulkUploaderManagerProps> = {
    handlerProps: {
        // investment: '627c5cf09453fca102b62563',
    },
};

class BulkUploaderManager extends Component<BulkUploaderManagerProps, BulkUploaderManagerState> {
    static defaultProps = defaultProps;

    constructor(props: BulkUploaderManagerProps) {
        super(props);

        this.state = {
            handler: null,
            bulkUploadType: null,
        };
    }

    componentDidMount(): void {
        this.loadHandler();
    }

    componentDidUpdate(prevProps: BulkUploaderManagerProps): void {
        if (!_.isEqual(prevProps.bulkUploadType, this.props.bulkUploadType)) {
            this.loadHandler();
        }
    }

    componentWillUnmount(): void {
        if (this.props.finishCallback) {
            this.props.finishCallback();
        }
    }

    getBulkUploadType = (p: BulkUploaderManagerProps | null = null) => {
        const props = p || this.props;
        return props.bulkUploadType || this.state.bulkUploadType;
    };

    loadHandler = () => {
        const bulkUploadType = this.getBulkUploadType();
        if (!bulkUploadType) {
            if (this.state.handler) {
                this.setState({ handler: null });
            }
            return;
        }
        let getHandlerFUnction = bulkUploadHandlers[bulkUploadType];
        let handler = getHandlerFUnction(this.props.handlerProps);

        this.setState({ handler });
    };

    setHandler = (bulkUploadType: BulkUploadType | null) => {
        this.setState({ bulkUploadType }, this.loadHandler);
    };

    showBulkUploadTypeDropdown = () => {
        return !this.state.handler;
    };

    render() {
        return (
            <div className={styles.main} style={this.props.containerStyle}>
                {<BulkUploaderDropzone close={this.props.close} handler={this.state.handler} setHandler={this.setHandler} />}
                {/* <div style={{height: '50px'}} /> */}
            </div>
        );
    }
}

export default BulkUploaderManager;

type ModalFunctionType = (
    modalContent?: JSX.Element | null,
    modalOpen?: boolean,
    modelClassName?: string,
    modelProps?: any,
    modelFullScreen?: boolean,
    modalCloseOnEscape?: boolean
) => void;

export const openBulkUploaderManager = (
    openModal: ModalFunctionType,
    bulkUploadType?: BulkUploadType,
    handlerProps: BulkUploadHandlerConfig = {},
    allowClose: boolean = true,
    finishCallback?: () => void
) => {
    openModal(
        <BulkUploaderManager
            close={allowClose ? () => openModal(null, false) : undefined}
            bulkUploadType={bulkUploadType}
            handlerProps={handlerProps}
            finishCallback={finishCallback}
        />,
        true,
        // 'bulk-upload-modal',
        styles.modal,
        {
            noClose: true,
        },
        true,
        false
    );
};
