import React, { Component } from 'react';
import _ from 'lodash';

import { FileTextResult } from '../../../../constants/file';
import { DraggableColumnHeaders } from '../components/DraggableColumnHeaders';
import { BulkUploadColumn } from '../types/bulkUploadTypes';
import { BulkUploaderHandlerColumnType } from '../bulkUploadHandlers/BulkUploadHandler';
import { reorderColumns } from '../utilities/helpers';

import styles from './BulkUploaderFileReconcileStage.module.scss';

const sortColumns = (columns: { [key: string]: BulkUploadColumn }, columnOrder: string[]) => {
    const sortedColumnResult: { [key: string]: BulkUploadColumn } = columnOrder.reduce(
        (acc, key) => {
            if (!columns[key]) return acc;
            acc[key] = columns[key];
            return acc;
        },
        {} as { [key: string]: BulkUploadColumn }
    );
    return sortedColumnResult;
};

type AllColumnsType = { [key: string]: BulkUploadColumn };

interface BulkUploaderFileRectifyStageProps<T> {
    rawFileTextResult: FileTextResult;
    setRectifiedFileTextResult: (rectifiedFileResult: FileTextResult) => void;
    columns: BulkUploaderHandlerColumnType;
}

interface BulkUploaderFileRectifyStageState<T> {
    allColumns: AllColumnsType;
}

const EXTRA_COLUMN_NAME = 'Unused Column';

export default class BulkUploaderFileRectifyStage<T = Record<string, string>> extends Component<BulkUploaderFileRectifyStageProps<T>, BulkUploaderFileRectifyStageState<T>> {
    constructor(props: BulkUploaderFileRectifyStageProps<T>) {
        super(props);
        this.state = {
            allColumns: this.generateAllColumns(),
        };
    }

    // look at the max number of columns in the text file and generate columns for the rest
    generateAllColumns = () => {
        // set state columns
        const allColumns: AllColumnsType = this.props.columns;
        const numColumns = Object.keys(allColumns).length;
        const numColumnsInTextFile = Math.max(...this.props.rawFileTextResult.lines.map((line) => line.split(',').length));
        for (let i = 0; i < numColumnsInTextFile; i++) {
            if (i < numColumns) continue;
            allColumns[`Column ${i + 1}`] = {
                displayName: `${EXTRA_COLUMN_NAME} ${i - numColumns + 1}`,
                fieldName: `${EXTRA_COLUMN_NAME} ${i - numColumns + 1}`,
                dataType: 'string',
            } as BulkUploadColumn;
        }
        return allColumns;
    };

    componentDidUpdate(prevProps: Readonly<BulkUploaderFileRectifyStageProps<T>>, prevState: Readonly<BulkUploaderFileRectifyStageState<T>>, snapshot?: any): void {}

    renderTable = () => {
        const colorGray = '#ccc';
        return (
            <table className={`${styles.table}`}>
                <thead>
                    <DraggableColumnHeaders
                        columns={Object.entries(this.state.allColumns).reduce(
                            (acc, [key, value], index) => {
                                acc[key] = value;
                                if (value.fieldName.toLowerCase().startsWith(EXTRA_COLUMN_NAME.toLowerCase())) {
                                    acc[key].headerStyle = { backgroundColor: colorGray };
                                }
                                return acc;
                            },
                            {} as { [key: string]: BulkUploadColumn & { headerStyle?: React.CSSProperties } }
                        )}
                        onDrop={(source: string, target: string) => {
                            const allColumns = sortColumns(this.state.allColumns, reorderColumns(source, target, Object.keys(this.state.allColumns)));
                            this.setState({ allColumns });
                        }}
                    />
                </thead>
                <tbody>
                    {this.props.rawFileTextResult.lines.map((line, index) => {
                        const values = line.split(',');
                        return (
                            <tr key={index}>
                                {Object.values(this.state.allColumns).map((column, columnIndex) => {
                                    const value = values[columnIndex] || '';
                                    return (
                                        <td
                                            key={columnIndex}
                                            style={{
                                                ...(column.fieldName.toLowerCase().startsWith(EXTRA_COLUMN_NAME.toLowerCase()) ? { backgroundColor: colorGray } : {}),
                                            }}
                                        >
                                            {value}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    };

    renderReconcileButton = () => {
        return (
            <button
                style={{ width: '200px' }}
                onClick={() => {
                    // make a list of indexes, which are the index in the allColumns object in the order of the original columns object
                    // has the same length as the original columns object
                    const columnIndexes: number[] = Object.keys(this.props.columns).reduce((acc, key, index) => {
                        acc[index] = Object.keys(this.state.allColumns).indexOf(key);
                        return acc;
                    }, [] as number[]);

                    const lines = this.props.rawFileTextResult.lines.map((line) => {
                        const newLine: string[] = [];
                        const lineSplit = line.split(',');
                        columnIndexes.forEach((index) => {
                            newLine.push(lineSplit[index] || '');
                        });
                        return newLine.join(',');
                    });

                    // create a new FileTextResult with the new columns only
                    const rectifiedFileResult: FileTextResult = {
                        ...this.props.rawFileTextResult,
                        lines,
                    };

                    this.props.setRectifiedFileTextResult(rectifiedFileResult);
                }}
            >
                Reconcile
            </button>
        );
    };

    renderDescription = () => {
        return (
            <>
                <span>
                    The file <strong>{this.props.rawFileTextResult.file.name}</strong> has more columns than expected.
                </span>
                <span>
                    Drag and drop the column headers to reorder them, then click <strong>Reconcile</strong>.
                </span>
            </>
        );
    };

    render() {
        return (
            <div className={styles.main}>
                {this.renderDescription()}
                <div style={{ height: '40px' }} />
                {this.renderReconcileButton()}
                <div style={{ height: '40px' }} />
                {this.renderTable()}
            </div>
        );
    }
}
