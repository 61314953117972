import React from 'react';
import '../../styles/widget.css';
import '../../styles/widget-TotalValueDisplay.css';
import formatCurrency from '../../utilities/format/formatCurrency';
import { EM_DASH } from '../../constants/constantStrings';
import { CURRENCIES } from '../../constants/currencyConstants';

// const isMobile = window.innerWidth <= 768;

interface TotalValueDisplayProps {
    totalValue: number;
    gainLoss: number;
    currency: keyof typeof CURRENCIES;
}

const TotalValueDisplay: React.FC<TotalValueDisplayProps> = ({ totalValue, gainLoss, currency }) => {
    const gainLossHasValue = gainLoss && Number(gainLoss.toFixed(2)) !== 0 && Number.isFinite(gainLoss);

    return (
        <div className="cv-container widget">
            <div className="cv-left">
                <span>Total Value</span>
                <span className="cv-gainLoss" style={{ color: !gainLossHasValue ? undefined : gainLoss > 0 ? 'var(--color-link)' : 'var(--color-dark-red)' }}>
                    {!gainLossHasValue || !Number.isFinite(gainLoss) ? (
                        EM_DASH
                    ) : (
                        <>
                            <img alt="arrow" src={`/images/icons/arrow${gainLoss > 0 ? 'UpGreen' : 'DownRed'}.png`} style={{ marginRight: '5px' }} />
                            {gainLossHasValue && gainLoss.toFixed(2) + '%'}
                        </>
                    )}
                </span>
            </div>
            <div className="cv-value">{formatCurrency(totalValue, currency, 0)}</div>
        </div>
    );
};

export default TotalValueDisplay;
