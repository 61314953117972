import React from 'react';
import '../styles/progress.css';

interface ProgressProps {
    currentStep: number;
    errors?: Record<number, boolean>;
    timedout?: boolean;
    steps?: string[];
}

const Progress: React.FC<ProgressProps> = ({ currentStep, errors, timedout, steps }) => {
    const renderStep = (step: number) => {
        if (currentStep === step) {
            if (errors?.[step]) {
                return (
                    <div key={`error-${step}`} className="progressComponent-step progressComponent-progress-error">
                        <span>!</span>
                    </div>
                );
            } else if (timedout) {
                return (
                    <div key={`notify-later-${step}`} className="progressComponent-step progressComponent-waiting">
                        <span>{step}</span>
                    </div>
                );
            } else {
                return (
                    <div key={`active-${step}`} className="progressComponent-step progressComponent-active">
                        <span>{step}</span>
                    </div>
                );
            }
        } else if (currentStep > step) {
            return (
                <div key={`complete-${step}`} className="progressComponent-step progressComponent-complete">
                    <span>&#10003;</span>
                </div>
            );
        }
        return (
            <div key={`default-${step}`} className="progressComponent-step">
                <span>{step}</span>
            </div>
        );
    };

    return (
        <div className="progressComponent-container" data-testid="progress-container">
            {steps?.map((step, stepIdx) => (
                <React.Fragment key={`progressStep_${stepIdx}`}>
                    {!!stepIdx && <div className="progressComponent-line" />}
                    <span key={step}>{renderStep(parseInt(step))}</span>
                </React.Fragment>
            ))}
        </div>
    );
};

export default Progress;

// Add this line to ensure the file is treated as a module
export {};
