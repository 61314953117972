import { FileParseResult, FileTextResult } from '../../../../constants/file';
import BulkUploadHandler, { BulkUploadHandlerCreateType, BulkUploadHandlerConfig } from './BulkUploadHandler';
import sharedFields, { sharedFieldEnum } from '../types/sharedFields';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
import api2 from '../../../../api2';
import { capitalizeFirstEveryWord } from '../../../../utilities/format/capitalizeFirst';
import { getInvestmentTypes } from '../../../../pages/Account/AddManualInvestmentStages/investmentTypeConstants';

export interface InvestmentUploadHandlerCreateType extends BulkUploadHandlerCreateType {
    account?: string;
    type?: string;
    name?: string;
    hidden?: boolean;
    investment_master?: string;
    connection?: string;
    addepar_loa_status_workflow_id?: string;
    addepar_portal_status_workflow_id?: string;
}

export interface InvestmentUploadHandlerConfig extends BulkUploadHandlerConfig {
    account?: string;
}

const getInvestmentTypeNames = () => {
    const investmentTypeNames = getInvestmentTypes().map((type) => type.name);
    return investmentTypeNames;
};

const investmentTypeField = sharedFieldEnum(Object.values(getInvestmentTypeNames()).map((val) => capitalizeFirstEveryWord(val)));

class InvestmentUploadHandler extends BulkUploadHandler<InvestmentUploadHandlerCreateType, InvestmentUploadHandlerConfig> {
    // The type of the bulk upload, eg. 'investment' or 'valuation'
    type = BulkUploadType.investment;

    // The base columns that are required for the bulk upload
    base_columns = {
        account: {
            ...sharedFields.object_id,
            displayName: 'Account',
            fieldName: 'account',
            required: true,
        },
        type: {
            ...investmentTypeField,
            displayName: 'Investment Type',
            fieldName: 'type',
            isValid: (val: string) => {
                return getInvestmentTypeNames()
                    .map((name) => name.toLowerCase())
                    .includes(val.toLowerCase());
            },
            format: (val: string) => {
                return getInvestmentTypeNames().find((name) => name.toLowerCase() === val.toLowerCase()) || '';
            },
            required: true,
        },
        name: {
            ...sharedFields.string,
            displayName: 'Name',
            fieldName: 'name',
            required: true,
        },
        hidden: {
            ...sharedFields.boolean,
            displayName: 'Hidden',
            fieldName: 'hidden',
            required: false,
        },
        investment_master: {
            ...sharedFields.object_id,
            displayName: 'Investment Master',
            fieldName: 'investment_master',
            required: false,
        },
        connection: {
            ...sharedFields.object_id,
            displayName: 'Connection',
            fieldName: 'connection',
            required: false,
        },
        addepar_loa_status_workflow_id: {
            ...sharedFields.string,
            displayName: 'Addepar LOA Status Workflow ID',
            fieldName: 'addepar_loa_status_workflow_id',
            required: false,
            style: { width: '250px' },
        },
        addepar_portal_status_workflow_id: {
            ...sharedFields.string,
            displayName: 'Addepar Portal Status Workflow ID',
            fieldName: 'addepar_portal_status_workflow_id',
            required: false,
            style: { width: '260px' },
        },
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof InvestmentUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (columnOrder: (keyof InvestmentUploadHandlerCreateType)[] = this.columnOrder): { [key in keyof InvestmentUploadHandlerCreateType]: BulkUploadColumn } => {
        // Sort the columns based on the given column order or the default order.
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);

        // set up special conditions based on the config
        if (this.config.account) {
            // remove account column if account is provided
            delete sortedColumns.account;
        } else if (sortedColumns.account) {
            // set account as required if not provided
            sortedColumns.account.required = true;
        }

        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof InvestmentUploadHandlerCreateType]: string }, columnOrder?: (keyof InvestmentUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        // any additional validation goes here
        return isDataValid;
    };

    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @returns {Object} An object with the correct fields for the line.
     */
    parseSingleCsvLine = (line: string, columnOrder?: (keyof InvestmentUploadHandlerCreateType)[]): InvestmentUploadHandlerCreateType => {
        // if not enough commas are included, the fields will be empty strings
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        // set up special conditions based on the config and data type
        return {
            ...(parsedValues.hidden ? { hidden: parsedValues.hidden.toLowerCase() === 'true' || parsedValues.hidden.toLowerCase() === 'yes' } : { hidden: false }),

            name: expectedColumns.name && expectedColumns.name.isValid(parsedValues.name) ? parsedValues.name : '',

            type: expectedColumns.type && expectedColumns.type.isValid(parsedValues.type) ? parsedValues.type : '',

            investment_master: expectedColumns.investment_master && expectedColumns.investment_master.isValid(parsedValues.investment_master) ? parsedValues.investment_master : '',

            connection: expectedColumns.connection && expectedColumns.connection.isValid(parsedValues.connection) ? parsedValues.connection : '',

            // Only include the investment if it's provided and valid.
            ...(parsedValues.account
                ? {
                      account: expectedColumns.account && expectedColumns.account.isValid(parsedValues.account) ? parsedValues.account : '',
                  }
                : {}),

            ...(parsedValues.addepar_loa_status_workflow_id ? { addepar_loa_status_workflow_id: parsedValues.addepar_loa_status_workflow_id } : {}),

            ...(parsedValues.addepar_portal_status_workflow_id ? { addepar_portal_status_workflow_id: parsedValues.addepar_portal_status_workflow_id } : {}),
        };
    };

    // Parse the text file results into a FileParseResult
    parseTextFileResult = async (
        textFileResult: FileTextResult,
        columnOrder?: (keyof InvestmentUploadHandlerCreateType)[]
    ): Promise<FileParseResult<InvestmentUploadHandlerCreateType>> => {
        try {
            const data = textFileResult.lines.map((line) => this.parseSingleCsvLine(line, columnOrder));
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<InvestmentUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<InvestmentUploadHandlerCreateType>;
        }
    };

    // Get the notes for the bulk upload type
    getNotes = (): string[] => {
        let notes: string[] = [];
        if (this.config.investment) {
            notes = [...notes];
        }
        return notes;
    };

    // function to create the object in the database from the parsed data
    create = async (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof InvestmentUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        // Ensure the data is valid
        if (!this.isDataValid(data, columnOrder)) {
            return { success: false, message: 'Invalid data' };
        }

        // require investment from config or in data
        if (!this.config.account && !data.account) {
            return { success: false, message: 'Account ID is required' };
        }

        // convert "type" from the display name to the investment type database string format
        const investmentType = getInvestmentTypeNames().find((typeName) => typeName.toLowerCase() === data.type.toLowerCase()) || null;

        if (!investmentType) {
            return { success: false, message: 'Invalid investment type' };
        }

        const attributes = {
            ...(data.addepar_loa_status_workflow_id && { addepar_loa_status_workflow_id: data.addepar_loa_status_workflow_id }),
            ...(data.addepar_portal_status_workflow_id && { addepar_portal_status_workflow_id: data.addepar_portal_status_workflow_id }),
        };

        try {
            // Attempt to create a single investment from the current object.
            const investment_id = (
                await api2.client.InvestmentApi.createInvestment({
                    CreateInvestmentRequest: {
                        type: investmentType,

                        account: this.config.account || data.account,
                        ...(data.description && { description: data.description }),

                        hidden: data.hidden,

                        name: data.name,

                        ...(data.investment_master && { investment_master: data.investment_master }),

                        ...(data.connection && { connection: data.connection }),

                        ...(Object.keys(attributes).length > 0 && { attributes }),
                    },
                })
            ).data.investment_id;

            // Return the new investment ID.
            return {
                success: true,
                message: 'Investment created successfully',
                id: investment_id,
            };
        } catch (err) {
            // Log and return null in case of an error.
            console.log('error creating investment:', err);
            return {
                success: false,
                message: `Error creating investment: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            await api2.client.InvestmentApi.deleteInvestment({
                investment_id: id,
            });
            return {
                success: true,
                message: 'Investment deleted successfully',
            };
        } catch (err) {
            console.log('error deleting investment:', err);
            return {
                success: false,
                message: `Error deleting investment: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the InvestmentUploadHandler
export const getInvestmentUploadHandler: (config: InvestmentUploadHandlerConfig) => InvestmentUploadHandler = (config: InvestmentUploadHandlerConfig) =>
    new InvestmentUploadHandler(config);

export default InvestmentUploadHandler;
