import React from 'react';

export const SortArrow = (props) => {
    const none = !props.up && !props.down;
    const up = none || props.up;
    const down = none || props.down;

    return (
        <div style={{ display: 'inline-flex', flexDirection: 'column', marginLeft: '10px', fontSize: '12px' }}>
            <div style={{ marginBottom: '-0px' }}>{up ? '▲' : <>&nbsp;</>}</div>
            <div>{down ? '▼' : <>&nbsp;</>}</div>
        </div>
    );
};
