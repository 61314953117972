// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { FC, ReactNode } from 'react';

import '../../styles/widget.css';

interface WidgetColumnProps {
    className?: string;
    children: ReactNode;
}

const WidgetColumn: FC<WidgetColumnProps> = ({ className, children }) => {
    return (
        <div className={`widget-column${className ? ` ${className}` : ''}`}>
            {children}
            <div style={{ clear: 'both', height: 40 }} />
        </div>
    );
};

export default WidgetColumn;
