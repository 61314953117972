// Copyright (AltExchange, LLC)
// See LICENSE.md file in project root directory

import { faSync } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import React, { Component } from 'react';

// components
import IconButton, { CopyButton } from '../../components/Buttons/IconButton';
import AddEditConnectionDrawer from '../../components/Drawers/AddEditConnectionDrawer';
import GenericDrawer from '../../components/Drawers/GenericDrawer';
import Dropdown from '../../components/Dropdowns/Dropdown';
import Checkbox from '../../components/Inputs/Checkbox';
import Progress from '../../components/Progress';
import renderInputError from '../../components/renderInputError';
import PaginationTable from '../../components/Tables/PaginationTable';
import Multitoggle from '../../components/Toggles/Multitoggle';

// utilities
import api from '../../api';
import api2 from '../../api2';
import { EM_DASH } from '../../constants/constantStrings';
import { ConnectionStatus } from '../../types/Connection';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import getUserRalInfoZip from '../../utilities/assetManagerOutreach/getUserRalInfoZip';
import formatDate from '../../utilities/format/formatDate';
import formatPhoneNumber from '../../utilities/format/formatPhoneNumber';
import { clearQueryParams, setQueryParam } from '../../utilities/query/queryParamHelpers';

import {
    AdminTeamSearchFilter,
    AdvisoryGroupSearchFilter,
    AssetManagerSearchFilter,
    getGroupIdsFromAdminTeams,
    UserSearchFilter,
} from '../../components/Filters/GenericSearchFilter';
import { ConnectionOutreachStatus, InvestmentTypes } from '../../openApiClient';
import '../../styles/connections.css';
import { addQueryToRoute } from '../../utilities/apiHelpers/addQueryToRoute';
import saveCsv from '../../utilities/file/saveCsv';

// const CONNECTION_STATUSES = ['All', 'Active', 'Authenticating', 'Awaiting 2FA', 'Error', 'Importing', 'Pending', 'Syncing', 'Under Review'];

const CONNECTION_STATUSES = {
    All: 'All',
    ...ConnectionStatus,
};

const INVESTMENT_STATUSES = {
    all: 'All',
    incomplete: 'Incomplete',
};

const ALL_OUTREACH_STATUSES = 'All Outreach Statuses';

const isConnectionSyncing = (connection) => {
    return !['Active', 'Pending', 'Error', 'Under Review'].includes(connection.status);
};

const ASSET_MANAGER = 'asset_manager';

export default class Connections extends Component {
    childCallables = null;
    state = {
        connection: {
            status: 'Active',
            name: '',
            connection_url: '',
            use_tfa: false,
            integration: '',
            // ral_status: '',
        },
        confirmDelete: -1,
        integrations: [],
        investmentTypes: [],
        errors: {},
        drawerOpen: false,
        drawerSize: 'lg',
        credentials: {},
        credsModified: false,
        syncingConnections: {},

        // filters
        connectionOutreachStatus: null,
        connectionStatus: CONNECTION_STATUSES.All,
        investmentStatus: INVESTMENT_STATUSES.all,
        userFilter: null,
        integrationFilter: 'all',
        assetManagerFilter: null,

        connectionOutreachStatuses: [],
        bulkOutreachMode: false,
        selectedConnections: {},

        currentConnections: [],
        assetManagers: [],
        adminTeamFilter: null,
        advisoryGroupFilter: null,

        isEnabledFilter: 'All',
    };

    // this is to prevent updating rows in the table that no longer exist
    // ref for last route string
    lastRoute = React.createRef();
    syncTimeouts = []; // start as []
    clearingSyncTimeouts = React.createRef();

    // compile list of timeouts to be able to clear them
    addSyncTimeout = (t) => {
        this.syncTimeouts = [...this.syncTimeouts, t];
    };

    // clear all timeouts
    clearSyncTimeouts = () => {
        this.clearingSyncTimeouts.current = true;
        this.syncTimeouts.forEach((t) => {
            if (t) {
                try {
                    clearTimeout(t);
                } catch {
                    // ignore
                }
            }
        });
        this.syncTimeouts = [];
        setTimeout(() => {
            this.clearingSyncTimeouts.current = false;
        }, 200);
    };

    componentDidMount = () => {
        this.syncTimeouts = [];

        this.mounted = true;
        this.loadQueryParams();
        this.props.setTitle(
            <>
                <span className="title-account-name">{this.props.user?.name}</span>
                <div className="separator" />
                Manage Connections
            </>
        );
        this.props.loading();
        this.loadData();
        this.props.loaded();
    };

    componentDidUpdate = (prevProps) => {
        if (this.props.user._id !== prevProps.user._id) {
            this.props.setTitle(
                <>
                    <span className="title-account-name">{this.props.user?.name}</span>
                    <div className="separator" />
                    Manage Connections
                </>
            );
        }
    };

    componentWillUnmount = () => {
        clearQueryParams();
        this.mounted = false;
        this.props.closeDrawer();
    };

    loadQueryParams = async () => {
        const url = new URL(window.location.href);

        // to open drawer if param is set
        let openConnectionId = url.searchParams.get('open_connection_id');
        if (openConnectionId) {
            this.openConnectionDrawer(openConnectionId);
            // remove param from url
            url.searchParams.delete('open_connection_id');
            url.searchParams.set('search', openConnectionId);
            window.history.replaceState({}, '', url);
        }

        // get values from query params
        let connectionStatus = url.searchParams.get('connectionStatus');
        if (connectionStatus !== null) {
            this.setState({ connectionStatus });
        }

        let investmentStatus = url.searchParams.get('investmentStatus');
        if (investmentStatus !== null) {
            this.setState({ investmentStatus });
        }

        let connectionOutreachStatus = url.searchParams.get('connectionOutreachStatus');
        if (connectionOutreachStatus !== null) {
            this.setState({ connectionOutreachStatus });
        }

        let integrationFilter = url.searchParams.get('integration');
        if (integrationFilter !== null) {
            this.setState({ integrationFilter });
        }

        let assetManagerFilterId = url.searchParams.get(ASSET_MANAGER);
        if (assetManagerFilterId !== null) {
            const assetManagerFilter = await api.get(`/assetmanagers/${assetManagerFilterId}`);
            this.setState({ assetManagerFilter });
        }

        let userFilterId = url.searchParams.get('user');
        if (userFilterId !== null) {
            try {
                const userFilter = (
                    await api2.client.UserApi.listUsers({
                        users: [userFilterId],
                    })
                ).data.users[0];
                this.setState({ userFilter });
            } catch (error) {
                console.error('Error fetching user', error);
            }
        }

        let isEnabledFilter = url.searchParams.get('isEnabled');
        if (isEnabledFilter !== null) {
            this.setState({ isEnabledFilter });
        }
        let admin_teams = url.searchParams.get('admin_teams');
        let adminTeamFilter = null;
        if (admin_teams) {
            try {
                adminTeamFilter = (
                    await api2.client.AdminTeamApi.listAdminTeams({
                        admin_teams: admin_teams.split(','),
                    })
                ).data.admin_teams;
            } catch (e) {
                console.error('error getting admin teams', e);
            }
        }
        if (adminTeamFilter) this.setState({ adminTeamFilter: adminTeamFilter[0] });
    };

    showIncomplete = () => ![INVESTMENT_STATUSES.all, undefined, null].includes(this.state.investmentStatus);
    // showIncomplete = () => this.state.investmentStatus !== INVESTMENT_STATUSES.complete

    loadData = async () => {
        this.props.loading(320, 'conn_loadData');
        const connectionOutreachStatuses = Object.values(ConnectionOutreachStatus);
        let integrations;
        try {
            integrations = await api2.paginateApiRoute(async (paginate_params) => {
                return (
                    await api2.client.IntegrationApi.listIntegrations({
                        ...paginate_params,
                    })
                ).data.integrations;
            });
        } catch (error) {
            console.error('Error fetching integrations', error);
        }
        const assetManagers = (await api.get(`/assetmanagers?minimal=true`))?.results ?? [];
        this.props.loaded('conn_loadData');
        this.setState({
            integrations: integrations,
            investmentTypes: Object.values(InvestmentTypes),
            connectionOutreachStatuses,
            assetManagers,
        });
    };

    setChildCallables = (callables) => {
        this.childCallables = callables;
    };

    getClassName = (status) => {
        switch (status) {
            case 'Active':
                return 'status green';
            case 'Pending':
                return 'status yellow';
            case 'Error':
                return 'status red';
            default:
                return 'status';
        }
    };

    handleTypeSelect = async (investment_idx, connection, connection_idx, newType) => {
        const investment = connection.investments[investment_idx];
        this.props.loading(320, 'conn_typeSelect');

        try {
            await api2.client.InvestmentApi.updateInvestment({
                investment_id: investment._id,
                UpdateInvestmentRequest: {
                    type: newType,
                },
            });
            investment.editing = undefined;
            investment.type = newType;
            connection.investments[investment_idx] = investment;
            this.updateRow(connection, connection_idx);
        } catch (err) {
            console.error('Error updating investment type', err);
        }

        this.props.loaded('conn_typeSelect');
    };

    updateRow = (connection, idx) => {
        if (this.clearingSyncTimeouts.current) return;
        this.childCallables.updateRow(connection, idx);
    };

    changeInvestmentType = (investment_idx, connection, connection_idx, e) => {
        e.stopPropagation();
        const investment = connection.investments[investment_idx];
        investment.editing = true;
        connection.investments[investment_idx] = investment;
        this.updateRow(connection, connection_idx);
    };

    expandRow = async (connection, idx, e) => {
        if (e) {
            e.stopPropagation();
        }

        if (connection.expanded) {
            connection.investments = undefined;
            connection.expanded = false;
            this.updateRow(connection, idx);
        } else if (connection.no_investments) {
            connection.no_investments = false;
            this.updateRow(connection, idx);
        } else {
            this.props.loading(320, 'conn_expandRow');
            let investments = [];
            try {
                let query = {
                    connections: [connection._id],
                    populate_investment_master: true,
                };

                if (this.state.investmentStatus === INVESTMENT_STATUSES.incomplete) {
                    query.incomplete = true;
                }
                investments = await api2.paginateApiRoute(async (paginate_params) => {
                    return (
                        await api2.client.InvestmentApi.listInvestments({
                            ...query,
                            ...paginate_params,
                        })
                    ).data.investments;
                });
            } catch (error) {
                console.log('Error fetching investments');
                console.error(error);
            }

            this.props.loaded('conn_expandRow');
            if (investments && investments.length > 0) {
                connection.investments = investments;
                connection.expanded = true;
            } else {
                connection.no_investments = true;
            }
            this.updateRow(connection, idx);
        }
    };

    toggleShowIncompleteDocs = async (connection, idx, investmentId) => {
        const investmentIdx = connection.investments.map((inv) => inv._id).indexOf(investmentId);
        connection.investments[investmentIdx].showUnprocessed = connection.investments[investmentIdx].showUnprocessed ? false : true;
        // add docs if expanded
        if (connection.investments[investmentIdx]?.showUnprocessed) {
            this.props.loading(320, 'conn_showIncompleteDocs');

            try {
                const unprocessedDocs = (
                    await api2.client.DocumentApi.listDocuments({
                        ownership_type: 'Investment',
                        ownership_ids: [investmentId],
                        hasValidMetadata: false,
                        shouldGenerateTransactions: true,
                    })
                ).data.documents;
                connection.investments[investmentIdx].unprocessedDocs = unprocessedDocs;
            } catch (err) {
                console.error('Error fetching unprocessed docs', err);
            }

            this.props.loaded('conn_showIncompleteDocs');
        } else {
            connection.investments[investmentIdx].unprocessedDocs = [];
        }
        this.updateRow(connection, idx);
    };

    removeConnection = async () => {
        if (this.state.confirmDelete === this.state.connection._id) {
            this.props.loading(320);
            await api.delete('/connections/' + this.state.connection._id);
            this.setState({ confirmDelete: -1 });
            this.childCallables.loadData();
            this.props.closeDrawer();
        } else {
            this.setState({ confirmDelete: this.state.connection._id });
            setTimeout(() => {
                if (this.state.confirmDelete === this.state.connection._id) {
                    this.setState({ confirmDelete: -1 });
                }
            }, 500);
        }
    };

    removeInvestment = async (connection, connection_idx, investment_idx) => {
        if (this.state.confirmDelete === investment_idx) {
            this.props.loading(0, 'mngInv_submitAddContact');

            const investmentId = connection.investments[investment_idx]?._id;

            if (!investmentId) {
                console.error('No investment ID to delete');
                return;
            }

            try {
                await api2.client.InvestmentApi.deleteInvestment({
                    investment_id: investmentId,
                });
                connection.investments.splice(investment_idx, 1);
                this.updateRow(connection, connection_idx);
                this.setState({ confirmDelete: -1 });
                this.props.loaded('mngInv_submitAddContact');
            } catch (err) {
                console.error(err);
                this.props.loaded();
            }
        } else {
            this.setState({ confirmDelete: investment_idx });
            setTimeout(() => {
                if (this.state.confirmDelete === investment_idx) {
                    this.setState({ confirmDelete: -1 });
                }
            }, 1000);
        }
    };

    testConnection = async () => {
        const connection = { ...this.state.connection };
        connection.status = 'Connecting';
        api.post(`/connections/${this.state.connection._id}/sync`);
        this.setState({ connection });
        const tOut = setTimeout(() => {
            this.checkSyncTest();
        }, 5000);
        this.addSyncTimeout(tOut);
    };

    checkSyncTest = async () => {
        if (this.state.connection?._id) {
            const res = await api.get(`/connections/${this.state.connection._id}`, {
                403: (err) => {
                    this.handleTestError(err.message);
                    return;
                },
                406: (err) => {
                    this.handleTestError(err.message);
                    return;
                },
                500: (err) => {
                    this.handleTestError(err.message);
                    return;
                },
            });

            if (res?.status) {
                const connection = { ...this.state.connection };
                connection.status = res.status;
                this.setState({ connection });
            }
            switch (res?.status) {
                default:
                    const tOut = setTimeout(() => this.checkSyncTest(), 5000);
                    this.addSyncTimeout(tOut);
                    break;
                case 'Pending':
                case 'Active':
                case 'Awaiting 2FA':
                    break;
                case 'Error':
                    console.error(res.error?.user);
                    this.handleTestError(res.error?.user);
                    break;
            }
        }
    };

    handleTestError = (err) => {
        const errors = { ...this.state.errors };
        errors.syncError = err;
        this.setState({ errors });
    };

    openConnectionDrawer = (connectionId = null) => {
        const p = this.props;
        p.openDrawer(
            () => (
                <AddEditConnectionDrawer
                    close={p.closeDrawer}
                    user={p.relevantUser}
                    accounts={p.accounts}
                    reloadData={() => {
                        this.childCallables.loadData();
                        this.clearSelectedConnections();
                    }}
                    loading={p.loading}
                    loaded={p.loaded}
                    setAlert={p.setAlert}
                    connectionId={connectionId}
                    adminOrAdvisorVue={true}
                    vue={p.vue}
                />
            ),
            null,
            '650px'
        );
    };

    renderSyncButton = (connection, idx) => {
        if (!connection.integration) return <>&nbsp;</>;
        return (
            <div
                className="a"
                onClick={async (e) => {
                    e.stopPropagation();
                    const res = await api.post('/connections/' + connection._id + '/sync');
                    if (res?.message === 'OK') {
                        connection.status = 'Connecting';
                        this.setConnectionSyncTracking(connection._id, true);
                        this.updateSyncingConnection(connection, idx, 1);
                        this.getSyncStatus(connection, idx);
                    } else {
                        console.log('error syncing');
                        setDismissableAlert(this.props.setAlert, 'Error syncing connection', true);
                    }
                }}
            >
                Sync
            </div>
        );
        // }
    };

    handleSyncErrors = (connection, idx, err) => {
        const errors = {};
        errors[connection.currentStep] = err;
        connection.syncErrors = errors;
        this.updateSyncingConnection(connection, idx, null);
    };

    updateSyncingConnection = (connection, idx, currentStep) => {
        if (this.clearingSyncTimeouts.current) return;

        if (!connection.steps) {
            connection.steps = {
                1: { title: 'Attempting to Connect' },
                2: { title: 'Authenticating' },
                3: { title: 'Retrieving Documents & Transactions...' },
            };
        }
        connection.currentStep = currentStep;
        this.updateRow(connection, idx);
    };

    setConnectionSyncTracking = (connId, syncing) => {
        this.setState((prevState) => {
            return {
                syncingConnections: {
                    ...prevState.syncingConnections,
                    [connId]: syncing,
                },
            };
        });
    };

    getSyncStatus = async (connection, idx) => {
        if (!this.mounted) return;
        const TIMEOUT = 5000;

        if (this.clearingSyncTimeouts.current) return;

        const resConn = await api.get(`/connections/${connection._id}`, {
            403: (err) => this.handleSyncErrors(connection, err.message),
            406: (err) => this.handleSyncErrors(connection, err.message),
            500: (err) => this.handleSyncErrors(connection, err.message),
        });

        if (resConn) {
            connection.status = resConn.status;

            if (['Authenticating'].includes(resConn.status)) {
                this.updateSyncingConnection(connection, idx, 2);
            } else if (['Awaiting 2FA'].includes(resConn.status)) {
                this.updateSyncingConnection(connection, idx, 2);
                this.setConnectionSyncTracking(connection._id, false);
                return;
            } else if (['Syncing'].includes(resConn.status)) {
                this.updateSyncingConnection(connection, idx, 3);
            } else if (['Active', 'Pending'].includes(resConn.status)) {
                this.updateSyncingConnection(connection, idx, null);
                this.setConnectionSyncTracking(connection._id, false);
                return;
            } else if ('Error' === resConn.status) {
                this.handleSyncErrors(resConn, idx, resConn.error?.user);
                this.updateSyncingConnection(connection, idx, null);
                this.setConnectionSyncTracking(connection._id, false);
                return;
            }
        }

        const tOut = setTimeout(() => this.getSyncStatus(connection, idx), TIMEOUT);
        this.addSyncTimeout(tOut);
    };

    getColumns = () => {
        const columns = [];

        const areAllConnectionSelected = _.isEqual(this.getSelectedConnectionIds().sort(), this.state.currentConnections.map((c) => c._id).sort());
        columns.push({
            title: (
                <>
                    <Checkbox
                        checked={areAllConnectionSelected}
                        setChecked={() => {
                            if (areAllConnectionSelected) {
                                this.clearSelectedConnections();
                            } else {
                                this.setState({
                                    selectedConnections: this.state.currentConnections.reduce((acc, c) => {
                                        acc[c._id] = true;
                                        return acc;
                                    }, {}),
                                });
                            }
                        }}
                        style={{ marginRight: '10px' }}
                    />
                </>
            ),
        });

        columns.push(
            ...[
                {
                    title: 'Connection',
                    sort: {
                        field: 'name',
                    },
                },
                // {
                //     title: 'Entity',
                //     sort: {
                //         field: 'account',
                //     },
                // },
                {
                    title: 'User',
                    sort: {
                        field: 'user',
                    },
                },
                {
                    title: 'Integration',
                    sort: {
                        field: 'integration',
                    },
                },
                {
                    title: 'Contacts',
                    sort: {
                        field: 'contacts',
                    },
                },
                {
                    title: 'Phone Number',
                    field: 'tfa_phone',
                    sort: {
                        field: 'tfa_phone',
                    },
                },
                {
                    title: 'Status',
                    sort: {
                        field: 'status',
                    },
                },
            ]
        );

        columns.push({
            title: 'Outreach Status',
            field: 'outreach_status',
            sort: {
                field: 'outreach_status',
            },
        });

        const nextColumns = [
            {
                title: 'Last Sync',
                sort: {
                    field: 'last_connection_at',
                },
            },
            // {
            //     title: 'Complete',
            //     // sort: {
            //     //     field: 'incomplete',
            //     // },
            // },
            {
                title: '2FA',
                sort: {
                    field: 'use_tfa',
                },
            },
            // {
            //     title: 'RAL',
            //     // sort: {
            //     //     field: 'ral_status',
            //     // },
            // },
            {
                title: '',
            },
        ];

        columns.push(...nextColumns);

        return columns;
    };

    setForReview = async (connection, i_idx, investment, checked) => {
        if (checked && investment._id) {
            this.props.loading(320, 'mngInv_submitAddContact');
            try {
                await api2.client.InvestmentApi.updateInvestment({
                    investment_id: investment._id,
                    UpdateInvestmentRequest: {
                        incomplete: true,
                    },
                });
                connection.investments[i_idx] = (
                    await api2.client.InvestmentApi.getInvestment({
                        investment_id: investment._id,
                    })
                ).data.investment;
            } catch (err) {
                console.error(err);
            }
        }
        if (!checked && investment._id) {
            this.props.loading(320, 'mngInv_submitAddContact');
            try {
                await api2.client.InvestmentApi.updateInvestment({
                    investment_id: investment._id,
                    UpdateInvestmentRequest: {
                        incomplete: false,
                    },
                });
                connection.investments[i_idx] = (
                    await api2.client.InvestmentApi.getInvestment({
                        investment_id: investment._id,
                    })
                ).data.investment;
                this.updateRow(connection);
            } catch (err) {
                console.error(err);
            }
        }
    };

    getTimeAgoStr = (date) => {
        if (!date) return EM_DASH;
        const dt = (new Date().getTime() - new Date(date).getTime()) / 1000 / 60 / 60; // Hours
        return dt < 2 || dt > 24 ? formatDate(date, true, false, true) : `${Math.floor(dt)} hours ago`;
    };

    renderRow = (connections, connIdx) => {
        const s = this.state;
        const conn = connections[connIdx];

        return (
            <React.Fragment key={'connTbl' + connIdx}>
                <tr
                    key={'connTblRow' + connIdx}
                    className="connection-row"
                    onClick={() => {
                        this.setState({ connection: conn });
                        this.openConnectionDrawer(conn?._id);
                    }}
                >
                    {
                        <td onClick={(e) => e.stopPropagation()}>
                            <Checkbox
                                checked={s.selectedConnections[conn._id] ?? false}
                                setChecked={(checked) => {
                                    const selectedConnections = {
                                        ...s.selectedConnections,
                                        [conn._id]: checked,
                                    };
                                    this.setState({ selectedConnections });
                                }}
                                style={{ marginRight: '10px' }}
                            />
                        </td>
                    }
                    <td onClick={this.expandRow.bind(this, conn, connIdx)}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span className={`arrow-${conn.expanded ? 'up' : 'down'}`} style={{ marginTop: '10px' }}>
                                &#9654;
                            </span>
                            <strong>{conn.name}</strong>

                            <div style={{ display: 'flex' }}>
                                <CopyButton
                                    text={conn._id}
                                    onClick={() => {
                                        setDismissableAlert(this.props.setAlert, 'Connection ID copied to clipboard.');
                                    }}
                                    title={`Copy Connection ID (${conn._id})`}
                                    color={!conn.is_enabled ? 'gray' : undefined}
                                />

                                {conn.most_recent_sync && (
                                    <IconButton
                                        faIcon={faSync}
                                        title={'Go To Sync Staging'}
                                        href={`/admin/connections/staging/${conn._id}`}
                                        size="18px"
                                        containerStyle={{ marginLeft: '10px' }}
                                        color="green"
                                    />
                                )}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div style={{ width: 'min-content' }}>
                            <a href={`/admin/users/${conn.user?._id ?? conn.user}`} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                <div>
                                    <div style={{ fontSize: '12px', color: '--color-light-gray' }}>{conn.user?.email}</div>
                                    <div>{conn.user?.name}</div>
                                </div>
                                {conn.user?.name && (
                                    <CopyButton text={conn.user?.name} title={`Copy ID to Clipboard (${conn.user?.name})`} color={conn.user.is_deactivated ? 'gray' : undefined} />
                                )}
                            </a>
                        </div>
                    </td>
                    <td>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                            {conn.integration?.name}
                            {conn.integration && <CopyButton text={conn.integration?._id} title={`Copy ID to Clipboard (${conn.integration?._id})`} />}
                        </div>
                    </td>
                    <td>
                        <div>
                            {conn.contacts?.map((c) => (
                                <div className={'table-ellipses'} style={{ width: '120px' }} key={c.email} title={c.email}>
                                    {c.email}
                                </div>
                            ))}
                        </div>
                    </td>
                    <td>{`${conn.tfa_phone ? formatPhoneNumber(conn.tfa_phone) : ''}`}</td>
                    <td className={this.getClassName(conn.status)}>{conn.status}</td>
                    {<td>{conn.outreach_status}</td>}
                    <td>
                        {isConnectionSyncing(conn) ? (
                            <Progress steps={Object.keys(conn.steps ?? {})} currentStep={conn.currentStep} errors={conn.syncErrors} />
                        ) : (
                            this.getTimeAgoStr(conn.last_connection_at)
                        )}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                        <span className={conn.use_tfa ? 'green-cm' : 'gray-x'}>{conn.use_tfa ? '✓' : 'X'}</span>
                    </td>
                    <td>{this.renderSyncButton(conn, connIdx)}</td>
                </tr>
                {conn.expanded && (
                    <React.Fragment key={`expanded_${connIdx}`}>
                        <tr className="expanded-row">
                            {<td />}
                            <td>
                                <strong>Investment Name</strong>
                            </td>
                            <td>
                                <strong>Investment Type</strong>
                            </td>
                            <td>
                                <strong>Investment Status</strong>
                            </td>
                            <td>
                                <strong>Set for Review</strong>
                            </td>
                            <td>
                                <strong>Active</strong>
                            </td>
                            <td />
                            {<td />}
                            <td />
                            <td />
                            <td />
                        </tr>
                        {conn.investments?.map((investment, i_idx) => {
                            return (
                                <>
                                    <tr className="expanded-row" key={i_idx}>
                                        {<td />}
                                        <td>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <a href={`/admin/users/${investment?.user?._id ?? investment?.user}/details/${investment._id}`}>{investment.name}</a>
                                                <CopyButton
                                                    text={investment._id}
                                                    onClick={() => {
                                                        setDismissableAlert(this.props.setAlert, 'Investment ID copied to clipboard.');
                                                    }}
                                                    title={`Copy Investment ID (${investment._id})`}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div style={{ width: 'min-content' }}>
                                                {investment.editing ? (
                                                    <Dropdown
                                                        options={this.state.investmentTypes}
                                                        select={this.handleTypeSelect.bind(this, i_idx, conn, connIdx)}
                                                        defaultSelection={investment.type}
                                                    />
                                                ) : (
                                                    <div className="a" onClick={this.changeInvestmentType.bind(this, i_idx, conn, connIdx)} style={{ whiteSpace: 'nowrap' }}>
                                                        {investment.type}
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td>{investment.incomplete ? 'Incomplete' : 'Complete'}</td>
                                        <td>
                                            <div>
                                                <Checkbox setChecked={this.setForReview.bind(this, conn, i_idx, investment)} checked={investment.incomplete ? true : false} />
                                            </div>
                                        </td>
                                        <td>
                                            <div>{investment.inactive ? 'No' : 'Yes'}</div>
                                        </td>
                                        <td>
                                            {this.showIncomplete() && (
                                                <span className="a" onClick={() => this.toggleShowIncompleteDocs(conn, connIdx, investment._id)}>
                                                    {investment.showUnprocessed ? 'Hide Docs' : 'Show Docs'}
                                                </span>
                                            )}
                                        </td>
                                        <td>{renderInputError(this.state.errors, 'investment_type')}</td>
                                        <td>
                                            <div className="a" style={{ color: '#B64040' }} onClick={this.removeInvestment.bind(this, conn, connIdx, i_idx)}>
                                                Remove
                                            </div>
                                        </td>
                                        <td />
                                        {<td />}
                                    </tr>
                                    {investment.showUnprocessed && (
                                        <>
                                            <tr className="expanded-row" style={{ backgroundColor: 'var(--color-accent)' }}>
                                                <td>
                                                    <strong>Document Name</strong>
                                                </td>
                                                <td>
                                                    <strong>Document Type</strong>
                                                </td>
                                                <td>
                                                    <strong>Had Valid Metadata</strong>
                                                </td>
                                                <td>
                                                    <strong>Open Document</strong>
                                                </td>
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                <td />
                                                {<td />}
                                                {<td />}
                                            </tr>
                                            {investment.unprocessedDocs?.map((doc) => {
                                                const currentLocation = `${window.location.pathname}${window.location.search}`;
                                                return (
                                                    <tr className="expanded-row" style={{ backgroundColor: 'var(--color-accent)' }} key={doc._id}>
                                                        <td>{doc.name}</td>
                                                        <td>{doc.type}</td>
                                                        <td>{doc.hasValidMetadata ? 'Valid Metadata' : 'Invalid Metadata'}</td>
                                                        <td>
                                                            <a href={'/admin/documents/' + doc._id + `?return_path=${currentLocation}`}>Open Document</a>
                                                        </td>
                                                        <td />
                                                        <td />
                                                        <td />
                                                        <td />
                                                        <td />
                                                        {<td />}
                                                        {<td />}
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )}
                                </>
                            );
                        })}
                    </React.Fragment>
                )}
                {connections[connIdx].no_investments && (
                    <tr className="expanded-row">
                        <td>No Investments</td>
                        {<td />}
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                )}
            </React.Fragment>
        );
    };

    getSelectedConnectionIds = () => {
        const s = this.state;
        return Object.entries(s.selectedConnections)
            .filter(([, selected]) => selected)
            .map(([connId]) => connId)
            .sort();
    };

    getSelectedConnections = () => {
        const ids = this.getSelectedConnectionIds();
        return this.state.currentConnections.filter((c) => ids.includes(c._id));
    };

    bulkExportConnections = async (connectionIds) => {
        const connectionResults = await api2.paginateApiRoute(async (paginate_params) => {
            return (
                await api2.client.ConnectionApi.listConnections({
                    connections: connectionIds,
                    ...paginate_params,
                    populate_asset_manager: true,
                    populate_account_list: true,
                    populate_account: true,
                    populate_user: true,
                    populate_integration: true,
                })
            ).data.connections;
        });
        const connectionData = [
            ['_id', 'name', 'tfa_phone', 'tfa_email', 'integration_name', 'integration_id', 'user_name', 'user_email', 'user_id', 'asset_manager_name', 'accounts'],
            ...connectionResults.map((conn) => [
                conn._id ?? '',
                conn.name ?? '',
                conn.tfa_phone ?? '',
                conn.tfa_email ?? '',
                conn.integration?.name ?? '',
                conn.integration?._id ?? '',
                conn.user?.name ?? '',
                conn.user?.email ?? '',
                conn.user?._id ?? '',
                conn.asset_manager?.name ?? '',
                conn.account_list?.map((a) => a.name).join(' | ') ?? '',
            ]),
        ];
        saveCsv(connectionData, 'connections.csv');
    };

    bulkPatchConnections = async (connectionIds, diff, consecutive = false) => {
        const functions = [];
        connectionIds.forEach((connId) => {
            functions.push(async () => await api.patch(`/connections/${connId}`, diff));
        });
        this.props.loading(320, 'bulkPatchConnections');
        let res = [];
        if (consecutive) {
            for (let i = 0; i < functions.length; i++) {
                const thisRes = await functions[i]();
                res.push(thisRes);
            }
        } else {
            res = await Promise.all(functions.map((f) => f()));
        }
        this.props.loaded('bulkPatchConnections');
        if (res.filter((x) => !x).length === 0) {
            setDismissableAlert(this.props.setAlert, 'Connections updated', false);
            this.childCallables.loadData();
            this.props.closeDrawer();
            this.clearSelectedConnections();
        } else {
            setDismissableAlert(this.props.setAlert, 'Error updating connections', true);
        }
    };

    bulkDeleteConnections = async (connectionIds) => {
        this.props.loading(320, 'bulkDeleteConnections');
        const successes = await Promise.all(
            connectionIds.map(async (connId) => {
                try {
                    await api2.client.ConnectionApi.deleteConnection({
                        connection_id: connId,
                    });
                } catch (error) {
                    console.error('Error deleting connection', error);

                    return false;
                }
                return true;
            })
        );
        this.props.loaded('bulkDeleteConnections');

        if (successes.filter((x) => !x).length === 0) {
            setDismissableAlert(this.props.setAlert, 'Connections deleted successfully', false);
            this.childCallables.loadData();
            this.props.closeDrawer();
            this.clearSelectedConnections();
        } else {
            setDismissableAlert(this.props.setAlert, 'Error deleting connections', true);
        }
    };

    renderChangeAssetManagerDrawer = () => {
        const p = this.props;

        // if all have the same name, make it the initial value
        let initialAssetManager = '';
        const assetManagers = this.getSelectedConnections()?.map((c) => c.asset_manager) ?? [];
        if (assetManagers.length > 0 && assetManagers.every((n) => n === assetManagers[0])) {
            initialAssetManager = assetManagers[0];
        }

        p.openDrawer(() => (
            <GenericDrawer
                title="Change Asset Manager"
                actionStr="Change Asset Manager"
                close={p.closeDrawer}
                loading={p.loading}
                loaded={p.loaded}
                loadData={async () => {
                    if (initialAssetManager) {
                        try {
                            const asset_manager = await api.get(`/assetmanagers/${initialAssetManager}`);
                            return {
                                asset_manager,
                            };
                        } catch (err) {
                            console.error('Could not load asset manager', err);
                        }
                    }
                }}
                onSubmit={async (data) => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }

                    const asset_manager = data.asset_manager?._id || null;

                    p.loading(320, 'bulkPatchConnections');
                    await this.bulkPatchConnections(connectionIds, { asset_manager });
                    p.loaded('bulkPatchConnections');
                }}
                reloadData={() => {
                    this.childCallables.loadData();
                    this.clearSelectedConnections();
                }}
                getFields={() => {
                    const fields = {
                        AssetManager: {
                            label: <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>Asset Manager</div>,
                            type: 'custom',
                            fieldName: 'asset_manager',
                            component: (value, onChange) => {
                                return (
                                    <AssetManagerSearchFilter
                                        defaultLabel={'None'}
                                        selected={value}
                                        onChange={(assetManager) => {
                                            onChange(assetManager);
                                        }}
                                        width={'100%'}
                                        defaultOptions={true}
                                        // noDefaultOption={true}
                                    />
                                );
                            },
                        },
                    };
                    return fields;
                }}
            />
        ));
    };

    renderChangeNameDrawer = () => {
        const p = this.props;

        // if all have the same name, make it the initial value
        let name = '';
        const names = this.getSelectedConnections()?.map((c) => c.name) ?? [];
        if (names.length > 0 && names.every((n) => n === names[0])) {
            name = names[0];
        }

        p.openDrawer(() => (
            <GenericDrawer
                title="Change Name"
                actionStr="Change Name"
                close={p.closeDrawer}
                loading={p.loading}
                loaded={p.loaded}
                onSubmit={async (data) => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    const name = data.name;
                    if (!name) {
                        return setDismissableAlert(p.setAlert, 'Name cannot be empty', true);
                    }
                    p.loading(320, 'bulkPatchConnections');
                    await this.bulkPatchConnections(connectionIds, { name });
                    p.loaded('bulkPatchConnections');
                }}
                reloadData={() => {
                    this.childCallables.loadData();
                    this.clearSelectedConnections();
                }}
                getFields={() => {
                    const fields = {
                        Name: {
                            placeholder: 'Select Name',
                            label: <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>Name</div>,
                            fieldName: 'name',
                            type: 'text',
                            initialValue: name,
                        },
                    };
                    return fields;
                }}
            />
        ));
    };

    renderChangeStatusDrawer = () => {
        const p = this.props;

        // if all have the same status, make it the initial value
        let status = ConnectionStatus.Active;
        const statuses = this.getSelectedConnections()?.map((c) => c.status) ?? [];
        if (statuses.length > 0 && statuses.every((n) => n === statuses[0])) {
            status = statuses[0];
        }

        p.openDrawer(() => (
            <GenericDrawer
                title="Change Status"
                actionStr="Change Status"
                close={p.closeDrawer}
                loading={p.loading}
                loaded={p.loaded}
                onSubmit={async (data) => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    const status = data.status;
                    p.loading(320, 'bulkPatchConnections');
                    await this.bulkPatchConnections(connectionIds, { status });
                    p.loaded('bulkPatchConnections');
                }}
                reloadData={() => {
                    this.childCallables.loadData();
                    this.clearSelectedConnections();
                }}
                getFields={() => {
                    const fields = {
                        Status: {
                            placeholder: 'Select Status',
                            label: <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>Status</div>,
                            fieldName: 'status',
                            type: 'option',
                            options: Object.values(ConnectionStatus),
                            // optionField: 'name',
                            optionClearable: false,
                            initialValue: status,
                        },
                    };
                    return fields;
                }}
            />
        ));
    };

    renderChangeOutreachStatusDrawer = () => {
        const p = this.props;
        const s = this.state;

        // if all have the same status, make it the initial value
        let outreach_status = s.connectionOutreachStatuses[0];
        const outreach_statuses = this.getSelectedConnections()?.map((c) => c.outreach_status) ?? [];
        if (outreach_statuses.length > 0 && outreach_statuses.every((n) => n === outreach_statuses[0])) {
            outreach_status = outreach_statuses[0];
        }

        p.openDrawer(() => (
            <GenericDrawer
                title="Change Outreach Status"
                actionStr="Change Outreach Status"
                close={p.closeDrawer}
                loading={p.loading}
                loaded={p.loaded}
                onSubmit={async (data) => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    const outreach_status = data.outreach_status;
                    p.loading(320, 'bulkPatchConnections');
                    await this.bulkPatchConnections(connectionIds, { outreach_status });
                    p.loaded('bulkPatchConnections');
                }}
                reloadData={() => {
                    this.childCallables.loadData();
                    this.clearSelectedConnections();
                }}
                getFields={() => {
                    const fields = {
                        OutreachStatus: {
                            placeholder: 'Select Outreach Status',
                            label: <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>Outreach Status</div>,
                            fieldName: 'outreach_status',
                            type: 'option',
                            options: this.state.connectionOutreachStatuses,
                            // optionField: 'name',
                            optionClearable: false,
                            initialValue: outreach_status,
                        },
                    };
                    return fields;
                }}
            />
        ));
    };

    renderChangeConnectionIntegrationDrawer = () => {
        const p = this.props;
        p.openDrawer(() => (
            <GenericDrawer
                title="Set Integration"
                actionStr="Set Integration"
                close={p.closeDrawer}
                loading={p.loading}
                loaded={p.loaded}
                onSubmit={async (data) => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    const integration = data.integration?._id ?? null;
                    p.loading(320, 'bulkPatchConnections');
                    await this.bulkPatchConnections(connectionIds, { integration });
                    p.loaded('bulkPatchConnections');
                }}
                reloadData={() => {
                    this.childCallables.loadData();
                    this.clearSelectedConnections();
                }}
                getFields={(drawerState) => {
                    const fields = {
                        Integration: {
                            placeholder: 'Select Integration',
                            label: <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>Integration</div>,
                            fieldName: 'integration',
                            type: 'option',
                            options: drawerState?.integrations ?? [],
                            optionField: 'name',
                            optionClearable: false,
                        },
                    };
                    return fields;
                }}
                loadState={async () => {
                    let integrations;
                    try {
                        integrations = await api2.paginateApiRoute(async (paginate_params) => {
                            return (
                                await api2.client.IntegrationApi.listIntegrations({
                                    ...paginate_params,
                                })
                            ).data.integrations;
                        });
                    } catch (error) {
                        console.error('Error fetching integrations:', error);
                    }
                    return { integrations };
                }}
            />
        ));
    };

    renderChangeConnectionNameDrawer = () => {
        const p = this.props;
        p.openDrawer(() => (
            <GenericDrawer
                title="Change Connection Name"
                actionStr="Change Connection Name"
                close={p.closeDrawer}
                loading={p.loading}
                loaded={p.loaded}
                onSubmit={async (data) => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    const name = data.name;
                    if (!name) {
                        return setDismissableAlert(p.setAlert, 'Please enter a name', true);
                    }
                    p.loading(320, 'bulkPatchConnections');
                    await this.bulkPatchConnections(connectionIds, { name });
                    p.loaded('bulkPatchConnections');
                }}
                reloadData={() => {
                    this.childCallables.loadData();
                    this.clearSelectedConnections();
                }}
                getFields={() => {
                    const fields = {
                        Name: {
                            placeholder: 'Select Name',
                            label: <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>Name</div>,
                            fieldName: 'name',
                            type: 'text',
                        },
                    };
                    return fields;
                }}
            />
        ));
    };

    renderChangeConnectionOutreachStatusDrawer = () => {
        const p = this.props;
        p.openDrawer(() => (
            <GenericDrawer
                title="Change Connection Outreach Status"
                actionStr="Change Connection Outreach Status"
                submitStr="Save"
                close={p.closeDrawer}
                loading={p.loading}
                loaded={p.loaded}
                onSubmit={async (data) => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    const outreach_status = data.outreach_status;
                    if (!outreach_status) {
                        return setDismissableAlert(p.setAlert, 'Please select an outreach status', true);
                    }
                    p.loading(320, 'bulkPatchConnections');
                    await this.bulkPatchConnections(connectionIds, { outreach_status: outreach_status ?? null });
                    p.loaded('bulkPatchConnections');
                }}
                reloadData={() => {
                    this.childCallables.loadData();
                    this.clearSelectedConnections();
                }}
                getFields={() => {
                    const fields = {
                        OutreachStatus: {
                            placeholder: 'Select Outreach Status',
                            label: 'Outreach Status',
                            fieldName: 'outreach_status',
                            type: 'option',
                            options: this.state.connectionOutreachStatuses,
                            optionClearable: false,
                        },
                    };
                    return fields;
                }}
            />
        ));
    };

    getSelectFilters = () => {
        const s = this.state;

        let selectFilters = [];

        selectFilters.push({
            label: 'Is Connection Enabled',
            render: () => {
                const OPTIONS = ['All', 'Enabled', 'Disabled'];
                const curOption = this.state.isEnabledFilter ?? OPTIONS[0];
                return (
                    <Multitoggle
                        containerStyle={{ width: '195px', height: '30px', marginBottom: '7px' }}
                        options={OPTIONS}
                        selection={curOption}
                        onSelect={(opt) => {
                            this.setState({ isEnabledFilter: opt });
                            setQueryParam('isEnabled', opt !== OPTIONS[0] ? opt : null);
                        }}
                    />
                );
            },
        });

        if (this.props.adminOrAdvisorVue) {
            selectFilters.push({
                label: 'User',
                render: () => (
                    <>
                        <UserSearchFilter
                            selected={s.userFilter}
                            onChange={async (userFilter) => {
                                this.setState({ userFilter });
                                setQueryParam('user', userFilter?._id);
                            }}
                            width={'300px'}
                        />
                    </>
                ),
            });
            selectFilters.push({
                label: 'Admin Teams',
                render: () => {
                    return (
                        <AdminTeamSearchFilter
                            width={'300px'}
                            selected={s.adminTeamFilter}
                            onChange={(adminTeamFilter) => {
                                this.setState({ adminTeamFilter });
                                if (Array.isArray(adminTeamFilter)) {
                                    setQueryParam('admin_teams', adminTeamFilter.map((t) => t._id).join(','));
                                } else if (adminTeamFilter && adminTeamFilter._id) {
                                    setQueryParam('admin_teams', adminTeamFilter._id);
                                } else {
                                    setQueryParam('admin_teams', null);
                                }
                            }}
                            defaultOptions={true}
                        />
                    );
                },
            });
            selectFilters.push({
                label: 'Advisory Groups',
                render: () => {
                    return (
                        <AdvisoryGroupSearchFilter
                            width={'300px'}
                            selected={s.advisoryGroupFilter}
                            onChange={(advisoryGroupFilter) => {
                                this.setState({ advisoryGroupFilter });
                                setQueryParam('advisory_groups', advisoryGroupFilter?._id);
                            }}
                            defaultOptions={true}
                        />
                    );
                },
            });
        }

        const integrationOptions = [{ name: 'All Integrations', _id: 'all' }, { name: 'AltX Manual Investments', _id: 'manual' }, ...s.integrations];

        let selectedIntegration = integrationOptions.find((int) => int._id === s.integrationFilter);
        selectedIntegration = selectedIntegration ? { value: selectedIntegration, label: selectedIntegration.name } : null;

        selectFilters.push({
            label: 'Integration',
            selected: selectedIntegration,
            options: integrationOptions.map((itg) => ({ value: itg, label: itg.name })),
            placeholder: 'Filter by integration',
            onChange: (itgValue) => {
                const integrationFilter = itgValue?._id;
                this.setState({ integrationFilter });
                setQueryParam('integration', integrationFilter);
            },
            isMulti: false,
        });

        selectFilters.push({
            label: 'Investment Status',
            render: () => {
                return (
                    <Dropdown
                        style={{ width: '150px' }}
                        disableDefaultOption={true}
                        options={Object.values(INVESTMENT_STATUSES)}
                        select={(investmentStatus) => {
                            this.setState({ investmentStatus });
                            setQueryParam('investmentStatus', investmentStatus !== INVESTMENT_STATUSES.all ? investmentStatus : null);
                        }}
                        selection={s.investmentStatus}
                    />
                );
            },
        });

        selectFilters.push({
            label: 'Connection Status',
            render: () => {
                return (
                    <Dropdown
                        style={{ width: '150px' }}
                        disableDefaultOption={true}
                        options={Object.values(CONNECTION_STATUSES)}
                        select={(connectionStatus) => {
                            this.setState({ connectionStatus });
                            setQueryParam('connectionStatus', connectionStatus !== CONNECTION_STATUSES.All ? connectionStatus : null);
                        }}
                        selection={s.connectionStatus}
                    />
                );
            },
        });

        selectFilters.push({
            label: 'Outreach Status',
            render: () => {
                return (
                    <Dropdown
                        style={{ width: '250px' }}
                        // disableDefaultOption={true}
                        defaultOptionString={ALL_OUTREACH_STATUSES}
                        options={Object.values(s.connectionOutreachStatuses)}
                        select={(connectionOutreachStatus) => {
                            if (connectionOutreachStatus === ALL_OUTREACH_STATUSES) {
                                connectionOutreachStatus = null;
                            }
                            this.setState({ connectionOutreachStatus });
                            setQueryParam('connectionOutreachStatus', connectionOutreachStatus);
                        }}
                        selection={s.connectionOutreachStatus ?? ALL_OUTREACH_STATUSES}
                    />
                );
            },
        });

        selectFilters.push({
            label: 'Asset Manager',
            render: () => {
                return (
                    <AssetManagerSearchFilter
                        defaultLabel={'All Asset Managers'}
                        selected={s.assetManagerFilter}
                        onChange={(assetManagerFilter) => {
                            this.setState({
                                assetManagerFilter,
                            });
                            setQueryParam(ASSET_MANAGER, assetManagerFilter?._id);
                        }}
                        width={'250px'}
                        defaultOptions={true}
                    />
                );
            },
        });

        return selectFilters;
    };

    clearSelectedConnections = () => {
        this.setState({ selectedConnections: {} });
    };

    getBulkActions = (p) => {
        let bulkActions = [
            {
                label: 'Sync',
                action: async () => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    const res = await Promise.all(connectionIds.map((connId) => api.post(`/connections/${connId}/sync`)));
                    const successfullSyncs = res.filter((x) => x);
                    const failedSyncs = res.filter((x) => !x);
                    if (successfullSyncs.length === connectionIds.length) {
                        setDismissableAlert(p.setAlert, 'Connections syncing', false);
                    } else if (failedSyncs.length === connectionIds.length) {
                        setDismissableAlert(p.setAlert, 'Error syncing connections', true);
                    } else {
                        setDismissableAlert(p.setAlert, `${successfullSyncs.length} connections syncing, ${failedSyncs.length} connections failed to sync`, false);
                    }
                },
            },
            {
                label: 'Mark As Synced',
                action: async () => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    p.loading(320, 'bulkPatchConnections');
                    await this.bulkPatchConnections(connectionIds, {
                        status: ConnectionStatus.Synced,
                        last_connection_at: new Date().toISOString(),
                    });
                    p.loaded('bulkPatchConnections');
                },
            },
            {
                label: 'Enable Connections',
                action: async () => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    p.loading(320, 'bulkPatchConnections');
                    await this.bulkPatchConnections(connectionIds, {
                        is_enabled: true,
                    });
                    p.loaded('bulkPatchConnections');
                },
            },
            {
                label: 'Disable Connections',
                action: async () => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    p.loading(320, 'bulkPatchConnections');
                    await this.bulkPatchConnections(connectionIds, {
                        is_enabled: false,
                    });
                    p.loaded('bulkPatchConnections');
                },
            },
            {
                label: 'Delete Connections',
                action: async () => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    p.loading(320, 'bulkDeleteConnections');
                    await this.bulkDeleteConnections(connectionIds);
                    p.loaded('bulkDeleteConnections');
                },
            },
            {
                label: 'Export Connections',
                action: async () => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    p.loading(320, 'bulkExportConnections');
                    await this.bulkExportConnections(connectionIds);
                    p.loaded('bulkExportConnections');
                },
            },
            {
                label: 'Set integration',
                action: async () => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    this.renderChangeConnectionIntegrationDrawer();
                },
            },
            {
                label: 'Change Name',
                action: async () => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    this.renderChangeNameDrawer();
                },
            },
            {
                label: 'Change Status',
                action: async () => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    this.renderChangeStatusDrawer();
                },
            },
            {
                label: 'Change Outreach Status',
                action: async () => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    this.renderChangeOutreachStatusDrawer();
                },
            },
            {
                label: 'Change Asset Manager',
                action: async () => {
                    const connectionIds = this.getSelectedConnectionIds();
                    if (!connectionIds.length) {
                        return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                    }
                    this.renderChangeAssetManagerDrawer();
                },
            },
        ];

        bulkActions.push({
            label: `Turn Bulk Outreach Mode ${this.state.bulkOutreachMode ? 'Off' : 'On'}`,
            action: async () => {
                const newValue = !this.state.bulkOutreachMode;
                this.setState({
                    bulkOutreachMode: newValue,
                });
                if (!newValue) {
                    this.clearSelectedConnections();
                }
            },
        });

        if (this.state.bulkOutreachMode) {
            const hasConnectionsSelected = this.getSelectedConnectionIds().length > 0;
            bulkActions.push({
                label: 'Asset Manager Outreach',
                subOptions: [
                    {
                        label: 'Export User Information',
                        action: async () => {
                            const connectionIds = this.getSelectedConnectionIds();
                            if (!connectionIds.length) {
                                return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                            }
                            p.loading(0, 'generating zip');
                            const zipRes = await getUserRalInfoZip(connectionIds);
                            p.loaded('generating zip');
                            if (zipRes) {
                                setDismissableAlert(p.setAlert, 'Exported user information');
                                this.clearSelectedConnections();
                            } else {
                                setDismissableAlert(p.setAlert, 'Error exporting user information', true);
                            }
                        },
                        disabled: !hasConnectionsSelected,
                    },
                    {
                        label: 'Add/Change Integration',
                        action: async () => {
                            this.renderChangeConnectionIntegrationDrawer();
                        },
                        disabled: !hasConnectionsSelected,
                    },
                    {
                        label: 'Rename Connections',
                        action: async () => {
                            this.renderChangeConnectionNameDrawer();
                        },
                        disabled: !hasConnectionsSelected,
                    },
                    {
                        label: 'Rent Phone Number',
                        action: async () => {
                            const connectionIds = this.getSelectedConnectionIds();
                            if (!connectionIds.length) {
                                return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                            }
                            this.bulkPatchConnections(connectionIds, { require_phone: true }, true);
                        },
                        disabled: !hasConnectionsSelected,
                    },
                    {
                        label: 'Unrent Phone Number',
                        action: async () => {
                            const connectionIds = this.getSelectedConnectionIds();
                            if (!connectionIds.length) {
                                return setDismissableAlert(p.setAlert, 'Please select at least one connection', true);
                            }
                            this.bulkPatchConnections(connectionIds, { require_phone: false });
                        },
                        disabled: !hasConnectionsSelected,
                    },
                    {
                        label: 'Change Outreach Status',
                        action: async () => {
                            this.renderChangeConnectionOutreachStatusDrawer();
                        },
                        disabled: !hasConnectionsSelected,
                    },
                ],
            });
        }

        return bulkActions;
    };

    getRoute = () => {
        const s = this.state;

        let route = '/connections?populate_most_recent_sync=true&populate_contacts=true&populate_integration=true&populate_user=true';
        if (!['All', undefined, null].includes(s.connectionStatus)) {
            route += `${route.includes('?') ? '&' : '?'}statuses=${s.connectionStatus}`;
        }

        if (this.showIncomplete()) {
            route += `${route.includes('?') ? '&' : '?'}incomplete=true`;
        }

        if (s.userFilter) {
            route += `${route.includes('?') ? '&' : '?'}users=${s.userFilter?._id}`;
        }

        if (s.integrationFilter !== 'all') {
            route += `${route.includes('?') ? '&' : '?'}integrations=${s.integrationFilter === 'manual' ? 'null' : s.integrationFilter}`;
        }

        if (s.connectionOutreachStatus) {
            route += `${route.includes('?') ? '&' : '?'}outreach_statuses=${s.connectionOutreachStatus}`;
        }

        if (s.assetManagerFilter?._id) {
            route += `${route.includes('?') ? '&' : '?'}asset_managers=${s.assetManagerFilter?._id}`;
        }

        if (s.isEnabledFilter !== 'All') {
            route += `${route.includes('?') ? '&' : '?'}is_enabled=${s.isEnabledFilter === 'Enabled'}`;
        }
        if (s.adminTeamFilter) {
            const group_ids = getGroupIdsFromAdminTeams(s.adminTeamFilter);
            if (group_ids.length) {
                group_ids.forEach((id) => {
                    route = addQueryToRoute(route, `advisory_groups=${id}`);
                });
            }
        }
        if (s.advisoryGroupFilter) {
            route += `${route.includes('?') ? '&' : '?'}advisory_groups=${s.advisoryGroupFilter?._id}`;
        }

        // set lastRoute ref
        this.lastRoute.current = route;

        return route;
    };

    render = () => {
        const p = this.props;

        const ret = (
            <>
                <div className="connections-container">
                    <PaginationTable
                        bulkActions={this.getBulkActions(p)}
                        noBulkActionCheckboxes={true}
                        selectFilters={this.getSelectFilters()}
                        loading={p.loading}
                        loaded={p.loaded}
                        title={
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: 'bold',
                                        fontSize: '35px',
                                        color: 'var(--color-primary)',
                                        width: '100%',
                                    }}
                                >
                                    Connections
                                </div>
                            </>
                        }
                        searchBoxes={[
                            {
                                title: 'Search',
                                placeholder: 'Connection name',
                            },
                        ]}
                        preFetchCallback={() => {
                            this.clearSyncTimeouts();
                            setTimeout(() => this.clearSyncTimeouts(), 1000);
                        }}
                        route={this.getRoute()}
                        noHeader={true}
                        columns={this.getColumns()}
                        rowInfo={{ render: this.renderRow }}
                        setCallables={this.setChildCallables}
                        postProcessData={(data) => {
                            const syncingConnections = this.state.syncingConnections;
                            data?.forEach((conn, connIdx) => {
                                if (isConnectionSyncing(conn) && !syncingConnections[conn._id]) {
                                    this.setConnectionSyncTracking(conn._id, true);
                                    this.getSyncStatus(conn, connIdx);
                                }
                            });
                            if (!_.isEqual(data, this.state.currentConnections)) {
                                this.setState({ currentConnections: data });
                            }
                            return data;
                        }}
                        initialSortField="name"
                        initialSortAscending={true}
                        useApi2={true}
                        getResultsFromResponse={(res) => res.connections}
                    />
                </div>
                <div style={{ clear: 'both', height: '100px' }} />
            </>
        );

        // //TODO: fix pagination table so this disgusting hack is not necessary
        // if (!this.childCallables && this.state.selectionNeeded) {
        //     this.setState({selectionNeeded: (+!!this.state.selectionNeeded) + 1})
        // } else if (this.childCallables && this.state.selectionNeeded) {
        //     this.setState({selectionNeeded: null});
        // }
        return ret;
    };
}
