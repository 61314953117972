// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import inputKey from '../../utilities/inputKey';

import '../../styles/drawer.css';

export default class Drawer extends React.Component {
    componentDidMount = () => {
        document.addEventListener('keydown', (e) => {
            inputKey(e, {
                Escape: () => {
                    this.props.drawer();
                },
            });
        });
    };

    componentWillUnmount = () => {
        document.removeEventListener('keydown', inputKey.bind('keyCallbacks', { Escape: this.props.drawer() }));
    };

    renderContent = () => {
        if (this.props && this.props.content) {
            return <div className="drawer-content">{this.props.content}</div>;
        } else return <></>;
    };

    getClassName = () => {};

    getViewerContentStyle = () => {
        const p = this.props;
        let w = p.size === 'sm' ? '350px' : '500px';

        if (p.width) {
            w = p.width;
        }

        return {
            '--drawer-width': w,
            bottom: '0px',
            top: '80px',
        };
    };

    render = () => {
        return (
            <>
                {this.props.viewerContent && (
                    <div className="drawerViewer" style={{ ...this.getViewerContentStyle() }}>
                        {this.props.viewerContent}
                    </div>
                )}
                <div className="drawer" style={{ ...this.getViewerContentStyle() }}>
                    <Scrollbars renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style, width: 0 }} />}>{this.renderContent()}</Scrollbars>
                </div>
            </>
        );
    };
}
