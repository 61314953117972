export enum Currency {
    USD = 'USD', // United States Dollar
    EUR = 'EUR', // Euro
    CNY = 'CNY', // Chinese Yuan Renminbi
    JPY = 'JPY', // Japanese Yen
    GBP = 'GBP', // British Pound Sterling
    AUD = 'AUD', // Australian Dollar
    CAD = 'CAD', // Canadian Dollar
    CHF = 'CHF', // Swiss Franc
    INR = 'INR', // Indian Rupee
    KRW = 'KRW', // South Korean Won
    BTC = 'BTC', // Bitcoin
}

export enum CurrencySymbol {
    USD = '$', // United States Dollar
    EUR = '€', // Euro
    CNY = '¥', // Chinese Yuan Renminbi
    JPY = '¥', // Japanese Yen
    GBP = '£', // British Pound Sterling
    AUD = '$', // Australian Dollar
    CAD = '$', // Canadian Dollar
    CHF = '₣', // Swiss Franc
    INR = '₹', // Indian Rupee
    KRW = '₩', // South Korean Won
    BTC = '₿', // Bitcoin
}
