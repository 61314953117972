// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import '../../../styles/widget.css';
import '../../../styles/performanceOverview.css';
import { EM_DASH } from '../../../constants/constantStrings';

const isMobile = window.innerWidth <= 768;

interface PerformanceOverviewProps extends RouteComponentProps {
    called?: number;
    roi?: number;
    moic?: number;
    irr?: number;
}

const PerformanceOverview: React.FC<PerformanceOverviewProps> = (props) => {
    const FIELDS = ['irr', 'called', 'roi', 'moic'] as const;

    const STYLE_MATCH = {
        called: 'percentage',
        moic: 'multiple',
        roi: 'percentage',
        irr: 'percentage',
    } as const;

    const LABELS = {
        irr: 'annualized return',
        moic: 'multiple on invested capital',
        roi: 'return on investment',
        called: '% capital called',
    } as const;

    const MOBILE_LABELS = {
        irr: 'IRR',
        moic: 'MOIC',
        roi: 'ROI',
        called: '% called',
    } as const;

    const getIndividualWidth = () => {
        const amount = FIELDS.filter((f) => typeof props[f] !== 'undefined').length;
        return `${(1 / amount) * 100}%`;
    };

    const renderField = (propName: keyof PerformanceOverviewProps, label: string, mobileLabel: string, style: 'percentage' | 'multiple', isLast: boolean) => {
        const data = props[propName];
        let dataDisplay;
        if (typeof data !== 'number' || !Number.isFinite(data)) {
            dataDisplay = EM_DASH;
        } else if (data > 1e12) {
            dataDisplay = style === 'percentage' ? `${(100 * Number(data.toExponential(1))).toFixed(1)}%` : `${Number(data.toExponential(1)).toFixed(1)}x`;
        } else {
            dataDisplay = style === 'percentage' ? `${(100 * data).toFixed(1)}%` : `${data.toFixed(1)}x`;
        }

        return (
            typeof props[propName] !== 'undefined' && (
                <div
                    key={`performance_widget_${propName}`}
                    className={`performanceOverview_metric ${isLast ? '' : 'performanceOverview_metricDivider'}`}
                    style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}
                >
                    <div className="performanceOverview_metricKey" style={{ fontSize: dataDisplay.length > 20 ? '15px' : dataDisplay.length > 10 ? '20px' : undefined }}>
                        {dataDisplay}
                    </div>
                    <div className="performanceOverview_metricValue">{isMobile ? mobileLabel : label}</div>
                </div>
            )
        );
    };

    const findLast = () => {
        const arr = FIELDS;
        let lastDefinedField = null;
        for (let i = 0; i < arr.length; i++) {
            let field = arr[i];
            if (typeof props[field] !== 'undefined') {
                lastDefinedField = field;
            }
        }
        return lastDefinedField;
    };

    const last = findLast();

    return (
        <div className="widget performanceOverview_component" style={{ '--performance-overview-metric-width': getIndividualWidth() } as React.CSSProperties}>
            {FIELDS.map((prop) => {
                return renderField(prop, LABELS[prop], MOBILE_LABELS[prop], STYLE_MATCH[prop], last === prop);
            })}
        </div>
    );
};

export default withRouter(PerformanceOverview);
