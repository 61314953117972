import { useState, useEffect } from 'react';
import { FC, ComponentType } from 'react';

// Define interfaces for the hook parameters and return types
interface KeyObject {
    inputKeys: string[];
    outputKey: string;
}

interface HotKeysProps {
    keys: (string | KeyObject)[];
}

interface HotkeyMap {
    [key: string]: boolean;
}

/**
 * Custom hook to track keyboard events
 * @param keys array of objects or strings
 *  if object, must be of form {inputKeys: [string], outputKey: string}
 *  otherwise, string that is the key to track, will be input and output
 * @returns map of key to boolean
 */
export const useHotKeys = ({ keys }: HotKeysProps): HotkeyMap => {
    const [trackedKeys, setTrackedKeys] = useState<KeyObject[]>([]);
    const [hotkeyMap, setHotkeyMap] = useState<HotkeyMap>({});

    // turn keys into trackedKeys
    useEffect(() => {
        const newTrackedKeys: KeyObject[] = [];

        keys.forEach((k) => {
            // if is string
            if (typeof k === 'string') {
                newTrackedKeys.push({
                    inputKeys: [k],
                    outputKey: k,
                });
            }
            // if is object
            else if (typeof k === 'object') {
                newTrackedKeys.push({
                    inputKeys: k.inputKeys,
                    outputKey: k.outputKey,
                });
            }
        });

        setTrackedKeys(newTrackedKeys);
    }, [keys]);

    useEffect(() => {
        const handler = (e: KeyboardEvent, down: boolean) => {
            const keyObj = trackedKeys.find((k) => k.inputKeys.includes(e.key));
            const keyChanged = hotkeyMap[e.key] !== down;
            if (!keyObj || !keyChanged) return;
            setHotkeyMap((prev) => {
                return { ...prev, [keyObj.outputKey]: down };
            });
        };

        const handleKeyDown = (e: KeyboardEvent) => handler(e, true);
        const handleKeyUp = (e: KeyboardEvent) => handler(e, false);

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, [trackedKeys, hotkeyMap]);

    return hotkeyMap;
};

// Define interfaces for the HOC
interface WithHotKeysProps {
    hotkeyMap: HotkeyMap;
}

/**
 * HOC that adds hotkey functionality to a component
 * @param Component The component to wrap
 * @param targetKeys Array of keys to track
 * @returns Wrapped component with hotkeyMap prop
 */
export const withHotKeys = <P extends object>(Component: ComponentType<P & WithHotKeysProps>, targetKeys: (string | KeyObject)[]): FC<P> => {
    const WrappedComponent: FC<P> = (props: P) => {
        const hotkeyMap = useHotKeys({ keys: targetKeys });
        return <Component {...props} hotkeyMap={hotkeyMap} />;
    };

    WrappedComponent.displayName = `withHotKeys(${Component.displayName || Component.name || 'Component'})`;

    return WrappedComponent;
};
