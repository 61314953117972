// returns

export const checkDetailsError = (document) => {
    const errors = {};
    if (!document.user) {
        errors.user = 'Error: Document is missing user.';
    }
    if (document.ownership_type) {
        if (!document.ownership_ids?.length) {
            errors.ownership_type = 'Error: If ownership type is selected, at least one ownership object must be selected.';
        }
    }
    // console.log('checkDetailsError', document, errors);
    return errors;
};
