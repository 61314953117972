export const toCsvLine = (line: string[]): string => {
    return line
        .map((field) => {
            if (field.includes(',') || field.includes('"')) {
                return `"${field.replace(/"/g, '""')}"`;
            } else {
                return field;
            }
        })
        .join(',');
};
