import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faRefresh } from '@fortawesome/free-solid-svg-icons';
import PaginationTable from '../../components/Tables/PaginationTable';
import { setQueryParam, clearQueryParams } from '../../utilities/query/queryParamHelpers';
import { EM_DASH, VUES } from '../../constants/constantStrings';
import AddEditAdminTeamDrawer, { TeamTypes } from './AddEditAdminTeamDrawer';
import formatDate, { formatUTCDate, formatUTCDateTime } from '../../utilities/format/formatDate';
import '../../styles/investmentMaster.css';
import IconTextButton from '../../components/Buttons/IconTextButton';
import Search from '../../components/Inputs/Search';
import { format } from 'path';
import { AdminTeam } from '../../types/AdminTeam';
import { AppProps } from '../../types/props/AppProps';

interface AdminTeamsTableProps extends Pick<AppProps, 'user' | 'vue' | 'history' | 'loading' | 'loaded' | 'setTitle' | 'closeDrawer' | 'openDrawer' | 'setAlert'> {}

interface AdminTeamsTableState {
    selectedUser?: { _id: string };
}

class AdminTeamsTable extends Component<AdminTeamsTableProps, AdminTeamsTableState> {
    childCallables: any = null;

    state: AdminTeamsTableState = {};

    componentDidMount = async () => {
        this.setTitle();
        await this.load();
        this.loadQueryParams();
    };

    componentDidUpdate = (prevProps: AdminTeamsTableProps) => {
        if (prevProps.user?.name !== this.props.user?.name) {
            this.setTitle();
        }
    };

    setTitle = () => {
        this.props.setTitle(
            <>
                <span className="title-account-name">{this.props.user?.name}</span>
                <div className="separator" />
                Admin Teams
            </>
        );
    };

    componentWillUnmount = () => {
        this.props.closeDrawer();
    };

    load = async () => {
        const p = this.props;

        if (p.vue !== VUES.ADMIN) {
            console.log('not admin, redirect to home');
            p.history.push('/');
            return;
        }

        p.loading(0, 'AdminTeamsTable_load');

        this.setState({}, () => {
            this.loadQueryParams();
            p.loaded('AdminTeamsTable_load');
        });
    };

    loadQueryParams = () => {
        const url = new URL(window.location.href);
        // Load query params if needed
    };

    setChildCallables = (callables: any) => {
        this.childCallables = callables;
    };

    getRoute = () => {
        return `/adminteams?populate_advisory_groups=true`;
    };

    getColumns = () => {
        return [
            {
                title: 'Name',
                field: 'name',
                sort: {
                    field: 'name',
                },
            },
            {
                title: 'Type',
                field: 'type',
                sort: {
                    field: 'type',
                },
                format: (value: string) => TeamTypes.find((type) => type.type === value)?.name || EM_DASH,
            },
            {
                title: 'Advisory Groups',
                field: 'advisory_groups',
                render: (col: any, row: any, items: AdminTeam[], i: number) => {
                    const team = items[i];
                    return (
                        <td key={'advisory_groups' + i} title={`Advisory Groups`}>
                            {team.advisory_groups.map((group) => group.name).join(', ')}
                        </td>
                    );
                },
            },
            {
                title: 'Created At',
                field: 'created_at',
                sort: {
                    field: 'created_at',
                },
                format: (value: string) => formatUTCDate(value),
            },
            {
                title: 'Edit',
                render: (col: any, row: any, items: AdminTeam[], i: number) => {
                    const team = items[i];
                    return (
                        <td key={'editEntity' + i} title={`Edit entity`} style={{ width: '50px' }}>
                            <FontAwesomeIcon
                                icon={faPencil}
                                size="xl"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.openAddEditAdminTeamDrawer(team._id);
                                }}
                            />
                        </td>
                    );
                },
            },
        ];
    };

    getBulkActions = () => {
        return [];
    };

    openAddEditAdminTeamDrawer = (teamId?: string) => {
        const p = this.props;
        p.openDrawer(() => (
            <AddEditAdminTeamDrawer
                adminTeamId={teamId}
                close={p.closeDrawer}
                loading={p.loading}
                loaded={p.loaded}
                setAlert={p.setAlert}
                reloadData={this.childCallables.loadData}
            />
        ));
    };

    render = () => {
        const s = this.state;
        const p = this.props;
        return (
            <>
                <div className="docProcess-container">
                    <PaginationTable
                        noInvestmentDropdown={true}
                        containerStyle={{ margin: '0px' }}
                        name="adminTeams"
                        initialSortField="name"
                        initialSortAscending={true}
                        loading={p.loading}
                        loaded={p.loaded}
                        disableDefaultOption={true}
                        noTypeDropdown={true}
                        noDateFilters={true}
                        dateFilterField="created_at"
                        titleStyle={{ margin: 0 }}
                        headerStyle={{
                            alignItems: 'flex-end',
                        }}
                        route={this.getRoute()}
                        title={
                            <>
                                Admin Teams
                                <IconTextButton icon="/images/icons/plus.png" text="New Admin Team" onClick={() => this.openAddEditAdminTeamDrawer()} />
                            </>
                        }
                        // searchBoxes={[
                        //     {
                        //         queryKey: 'search',
                        //     },
                        // ]}
                        columns={this.getColumns()}
                        setCallables={this.setChildCallables}
                        selectedUserId={s.selectedUser?._id}
                        vue={p.vue}
                        disableTypeFilter={true}
                        postProcessData={(data: AdminTeam[]) => {
                            return data;
                        }}
                        enableClickSelect={true}
                        useApi2={true}
                        getResultsFromResponse={(res: any) => res.admin_teams}
                    />
                </div>

                <div style={{ clear: 'both', height: '100px' }} />
            </>
        );
    };
}

export default AdminTeamsTable;
