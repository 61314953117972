export interface FileResult {
    success: boolean;
    message: string;
    file: File;
}

export interface FileTextResult extends FileResult {
    lines: string[];
}

export interface FileParseResult<T> extends FileTextResult {
    data: T[];
    columnHeaders?: string[];
}

export interface SingleFileParseEditResult<T = Record<string, string>, Status = string> {
    data: T;
    fileIdx: number;
    dataIdx: number;
    isValid: boolean;
    status: Status;
    errors: string[];
    messages: string[];
    created_id?: string;
}

export interface FileParseEditResult<T, SingleResult = SingleFileParseEditResult<T>> {
    fileParsingResult: FileParseResult<T>;
    editResults: SingleResult[];
    errors?: string[];
}

export enum FileTypes {
    csv = 'csv',
    // excel = 'excel',
}

export const FileTypeDetails = {
    [FileTypes.csv]: {
        name: FileTypes.csv,
        mimeType: 'text/csv',
    },
    // [FileTypes.excel]: {
    //     name: FileTypes.excel,
    //     mimeType: 'application/vnd.ms-excel',
    // },
};

export const getMimeTypes = (fileTypes: FileTypes[] = []) => {
    return fileTypes.map((fileType: FileTypes) => FileTypeDetails[fileType].mimeType) || [];
};
