import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadDocumentFromUrl } from '../utilities/file/downloadDocument';
import sanitizeFilename from '../utilities/format/sanitizeFilename';
import { faDownload, faEnvelope, faX } from '@fortawesome/free-solid-svg-icons';

import styles from '../styles/singleAccountRalDisplay.module.scss';
import { downloadDocument } from '../utilities/documents/downloadDocument';

// Define types for the props
interface RalRequest {
    sent_at?: string;
    signed_ral?: { _id: string; name: string } | string;
    ownership_ids?: { name: string }[];
    ownership_type: string;
    reporting_auth_urls?: { downloadUrl: string };
    name: string;
}

interface ParentConnection {
    name: string;
    user_name?: string;
    user?: { name: string };
}

interface SingleAccountRalDisplayProps {
    ralRequest: RalRequest;
    account: any; // Define more specific type if available
    parentConnection: ParentConnection;
}

const SingleAccountRalDisplay: React.FC<SingleAccountRalDisplayProps> = ({ ralRequest, account, parentConnection }) => {
    const isSent = ralRequest.sent_at;
    const ralDocId = typeof ralRequest.signed_ral === 'string' ? ralRequest.signed_ral : ralRequest.signed_ral?._id;

    const getRalDocName = (): string | null => {
        if (!ralRequest.signed_ral || typeof ralRequest.signed_ral === 'string') return null;
        return `${sanitizeFilename(parentConnection.name)}__${sanitizeFilename(parentConnection.user_name || parentConnection.user?.name || 'UnknownUser')}__${sanitizeFilename(ralRequest.signed_ral.name)}__RAL.pdf`;
    };

    let docName = getRalDocName();

    const ownerNames = ralRequest.ownership_ids?.map((obj) => obj.name) || [];
    const ownerCount = ownerNames.length;

    const ownershipTypeStr = ralRequest.ownership_type === 'Account' ? 'Entity' : ralRequest.ownership_type;

    const description = ownerCount === 0 ? null : `${ownershipTypeStr}: ${ownerNames[0]}${ownerCount > 1 ? ` (+ ${ownerCount - 1} ${ownerCount !== 2 ? 's' : ''})` : ''}`;

    const fullDescription = `${ownershipTypeStr}: ${ownerNames.join(', ')}`;

    const downloadRAL = async () => {
        const url = ralRequest?.reporting_auth_urls?.downloadUrl;
        if (url) {
            await downloadDocumentFromUrl(url, docName, true);
        } else {
            try {
                downloadDocument(ralDocId, !!docName);
            } catch (e) {
                console.error('Error downloading RAL', e);
            }
        }
    };

    return (
        <div
            key={`ral-${ralRequest.name}-${ralDocId}-${parentConnection.name}`}
            style={{
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
            }}
            title={`${ralDocId ? 'RAL Signed' : isSent ? 'RAL Sent' : 'RAL Not Sent'} for ${description}`}
        >
            {ralDocId ? (
                <FontAwesomeIcon
                    title={`Download Signed RAL for ${fullDescription}`}
                    icon={faDownload}
                    size="1x"
                    className={styles.download}
                    style={{ margin: '0px', marginRight: '5px' }}
                    onClick={(e) => {
                        e.stopPropagation();
                        downloadRAL();
                    }}
                />
            ) : isSent ? (
                <FontAwesomeIcon title={`RAL Sent for ${fullDescription}`} icon={faEnvelope} size="1x" className={styles.envelope} style={{ margin: '0px', marginRight: '5px' }} />
            ) : (
                <FontAwesomeIcon title={`No RAL for ${fullDescription}`} icon={faX} size="1x" className={styles.x} style={{ margin: '0px', marginRight: '5px' }} />
            )}

            <div className="table-ellipses" style={{ width: '250px', marginLeft: '5px' }}>
                {ownerCount > 0 ? description : 'User'}
            </div>
        </div>
    );
};

export default SingleAccountRalDisplay;
