import React, { ReactNode, CSSProperties } from 'react';
import '../../styles/emptyOverlay.css';

interface EmptyOverlayProps {
    isEmpty: boolean;
    containerStyle?: CSSProperties;
    textStyle?: CSSProperties;
    emptyText?: ReactNode;
    children?: ReactNode;
}

const EmptyOverlay: React.FC<EmptyOverlayProps> = ({ isEmpty, containerStyle, textStyle, emptyText, children }) => {
    return (
        <div className="emptyOverlay_component">
            {/* overlay div - fits to space, no click through */}
            {isEmpty && (
                <div className="emptyOverlay_overlay" style={{ ...containerStyle }}>
                    {/* internal div needed for text to enforce padding */}
                    <div className="emptyOverlay_text" style={{ ...textStyle }}>
                        {emptyText}
                    </div>
                </div>
            )}

            {children}
        </div>
    );
};

export default EmptyOverlay;
