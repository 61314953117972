import React, { useEffect, useState, useCallback } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartData, ChartOptions } from 'chart.js/auto';
import { getColorFromIndex } from '../../utilities/AdvisorVue/getColorFromIndex';
import { EM_DASH } from '../../constants/constantStrings';
import '../../styles/clientDonutChart.css';
import { InvestmentType } from '../../types/Investment'; // Import from types

interface ClientDonutChartProps {
    amountsByType?: InvestmentType[];
    blank: boolean;
}

const ClientDonutChart: React.FC<ClientDonutChartProps> = ({ amountsByType = [], blank }) => {
    const [data, setData] = useState<ChartData<'doughnut', number[], string> | null>(null);
    const [sumStr, setSumStr] = useState<string>('');
    const [sumMultStr, setSumMultStr] = useState<string>('');

    const loadData = useCallback(() => {
        const investments = amountsByType ?? [];
        let sum = investments.reduce((x, i) => x + i.amount, 0);

        let calculatedSumStr = '';
        let calculatedSumMultStr = '';

        const BILLION = 1_000_000_000;
        const MILLION = 1_000_000;
        const THOUSAND = 1_000;

        if (!Number.isFinite(sum)) {
            calculatedSumStr = EM_DASH;
        } else if (sum >= BILLION) {
            sum /= BILLION;
            calculatedSumMultStr = 'billion';
            calculatedSumStr = sum.toFixed(1);
        } else if (sum >= MILLION) {
            sum /= MILLION;
            calculatedSumMultStr = 'million';
            calculatedSumStr = sum.toFixed(1);
        } else if (sum >= THOUSAND) {
            sum /= THOUSAND;
            calculatedSumStr = sum.toFixed(1);
            calculatedSumMultStr = 'thousand';
        } else {
            calculatedSumStr = sum.toFixed(2);
        }

        const datasets = [
            {
                backgroundColor: blank || sum === 0 ? ['#E0E0E0'] : investments.map((_, i) => getColorFromIndex(i)),
                data: blank || sum === 0 ? [1] : investments.map((t) => t.amount),
            },
        ];

        setData({
            labels: investments.map((t) => t.name),
            datasets,
        });
        setSumStr(calculatedSumStr);
        setSumMultStr(calculatedSumMultStr);
    }, [amountsByType, blank]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const getChartOptions = (): ChartOptions<'doughnut'> => ({
        cutout: '65%',
        animation: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    });

    return (
        <div className="clientDonutComponent">
            <div className="clientDonutGraph">
                {data && <Doughnut style={{ width: '100%', height: '100%', position: 'relative', backgroundColor: 'transparent' }} data={data} options={getChartOptions()} />}
                <div className="clientDonutText">
                    {!blank && (
                        <>
                            <div>{(sumStr === EM_DASH ? '' : '$') + sumStr}</div>
                            <div>{sumMultStr}</div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export { ClientDonutChart };
