import { SingleFileParseEditResult } from '../../../../constants/file';

const generateOutputCsv = <T>(results: SingleFileParseEditResult<T>[]) => {
    let csvContent = [];

    // gather all used header and make them a set
    let headers = ['ID', 'Status', ...Array.from(new Set<string>(results.map((singleResult) => Object.keys(singleResult.data as Record<string, string>)).flat()))];

    csvContent.push(headers);

    // for each result, create a line with the correct order of headers
    results.forEach((singleResult) => {
        const line = headers.map((header) => {
            if (header === 'ID') {
                return singleResult.created_id || '';
            } else if (header === 'Status') {
                return singleResult.status;
            } else {
                return singleResult.data[header as keyof T] || '';
            }
        });
        csvContent.push(line);
    });

    return csvContent;
};

export default generateOutputCsv;
