export class TimelessDate {
    private date: string;
    constructor(dateString?: string) {
        if (!dateString) {
            const today = new Date();
            this.date = TimelessDate.fromDate(today).toString();
        } else if (!this.isValidDate(dateString)) {
            throw new Error('Invalid date format. Expected YYYY-MM-DD');
        } else {
            this.date = dateString;
        }
    }

    private isValidDate(dateString: string): boolean {
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (!regex.test(dateString)) {
            return false;
        }
        const date = new Date(dateString);
        return date instanceof Date && !isNaN(date.getTime());
    }

    public static fromDate(date: Date): TimelessDate {
        const year = date.getTimezoneOffset() > 0 ? date.getUTCFullYear() : date.getFullYear();
        const month = String(date.getTimezoneOffset() > 0 ? date.getUTCMonth() + 1 : date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getTimezoneOffset() > 0 ? date.getUTCDate() : date.getDate()).padStart(2, '0');
        const dateString = `${year}-${month}-${day}`;
        return new TimelessDate(dateString);
    }

    public static parse(dateString: string): TimelessDate {
        const date = new Date(dateString);
        return this.fromDate(date);
    }

    public toDate(): Date {
        return new Date(this.date);
    }

    public toString(): string {
        return this.date;
    }

    public toISOString(): string {
        return `${this.date}T00:00:00.000Z`;
    }

    public getYear(): number {
        return parseInt(this.date.split('-')[0], 10);
    }

    public getMonth(): number {
        return parseInt(this.date.split('-')[1], 10);
    }

    public getDay(): number {
        return parseInt(this.date.split('-')[2], 10);
    }
}
