import React, { useState, MouseEvent, CSSProperties } from 'react';
import '../styles/draggableDivider.css';

const DEFAULT_BORDER_THICKNESS = 2;
const DEFAULT_THICKNESS = 2;
const MAX_DELTA = 250;

interface DraggableDividerProps {
    horizontal?: boolean;
    size?: number;
    borderWidth?: number;
    onDrag?: (delta: number) => void;
}

const DraggableDivider: React.FC<DraggableDividerProps> = (props) => {
    const [startPosition, setStartPosition] = useState<number | null>(null);

    const handleDragStart = (e: MouseEvent<HTMLDivElement>) => {
        setStartPosition(props.horizontal ? e.clientY : e.clientX);
    };

    const handleDragEnd = () => {
        setStartPosition(null);
    };

    const handleDrag = (e: MouseEvent<HTMLDivElement>) => {
        if (startPosition === null) return;
        const currentPosition = props.horizontal ? e.clientY : e.clientX;
        const delta = currentPosition - startPosition;
        if (delta === 0 || Math.abs(delta) > MAX_DELTA) {
            return;
        }
        props.onDrag?.(delta);
        setStartPosition(currentPosition);
    };

    const getStyle = (): CSSProperties => {
        const totalWidth = props.size ?? DEFAULT_THICKNESS;
        let borderWidth = props.borderWidth ?? DEFAULT_BORDER_THICKNESS;
        if (borderWidth > totalWidth / 2) borderWidth = totalWidth / 2;
        const innerWidth = totalWidth - borderWidth * 2;

        return {
            width: props.horizontal ? '100%' : innerWidth,
            height: props.horizontal ? innerWidth : '100%',
            cursor: props.horizontal ? 'row-resize' : 'col-resize',
            border: `${borderWidth}px solid var(--color-light-gray)`,
        };
    };

    return (
        <div
            className="draggableDivider_container"
            style={getStyle()}
            onMouseMove={handleDrag}
            onMouseDown={handleDragStart}
            onMouseUp={handleDragEnd}
            onMouseLeave={handleDragEnd}
        />
    );
};

export default DraggableDivider;
