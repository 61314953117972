import React from 'react';
import '../../styles/DocumentToolbar.css';

export class ToolbarAction extends React.Component {
    render() {
        const { className, onClick, children, text, disabled } = this.props;
        return (
            <div className={className + `${disabled ? ' toolbarTool-disabled' : ''}`} onClick={disabled ? null : onClick}>
                {children}
                {text}
            </div>
        );
    }
}

class Toolbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toolbarStyle: 'pageControl',
            focusResultIndex: 0,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.totalSearchResults !== this.props.totalSearchResults) {
            this.focusResultHandler(-this.state.focusResultIndex);
        }
    }

    toolDownloadHandler = (event) => {
        this.props.downloadDocument();
    };

    toolZoomInHandler = (event) => {
        this.props.setPageSizeStyle((prevStyle) => parseInt(prevStyle) + 5 + '%');
    };

    toolZoomOutHandler = (event) => {
        this.props.setPageSizeStyle((prevStyle) => parseInt(prevStyle) - 5 + '%');
    };

    toolFitWidthHandler = (event) => {
        this.props.setPageSizeStyle((prevStyle) => '95%');
    };

    toolFitScreenHandler = (event) => {
        this.props.setPageSizeStyle((prevStyle) => '90%');
    };

    toolEnableSearchHandler = (state) => {
        this.setState((prevState) => ({
            toolbarStyle: prevState.toolbarStyle === 'pageControl' ? 'pageSearch' : 'pageControl',
        }));
        this.props.setDocumentSearchParams('');
    };

    searchParamHandler = (event) => {
        this.props.setDocumentSearchParams(event.target.value);
    };

    focusResultHandler = (event) => {
        this.props.setDocumentSearchResults((prevState) => {
            const newSearchResultState = { ...prevState };
            let newIndex = this.state.focusResultIndex + event >= 0 ? this.state.focusResultIndex + event : this.props.totalSearchResults;
            newIndex = newIndex > this.props.totalSearchResults - 1 ? 0 : newIndex;
            let index = 0;

            for (const key in newSearchResultState) {
                const results = [...newSearchResultState[key]];
                newSearchResultState[key] = results.map((result) => ({
                    ...result,
                    focus: index++ === newIndex,
                }));
            }

            this.setState({ focusResultIndex: newIndex });
            return newSearchResultState;
        });

        this.setState((prevState) => {
            let newIndex = prevState.focusResultIndex + event >= 0 ? prevState.focusResultIndex + event : this.props.totalSearchResults;
            newIndex = newIndex > this.props.totalSearchResults - 1 ? 0 : newIndex;
            return { focusResultIndex: newIndex };
        });
    };

    render() {
        const { toolbarStyle, focusResultIndex } = this.state;
        const { totalSearchResults } = this.props;

        if (toolbarStyle === 'pageControl') {
            return (
                <div className="ToolbarContainer">
                    <ToolbarAction className="toolbarTool tool-download" onClick={this.toolDownloadHandler} />
                    <ToolbarAction className="toolbarToolSeparator" />
                    <ToolbarAction className="toolbarTool tool-zoom-out" onClick={this.toolZoomOutHandler} />
                    <ToolbarAction className="toolbarToolSeparator" />
                    <ToolbarAction className="toolbarTool tool-zoom-in" onClick={this.toolZoomInHandler} />

                    <ToolbarAction className="toolbarToolSeparator" />
                    <ToolbarAction className="toolbarTool tool-fitWidth" onClick={this.toolFitWidthHandler} />
                    <ToolbarAction className="toolbarToolSeparator" />
                    <ToolbarAction className="toolbarTool tool-fitScreen" onClick={this.toolFitScreenHandler} />

                    <ToolbarAction className="toolbarToolSeparator" />
                    <ToolbarAction className="toolbarTool tool-search" onClick={this.toolEnableSearchHandler} />
                </div>
            );
        } else if (toolbarStyle === 'pageSearch') {
            return (
                <div className="ToolbarContainer">
                    <ToolbarAction className="toolbarTool tool-previous" onClick={() => this.focusResultHandler(-1)} />
                    <ToolbarAction className="toolbarToolSeparator" />
                    <ToolbarAction className="toolbarTool tool-infoText" text={`${totalSearchResults === 0 ? 0 : focusResultIndex + 1}/${totalSearchResults}`} />
                    <ToolbarAction className="toolbarToolSeparator" />
                    <ToolbarAction className="toolbarTool tool-next" onClick={() => this.focusResultHandler(1)} />
                    <ToolbarAction className="toolbarToolSeparator" />
                    <ToolbarAction className="toolbarTool tool-close" onClick={this.toolEnableSearchHandler} />
                    <ToolbarAction className="toolbarToolSeparator" />
                    <ToolbarAction className="tool-searchBar">
                        <input autoFocus autoComplete="off" type="text" id="searchBar" onChange={this.searchParamHandler} />
                    </ToolbarAction>
                </div>
            );
        }
    }
}

export default Toolbar;
