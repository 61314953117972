import { saveAs } from 'file-saver';

import api2 from '../../api2';
import promiseAllRequests from '../apiHelpers/promiseAllRequests';
import saveZip from '../file/saveZip';

const allowedExtensions = ['.pdf', '.csv', '.xlsx', '.xls'];

const fetchDocument = async (document_id) => {
    try {
        const documentRes = await api2.client.DocumentApi.getDocument({
            document_id,
            populate_user: true,
            populate_ownership: true,
        });
        return documentRes.data.document;
    } catch (err) {
        console.error('Error fetching document', err);
        throw err;
    }
};

// function to get filename from document
const getFullDocFilename = (doc) => {
    // build save filename
    let docName = doc?.name ?? (doc._id ? `document_${doc._id}` : 'document');

    // if name ends with an extension, remove it
    allowedExtensions.forEach((ext) => {
        if (docName.toLowerCase().endsWith(ext)) {
            docName = docName.slice(0, -ext.length);
        }
    });

    const userName = doc?.user?.name ?? doc?.user;
    const ownershipType = doc?.ownership_type;
    const firstOwnedObjectName = doc?.ownership_ids?.[0]?.name ?? doc?.ownership_ids?.[0];
    const fileType = doc.file_type;
    let filename = `${docName}__${userName}`;
    if (ownershipType) {
        filename += `__${ownershipType}__${firstOwnedObjectName}`;
    }
    // ensure the file extension is correct
    if (!allowedExtensions.some((ext) => filename.toLowerCase().endsWith(ext))) {
        filename += '.pdf';
    } else {
        filename += `.${fileType}`;
    }
    return filename;
};

// if successful, returns an object like { blob, filename, and document }
// if save is true, it will save the file to the user's computer as document.name
// filenameOverride can be a string or a function that takes the document object and returns a string
const downloadDocument = async (documentId, save = true, filenameOverride = null) => {
    try {
        // get the document object to get the download url
        const document = await fetchDocument(documentId);
        const downloadUrl = document.file_download_urls.doc;
        if (downloadUrl) {
            let filename = document.name;

            if (filenameOverride) {
                if (typeof filenameOverride === 'function') {
                    filename = filenameOverride(document);
                } else {
                    filename = filenameOverride;
                }
            }

            let blobRes = await api2.getBlob(downloadUrl);
            if (blobRes.success) {
                const blob = blobRes.data;

                // clean up the filename
                const fileType = `.${document.file_type}`;
                const ext = allowedExtensions.includes(fileType) ? fileType : '.pdf';
                filename = filename.replace(new RegExp(`${ext}$`), '').replace(/[^a-zA-Z0-9]/g, '_') + ext;

                if (save) {
                    saveAs(blob, filename);
                }
                return {
                    success: true,
                    blob,
                    filename,
                    document,
                };
            } else {
                const errorMsg = 'No blob found for document';
                console.error(errorMsg, document);
                throw new Error(errorMsg);
            }
        } else {
            const errorMsg = 'No download url found for document';
            console.error(errorMsg, document);
            throw new Error(errorMsg);
        }
    } catch (err) {
        console.error('Error downloading document', err);
        throw err;
    }
};

const downloadDocumentZip = async (documentIds, getDocFilename = (doc) => doc.name, zipFilename = 'documents.zip') => {
    try {
        // get documents
        const downloadPromises = documentIds.map((id) => downloadDocument(id, false));
        const downloadResults = await promiseAllRequests(downloadPromises);
        if (!downloadResults.every((r) => r.success)) {
            console.error('Error in downloading one or more documents.');
            throw new Error('Error downloading documents');
        }
        // create the zip
        await saveZip(
            downloadResults.map((res) => {
                const filename = getDocFilename(res.document);
                return {
                    blob: res.blob,
                    filename,
                };
            }),
            zipFilename
        );
    } catch (err) {
        console.error('Error downloading zip', err);
        throw err;
    }
};

export { downloadDocument, downloadDocumentZip, getFullDocFilename, fetchDocument };
