// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import dateToUTCMidnight from '../../../utilities/date/dateToUTCMidnight';

const MILLIS_PER_DAY = 24 * 60 * 60 * 1000;
const nowUtc = dateToUTCMidnight();

export default {
    emptyText: 'Track and manage upcoming capital calls.',
    textStyle: {
        fontSize: '16px',
    },
    props: {
        callsDue: [
            {
                _id: 1,
                investment: 1,
                date: dateToUTCMidnight(nowUtc.getTime() + 2 * MILLIS_PER_DAY),
                open: false,
                amount: 20000,
            },
            {
                _id: 2,
                investment: 2,
                date: dateToUTCMidnight(nowUtc.getTime() + 4 * MILLIS_PER_DAY),
                open: true,
                amount: 20000,
            },
            {
                _id: 3,
                investment: 3,
                date: dateToUTCMidnight(nowUtc.getTime() + 4 * MILLIS_PER_DAY),
                open: false,
                amount: 20000,
            },
            {
                _id: 4,
                investment: 3,
                date: dateToUTCMidnight(nowUtc.getTime() + 4 * MILLIS_PER_DAY),
                open: false,
                amount: 20000,
            },
            {
                _id: 5,
                investment: 3,
                date: dateToUTCMidnight(nowUtc.getTime() + 4 * MILLIS_PER_DAY),
                open: false,
                amount: 20000,
            },
        ],
        investments: [
            {
                _id: 1,
                name: 'Investment Name goes here...',
            },
            {
                _id: 2,
                name: 'Another investment name...',
            },
        ],
    },
};
