import React, { useState } from 'react';
import '../styles/tabSwitcher.css';

// Define the types for the component's props
interface TabItem {
    title: string;
    titleSuffix?: string;
    disabled?: boolean;
}

interface TabSwitcherProps {
    items: TabItem[];
    defaultSelected?: number;
    selectedTabTitle?: string;
    onSelect?: (index: number, item: TabItem) => void;
}

const TabSwitcher: React.FC<TabSwitcherProps> = ({ items, defaultSelected, selectedTabTitle, onSelect }) => {
    const [activeIndex, setActiveIndex] = useState<number | undefined>(defaultSelected);

    const handleTabClick = (index: number) => {
        setActiveIndex(index);
        if (onSelect) {
            onSelect(index, items[index]);
        }
    };

    return (
        <div className="tab-switcher">
            {items.map((item, index) => (
                <div key={index} className={`tab ${activeIndex === index ? 'tab-active' : 'tab-inactive'}`} onClick={() => handleTabClick(index)}>
                    {`${item.title}${item.titleSuffix ? ` ${item.titleSuffix}` : ''}`}
                </div>
            ))}
            <div data-testid="content">{activeIndex !== undefined && items[activeIndex] ? (items[activeIndex] as any).content : null}</div>
        </div>
    );
};

export default TabSwitcher;
