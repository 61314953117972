// remove field if value is false, otherwise set field to value
export const setQueryParam = (field, value) => {
    const url = new URL(window.location.href);
    if (value) {
        url.searchParams.set(field, value);
    } else {
        url.searchParams.delete(field);
    }
    window.history.replaceState({}, '', url);
};

export const getQueryParam = (field) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(field);
};

// clear query params
export const clearQueryParams = (except = [], only_clear_these = []) => {
    if (typeof except === 'string') {
        except = [except];
    }
    if (typeof only_clear_these === 'string') {
        only_clear_these = [only_clear_these];
    }
    const url = new URL(window.location.href);
    const keys = [...url.searchParams.keys()];
    keys.forEach((key) => {
        if (except.includes(key)) return;
        if (only_clear_these.length && !only_clear_these.includes(key)) return;
        url.searchParams.delete(key);
    });
    window.history.replaceState({}, '', url);
};
