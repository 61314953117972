import React from 'react';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import Multitoggle from './Multitoggle';
import getSetGlobalInvestmentFilter, { INVESTMENT_FILTER_OPTIONS } from '../../common/getSetGlobalInvestmentFilter';
import IconTooltip from '../Icons/IconTooltip';

interface GlobalInvestmentFilterProps {
    onChange?: (newValue: String) => void;
    containerStyle?: { [key: string]: React.CSSProperties | string };
    toggleStyle?: { [key: string]: React.CSSProperties | string };
}

const GlobalInvestmentFilter = ({ onChange, containerStyle }: GlobalInvestmentFilterProps) => {
    const is_admin = UserPermissions().access.admin;
    let toggle_options = [INVESTMENT_FILTER_OPTIONS.all, INVESTMENT_FILTER_OPTIONS.active];
    if (is_admin) {
        toggle_options.push(INVESTMENT_FILTER_OPTIONS.hidden);
    }

    const currentFilter = getSetGlobalInvestmentFilter(null, INVESTMENT_FILTER_OPTIONS.all);

    const handleSelect = (newValue: string) => {
        getSetGlobalInvestmentFilter(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                // flexDirection: 'column',
                ...containerStyle,
            }}
        >
            {/* <label style={{
                fontSize: '14px',
                lineHeight: '20px',
            }}>Show Investments</label> */}
            <Multitoggle
                containerStyle={{
                    fontSize: '18px',
                    height: '30px',
                    width: toggle_options.length * 70,
                }}
                options={toggle_options}
                defaultSelected={currentFilter ?? undefined}
                selection={currentFilter}
                onSelect={handleSelect}
            />
            <IconTooltip
                text={`This global investment filter is applied to all tables.  "All" includes ${is_admin ? `"Active" and "Inactive"` : `"Active", "Inactive", and "Hidden"`} investments.`}
            />
        </div>
    );
};

export default GlobalInvestmentFilter;
