const isPdfBlob = async (blob) => {
    return await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = function (e) {
            if (e.target.readyState === FileReader.DONE) {
                const uint = new Uint8Array(e.target.result);
                // Check for the magic number `%PDF-`
                if (uint[0] === 0x25 && uint[1] === 0x50 && uint[2] === 0x44 && uint[3] === 0x46 && uint[4] === 0x2d) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }
        };
        // Read the first few bytes of the blob
        const blobSlice = blob.slice(0, 5);
        reader.readAsArrayBuffer(blobSlice);
    });
};

export { isPdfBlob };
