import React, { useState, useEffect, useRef, CSSProperties, MouseEvent, forwardRef } from 'react';
import Dropzone, { DropzoneRef, DropEvent, FileRejection } from 'react-dropzone';
import '../styles/dragDropComponent.css';

const stopEvent = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
};

export const FILE_STATUSES = {
    unset: 'unset',
    error: 'error',
    processing: 'processing',
    success: 'success',
} as const;

const PROCESSING_TIME = 0.7; // seconds
const PROCESSED_DELAY = 0.3; // seconds

type FileStatus = keyof typeof FILE_STATUSES;

interface DragDropComponentProps {
    onDrop: (file: File) => void;
    fileStatus: FileStatus;
    file?: File;
    clearFile: () => void;
    allowedFileTypes?: string[];
    multiple?: boolean;
    containerStyle?: CSSProperties;
}

// Extend CSSProperties to include custom properties
interface CustomCSSProperties extends CSSProperties {
    '--processing-time'?: string;
}

export const fileToBlob = async (_file: File): Promise<Blob> => {
    const arrayBuffer = await _file.arrayBuffer();
    if (_file.type === 'text/csv') {
        const text = new TextDecoder().decode(arrayBuffer);
        return new Blob([text], { type: _file.type });
    }
    const uint8Array = new Uint8Array(arrayBuffer);
    return new Blob([uint8Array], { type: _file.type });
};

const DragDropComponent = forwardRef<DropzoneRef, DragDropComponentProps>((props, ref) => {
    const [dragging, setDragging] = useState(false);
    const [fileStatus, setFileStatus] = useState<FileStatus>(FILE_STATUSES.unset);
    const dropzoneRef = useRef<DropzoneRef>(null);

    useEffect(() => {
        if (!props.file) {
            setFileStatus(FILE_STATUSES.unset);
        }
    }, [props.file]);

    const setProcessingThenStatus = (status: FileStatus) => {
        if (!Object.values(FILE_STATUSES).includes(status)) return;
        setFileStatus(FILE_STATUSES.processing);
        setTimeout(
            () => {
                setFileStatus(status);
            },
            (PROCESSING_TIME + PROCESSED_DELAY) * 1000
        );
    };

    const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
        if (acceptedFiles.length < 1) return;
        const file = acceptedFiles[0];
        if (props.allowedFileTypes && !props.allowedFileTypes.includes(file.type)) {
            if (props.allowedFileTypes.includes('text/csv') && file.name.includes('.csv')) {
                props.onDrop(file);
                setProcessingThenStatus(FILE_STATUSES.success);
                return;
            }
            props.clearFile();
            setProcessingThenStatus(FILE_STATUSES.error);
        } else {
            props.onDrop(file);
            setProcessingThenStatus(FILE_STATUSES.success);
        }
    };

    const getAllowedTypesString = () => {
        if (!props.allowedFileTypes) return '';
        const listStr = props.allowedFileTypes.map((t) => (t.includes('/') ? t.split('/')[1] : t));
        return `Supported formats: ${listStr.join(', ')}`;
    };

    const getStyle = (): CustomCSSProperties => ({
        '--processing-time': `${PROCESSING_TIME}s`,
        ...props.containerStyle,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    });

    DragDropComponent.displayName = 'DragDropComponent';

    const renderBrowseFiles = () => (
        <div
            className="a"
            onClick={async () => {
                if (dropzoneRef.current) {
                    await dropzoneRef.current.open();
                }
            }}
        >
            Browse Files
        </div>
    );

    const renderSupportedFileTypes = () => {
        if (!props.allowedFileTypes) return null;
        return <div className="addEditDocument-dragDrop-filetypes">{getAllowedTypesString()}</div>;
    };

    const filename = props.file?.name ?? 'Unknown filename';

    return (
        <div
            style={{
                width: '100%',
                height: 'calc(100vh - 80px)',
            }}
        >
            <Dropzone onDrop={onDrop} ref={dropzoneRef}>
                {({ getRootProps, getInputProps }) => (
                    <>
                        <input {...getInputProps()} />
                        <div
                            data-testid="dropzone"
                            style={getStyle()}
                            {...getRootProps({ className: 'dragDropComponent' + (dragging ? ' dragDropComponent-dragging' : '') })}
                            onDragOver={() => setDragging(true)}
                            onDragLeave={() => setDragging(false)}
                        >
                            {fileStatus === FILE_STATUSES.unset && (
                                <div className="dragDropComponent-unset" onClick={stopEvent}>
                                    <div>Drag & Drop your document{props.multiple ? 's' : ''} here</div>
                                    <div>or</div>
                                    {renderBrowseFiles()}
                                    {renderSupportedFileTypes()}
                                </div>
                            )}
                            {fileStatus === FILE_STATUSES.success && (
                                <div className="dragDropComponent-success" onClick={stopEvent} title={filename}>
                                    <img src="/images/icons/smallDoc.png" alt="document icon" style={{ marginRight: '5px' }} />
                                    <span>{filename}</span>
                                    <img
                                        alt="cancel icon"
                                        className="dragDropComponent-success-cancel"
                                        src="/images/icons/cancel.png"
                                        onClick={(e) => {
                                            stopEvent(e);
                                            setFileStatus(FILE_STATUSES.unset);
                                            props.clearFile();
                                        }}
                                    />
                                </div>
                            )}
                            {fileStatus === FILE_STATUSES.processing && (
                                <div className="dragDropComponent-processing" onClick={stopEvent}>
                                    <div>Processing file....</div>
                                    <div className="dragDropComponent-processingBar">
                                        <div />
                                    </div>
                                </div>
                            )}
                            {fileStatus === FILE_STATUSES.error && (
                                <div className="dragDropComponent-error" onClick={stopEvent}>
                                    <div>Unsupported file format</div>
                                    <div>Please try another file.</div>
                                    {renderBrowseFiles()}
                                    {renderSupportedFileTypes()}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </Dropzone>
        </div>
    );
});
export { DragDropComponent };
