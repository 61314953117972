import { getUserStatusColor } from '../color/getUserStatusColor';

export const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
        case 'active':
            return 'var(--color-dark-green)';
        case 'invite pending':
            return 'var(--altx-orange)';
        case 'deactivated':
            return 'var(--color-dark-red)';
        default:
            return '#434343';
    }
};

export const getClientStatusColor = (client) => {
    return client?.connectionRequest ? 'var(--altx-orange)' : getUserStatusColor(client?.status);
};
