import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DocumentMetadataCheckmarkProps {
    document: {
        shouldGenerateTransactions: boolean;
        hasValidMetadata: boolean;
    };
}

const DocumentMetadataCheckmark = ({ document }: DocumentMetadataCheckmarkProps) => {
    // set checkmark green if shouldGenerateTransactions is true and hasValidMetadata is true
    // set checkmark red if shouldGenerateTransactions is true and hasValidMetadata is false
    // set checkmark grey if shouldGenerateTransactions is false
    let color = 'var(--color-gray)';
    let title = 'Not Generating Transactions';
    if (document.shouldGenerateTransactions) {
        color = document.hasValidMetadata ? 'var(--color-dark-green)' : 'var(--color-dark-red)';
        title = document.hasValidMetadata ? 'Valid Metadata' : 'Invalid Metadata';
    }
    return <FontAwesomeIcon icon={faCircleCheck} size="xl" title={title} color={color} />;
};

export default DocumentMetadataCheckmark;
