import React from 'react';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import Multitoggle from './Multitoggle';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import UTCDatePicker from '../Inputs/UTCDatePicker/UTCDatePicker';
import api2 from '../../api2';
import { TimelessDate } from '../../types/TimelessDate';

const OPTIONS = ['No', 'Yes'];

interface InvestmentInactiveToggleProps {
    investment: {
        _id: string;
        inactive: boolean;
        inactive_date?: string;
    };
    loading?: (progress: number, key: string) => void;
    loaded?: (key: string) => void;
    onChange?: (newValue: boolean) => void;
    onChangeDate?: (date: Date) => void;
    setAlert?: (message: string, isError?: boolean) => void;
    containerStyle?: React.CSSProperties;
    allowEditDate?: boolean;
}

const InvestmentInactiveToggle: React.FC<InvestmentInactiveToggleProps> = ({ investment, loading, loaded, onChange, onChangeDate, setAlert, containerStyle, allowEditDate }) => {
    const curOption = OPTIONS[Number(!investment.inactive)];

    const handleSelect = async (opt: string) => {
        if (!UserPermissions().canUpdateAnyInvestments) return;
        const newValue = !OPTIONS.indexOf(opt);
        if (newValue === investment.inactive) return;

        loading?.(0, 'inactiveToggleUpdate');
        try {
            await api2.client.InvestmentApi.updateInvestment({
                investment_id: investment._id,
                UpdateInvestmentRequest: { inactive: newValue },
            });
            if (setAlert) {
                setDismissableAlert(setAlert, `Investment set to ${newValue ? 'in' : ''}active.`);
            }
            await onChange?.(newValue);
        } catch (err) {
            setDismissableAlert(setAlert, `Error setting investment to ${newValue ? 'in' : ''}active.`, true);
        }
        loaded?.('inactiveToggleUpdate');
    };

    const handleDateChange = async (newDate: TimelessDate | null) => {
        loading?.(0, 'inactiveDateUpdate');
        try {
            await api2.client.InvestmentApi.updateInvestment({
                investment_id: investment._id,
                UpdateInvestmentRequest: {
                    inactive_date: newDate ? newDate.toString() : undefined,
                },
            });
            if (setAlert) {
                setDismissableAlert(setAlert, `Investment inactive date updated.`);
            }
            if (newDate) {
                await onChangeDate?.(newDate.toDate());
            }
        } catch (err) {
            setDismissableAlert(setAlert, `Error updating investment inactive date.`, true);
        }
        loaded?.('inactiveDateUpdate');
    };

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', ...containerStyle }} className="pdfIgnore">
                <div>Active Investment</div>
                <Multitoggle
                    containerStyle={{ width: '70px', marginLeft: '10px' }}
                    options={OPTIONS}
                    selection={curOption}
                    onSelect={handleSelect}
                    optionStyle={{ cursor: UserPermissions().canUpdateAnyInvestments ? 'pointer' : 'default' }}
                />
            </div>
            {allowEditDate && investment.inactive_date && investment.inactive && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '20px', ...containerStyle }}>
                    <div style={{ marginRight: '5px' }}>Inactive Date</div>
                    <UTCDatePicker
                        placeholderText="MM/DD/YYYY"
                        dateFormat="MM-dd-yyyy"
                        selected={investment.inactive_date ? TimelessDate.parse(investment.inactive_date) : null}
                        onChange={handleDateChange}
                    />
                </div>
            )}
        </>
    );
};

export default InvestmentInactiveToggle;
