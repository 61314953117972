const statusMappings: { [key: string]: string } = {
    Active: 'Enabled',
    Staging: 'Disabled',
};

const reverseMappings: { [key: string]: string } = Object.fromEntries(Object.entries(statusMappings).map(([key, value]) => [value, key]));

const rephraseClientStatus = (status: string, reverse: boolean = false): string => {
    const mappings = reverse ? reverseMappings : statusMappings;
    return mappings[status] || status;
};

export default rephraseClientStatus;
