import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import '../../styles/widget.css';
import '../../styles/widget-AllocationChart.css';

import { Doughnut } from 'react-chartjs-2';
// eslint-disable-next-line
import { Chart as ChartJS, ChartData, ChartOptions } from 'chart.js/auto';

import { getColorFromIndex } from '../../utilities/AdvisorVue/getColorFromIndex';
import { isFiniteNonzero } from '../../utilities/validation/numericalValidation';

interface Investment {
    type: string;
    valuation: number;
}

interface AllocationChartProps extends RouteComponentProps {
    investments: Investment[];
    advisorVue?: boolean;
    loading: (duration: number, key?: string) => void;
    loaded: (key?: string) => void;
    containerStyle?: React.CSSProperties;
}

const AllocationChart: React.FC<AllocationChartProps> = (props) => {
    const [types, setTypes] = useState<{ name: string; value: number }[]>([]);
    const [data, setData] = useState<ChartData<'doughnut', number[], string> | null>(null);
    const [sum, setSum] = useState<number>(0);
    const [sumMultStr, setSumMultStr] = useState<string>('');

    const loadData = useCallback(() => {
        const investments = props.investments;
        if (!props.advisorVue && (!investments || !investments.length)) return;

        props.loading(320, 'allocationChart');

        const getInvestmentValue = (i: Investment) => {
            const value = i.valuation;
            return isFiniteNonzero(value) ? value : 0;
        };

        const types: { name: string; value: number }[] = [];
        for (const i of investments) {
            let value = getInvestmentValue(i);
            value = Number.isFinite(value) ? value : 0;

            const existing = types.find((t) => t.name === i.type);
            if (existing) existing.value += value;
            else {
                types.push({
                    name: i.type,
                    value,
                });
            }
        }

        let sum = types.reduce((x, i) => {
            let investmentValue = i.value;
            if (Number.isFinite(investmentValue)) {
                x += investmentValue;
            }
            return x;
        }, 0);
        let sumMultStr = '';

        const BILLION = 1000000000;
        const MILLION = 1000000;
        const THOUSAND = 1000;
        if (sum >= BILLION) {
            sum /= BILLION;
            sumMultStr = 'billion';
            sum = parseFloat(sum.toFixed(1));
        } else if (sum >= MILLION) {
            sum /= MILLION;
            sumMultStr = 'million';
            sum = parseFloat(sum.toFixed(1));
        } else if (sum >= THOUSAND) {
            sum /= THOUSAND;
            sum = parseFloat(sum.toFixed(1));
            sumMultStr = 'thousand';
        } else {
            sum = parseFloat(sum.toFixed(2));
        }

        types.sort((a, b) => b.value - a.value);

        const datasets = [
            {
                backgroundColor: types.map((_, i) => getColorFromIndex(i)),
                data: types.map((t) => t.value),
            },
        ];

        setTypes(types);
        setData({
            labels: types.map((t) => t.name),
            datasets,
        });
        setSum(sum);
        setSumMultStr(sumMultStr);

        props.loaded('allocationChart');
    }, [props]);

    useEffect(() => {
        loadData();
    }, [props.investments]);

    const getChartOptions = (): ChartOptions<'doughnut'> => ({
        cutout: '70%',
        animation: false,
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
        },
    });

    const renderLegendKeys = () => {
        return (
            <div className="donutLegend">
                {types.map((elem, idx) => (
                    <div className="donutLegendItem" key={`legendKey${idx}`}>
                        <div className="donutLegendSquare" style={{ backgroundColor: getColorFromIndex(idx) }} />
                        <div className="donutLegendText">{elem.name}</div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="widget donutComponent" style={{ ...props.containerStyle }}>
            <div className="donutGraph">
                <div className="donutText">
                    <div>{'$' + sum}</div>
                    <div>{sumMultStr}</div>
                </div>
                {data && <Doughnut data={data} options={getChartOptions()} style={{ position: 'relative', backgroundColor: 'transparent' }} />}
            </div>

            {renderLegendKeys()}
        </div>
    );
};

export default withRouter(AllocationChart);
