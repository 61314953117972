import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import styles from './DropzoneContainer.module.scss';
import { FileTypes, getMimeTypes } from '../../../../constants/file';

interface DropzoneContainerState {
    dragging: boolean;
}

export interface DropzoneContainerChildrenProps {
    openFileDialog: () => void;
}

interface DropzoneContainerProps {
    children?: ({ openFileDialog }: DropzoneContainerChildrenProps) => JSX.Element;
    onDrop: (files: any[]) => void;
    allowedFileTypes?: FileTypes[];
    disabled?: boolean;
    className?: string;
}

export default class DropzoneContainer extends Component<DropzoneContainerProps, any> {
    fileRefs = [];

    state: DropzoneContainerState = {
        dragging: false,
    };

    draggingOffRequest = React.createRef();
    draggingTimeout = null;

    renderDragging = () => (
        <div className={`${styles.bulkTrans_dragging}`}>
            <div className={`${styles.bulkTrans_docIcon_white}`} />
            Drop your commitments CSV here
        </div>
    );

    setDragging = (dragging: boolean, force = false) => {
        this.setState({ dragging });
    };

    onDrop = async (files: any[]) => {
        this.setDragging(false);

        // Check if the file types are allowed
        if (this.props.allowedFileTypes) {
            // filter them out by matching mime types
            const isTypeAllowed = (file: any) => getMimeTypes(this.props.allowedFileTypes).includes(file.type);
            const invalidFiles = files.filter((file) => !isTypeAllowed(file));

            // if there are invalid files, alert the user and return
            if (invalidFiles.length > 0) {
                const invalidFileNames = invalidFiles.map((file) => file.name).join('\n');
                window.alert(`These files have an invalid type:\n\n${invalidFileNames}`);
                return;
            }
        }

        // if the file types are allowed, call the onDrop function
        this.props.onDrop(files);
    };

    render = () => {
        const s = this.state;

        const allowMultiple = true;

        return (
            <Dropzone
                disabled={this.props.disabled}
                onDrop={this.onDrop}
                multiple={allowMultiple}
                onDragEnter={() => this.setDragging(true)}
                onDragLeave={() => this.setDragging(false)}
                noClick
            >
                {({ getRootProps, getInputProps, open }) => {
                    return (
                        <div
                            {...getRootProps({ className: `${styles.main} ${this.props.className}` })}
                            onMouseOut={!s.dragging ? () => {} : () => this.setDragging(false)}
                            onClick={(e) => e.stopPropagation()}
                        >
                            {this.props.children?.({
                                openFileDialog: open,
                            } as DropzoneContainerChildrenProps)}

                            {s.dragging && this.renderDragging()}
                        </div>
                    );
                }}
            </Dropzone>
        );
    };
}
