import React from 'react';

import { DrawerInputObject } from './DrawerInputObject';
import { DrawerInputObject2 } from './DrawerInputObject2';
import { compact } from 'lodash';

// used for commonData and metadata
// fieldDataStructure: list of component input objects
// stateField: [string] field in the state where the data is located
// stateData: [object] the current state data (ie. state[stateField])
// data: object - each has {label, type, value, placeholder, options}
export const renderInputComponentList = (
    fieldDataStructure,
    setValue,
    stateData = {},
    errors = {},
    notateRequired = false,
    readOnly = false,
    useObject2 = false,
    inlineLabel = false,
    compactMode = false,
    customClassName = '',
    setStateData = () => {}
) => {
    return Object.values(fieldDataStructure).map((obj, objIdx) => {
        if (useObject2) {
            return (
                <div key={'inputComponent' + objIdx} style={{ position: 'relative' }} className={customClassName}>
                    <DrawerInputObject2
                        object={obj}
                        onChangeCallback={(value) => {
                            setValue(obj.fieldName, value);
                        }}
                        stateData={stateData}
                        setStateData={setStateData}
                        value={obj.calculateFromData?.(stateData) ?? stateData[obj.fieldName]}
                        errors={errors}
                        notateRequired={notateRequired}
                        readOnly={readOnly}
                        inlineLabel={inlineLabel}
                        compactMode={compactMode}
                        renderRightComponent={obj?.renderRightComponent}
                    />
                </div>
            );
        } else {
            return (
                <div key={'inputComponent' + objIdx} style={{ position: 'relative' }} className={customClassName}>
                    <DrawerInputObject
                        object={obj}
                        onChangeCallback={(value) => {
                            setValue(obj.fieldName, value);
                        }}
                        value={obj.calculateFromData?.(stateData) ?? stateData[obj.fieldName]}
                        errors={errors}
                        notateRequired={notateRequired}
                        readOnly={readOnly}
                        inlineLabel={inlineLabel}
                        compactMode={compactMode}
                    />
                </div>
            );
        }
    });
};
