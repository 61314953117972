import React, { useEffect, useState } from 'react';
import '../styles/statusCircle.css';

// Define the types for the component's props
interface StatusCircleProps {
    percent?: number;
    animationTime?: number;
    animationDoneCallback?: () => void;
    size?: number;
    filledColor?: string;
    emptyColor?: string;
    thickness?: number;
    style?: React.CSSProperties;
    onClick?: () => void;
    showPercentText?: boolean;
}

export const StatusCircle: React.FC<StatusCircleProps> = ({
    percent = 0,
    animationTime,
    animationDoneCallback,
    size = 60,
    filledColor = 'black',
    emptyColor = '#0000',
    thickness = 40,
    style = {},
    onClick,
    showPercentText = false,
}) => {
    const [_percent, setPercent] = useState(0);

    useEffect(() => {
        let isMounted = true;

        if (animationTime) {
            Array.from(Array(100))
                .map((_, idx) => idx + 1)
                .forEach((n) =>
                    setTimeout(
                        () => {
                            if (!isMounted) return;
                            setPercent(n);
                            if (n === 100) {
                                animationDoneCallback?.();
                            }
                        },
                        (n / 100) * animationTime * 1000
                    )
                );
        } else {
            setPercent(percent);
        }

        return () => {
            isMounted = false;
        };
    }, [percent, animationTime, animationDoneCallback]);

    const getStyle = (): React.CSSProperties =>
        ({
            '--statusCircle-size': `${size}px`,
            '--statusCircle-percent': _percent,
            '--statusCircle-filledColor': filledColor,
            '--statusCircle-emptyColor': emptyColor,
            '--statusCircle-thickness': thickness,
            '--statusCircle-fontSize': size / 5,
            ...style,
        }) as React.CSSProperties;

    return (
        <div className={`statusCircleContainer${onClick ? ' statusCircleContainerHover' : ''}`} style={getStyle()} onClick={onClick} data-testid="status-circle">
            {showPercentText && <span>{_percent}%</span>}
        </div>
    );
};
