import React, { useState, useEffect, useRef, FunctionComponent, MouseEventHandler } from 'react';

import Documents from '../../pages/Account/Documents';
import Transactions from '../../pages/Account/Transactions';

import { openBulkDocumentUploader } from '../Uploaders/BulkDocumentUploader';
import { clearQueryParams } from '../../utilities/query/queryParamHelpers';
import { VUES } from '../../constants/constantStrings';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';

import '../../styles/documentsAndTransactionsTablesWidgets.css';
import { openBulkUploaderManager } from '../Uploaders/BulkUploader/BulkUploaderManager/BulkUploaderManager';
import { BulkUploadType } from '../Uploaders/BulkUploader/types/bulkUploadTypes';
import ValuationTable from '../../pages/Account/ValuationTable';

const DOC_TRANS_TAB_IDX = 'docTransTabIdx';

const TABLES = {
    TRANSACTIONS: 'Transactions',
    DOCUMENTS: 'Documents',
    VALUATIONS: 'Valuations',
};

interface Props {
    modal: any;
    investment: any;
    setAlert: any;
    vue: string;
    relevantUser: any;
}

const DocumentsAndTransactionsTablesWidgets: FunctionComponent<Props> = (props) => {
    const [tabIdx, setTabIdx] = useState<number>(0);
    const createNewCallback = useRef<((investment: any) => void) | null>(null);
    const callables = useRef<any>(null);

    useEffect(() => {
        const storedTabIdx = sessionStorage.getItem(DOC_TRANS_TAB_IDX);
        if (storedTabIdx) {
            setTabIdx(parseInt(storedTabIdx) || 0);
        }
    }, []);

    const getTabs = () => {
        const p = props;
        const tabs = [
            {
                name: TABLES.TRANSACTIONS,
                component: () => (
                    <Transactions
                        {...p}
                        noTitle
                        includeEntity
                        noInvestmentDropdown
                        tableContainerStyle={{ margin: 0 }}
                        setCreateNewCallback={(callback: (investment: any) => void) => {
                            createNewCallback.current = callback;
                        }}
                        investmentId={p.investment?._id}
                        investment={p.investment}
                        setCallables={(newCallables: any) => {
                            callables.current = newCallables;
                        }}
                        viewAssociatedDocumentCallback={() => setTabIdx(1)}
                    />
                ),
            },
            {
                name: TABLES.DOCUMENTS,
                component: () => (
                    <Documents
                        {...p}
                        noTitle
                        includeEntity
                        noInvestmentDropdown
                        showCapStatementReport
                        tableContainerStyle={{ margin: 0 }}
                        setCreateNewCallback={(callback: (investment: any) => void) => {
                            createNewCallback.current = callback;
                        }}
                        investmentId={p.investment?._id}
                        investment={p.investment}
                        setCallables={(newCallables: any) => {
                            callables.current = newCallables;
                        }}
                        relevantUser={p.relevantUser}
                    />
                ),
            },
            {
                name: TABLES.VALUATIONS,
                component: () => (
                    <ValuationTable
                        {...p}
                        noTitle
                        includeEntity
                        noInvestmentDropdown
                        showCapStatementReport
                        tableContainerStyle={{ margin: 0 }}
                        setCreateNewCallback={(callback: (investment: any) => void) => {
                            createNewCallback.current = callback;
                        }}
                        investmentId={p.investment?._id}
                        investment={p.investment}
                        setCallables={(newCallables: any) => {
                            callables.current = newCallables;
                        }}
                        relevantUser={p.relevantUser}
                    />
                ),
            },
        ];

        return tabs;
    };

    const getCurTab = () => getTabs()[tabIdx];

    const renderTab = (tab: any, index: number) => {
        return (
            <div
                className={`ddt-tabElem ddt-tab ${tabIdx === index ? 'ddt-tabSelected' : ''}`}
                onClick={() => {
                    if (tabIdx === index) return;
                    clearQueryParams();
                    setTabIdx(index);
                    sessionStorage.setItem(DOC_TRANS_TAB_IDX, index.toString());
                }}
                key={`dttTab${index}`}
            >
                {tab.name}
            </div>
        );
    };

    const renderAddBtn = () => {
        const p = props;
        const text = `New ${getCurTab().name.substring(0, getCurTab().name.length - 1)}`;

        const allowBulkUpload = p.vue !== VUES.INVESTOR;

        let openBulkUploaderFunc: MouseEventHandler<HTMLDivElement> | undefined;
        if (allowBulkUpload && getCurTab().name === TABLES.DOCUMENTS) {
            openBulkUploaderFunc = () => openBulkDocumentUploader(p.modal, p.investment, p.setAlert, callables.current?.loadData);
        } else if (allowBulkUpload && getCurTab().name === TABLES.TRANSACTIONS) {
            openBulkUploaderFunc = () =>
                openBulkUploaderManager(p.modal, BulkUploadType.transaction, { investment: p.investment?._id ?? p.investment }, true, callables.current?.loadData);
        } else if (allowBulkUpload && getCurTab().name === TABLES.VALUATIONS) {
            openBulkUploaderFunc = () =>
                openBulkUploaderManager(p.modal, BulkUploadType.valuation, { investment: p.investment?._id ?? p.investment }, true, callables.current?.loadData);
        }

        return !createNewCallback.current ? (
            <></>
        ) : (
            <>
                {allowBulkUpload && (
                    <div style={{ marginRight: '180px' }} className="ddt-tabElem ddt-addBtn" onClick={openBulkUploaderFunc}>
                        <div className="plus-icon" />
                        Bulk Upload
                    </div>
                )}
                <div className="ddt-tabElem ddt-addBtn" onClick={() => createNewCallback.current?.(props.investment)}>
                    <div className="plus-icon" />
                    {text}
                </div>
            </>
        );
    };

    const tabName = getCurTab().name;
    let canCreate = false;
    if (tabName === TABLES.TRANSACTIONS) {
        canCreate = UserPermissions().canCreateTransactions;
    } else if (tabName === TABLES.DOCUMENTS) {
        canCreate = UserPermissions().canCreateDocuments;
    } else if (tabName === TABLES.VALUATIONS) {
        canCreate = UserPermissions().canCreateAnyInvestments;
    }

    return (
        <div className="widget">
            <div className="widget-row" style={{ overflowY: 'unset' }}>
                <div className="dtt-tabContainer">
                    {getTabs().map(renderTab)}
                    {canCreate && renderAddBtn()}
                </div>
            </div>
            <div className="widget-row" style={{ overflowY: 'unset' }}>
                <div className="dtt-table" style={{ marginTop: '20px', marginBottom: '0px' }}>
                    {getCurTab().component()}
                </div>
            </div>
        </div>
    );
};

export default DocumentsAndTransactionsTablesWidgets;
