import {
    FileParseResult,
    FileTextResult,
    // FileTypes
} from '../../../../constants/file';
import BulkUploadHandler, { BulkUploadHandlerCreateType, BulkUploadHandlerConfig } from './BulkUploadHandler';
import sharedFields from '../types/sharedFields';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
// import api2 from '../../../../api2';
import validator from 'validator';
import api from '../../../../api';
// import apiClient from '../../../../apiClient';
import { sharedFieldEnum } from '../types/sharedFields';

export interface AdvisoryGroupUploadHandlerCreateType extends BulkUploadHandlerCreateType {
    name?: string;
    owner?: string;
    plan?: string;
}

export interface AdvisoryGroupUploadHandlerConfig extends BulkUploadHandlerConfig {}

export interface AdvisoryGroupUploadHandlerState {
    groupPlans: any[];
}

const getPlanField = (groupPlans: any[]) => {
    return {
        ...sharedFieldEnum(groupPlans.map((plan) => plan.plan_name)),
        displayName: 'Plan ID',
        fieldName: 'plan',
        isValid: (val: string) => {
            return (
                groupPlans.map((plan) => plan.plan_name.toLowerCase()).includes(val.toLowerCase()) ||
                groupPlans.map((plan) => plan._id).includes(val) ||
                groupPlans.map((plan) => plan.name.toLowerCase()).includes(val.toLowerCase())
            );
        },
        format: (val: string) => {
            return (
                groupPlans.find((plan) => plan.plan_name.toLowerCase() === val.toLowerCase() || plan._id === val || plan.name.toLowerCase() === val.toLowerCase())?.plan_name || ''
            );
        },
        required: false,
        style: { width: '350px' },
    };
};

export const onLoadHandler = async (thisHandler: BulkUploadHandler<AdvisoryGroupUploadHandlerCreateType, AdvisoryGroupUploadHandlerConfig, AdvisoryGroupUploadHandlerState>) => {
    try {
        const response = await api.get('/advisorygroupplans');
        const groupPlans = response?.data || [];

        // set the state for the handler
        thisHandler.state = {
            groupPlans,
        };

        // set "plan" base_columns field, needs to be done here because it requires the groupPlans data
        thisHandler.base_columns.plan = getPlanField(groupPlans);
    } catch (error) {
        console.error('Error loading advisory group plans:', error);
        thisHandler.state = {
            groupPlans: [],
        };
    }
};

class AdvisoryGroupUploadHandler extends BulkUploadHandler<AdvisoryGroupUploadHandlerCreateType, AdvisoryGroupUploadHandlerConfig, AdvisoryGroupUploadHandlerState> {
    // The type of the bulk upload, eg. 'advisory_group' or 'valuation'
    type = BulkUploadType.advisory_group;

    // The base columns that are required for the bulk upload
    base_columns = {
        name: {
            ...sharedFields.string,
            displayName: 'Full Name',
            fieldName: 'name',
            isValid: (val: string) =>
                validator.isLength(val + '', { min: 2, max: 70 }) &&
                validator.isAlphanumeric(val + '', 'en-US', {
                    ignore: ' -,&/.',
                }),
            invalidMessage: 'The name must be between 2 and 70 characters and contain only letters, number, spaces, and dashes',
            required: true,
        },
        owner: {
            ...sharedFields.object_id,
            displayName: 'Owner ID',
            fieldName: 'owner',
            required: true,
        },
        plan: {
            ...getPlanField([]),
        },
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof AdvisoryGroupUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (columnOrder: (keyof AdvisoryGroupUploadHandlerCreateType)[] = this.columnOrder): { [key in keyof AdvisoryGroupUploadHandlerCreateType]: BulkUploadColumn } => {
        // Sort the columns based on the given column order or the default order.
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);
        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof AdvisoryGroupUploadHandlerCreateType]: string }, columnOrder?: (keyof AdvisoryGroupUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        // any additional validation goes here
        return isDataValid;
    };

    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @returns {Object} An object with the correct fields for the line.
     */
    parseSingleCsvLine = (line: string, columnOrder?: (keyof AdvisoryGroupUploadHandlerCreateType)[]): AdvisoryGroupUploadHandlerCreateType => {
        // if not enough commas are included, the fields will be empty strings
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        // try to find plan from name or id
        // let plan = parsedValues.plan;
        // for (let i = 0; i < (this.state?.groupPlans?.length || 0); i++) {
        //     const groupPlan = this.state?.groupPlans[i];
        //     if ([groupPlan.name.toLowerCase(), groupPlan.plan_name.toLowerCase(), groupPlan._id].includes(parsedValues.plan.toLowerCase())) {
        //         plan = groupPlan.name;
        //         break;
        //     }
        // }

        let plan = expectedColumns.plan?.format(parsedValues.plan);

        // set up special conditions based on the config and data type
        return {
            name: expectedColumns.name?.format(parsedValues.name),
            owner: expectedColumns.owner?.format(parsedValues.owner),
            plan,
        };
    };

    // Parse the text file results into a FileParseResult
    parseTextFileResult = async (
        textFileResult: FileTextResult,
        columnOrder?: (keyof AdvisoryGroupUploadHandlerCreateType)[]
    ): Promise<FileParseResult<AdvisoryGroupUploadHandlerCreateType>> => {
        try {
            const data = textFileResult.lines.map((line) => this.parseSingleCsvLine(line, columnOrder));
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<AdvisoryGroupUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<AdvisoryGroupUploadHandlerCreateType>;
        }
    };

    // Get the notes for the bulk upload type
    getNotes = (): string[] => {
        let notes: string[] = [];
        return notes;
    };

    // function to create the object in the database from the parsed data
    create = async (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof AdvisoryGroupUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        // Ensure the data is valid
        if (!this.isDataValid(data, columnOrder)) {
            return { success: false, message: 'Invalid data' };
        }

        // get plan id from name
        let planId = undefined;
        if (data.plan) {
            planId = this.state?.groupPlans.find(
                (plan) => plan.name.toLowerCase() === data.plan.toLowerCase() || plan.plan_name.toLowerCase() === data.plan.toLowerCase() || plan._id === data.plan
            )?._id;
        }

        try {
            // Attempt to create a single advisory group from the current object.
            const createResult = await api.post(
                '/advisorygroups',
                {
                    name: data.name,
                    owner_id: data.owner,
                    plan: planId,
                },
                {},
                true
            );

            // Return the new advisory group ID.
            return {
                success: true,
                message: 'AdvisoryGroup created successfully',
                id: createResult.advisoryGroup._id,
            };
        } catch (err) {
            // Log and return null in case of an error.
            console.log('error creating advisory group:', err);
            return {
                success: false,
                message: `Error creating advisory group: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            const deleteResult = await api.delete(`/advisorygroups/${id}`, {}, true);
            if (deleteResult.message === 'OK') {
                return {
                    success: true,
                    message: 'AdvisoryGroup deleted successfully',
                };
            } else {
                return {
                    success: false,
                    message: 'Error deleting advisory group',
                };
            }
        } catch (err) {
            console.log('error deleting advisory group:', err);
            return {
                success: false,
                message: `Error deleting advisory group: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the AdvisoryGroupUploadHandler
export const getAdvisoryGroupUploadHandler: (config: AdvisoryGroupUploadHandlerConfig) => AdvisoryGroupUploadHandler = (config: AdvisoryGroupUploadHandlerConfig) =>
    new AdvisoryGroupUploadHandler(config, onLoadHandler);

export default AdvisoryGroupUploadHandler;
