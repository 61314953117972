// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

export default {
    emptyText: 'Track your Hedge Funds total value over time',
    textStyle: {
        fontSize: '16px',
    },
    props: {
        data: [
            { year: 2022, month: 3, value: 4500000 },
            { year: 2022, month: 4, value: 5800000 },
            { year: 2022, month: 5, value: 5800000 },
            { year: 2022, month: 6, value: 8500000 },
            { year: 2022, month: 7, value: 8500000 },
            { year: 2022, month: 8, value: 9300000 },
            { year: 2022, month: 9, value: 4500000 },
            { year: 2022, month: 10, value: 5800000 },
            { year: 2022, month: 11, value: 5800000 },
            { year: 2022, month: 0, value: 8500000 },
            { year: 2021, month: 1, value: 9300000 },
            { year: 2021, month: 2, value: 4500000 },
        ],
    },
};
