import React from 'react';
import { RALRequestSignatory } from '../../types/RalRequest';
import ObjectListEditor, { GenericTypeWithControls } from './ObjectListEditor';

interface SignatoryListEditorProps {
    readOnly?: boolean;
    label?: string;
    signatories: RALRequestSignatory[];
    setSignatories: (signatories: RALRequestSignatory[]) => void;
    _nonEditableFields?: (keyof RALRequestSignatory)[];
}

const RalSignatoryListEditor = ({ readOnly = false, label, signatories, setSignatories }: SignatoryListEditorProps) => {
    const orderedSignatories = signatories.map((signatory) => {
        const { role, first_name, last_name, email, ...rest } = signatory;
        return { role, first_name, last_name, email, ...rest };
    });
    return (
        <ObjectListEditor<RALRequestSignatory>
            readOnly={readOnly}
            label={label}
            objects={orderedSignatories}
            setObjects={setSignatories}
            newObjectTemplate={{
                role: '',
                first_name: '',
                last_name: '',
                email: '',
            }}
        />
    );
};

export default RalSignatoryListEditor;

export interface RequiredSignatory {
    count: number;
    role: string;
}

export const generateSignatoriesFromRequiredSignatories = (requiredSignatories: RequiredSignatory[]): GenericTypeWithControls<RALRequestSignatory>[] => {
    let newSignatories: GenericTypeWithControls<RALRequestSignatory>[] = [];
    requiredSignatories.forEach((sign) => {
        for (let i = 0; i < sign.count; i++) {
            newSignatories.push({
                first_name: '',
                last_name: '',
                role: sign.role,
                email: '',
                _nonEditableFields: ['role'],
                _required: true,
            });
        }
    });
    return newSignatories;
};

export const mergeAndGenerateSignatories = (
    requiredSignatories: RequiredSignatory[],
    existingSignatories: GenericTypeWithControls<RALRequestSignatory>[]
): GenericTypeWithControls<RALRequestSignatory>[] => {
    // Generate required signatories with the function provided
    const generatedRequiredSignatories = generateSignatoriesFromRequiredSignatories(requiredSignatories);

    // Mark all existing signatories as not required initially
    existingSignatories.forEach((signatory) => {
        signatory._required = false;
    });

    // Merge logic: For each generated required signatory, check if it exists in the existing ones
    // If it does, mark it as required and non-editable for the role field
    // If it doesn't, add it to the existing signatories
    generatedRequiredSignatories.forEach((generatedSignatory) => {
        const existingSignatoryIndex = existingSignatories.findIndex((existingSignatory) => existingSignatory.role === generatedSignatory.role);

        if (existingSignatoryIndex !== -1) {
            existingSignatories[existingSignatoryIndex]._required = true;
            existingSignatories[existingSignatoryIndex]._nonEditableFields = ['role'];
        } else {
            existingSignatories.push({
                ...generatedSignatory,
                _required: true,
                _nonEditableFields: ['role'],
            });
        }
    });

    return existingSignatories;
};
