// const getInvestorPageBasePath = (
//     userAccess: UserAccess,
//     relevantUserId: string | null, // only required for investors
//     accountId: string | null, // only required for investors
// ): string => {
//     const pathMap = {
//         admin: `/admin/users/${relevantUserId}`,
//         advisor: `/advisor/clients/${relevantUserId}`,
//         investor: `/accounts/${accountId ?? 'all'}`,
//     }
//     return pathMap[userAccess];
// }

const TAB = 'tab';

const getClientVueBasePath = (userId: string) => `/advisor/clients/${userId}`;

const investments = (userId: string) => `${getClientVueBasePath(userId)}?${TAB}=Investments`;
const capitalCalls = (userId: string) => `${getClientVueBasePath(userId)}?${TAB}=Capital+Calls`;
const transactions = (userId: string) => `${getClientVueBasePath(userId)}?${TAB}=Transactions`;
const documents = (userId: string) => `${getClientVueBasePath(userId)}?${TAB}=Documents`;
const taxCenter = (userId: string) => `${getClientVueBasePath(userId)}?${TAB}=Tax+Center`;
const manageInvestments = (userId: string) => `${getClientVueBasePath(userId)}?${TAB}=Manage+Investments`;
const performance = (userId: string) => `${getClientVueBasePath(userId)}?${TAB}=Performance`;
const manageAccount = (userId: string) => `${getClientVueBasePath(userId)}?${TAB}=Manage+Account`;

const investmentDetails = (userId: string, investmentId: string) => `${getClientVueBasePath(userId)}/details/${investmentId}`;

const viewDocument = (userId: string, accountId: string, investmentId: string, documentId: string) => `${getClientVueBasePath(userId)}/documents/${documentId}`;

const routes = {
    client: {
        investments,
        capitalCalls,
        transactions,
        documents,
        taxCenter,
        manageInvestments,
        performance,
        manageAccount,

        investmentDetails,

        viewDocument,
    },
};

export default routes;
