import React, { useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

import '../../../styles/widget.css';
import '../../../styles/widget-totalValue.css';

import moment from 'moment';
import dateToUTCMidnight from '../../../utilities/date/dateToUTCMidnight';
import { CURRENCIES, CURRENCY_SYMBOLS } from '../../../constants/currencyConstants';

const THOUSAND = 1_000;
const MILLION = 1_000_000;
const BILLION = 1_000_000_000;

const colors = ['#2A4266', '#009F9B', '#F2A900'];

interface InvestmentValueChartProps extends RouteComponentProps {
    data: any[];
    dataInfo: { key: string; label: string; color?: string }[];
    title?: string;
    fontSize?: number;
    currency?: keyof typeof CURRENCIES;
}

const InvestmentValueChart: React.FC<InvestmentValueChartProps> = (props) => {
    // const [keys, setKeys] = useState<string[]>([]);
    // const [selectedKey, setSelectedKey] = useState<string>('');
    // const [data, setData] = useState<any[]>([]);
    // const [labels, setLabels] = useState<string[]>([]);

    useEffect(() => {
        loadData();
    }, [props.data]);

    // const sortByMonthYear = (x1: any, x2: any) => {
    //     let x1Time = x1.time ?? Date.UTC(x1.year, x1.month);
    //     let x2Time = x2.time ?? Date.UTC(x2.year, x2.month);
    //     return x1Time - x2Time;
    // };

    const loadData = () => {
        // Implement the logic to load data
    };

    const getYRangeMinMax = () => {
        const curData = props.data;
        if (!curData) return [0, 0];
        const curValues = curData.map((d) => d?.value);
        let [min, max] = [Math.min(...curValues), Math.max(...curValues)];

        let minTickSize = (max * 1.2) / 8;
        let tickSize = 1;

        for (let i = 1; i <= 9 && tickSize < minTickSize; i++) {
            tickSize = 10 ** i / 5;
        }

        let displayMin = min >= 0 ? Math.max(min - min * 0.1, 0) : min * 1.1;
        let displayMax = max * 1.1;

        displayMin = Math.floor(displayMin / tickSize) * tickSize;
        displayMax = Math.ceil(displayMax / tickSize) * tickSize;

        return [displayMin, displayMax];
    };

    const getChartData = (data: any[]) => {
        const map_and_sort = (data: any[], key: string) => {
            let ret = data.length
                ? data.map((d) => ({
                      x: d.time ? new Date(d.time) : new Date(Date.UTC(d.year, d.month)),
                      y: d[key],
                  }))
                : [];
            ret = ret.sort((a, b) => {
                const aTime = (dateToUTCMidnight(a.x) as Date).getTime();
                const bTime = (dateToUTCMidnight(b.x) as Date).getTime();
                return aTime - bTime;
            });
            return ret;
        };

        let datasets: any[] = [];

        if (props.dataInfo) {
            props.dataInfo.forEach((dInfo, i) => {
                let key = dInfo.key;
                let color = dInfo.color ?? colors[i % colors.length];
                let resData = map_and_sort(data, key);
                datasets.push({
                    label: dInfo.label,
                    data: resData,
                    pointHoverRadius: 5,
                    pointRadius: 3,
                    pointBorderWidth: 0,
                    pointBackgroundColor: color,
                    backgroundColor: color + 'b9',
                    borderColor: color,
                });
            });
        }

        let returnData = { datasets };

        let [min, max] = getYRangeMinMax();
        min = 0;

        let config = {
            animation: { duration: 0 },
            responsive: true,
            maintainAspectRatio: false,
            fill: true,
            scales: {
                x: {
                    display: true,
                    type: 'time' as const,
                    time: { unit: 'month' as const, displayFormats: { year: 'MMM yyyy', month: 'MMM yyyy' } },
                    stacked: true,
                    grid: {
                        display: false,
                    },
                    ticks: {
                        font: {
                            size: props.fontSize ?? 15,
                        },
                    },
                },
                y: {
                    type: 'linear' as const,
                    min,
                    max,
                    stacked: true,
                    ticks: {
                        font: {
                            size: props.fontSize ?? 16,
                        },
                        callback: function (tickValue: string | number) {
                            let v: string | number = tickValue;
                            if (typeof tickValue === 'number') {
                                if (Math.abs(tickValue) >= BILLION) {
                                    v = `${(tickValue / BILLION).toFixed(1)}b`;
                                } else if (Math.abs(tickValue) >= MILLION) {
                                    v = `${(tickValue / MILLION).toFixed(1)}m`;
                                } else if (Math.abs(tickValue) >= THOUSAND) {
                                    v = `${(tickValue / THOUSAND).toFixed(1)}k`;
                                }
                            }
                            const currency = props.currency ?? CURRENCIES.USD;
                            const currencySymbol = CURRENCY_SYMBOLS[currency] ?? CURRENCY_SYMBOLS.USD;
                            return `${currencySymbol}${v}  `;
                        },
                    },
                    grid: {
                        drawBorder: false,
                    } as any,
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    mode: 'index' as 'index',
                    includeInvisible: true,
                    callbacks: {
                        title: function (ttis: any) {
                            let ret = '';
                            if (ttis.length) {
                                ret = moment(dateToUTCMidnight(ttis[0].raw.x) as Date).format('MMMM yyyy');
                            }
                            return ret;
                        },
                        label: function (tti: any) {
                            let datum = tti.raw;
                            return tti?.dataset?.label + ': ' + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(datum.y);
                        },
                        afterBody: function (input: any) {
                            let amount = input.reduce((acc: number, cur: any) => acc + cur?.raw?.y, 0);
                            return 'Total: ' + new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
                        },
                    },
                    backgroundColor: '#0d2957e8',
                },
            },
            elements: {
                point: {
                    radius: 0,
                },
                line: {
                    borderWidth: 3,
                },
            },
        };

        return { config, data: returnData };
    };

    const { config, data: chartData } = getChartData(props.data);

    return (
        <div className="widget totalValueComponent">
            <div className="widget-header">{props.title ?? 'Investment Value'}</div>
            <div className="cashFlowGraph">
                <Chart type={'line'} data={chartData} options={config} style={{ position: 'relative', backgroundColor: 'transparent', height: '350px' }} />
            </div>
        </div>
    );
};

export default withRouter(InvestmentValueChart);
