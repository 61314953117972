import { AuditLog } from './AuditLog';

export enum AccountIdType {
    SSN = 'SSN',
    EIN = 'EIN',
    TIN = 'TIN',
}

export enum AccountType {
    INDIVIDUAL = 'individual',
    JOINT = 'joint',
    TRUST = 'trust',
    IRA = 'ira',
    LLC = 'llc',
    PARTNERSHIP = 'partnership',
    CORPORATION = 'corporation',
    OTHER = 'other',
}

export interface AccountUser {
    _id: string;
    name: string;
    email: string;
}

export interface AccountAddress {
    streetLine1: string;
    streetLine2: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
}

export interface Account {
    _id: string;
    name: string;
    user: string | AccountUser;
    audit_log: AuditLog[] | null;
    reporting_auth_id: string;
    signed_on: string;
    investments_count: number | null;
    address: AccountAddress;
    performance: Record<string, any>;
    account_type: string;
    id_type: string;
    id_number: string;
    created_at: string; // Assuming date-time string
    updated_at: string; // Assuming date-time string
    deleted_at: string | null; // Assuming date-time string
}
