export enum AuditResultCheckNames {
    // BIQC Checks
    INVESTMENT_UNDER_ADMIN_REVIEW = 'Investment Under Administrative Review',
    UNREALIZED_VALUE_SPIKE = 'Unrealized Value Spike',
    // BIQC - Cap/NAV Statements
    CAP_NAV_STATEMENT_BALANCE_MISMATCH = 'Ending/beginning balance mismatch',
    CAP_NAV_STATEMENT_INVALID_DATE_RANGE = 'Invalid date range',
    CAP_NAV_STATEMENT_INACCURATE_DISTRIB_SUM = 'Inaccurate distributions sum',
    CAP_NAV_STATEMENT_INACCURATE_CONTRIB_SUM = 'Inaccurate contributions sum',
    CAP_NAV_STATEMENT_TIMELINESS = 'Cap/NAV Statement Timeliness',
    HAVE_CAP_NAV_STATEMENT_EVERY_PERIOD = 'Have Cap/NAV Statement every period',
    // BIQC - Tax Documents
    TAX_DOC_TIMELINESS = 'Tax Document Timeliness',
    HAS_TAX_DOC_EVERY_YEAR = 'Has Tax Document every year since investment start',
    // BIQC - Transactions
    TRANS_WITH_DEFAULT_DATE_DATA = 'Transaction with default date data',
    TRAN_DUPLICATES = 'Duplicate Transactions',
    // Document Processing - Sorting
    UNSORTED_DOCUMENT = 'Unsorted Document',
    // Document Processing - Tagging
    CAP_STMT_WITH_INVALID_METADATA = 'Capital Statement With Invalid Metadata',
    CAP_CALL_WITH_INVALID_METADATA = 'Capital Call With Invalid Metadata',
    DIST_WITH_INVALID_METADATA = 'Distribution With Invalid Metadata',
    HEDGE_FUND_STMT_WITH_INVALID_METADATA = 'NAV Statement WithInvalid Metadata',
    NET_CAP_CALL_WITH_INVALID_METADATA = 'Net Capital Call With Invalid Metadata',
    NET_DIST_WITH_INVALID_METADATA = 'Net Distribution With Invalid Metadata',
    TAX_DOC_WITH_INVALID_METADATA = 'Tax Document With Invalid Metadata',
    // Connections
    MANUAL_CONN_PORTAL_SYNC = 'Manual Connection Portal Sync',
    CONN_STATUS_NOT_ACTIVE = 'Connection Status Not Active',
    CONN_WITHOUT_CONTACTS = 'Connection Without Contacts',
    CONN_COMPLETED_BY_ASSET_MANAGER = 'Connection Completed By Asset Manager',
    UNMAPPED_DATA_IN_CONNECTION = 'Unmapped Data in Most Recent Connection Sync (Staging Area)',
    // Messages
    MSGS_UNREAD_3_DAYS = 'Messages Unread for 3 Days',
    MSGS_WITH_DOCS_ATTACHED = 'Messages with Documents Attached',
    MSGS_FLAGGED_FOR_5_DAYS = 'Messages Flagged for 5 Days',
    // Investments
    FUND_INVESTMENTS_WITHOUT_SECURITY_MASTER_TICKER = 'Fund investments without Security Master Ticker',
    FUND_INVESTMENTS_MISSING_SUBTYPE = 'Fund investments missing Subtype',
    INVESTMENT_TYPE_OF_DIVERSE_INVESTMENTS = 'Non-hidden Investment has type Diverse Investments',
    UNHIDDEN_DIVERSE_INVESTMENTS = 'Unhidden Diverse Investments',
    // Extegrations
    EXTEGRATION_SYNC_FAILED_FOR_ADVISORY_GROUP = 'Extegration Sync Failed by Advisory Group',
}
