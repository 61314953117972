import admin from './admin';
import advisor from './advisor';
import investor from './investor';
import client from './client';

export default {
    admin,
    advisor,
    investor,
    client,
};
