import { FileParseResult, FileTextResult } from '../../../../constants/file';
import BulkUploadHandler, { BulkUploadHandlerCreateType, BulkUploadHandlerConfig } from './BulkUploadHandler';
import sharedFields from '../types/sharedFields';
import { BulkUploadColumn, BulkUploadRequestResult, BulkUploadType } from '../types/bulkUploadTypes';
import validator from 'validator';
import api from '../../../../api';

export interface ContactUploadHandlerCreateType extends BulkUploadHandlerCreateType {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
}

export interface ContactUploadHandlerConfig extends BulkUploadHandlerConfig {}

class ContactUploadHandler extends BulkUploadHandler<ContactUploadHandlerCreateType, ContactUploadHandlerConfig> {
    // The type of the bulk upload, eg. 'contact' or 'valuation'
    type = BulkUploadType.contact;

    // The base columns that are required for the bulk upload
    base_columns = {
        first_name: {
            ...sharedFields.string,
            displayName: 'First Name',
            fieldName: 'first_name',
            isValid: (val: string) => validator.isLength(val + '', { min: 2, max: 140 }),
            invalidMessage: 'The first name must be between 2 and 140 characters and contain only letters, number, spaces, and dashes',
            required: true,
        },
        last_name: {
            ...sharedFields.string,
            displayName: 'Last Name',
            fieldName: 'last_name',
            isValid: (val: string) => validator.isLength(val + '', { min: 2, max: 140 }),
            invalidMessage: 'The last name must be between 2 and 140 characters and contain only letters, number, spaces, and dashes',
            required: true,
        },
        email: {
            ...sharedFields.string,
            displayName: 'Email',
            fieldName: 'email',
            isValid: (val: string) => validator.isEmail(val + ''),
            invalidMessage: 'Please enter a valid email address',
            required: true,
        },
        phone: {
            ...sharedFields.string,
            displayName: 'Phone',
            fieldName: 'phone',
            isValid: (val: string) => !val || validator.isMobilePhone(val + ''),
            invalidMessage: 'Please enter a valid phone number',
            required: false,
        },
    };

    // The order of the columns in the CSV file
    columnOrder = Object.keys(this.base_columns) as (keyof ContactUploadHandlerCreateType)[];

    // sort the columns based on the order in columnOrder, or the default order if not provided
    getColumns = (columnOrder: (keyof ContactUploadHandlerCreateType)[] = this.columnOrder): { [key in keyof ContactUploadHandlerCreateType]: BulkUploadColumn } => {
        // Sort the columns based on the given column order or the default order.
        const sortedColumns = this._sortColumns(this.base_columns, columnOrder);
        return sortedColumns;
    };

    // check a single row of data to see if it is valid
    isDataValid = (data: { [key in keyof ContactUploadHandlerCreateType]: string }, columnOrder?: (keyof ContactUploadHandlerCreateType)[]) => {
        const columns = this.getColumns(columnOrder);
        const isDataValid = this._isColumnDataValid(columns, data, columnOrder);
        // any additional validation goes here
        return isDataValid;
    };

    /**
     * Parses a single line from a CSV file into an object with the correct fields.
     * Does not handle validation.
     * @param {string} line A single line from a CSV file.
     * @returns {Object} An object with the correct fields for the line.
     */
    parseSingleCsvLine = (line: string, columnOrder?: (keyof ContactUploadHandlerCreateType)[]): ContactUploadHandlerCreateType => {
        // if not enough commas are included, the fields will be empty strings
        const expectedColumns = this.getColumns(columnOrder);
        const parsedValues = this._parseSingleCsvLine(line, columnOrder);

        // replaces extra characters in the amount field
        if (parsedValues.amount) {
            parsedValues.amount = expectedColumns.amount?.format(parsedValues.amount).toString();
        }

        // set up special conditions based on the config and data type
        return {
            first_name: parsedValues.first_name,
            last_name: parsedValues.last_name,
            email: parsedValues.email,
            phone: parsedValues.phone,
        };
    };
    parseTextFileResult = async (
        textFileResult: FileTextResult,
        columnOrder?: (keyof ContactUploadHandlerCreateType)[]
    ): Promise<FileParseResult<ContactUploadHandlerCreateType>> => {
        try {
            // Skip the header row by slicing the lines array
            const data = textFileResult.lines.slice(1).map((line) => this.parseSingleCsvLine(line, columnOrder));
            return {
                success: true,
                message: 'File parsed successfully',
                file: textFileResult.file,
                data,
            } as FileParseResult<ContactUploadHandlerCreateType>;
        } catch (err: any) {
            return {
                success: false,
                message: `Error parsing file: ${err.message}`,
                file: textFileResult.file,
            } as FileParseResult<ContactUploadHandlerCreateType>;
        }
    };

    // Get the notes for the bulk upload type
    getNotes = (): string[] => {
        let notes: string[] = [];
        return notes;
    };

    // function to create the object in the database from the parsed data
    create = async (
        columnObj: { [key: string]: BulkUploadColumn },
        data: { [key: string]: any },
        columnOrder?: (keyof ContactUploadHandlerCreateType)[]
    ): Promise<BulkUploadRequestResult> => {
        // Ensure the data is valid
        if (!this.isDataValid(data, columnOrder)) {
            return { success: false, message: 'Invalid data' };
        }

        try {
            // Attempt to create a single contact from the current object.
            const createResult = await api.post(
                '/contacts',
                {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    email: data.email,
                    phone: data.phone,
                },
                {},
                true
            );

            // Return the new contact ID.
            return {
                success: true,
                message: 'Contact created successfully',
                id: createResult.contact._id,
            };
        } catch (err) {
            // Log and return null in case of an error.
            console.log('error creating contact:', err);
            return {
                success: false,
                message: `Error creating contact: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };

    // function to delete the object from the database
    delete = async (id: string): Promise<BulkUploadRequestResult> => {
        try {
            const deleteResult = await api.post(
                `/contacts/delete`,
                {
                    _id: id,
                },
                {},
                true
            );
            if (deleteResult.success) {
                return {
                    success: true,
                    message: 'Contact deleted successfully',
                };
            } else {
                return {
                    success: false,
                    message: 'Error deleting contact',
                };
            }
        } catch (err) {
            console.log('error deleting contact:', err);
            return {
                success: false,
                message: `Error deleting contact: ${(err as any)?.response?.data?.message || (err as any).message}`,
            };
        }
    };
}

// getter for the ContactUploadHandler
export const getContactUploadHandler: (config: ContactUploadHandlerConfig) => ContactUploadHandler = (config: ContactUploadHandlerConfig) => new ContactUploadHandler(config);

export default ContactUploadHandler;
