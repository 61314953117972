// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import '../../styles/widget.css';
import '../../styles/widget-NotificationSettingControls.css';
import _ from 'lodash';
import api from '../../api';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import Multitoggle from '../Toggles/Multitoggle';

const isMobile = () => window.innerWidth <= 768;

// in order to order the list
const NOTIFICATION_TYPES = [
    // 'Master Enabled',
    'Capital Call',
    'Distribution',
    'Tax Document',
    // 'Connection Active',
    // 'Connection Error',
];

interface NotificationSettingControlsProps extends RouteComponentProps {
    user: any;
    setAlert: (message: string, isError: boolean) => void;
}

const NotificationSettingControls: React.FC<NotificationSettingControlsProps> = (props) => {
    const [user, setUser] = useState<any>(null);
    const [notificationSettings, setNotificationSettings] = useState<any>(null);
    const [showNotifTooltip, setShowNotifTooltip] = useState<boolean>(false);

    const loadData = useCallback(() => {
        setUser(props.user);
        setNotificationSettings(props.user?.notificationSettings);
    }, [props.user]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        if (!_.isEqual(user, props.user)) {
            loadData();
        }
    }, [props.user, user, loadData]);

    const handleToggle = async (key: string, enabled: boolean) => {
        const stateSettings = { ...notificationSettings };
        const newEnabled = !enabled;
        stateSettings[key] = newEnabled;
        setNotificationSettings(stateSettings);
        try {
            const res = await api.patch(`/users/${user._id}/notificationSettings`, {
                key,
                enabled: newEnabled,
            });
            if (!res) {
                throw new Error('API Error');
            }
        } catch (error) {
            // console.error('Error changing notification setting', error);
            stateSettings[key] = enabled;
            setNotificationSettings(stateSettings);
            setDismissableAlert(props.setAlert, 'Error changing notification setting', true);
        }
    };

    const settings = notificationSettings ? notificationSettings : (user?.notificationSettings ?? {});
    if (!settings) return null;

    return (
        <div className="investment-accounts" style={{ width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h1 style={{ margin: '0px' }}>Notification Settings</h1>
                <div style={{ width: '20px', height: '20px' }}>
                    <div
                        className="show-tooltip"
                        style={{ marginLeft: '20px' }}
                        onClick={isMobile() ? () => setShowNotifTooltip(!showNotifTooltip) : undefined}
                        onMouseEnter={!isMobile() ? () => setShowNotifTooltip(true) : undefined}
                        onMouseLeave={!isMobile() ? () => setShowNotifTooltip(false) : undefined}
                    >
                        ?
                    </div>
                    {showNotifTooltip && (
                        <div className="popper">
                            Turn ON notifications below to receive an email for an upcoming capital call, distribution, or when we have received a current tax document.
                        </div>
                    )}
                </div>
            </div>
            <div
                className="accounts-list"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginTop: '20px',
                }}
            >
                {NOTIFICATION_TYPES.map((key, keyIdx) => {
                    const enabled = settings[key];
                    return (
                        <div style={{ display: 'flex', height: '40px', alignItems: 'center' }} key={`notif${keyIdx}`}>
                            <div style={{ width: '200px', fontSize: '18px' }}>{settings[key]?.alias ?? key}</div>
                            <Multitoggle
                                keyStr={'notifSettingToggle'}
                                options={['OFF', 'ON']}
                                selection={enabled ? 'ON' : 'OFF'}
                                zIndex={2000}
                                onSelect={() => handleToggle(key, enabled)}
                                containerStyle={{
                                    width: '130px',
                                    height: '24px',
                                }}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default withRouter(NotificationSettingControls);
