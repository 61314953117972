import React from 'react';
import { saveAs } from 'file-saver';
import api from '../../api';
import api2 from '../../api2';

interface DownloadStatementReportButtonProps {
    investmentId: string;
    fileNamePrefix: string;
}

const DownloadStatementReportButton: React.FC<DownloadStatementReportButtonProps> = ({ investmentId, fileNamePrefix }) => {
    const downloadReport = async () => {
        try {
            const response = await api2.client.InvestmentApi.getStatementHistory({
                investment_id: investmentId,
            });
            const data =
                response.data.statements?.map((statement) => {
                    const return_statement = {
                        ...statement,
                        ...Object.fromEntries(
                            Object.entries(statement?.metadata ?? {}).map(([key, value]) => [key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase()), value])
                        ),
                    };
                    delete return_statement.metadata;
                    return return_statement;
                }) || [];
            const csvContent = convertToCSV(data);
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const fileName = `${fileNamePrefix}_statement_report.csv`;

            saveAs(blob, fileName);
        } catch (error) {
            console.error('Error downloading the report:', error);
        }
    };

    const convertToCSV = (objArray: any[]) => {
        if (objArray.length === 0) {
            return 'No data available\r\n';
        }
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';

        // Collect all unique keys from all objects
        const allKeys: any[] = Array.from(new Set(array.flatMap((obj: any) => Object.keys(obj))));
        const header = allKeys.join(',') + '\r\n';
        str += header;

        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (const key of allKeys) {
                if (line !== '') line += ',';
                line += array[i][key] !== undefined ? array[i][key] : '';
            }
            str += line + '\r\n';
        }
        return str;
    };

    return <button onClick={downloadReport}>Download Statement Report</button>;
};

export default DownloadStatementReportButton;
