import moment from 'moment';
import dateToUTCMidnight from './dateToUTCMidnight';
import { formatUTCDate } from '../format/formatDate';
import { EM_DASH } from '../../constants/constantStrings';

/**
 * This file contains utility functions related to date and time operations.
 *
 * - `getDatetimeDiffString(datetime1, datetime2)`: Calculates the difference between two datetime values and returns a string
 *   representing the duration in a human-readable format. The function ensures that `datetime1` is always earlier than `datetime2`
 *   before calculating the difference. The duration is formatted differently based on its length, ranging from seconds to hours,
 *   making it easy to understand at a glance.
 */

export const getDatetimeDiffString = (datetime1, datetime2) => {
    let d1, d2;

    // Make sure datetime1 is earlier than datetime2
    if (datetime1 < datetime2) {
        d1 = datetime1;
        d2 = datetime2;
    } else {
        d1 = datetime2;
        d2 = datetime1;
    }

    let duration = EM_DASH;
    if (d1 && d2) {
        duration = moment(d2).diff(moment(d1), 'miliseconds');
        // Only print up to the highest unit of time
        if (duration < 1000) {
            duration = moment.utc(duration).format('s.SS') + ' secs';
        } else if (duration < 60000) {
            duration = moment.utc(duration).format('s.SS') + ' secs';
        } else if (duration < 3600000) {
            duration = moment.utc(duration).format('m:ss.SS') + ' mins';
        } else {
            duration = moment.utc(duration).format('H:mm:ss.SS') + ' hrs';
        }
    }

    return duration;
};
