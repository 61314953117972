const isExcelBlob = async (blob) => {
    return await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = function (e) {
            if (e.target.readyState === FileReader.DONE) {
                const uint = new Uint8Array(e.target.result);
                // Check for the ZIP file signature which XLSX files use
                if (
                    (uint[0] === 0x50 && uint[1] === 0x4b && uint[2] === 0x03 && uint[3] === 0x04) ||
                    (uint[0] === 0x50 && uint[1] === 0x4b && uint[2] === 0x05 && uint[3] === 0x06) ||
                    (uint[0] === 0x50 && uint[1] === 0x4b && uint[2] === 0x07 && uint[3] === 0x08)
                ) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }
        };
        // Read the first few bytes of the blob to check the signature
        const blobSlice = blob.slice(0, 4);
        reader.readAsArrayBuffer(blobSlice);
    });
};

export { isExcelBlob };
