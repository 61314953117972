import React, { useEffect, useState } from 'react';

interface ConnectionOauthSetupButtonProps {
    connection_id?: string;
    integration_name?: string;
    keyValueList?: any;
}

const INTEGRATIONS: any = {
    carta: {
        prodUrl: 'https://login.app.carta.com/o/authorize/',
        devUrl: 'https://login.playground.carta.team/o/authorize/',
        prodOauthId: 'WE4KI3EaTxtCOYeAMhN8Y1E4rRZt2oxM6KslP0JR',
        devOauthId: 'w3fB6Kz6G7mncMuHd4OGEcB3fDolGsF0B3ibQAw3',
        scope: 'openid profile email read_portfolio_info read_portfolio_fundinvestmentdocuments read_user_info',
    },
    addepar: {
        getUrl: (isProd: boolean, credentials: any) => {
            let baseUrl = credentials.addepar_api_endpoint || 'https://id.addepar.com';
            if (!baseUrl.startsWith('https://')) {
                baseUrl = 'https://' + baseUrl;
            }
            baseUrl = baseUrl.replace(/\/api\/*$/, '');
            baseUrl = baseUrl.replace(/([^:]\/)\/+/g, '$1');
            return `${baseUrl}/oauth2/authorize`;
        },
        prodOauthId: 'altexchange',
        devOauthId: 'altex_dev',
        scope: 'entities_write groups positions_write profile portfolio transactions_write',
        forceHttps: true,
    },
    // Add more integrations here
};

const ConnectionOauthSetupButton: React.FC<ConnectionOauthSetupButtonProps> = ({ connection_id, integration_name, keyValueList }) => {
    console.log('ConnectionOauthSetupButton', connection_id, integration_name, keyValueList);
    const [oauthUrl, setOauthUrl] = useState<string | null>(null);

    useEffect(() => {
        if (!connection_id || !integration_name) {
            return;
        }
        const credentials = keyValueList.reduce((res: any, kv: any) => ((res[kv.key] = kv.value), res), {});
        const integrationKey = integration_name.toLowerCase();
        const integration = INTEGRATIONS[integrationKey];
        if (!integration) {
            return;
        }

        const generateRandomState = () => {
            return Math.random().toString(36).substring(2);
        };

        const buildUrl = (integration: any, redirectUri: string, state: string) => {
            const isProd = window.location.hostname === 'app.altexchange.com';
            const oauthId = isProd ? integration.prodOauthId : integration.devOauthId;
            let baseUrl: string;

            if (integration.getUrl) {
                baseUrl = integration.getUrl(isProd, credentials);
            } else {
                baseUrl = isProd ? integration.prodUrl : integration.devUrl;
            }

            if (!oauthId || !baseUrl) {
                return null; // Cannot build URL without necessary data
            }

            let query = `?client_id=${oauthId}`;
            query += `&redirect_uri=${encodeURIComponent(redirectUri)}`;
            query += `&response_type=code&scope=${encodeURIComponent(integration.scope)}`;
            query += `&state=${state}`;
            return `${baseUrl}${query}`;
        };

        let redirectUri = `${window.location.origin}/oauth/${integrationKey}`;
        if (integration.forceHttps) {
            redirectUri = redirectUri.replace(/^http:/, 'https:');
        }
        const state = generateRandomState();
        const url = buildUrl(integration, redirectUri, state);

        if (!url) {
            return; // Cannot proceed without valid OAuth URL
        }

        // Save lookup info to local storage with prefix
        const localStorageKey = `connection_oauth_setup_${state}`;
        localStorage.setItem(localStorageKey, JSON.stringify({ connection_id, integration_name }));
        setOauthUrl(url);

        const handleBeforeUnload = () => {
            localStorage.removeItem(localStorageKey);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            localStorage.removeItem(localStorageKey);
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [connection_id, integration_name, keyValueList]);

    if (!connection_id || !integration_name || !oauthUrl) {
        return <></>;
    }

    return (
        <a href={oauthUrl} className="oauth-setup-button">
            Setup OAuth for {integration_name}
        </a>
    );
};

export default ConnectionOauthSetupButton;
