import React, { useEffect, useCallback } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import inputKey from '../../utilities/inputKey';

import '../../styles/modal.css';

interface ModalProps {
    content?: React.ReactNode;
    className?: string;
    modalProps: {
        noClose?: boolean;
        containerStyle?: React.CSSProperties;
    };
    closeOnEscape?: boolean;
    modal: (content: React.ReactNode, show: boolean, title?: string, props?: object, fullScreen?: boolean) => void;
    fullScreen?: boolean;
}

const Modal: React.FC<ModalProps> = ({ content, className, modalProps, closeOnEscape = true, modal, fullScreen }) => {
    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            inputKey(e, {
                Escape: () => (closeOnEscape ? closeModal() : null),
            });
        },
        [closeOnEscape]
    );

    const closeModal = () => modal(<></>, false, '', {}, fullScreen);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    const renderContent = () => {
        if (content) {
            return (
                <div className={className}>
                    {!modalProps.noClose && (
                        <div className="modal-back" onClick={() => modal(<></>, false, '', {}, fullScreen)}>
                            ← Back
                        </div>
                    )}
                    {content}
                </div>
            );
        } else return <></>;
    };

    return (
        <div className="modal-container" style={{ ...modalProps.containerStyle }}>
            <Scrollbars>{renderContent()}</Scrollbars>
        </div>
    );
};

export default Modal;
