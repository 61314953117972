import React from 'react';
import '../../styles/forbiddenpage.css';

const ForbiddenPage = () => {
    return (
        <div className="forbidden-container">
            <div className="forbidden-content">
                <h1 className="forbidden-title">403 Forbidden</h1>
                <p className="forbidden-message">Sorry, you are not authorized to perform this action.</p>
            </div>
        </div>
    );
};

export default ForbiddenPage;
