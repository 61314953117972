// export type UserAccess = 'admin' | 'advisor' | 'investor';

export enum ClientPageOptions {
    investments = 'investments',
    capitalCalls = 'capitalCalls',
    transactions = 'transactions',
    documents = 'documents',
    taxCenter = 'taxCenter',
    manageInvestments = 'manageInvestments',
    performance = 'performance',
    manageAccount = 'manageAccount',
}

export enum AdditionalClientPageOptions {}

export type InvestorPageOptions = ClientPageOptions | AdditionalClientPageOptions;
