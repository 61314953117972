export const reorderColumns = (column1: string, column2: string, columnOrder: string[]): string[] => {
    const index1 = columnOrder.indexOf(column1);
    const index2 = columnOrder.indexOf(column2);
    if (index1 === -1 || index2 === -1) {
        throw new Error('Invalid column provided');
    }
    const newColumnOrder = [...columnOrder];
    newColumnOrder[index1] = column2;
    newColumnOrder[index2] = column1;
    return newColumnOrder;
};
