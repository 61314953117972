import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import formatCurrency from '../../../utilities/format/formatCurrency';
import { UserPermissions } from '../../../utilities/AdvisorVue/permissions';

import '../../../styles/widget.css';
import '../../../styles/widget-InvestmentPerformanceSummary.css';
import { calcTotalValue, calcAdditionsWithdrawals } from '../../../utilities/calculate/financeCalculations';
import { EM_DASH } from '../../../constants/constantStrings';

import MostRecentValuation from '../MostRecentValuation';
import { Currency } from '../../../types/Currency';
import { Investment } from '../../../types/Investment';
import { formatUTCDate } from '../../../utilities/format/formatDate';
import IconTooltip from '../../Icons/IconTooltip';

interface InvestmentPerformanceSummaryProps extends RouteComponentProps {
    investment: Investment;
    title?: string;
    headerComponent?: React.ReactNode;
    containerStyle?: React.CSSProperties;
    openAddEditCommitmentDrawer?: (investment: Investment) => void;
    openAddEditUnrealizedValueDrawer?: (investment: Investment) => void;
}

const InvestmentPerformanceSummary: React.FC<InvestmentPerformanceSummaryProps> = (props) => {
    const [currentValuation, setCurrentValuation] = useState<{ amount: number; date: string } | null>(null);

    useEffect(() => {
        loadData();
    }, [props.investment]);

    const loadData = async () => {
        const i = props.investment;
        if (!i) return;
        // Load data logic here
    };

    const renderHedgeFundTable = (investment: Investment) => {
        const itd = investment?.performance?.itd;
        const gain = itd?.gain ?? 0;

        const additionsWithdrawals = calcAdditionsWithdrawals(investment);
        const gainLoss = gain;
        const currentValue = investment?.valuation ?? 0;

        const data = {
            invested: {
                name: 'Initial Investment',
                value: investment.initial_contribution ?? 0,
            },
            added: {
                name: 'Additions/Withdrawals',
                value: additionsWithdrawals,
            },
            gainLoss: {
                name: 'Gain/Loss',
                value: gainLoss,
            },
        };
        const currValue = {
            name: 'Current Value (Unrealized)',
            value: currentValue,
            icon: !UserPermissions().canUpdateAnyInvestments ? null : '/images/icons/edit.png',
            onIconClick: !UserPermissions().canUpdateAnyInvestments ? null : () => props.openAddEditUnrealizedValueDrawer?.(investment),
        };
        return renderTable(data, currValue);
    };

    const renderInvestedTable = () => {
        const i = props.investment;
        if (i?.type === 'Hedge Fund') {
            return renderHedgeFundTable(i);
        }

        const data = {
            committed: {
                name: 'Capital Committed',
                value: i.performance?.itd?.committed ?? 0,
                icon: !UserPermissions().canUpdateAnyInvestments ? null : '/images/icons/edit.png',
                onIconClick: !UserPermissions().canUpdateAnyInvestments ? null : () => props.openAddEditCommitmentDrawer?.(i),
            },
            called: {
                name: 'Capital Called',
                value: i?.performance?.itd?.contributions,
            },
            fees: {
                name: 'Fees',
                value: i?.performance?.itd?.fees,
            },
        };
        let totalVal = i?.performance?.itd?.amount_invested_to_date - i?.performance?.itd?.fees;
        const total = {
            name: 'Net Amount Invested',
            value: totalVal,
        };
        return renderTable(data, total);
    };

    const renderTable = (data: any, total: any) => {
        const p = props;
        return (
            <table>
                <tbody>
                    {Object.keys(data).map((key, keyIdx) => (
                        <tr key={'perfSummary' + keyIdx}>
                            <td style={{ display: 'flex', alignItems: 'center' }}>
                                {data[key].name}
                                {data[key].icon && (
                                    <img
                                        className="investment-performance-summary-icon pdfIgnore"
                                        src={data[key].icon}
                                        alt="table icon"
                                        onClick={data[key].onIconClick && UserPermissions().canUpdateAnyInvestments ? data[key].onIconClick : null}
                                    />
                                )}
                            </td>
                            <td
                                style={{
                                    color: data[key].value < 0 ? 'var(--color-dark-red)' : data[key].value > 0 ? 'var(--color-dark-green)' : 'var(--color-dark-grey)',
                                }}
                            >
                                {data[key].displayValue ?? (Number.isFinite(data[key].value) ? `${formatCurrency(data[key].value, p.investment?.currency as Currency)}` : EM_DASH)}
                            </td>
                        </tr>
                    ))}
                    <tr className="investment-performance-summary-divider" />
                    <tr>
                        <td style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                            {total.name}
                            {total.icon && (
                                <img
                                    src={total.icon}
                                    style={{ cursor: 'pointer', marginLeft: '15px' }}
                                    alt="icon"
                                    onClick={!UserPermissions().canUpdateAnyInvestments ? null : total.onIconClick}
                                />
                            )}
                        </td>
                        <td style={{ color: total.value < 0 ? 'var(--color-dark-red)' : total.value > 0 ? 'var(--color-dark-green)' : 'var(--color-dark-grey)' }}>
                            {total.displayValue ?? (Number.isFinite(total.value) ? `${formatCurrency(total.value, p.investment?.currency as Currency)}` : EM_DASH)}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const renderGainLossTable = () => {
        const i = props.investment;
        const data = {
            mostRecentValuation: {
                name: `Last Reported Value${currentValuation?.date ? ` (as of ${formatUTCDate(currentValuation.date, 'MM/DD/YYYY')})` : ''}`,
                displayValue: (
                    <MostRecentValuation
                        investmentId={i._id}
                        currency={i?.currency}
                        shouldDisplayDate={false}
                        onValuationFetched={(newValuation) => {
                            if (!currentValuation || currentValuation.amount !== newValuation.amount || currentValuation.date !== newValuation.date) {
                                setCurrentValuation(newValuation);
                            }
                        }}
                    />
                ),
            },
            unrealizedValue: {
                name: (
                    <span style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        Current Value
                        <IconTooltip text={"This is the latest valuation we've received plus any capital calls and minus principal distributions."} />
                    </span>
                ),
                value: i?.valuation,
            },
            realizedValue: {
                name: (
                    <span className="">
                        Realized Value <span style={{ fontSize: '14px' }}>(Distributions)</span>
                    </span>
                ),
                value: i?.performance?.itd?.distributions,
            },
            totalValue: {
                name: 'Total Value',
                value: calcTotalValue(i),
            },
        };
        const total = {
            name: 'Gain / Loss',
            value: i?.performance?.itd?.gain,
        };
        return renderTable(data, total);
    };

    return (
        <div className="widget" style={{ ...props.containerStyle }}>
            <div className="widget-flex-header">
                <div className="investment-performance-summary-header">{props.title ?? 'Investment Performance'}</div>
                {props.headerComponent ?? <></>}
            </div>
            <div className={props?.investment?.type === 'Hedge Fund' ? 'full-width-performance-summary-table' : 'investment-performance-summary-tables'}>
                {renderInvestedTable()}
                {props?.investment?.type !== 'Hedge Fund' && renderGainLossTable()}
            </div>
        </div>
    );
};

export default withRouter(InvestmentPerformanceSummary);
