import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';

// get investment date in this order
// 1. first_cashflow_date
// 2. started_at
// 3. created_at
export const getInvestmentDate = (investment) => {
    let date = investment?.first_cashflow_date
        ? dateToUTCMidnight(investment.first_cashflow_date)
        : investment?.started_at
          ? dateToUTCMidnight(investment?.started_at)
          : investment?.created_at
            ? dateToUTCMidnight(investment?.created_at)
            : null;
    return date;
};
