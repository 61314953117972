export enum ConnectionStatus {
    UnderReview = 'Under Review',
    Pending = 'Pending',
    Importing = 'Importing',
    Authenticating = 'Authenticating',
    Syncing = 'Syncing',
    Active = 'Active',
    Error = 'Error',
}

export interface Connection {
    integration?: string;
    name: string;
    ral_requests?: string[];
    asset_manager?: string;
    user?: string;
    status: ConnectionStatus;
    ral_status: string;
    outreach_status: string;
    outreach_status_updated_at?: string;
    error?: {
        user: string;
        message: string;
        traceback: string;
    };
    error_information?: {
        error_job_id: string;
        error_job_type: 'import' | 'worker';
        error_code: string;
    };
    credentials?: Record<string, string>;
    tfa_email?: string;
    tfa_phone?: string;
    require_phone: boolean;
    newest_data_at?: string;
    contacts?: string[];
    last_connection_at?: string;
    use_tfa: boolean;
    is_enabled: boolean;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
}
