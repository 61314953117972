import React from 'react';

import '../../styles/visibilityIcon.css';

export const VisibilityIcon = (props) => {
    return (
        <img
            className="visibility-icon-image"
            style={{ position: 'absolute', right: '10px', cursor: 'pointer', ...props.style }}
            src={`/images/icons/eye${props.show ? '_filled' : ''}.png`}
            alt={`${props.show ? 'Hide' : 'Show'} Password`}
            title={`${props.show ? 'Hide' : 'Show'} Password`}
            onClick={props.toggleShow}
        />
    );
};
