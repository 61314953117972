// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

export default {
    emptyText: (addInvestmentFunc) => (
        <div style={{ width: '400px', color: 'white' }}>
            Get a consolidated view of all your investments.
            <button
                style={{
                    fontSize: '18px',
                    width: '265px',
                    height: '46px',
                    marginTop: '30px',
                }}
                onClick={addInvestmentFunc}
            >
                Add First Investment
            </button>
        </div>
    ),
    textStyle: {
        fontSize: '24px',
    },
    containerStyle: {
        left: 0,
        width: '100%',
        height: '100%',
        top: '-40px',
    },
    props: {
        investments: [
            {
                performance: {
                    itd: {
                        appreciation: 0,
                        contributions: 6400000,
                        distributions: 8128000,
                        other: 0,
                        irr: 0.1552765559462761,
                    },
                    ytd: {
                        appreciation: 0,
                        contributions: 0,
                        distributions: 0,
                        other: 0,
                        irr: 0,
                    },
                },
                _id: '61fb18e2e640fff4451cec63',
                type: 'Real Estate',
                quarterly: [],
                cashflow: [],
                valuation: 6400000,
                committed: [
                    {
                        date: '2021-01-01T07:00:00.000Z',
                        amount: 6400000,
                        _id: '61fb4a560a0348a5a56e5b19',
                    },
                ],
                name: 'IEQ Capital Access Fund - Kennedy Wilson Real Estate VI, L.P.',
                account: '61e86533098655f6ff0e71a5',
            },
            {
                performance: {
                    itd: {
                        appreciation: 0,
                        contributions: 800000,
                        distributions: 920000,
                        other: 0,
                        irr: 0.037544993139428495,
                    },
                    ytd: {
                        appreciation: 0,
                        contributions: 0,
                        distributions: 0,
                        other: 0,
                        irr: 0,
                    },
                },
                _id: '61fb347415601868ae99e416',
                type: 'Private Equity',
                quarterly: [],
                cashflow: [],
                valuation: 2000000,
                committed: [
                    {
                        date: '2021-04-01T07:00:00.000Z',
                        amount: 2000000,
                        _id: '61fb348d15601868ae99e43c',
                    },
                ],
                name: 'IEQ Capital Access Fund - Prime Special Situations 2020 Fund, LP',
                account: '61e86533098655f6ff0e71a5',
            },
            {
                performance: {
                    itd: {
                        appreciation: 0,
                        contributions: 400000,
                        distributions: 460000,
                        other: 0,
                        irr: 0.13670233608191018,
                    },
                    ytd: {
                        appreciation: 0,
                        contributions: 0,
                        distributions: 0,
                        other: 0,
                        irr: 0,
                    },
                },
                _id: '61fb5c966abd432909751095',
                type: 'Private Debt',
                quarterly: [],
                cashflow: [],
                valuation: 1000000,
                committed: [
                    {
                        date: '2018-10-01T07:00:00.000Z',
                        amount: 1000000,
                        _id: '61fb5d1e6abd4329097512ea',
                    },
                ],
                name: 'Oak Hill Capital II',
            },
            {
                performance: {
                    itd: {
                        appreciation: 0,
                        contributions: 400000,
                        distributions: 460000,
                        other: 0,
                        irr: 0.13670233608191018,
                    },
                    ytd: {
                        appreciation: 0,
                        contributions: 0,
                        distributions: 0,
                        other: 0,
                        irr: 0,
                    },
                },
                _id: '61fb5c966abd432909751095',
                type: 'Private Debt',
                quarterly: [],
                cashflow: [],
                valuation: 1000000,
                committed: [
                    {
                        date: '2018-10-01T07:00:00.000Z',
                        amount: 1000000,
                        _id: '61fb5d1e6abd4329097512ea',
                    },
                ],
                name: 'Leazar Capital IV',
            },
        ],
    },
};
