// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

export default {
    emptyText: 'Track your commitments over time.',
    textStyle: {
        fontSize: '16px',
    },
    props: {
        data: {
            'Total Commitment': [
                { month: 9, year: 2021, value: 4500000, investments: {} },
                { month: 10, year: 2021, value: 5800000, investments: {} },
                { month: 11, year: 2021, value: 5800000, investments: {} },
                { month: 12, year: 2021, value: 8500000, investments: {} },
                { month: 1, year: 2022, value: 8500000, investments: {} },
                { month: 2, year: 2022, value: 9300000, investments: {} },
            ],
        },
    },
};
