export const PDF_EXPORT_ELEMENT_STATUS = {
    NOT_STARTED: 'notStarted',
    LOADING: 'loading',
    READY: 'ready',
    DONE: 'done',
    ERROR: 'error',
};

export const STATUS_COLOR_MAP = {
    [PDF_EXPORT_ELEMENT_STATUS.NOT_STARTED]: 'var(--color-gray)',
    [PDF_EXPORT_ELEMENT_STATUS.LOADING]: 'var(--color-dark-blue)',
    [PDF_EXPORT_ELEMENT_STATUS.READY]: 'var(--color-green)',
    [PDF_EXPORT_ELEMENT_STATUS.DONE]: 'var(--color-dark-green)',
    [PDF_EXPORT_ELEMENT_STATUS.ERROR]: 'var(--color-dark-red)',
};
