class ErrorGroup extends Error {
    errors: any[]; // Add this line to declare the 'errors' property
    constructor(errors: any[], message = 'Multiple errors occurred') {
        super(message);
        this.errors = errors;
        this.name = 'ErrorGroup';
    }
}

export default ErrorGroup;
