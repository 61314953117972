import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ClientDonutChart } from './ClientDonutChart';
import { ClientListStatus } from './ClientListStatus';
import { getClientStatusColor } from '../../utilities/AdvisorVue/getClientStatusColor';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import DocumentsCollected from '../Widgets/documents/DocumentsCollected2';

import '../../styles/clientCard.css';

interface Client {
    _id: string;
    name?: string;
    email?: string;
    receiver?: string;
    status?: string;
    isInvite?: boolean;
    isConnectionRequest?: boolean;
    investments_count?: number;
    callsDue?: number;
    valuationByInvestmentType?: Record<string, { amount: number; investments: number[] }>;
    currentAdvisor?: {
        invitationId: string;
    };
}

interface User {
    advisor_group_memberships?: { group: string }[];
    group?: string;
}

interface ClientCardProps extends RouteComponentProps {
    client?: Client;
    user: User;
    idx: number;
    width?: string;
    height?: string;
    style?: React.CSSProperties;
    showTaxView?: boolean;
    viewSettings: (client: Client) => void;
    sendInvite: (email: string, name: string, resend: boolean, groupId?: string) => void;
    declineInvite: (invitationId: string) => void;
    acceptInvite: (invitationId: string, groupId?: string) => void;
}

const ClientCard: React.FC<ClientCardProps> = (props) => {
    const getCallsDueColor = (callsDue?: number) => (callsDue && callsDue > 0 ? 'var(--color-dark-red)' : '#969696');

    const getClient = () => (!props.client ? null : { ...props.client, status: props.client.status ?? 'Invite Pending' });
    const client = getClient();

    if (!client) return null;

    let showTaxView =
        !UserPermissions().canReadCapitalCalls && !UserPermissions().canReadAnyInvestments && UserPermissions().canReadDocuments && !client.isInvite && !client.isConnectionRequest;

    if (props.showTaxView) {
        showTaxView = true;
    }

    const renderCountData = (client: Client) => {
        if (showTaxView && !client.isConnectionRequest && !client.isInvite) {
            return (
                <div className="clientCard-documents">
                    <DocumentsCollected userId={client._id} small={true} investments={[]} forceUpdate={false} vue={''} history={props.history} account={null} />
                </div>
            );
        }
        return (
            <div className="clientCard-countData">
                {UserPermissions().canReadCapitalCalls && !client.isConnectionRequest && (
                    <div className="clientCard-callsDue">
                        <div style={{ backgroundColor: getCallsDueColor(client.callsDue) }}>{client.callsDue}</div>
                        <div>capital calls due</div>
                    </div>
                )}
                {UserPermissions().canReadAnyInvestments && (
                    <div className="clientCard-investments">
                        <div>{client.investments_count ?? '-'}</div>
                        <div>investments</div>
                    </div>
                )}
            </div>
        );
    };

    const renderSettingsIcon = (client: Client) => (
        <img
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                props.viewSettings(client);
            }}
            className="clientCard-settings"
            src="/images/icons/settings.png"
            alt="settings"
            title="Settings"
            style={{ zIndex: 100 }}
        />
    );

    const truncate = (input: string, len: number) => (input.length > len ? `${input.substring(0, len)}...` : input);

    const renderName = (client: Client) => {
        const name = client.name ?? truncate(client.receiver!, 25);
        return (
            <div className="clientCard-name table-ellipses-3-line" title={name}>
                {name}
            </div>
        );
    };

    const renderButton = (text: string, color: string, fn: () => void) => (
        <div
            className="clientCard-optionsButton"
            style={{ color }}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                fn();
            }}
        >
            {text}
        </div>
    );

    const renderOptions = (client: Client) => {
        const groupId = props.user.advisor_group_memberships?.[0]?.group;
        return (
            <div className="clientCard-options">
                {client.status === 'Invite Pending' && client.isInvite ? (
                    renderButton('Resend Invite', 'var(--color-link)', () => props.sendInvite(client.email ?? client.receiver!, client.name!, true, groupId))
                ) : client.isConnectionRequest ? (
                    <>
                        {renderButton('Decline', 'var(--color-dark-red)', () => props.declineInvite(client.currentAdvisor?.invitationId!))}
                        {renderButton('Accept', 'var(--color-link)', () => props.acceptInvite(client.currentAdvisor?.invitationId!, groupId))}
                    </>
                ) : client.status === 'Deactivated' ? (
                    renderButton('Invite', 'var(--color-link)', () => props.sendInvite(client.email ?? client.receiver!, client.name!, true))
                ) : null}
            </div>
        );
    };

    const canClickAndViewClient = ['Active', 'Staging'].includes(client.status!);

    const getStyle = (
        client: Client
    ): React.CSSProperties & {
        '--clientCard-statusColor': string;
        '--clientCard-backgroundColor': string;
    } => {
        const { width, height, style } = props;

        const clientStyle = {
            ...style,
            '--clientCard-width': width ?? '340px',
            '--clientCard-height': height ?? '230px',
            '--clientCard-statusColor': getClientStatusColor(client) ?? 'rgba(0,0,0,0)',
            '--clientCard-backgroundColor': client.isConnectionRequest ? 'var(--color-light-separator)' : '#fbfbfb',
        } as React.CSSProperties & {
            '--clientCard-statusColor': string;
            '--clientCard-backgroundColor': string;
        };

        if (canClickAndViewClient) {
            clientStyle.cursor = 'pointer';
        }

        return clientStyle;
    };

    // Transform the valuationByInvestmentType object into an array that matches the expected structure
    const valuationByInvestmentTypeArray = client.valuationByInvestmentType
        ? Object.keys(client.valuationByInvestmentType).map((key) => ({
              name: key,
              amount: client.valuationByInvestmentType![key].amount,
              investments: client.valuationByInvestmentType![key].investments,
          }))
        : [];

    return (
        <div
            key={`clientCard${props.idx}`}
            className="clientCard-component"
            style={getStyle(client)}
            onClick={canClickAndViewClient ? () => props.history.push(`/advisor/clients/${client._id}`) : undefined}
        >
            {renderName(client)}
            {renderSettingsIcon(client)}
            {renderOptions(client)}

            <div className="clientCard-info">
                <ClientListStatus client={client} />
            </div>

            <div className={`clientCard-donut${showTaxView && !client.isConnectionRequest && !client.isInvite ? '-low' : ''}`}>
                <ClientDonutChart amountsByType={valuationByInvestmentTypeArray} blank={client.status === 'Invite Pending'} />
            </div>

            {client.status !== 'Invite Pending' && renderCountData(client)}

            {client.isConnectionRequest && <div className="clientCard-message">has invited you to view their investments.</div>}
        </div>
    );
};

export default withRouter(ClientCard);
