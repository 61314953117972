import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import '../../styles/widget.css';
import '../../styles/widget-CapitalCalls.css';
import formatCurrency from '../../utilities/format/formatCurrency';
import EmptyOverlay from '../EmptyStates/EmptyOverlay';
import emptyStateData from '../EmptyStates/emptyStateFakeData';
import dateToUTCMidnight from '../../utilities/date/dateToUTCMidnight';
import { getNetType } from '../../utilities/netTransactionUtils';
import { calculateNetAmountFromTransaction } from '../../utilities/calculate/calculateNetAmount';
import api2 from '../../api2';
import { Investment } from '../../types/Investment'; // Assuming these types are defined

interface Transaction {
    _id: string;
    date: string;
    amount: number;
    open: boolean;
    investment: string | Investment;
    joint_transaction?: Transaction;
}

interface CapitalCallsWidgetParams {
    account: string;
}

interface CapitalCallsWidgetProps extends RouteComponentProps<CapitalCallsWidgetParams> {
    investments: Investment[];
    showEmptyState: boolean;
    updateCallsDue: (callsDue: Transaction[]) => void;
    viewInvestment: (investmentId: string) => void;
}

const CapitalCallsWidget: React.FC<CapitalCallsWidgetProps> = (props) => {
    const [callsDue, setCallsDue] = useState<Transaction[]>([]);

    useEffect(() => {
        loadData();
    }, [props.investments]);

    const loadData = async () => {
        try {
            const response = await api2.client.TransactionApi.listTransactions({
                types: ['capital_call'],
                open: true,
                sort_field: 'date',
                sort_direction: 'desc',
                populate_investment: true,
                investments: props.investments.map((i) => i._id),
            });
            console.log('API response:', response);
            const callsDue = response.data.transactions as Transaction[];
            console.log('Calls due:', callsDue);
            if (callsDue) {
                setCallsDue(callsDue);
            }
        } catch (error) {
            console.error('Error loading data:', error);
        }
    };

    const displayDate = (dateStr: string) => {
        const date = dateToUTCMidnight(dateStr);
        const nowUtc = dateToUTCMidnight();
        if ((date as Date).getTime() < (nowUtc as Date).getTime()) return 'Past Due';
        if ((date as Date).getTime() < (nowUtc as Date).getTime() + 24 * 60 * 60 * 1000) return 'Today';
        if ((date as Date).getTime() < (nowUtc as Date).getTime() + 48 * 60 * 60 * 1000) return 'Tomorrow';
        return null;
    };

    const updateMetStatus = async (id: string, met: boolean) => {
        let updatedCallsDue = [...callsDue];
        const foundIdx = updatedCallsDue.findIndex((call) => call._id === id);
        updatedCallsDue[foundIdx].open = !met;
        props.updateCallsDue(updatedCallsDue);

        try {
            await api2.client.TransactionApi.updateTransaction({
                transaction_id: id,
                UpdateTransactionRequest: { open: !met },
            });
        } catch (err) {
            console.error('Error updating met status of capital call.');
        }
    };

    const renderCallNotice = (call: Transaction, idx: number) => {
        let investment = call.investment;

        if (props.showEmptyState) {
            // get investments from the fake data if props.showEmptyState is true
            const investments = emptyStateData.CapitalCallsWidget.props.investments;
            if (typeof call.investment === 'string') {
                if (typeof call.investment === 'string') {
                    investment = investments?.find((i) => i._id.toString() === call.investment) as unknown as Investment;
                }
            }
        }

        if (!investment) return null;

        const displayDateStr = displayDate(call.date);

        let amount = call.amount;
        if (amount < 0) return null;

        let extraMessage = '';
        if (call.joint_transaction) {
            const netType = getNetType(call.joint_transaction);
            const netAmount = calculateNetAmountFromTransaction(call.joint_transaction);
            if (typeof investment !== 'string') {
                extraMessage += `(from ${netType} with total of ${formatCurrency(netAmount, investment?.currency)})`;
            }
        }

        return (
            <React.Fragment key={'noticeRow' + idx}>
                <div className="callNotices_row">
                    <div className="callNotices_row_metContainer">
                        <div className={'callNotices_row_metCircle'} onClick={() => updateMetStatus(call._id, call.open)}>
                            {!call.open && (
                                <div className="callNotices_row_metCircleCheckContainer">
                                    <div className="callNotices_row_metCircleCheck" />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="callNotices_row_info">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {`${formatCurrency(amount, (investment as Investment)?.currency)} due`}
                            <span
                                style={{
                                    fontSize: '12px',
                                    marginLeft: '10px',
                                }}
                            >
                                {extraMessage}
                            </span>
                        </div>
                        <div onClick={() => props.viewInvestment((investment as Investment)._id)}>{(investment as Investment).name}</div>
                    </div>
                    <div
                        className={
                            'callNotices_row_dueDate' +
                            (displayDateStr ? ' callNotices_row_dueDateHighlight' : '') +
                            (displayDateStr === 'Past Due' ? ' callNotices_row_dueDatePast' : '')
                        }
                    >
                        {displayDateStr ?? moment(call.date).format('MMM Do')}
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const callsDueData = props.showEmptyState ? emptyStateData.CapitalCallsWidget.props.callsDue.map((call: any) => ({ ...call, _id: call._id.toString() })) : callsDue;

    if (!callsDueData) return <></>;

    return (
        <EmptyOverlay isEmpty={props.showEmptyState} emptyText={emptyStateData.CapitalCallsWidget.emptyText} textStyle={emptyStateData.CapitalCallsWidget.textStyle}>
            <div className="widget callNotices_component">
                <div className="callNotices_header">
                    {callsDueData.length > 0 && (
                        <div className="callNotices_headerText">
                            You have {callsDueData.length} capital call notice{callsDueData.length > 1 ? 's' : ''}
                        </div>
                    )}
                    {callsDueData.length === 0 && <div className="callNotices_headerText">You have no capital calls due.</div>}
                    {callsDueData.length > 0 && (
                        <div className="callNotices_headerSeeAll">
                            <span onClick={() => props.history.push(`/accounts/${props.match.params.account}/calls`)}>see all</span>
                        </div>
                    )}
                </div>

                {callsDueData.length !== 0 && (
                    <div style={{ marginLeft: '0px' }} className="callNotices_met">
                        Unfunded
                    </div>
                )}

                {callsDueData.map(renderCallNotice)}
            </div>
        </EmptyOverlay>
    );
};

export default withRouter(CapitalCallsWidget);
