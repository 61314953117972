import React from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import Papa from 'papaparse';

import FloatingButton from '../../components/Buttons/FloatingButton';
import ExcelViewer from './ExcelViewer';
import { isExcelBlob } from '../../utilities/documents/isExcelBlob';
import api2 from '../../api2';
import { isPdfBlob } from '../../utilities/documents/isPdfBlob';

const MIME_EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const MIME_PDF = 'application/pdf';
const MIME_CSV = 'text/csv';

class DocumentLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileData: null,
            fileType: null,
            isLoadingFile: false,
        };
    }

    componentDidMount() {
        this.downloadPdf();
        this.setPdfFromBlob();
    }

    componentDidUpdate(prevProps) {
        if (this.props.doc?._id !== prevProps.doc?._id) {
            this.setState({ fileData: null });
        }
        // console.log("DocumentLoader componentDidUpdate", this.props, prevProps);
        if (this.props.fileBlob !== prevProps.fileBlob) {
            this.setPdfFromBlob();
        }
        if (this.props.doc?.file_download_urls?.doc !== prevProps.doc?.file_download_urls?.doc) {
            this.downloadPdf();
        }
    }

    setPdfFromBlob = async () => {
        if (!this.props.fileBlob) return;
        const fileType = (await isPdfBlob(this.props.fileBlob)) ? MIME_PDF : (await this.isExcel(this.props.fileBlob)) ? MIME_EXCEL : MIME_CSV;

        let parsedCsv;
        if (fileType === MIME_CSV) {
            let csvText = await this.blobToText(this.props.fileBlob);
            parsedCsv = await this.parseCSV(csvText);
        }

        this.setState({ fileBlob: this.props.fileBlob, fileData: URL.createObjectURL(this.props.fileBlob), fileType, parsedCsv });
    };

    parseCSV = (csvString) => {
        return new Promise((resolve, reject) => {
            Papa.parse(csvString, {
                header: true,
                complete: (result) => {
                    const data = result.data.filter((row) => {
                        return Object.keys(row).some((key) => row[key]);
                    });
                    resolve(data);
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    };

    ensureExcelFile = async (blob) => {
        const doc = this.props.doc;
        const isExcel = await isExcelBlob(blob);
        if (!doc || !isExcel) return;
        const update = {};
        if (!doc.name.endsWith('.xlsx')) {
            update.name = doc.name.replace(/\.[^/.]+$/, '.xlsx');
            if (!update.name.endsWith('.xlsx')) {
                update.name += '.xlsx';
            }
        }
        if (doc.file_type !== 'xlsx') {
            update.file_type = 'xlsx';
        }

        if (Object.keys(update).length) {
            const shouldUpdate = window.confirm('This file is not an Excel file. Would you like to convert it to an Excel file?');

            if (shouldUpdate) {
                try {
                    await api2.client.DocumentApi.updateDocument({
                        document_id: doc._id,
                        UpdateDocumentRequest: update,
                    });
                    window.location.reload();
                } catch (error) {
                    console.error('Error updating document', error);
                }
            }
        }
    };

    isExcel = async (blob) => {
        const isExcel = await isExcelBlob(blob);

        if (isExcel) {
            this.ensureExcelFile(blob);
        }

        // window.location.reload();
        return isExcel;
    };

    downloadPdf = async () => {
        if (!this.props.doc?.file_download_urls?.doc) {
            return;
        }
        try {
            this.setState({ isLoadingFile: true });
            const response = await axios.get(this.props.doc?.file_download_urls?.doc, { responseType: 'blob' });
            let fileBlob = new Blob([response.data]);
            const fileType = (await isPdfBlob(fileBlob)) ? MIME_PDF : (await this.isExcel(fileBlob)) ? MIME_EXCEL : MIME_CSV;

            fileBlob = new Blob([response.data], { type: fileType });
            const url = URL.createObjectURL(fileBlob);
            console.log('DocumentLoader downloadPdf', url, fileType, fileBlob, response.data);

            let parsedCsv;
            if (fileType === MIME_CSV) {
                let csvText = await this.blobToText(fileBlob);
                parsedCsv = await this.parseCSV(csvText);
            }

            // console.log("Downloaded pdf file", this.props.doc);
            this.setState({ fileBlob, fileData: url, parsedCsv, isLoadingFile: false, fileType });
        } catch (error) {
            console.error('Error downloading the pdf file', error);
            this.setState({ isLoadingFile: false });
        }
    };

    renderFloatingButtons = () => {
        const BUTTON_SPACING = 20;
        return (
            <>
                {/* Previous doc button */}
                {this.props.goToPreviousDoc && this.props.previousAndNextDocIds?.previous && (
                    <FloatingButton
                        children={'<'}
                        containerStyle={{
                            position: 'absolute',
                            left: BUTTON_SPACING,
                            top: '50%',
                        }}
                        buttonStyle={{
                            fontSize: '2.5rem',
                        }}
                        onClick={this.props.goToPreviousDoc}
                        disabled={this.state.isLoadingFile}
                    />
                )}
                {/* Next doc button */}
                {this.props.goToNextDoc && this.props.previousAndNextDocIds?.next && (
                    <FloatingButton
                        children={'>'}
                        containerStyle={{
                            position: 'absolute',
                            right: BUTTON_SPACING,
                            top: '50%',
                        }}
                        buttonStyle={{
                            fontSize: '2.5rem',
                        }}
                        onClick={this.props.goToNextDoc}
                        disabled={this.state.isLoadingFile}
                    />
                )}
            </>
        );
    };

    blobToText = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsText(blob);
        });
    };

    renderCsv = () => {
        if (!this.state.parsedCsv) return null;

        if (this.state.parsedCsv.length === 0) return 'Error parsing CSV file';

        const keys = Object.keys(this.state.parsedCsv[0]).filter((key) => key);

        const thTdStyles = {
            border: '1px solid black',
            padding: '8px',
            textAlign: 'left',
            // width: `${100 / keys.length}%`,
        };

        return (
            <table
                style={{
                    width: '100%',
                    borderCollapse: 'collapse',
                    tableLayout: 'fixed',
                }}
            >
                <thead>
                    <tr style={{ ...thTdStyles, backgroundColor: '#f2f2f2' }}>
                        {keys.map((key, index) => (
                            <th key={index}>{key}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {this.state.parsedCsv.map((row, index) => (
                        <tr key={index}>
                            {keys.map((key, i) => (
                                <td key={i} style={{ ...thTdStyles }}>
                                    {row[key]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    render() {
        const text_styles = {
            width: '100%',
            height: 'calc(100vh - 80px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'auto',
            // fontSize: '2rem',
        };

        return (
            <>
                {this.state.isLoadingFile ? (
                    <div style={text_styles}>Loading...</div>
                ) : !this.state.fileData ? (
                    <div style={text_styles}>This document file could not be loaded</div>
                ) : this.state.fileType === MIME_PDF ? (
                    <object
                        data={this.state.fileData}
                        type={this.state.fileType}
                        style={{
                            width: '100%',
                            height: 'calc(100vh - 80px)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            ...text_styles,
                        }}
                    >
                        {/* <p style={text_styles}> */}
                        You don't have a required viewer plugin for this browser. You can&nbsp;
                        <a href={this.state.fileData}>click here to download the file instead.</a>
                        {/* </p> */}
                    </object>
                ) : this.state.fileType === MIME_CSV ? (
                    this.renderCsv()
                ) : this.state.fileType === MIME_EXCEL ? (
                    <ExcelViewer fileBlob={this.state.fileBlob} />
                ) : (
                    <div>Not Supported</div>
                )}
                {this.renderFloatingButtons()}
            </>
        );
    }
}

export default DocumentLoader;
