export const parseCsvLine = (line: string): string[] => {
    line = line.replace(/"""/g, (match, offset, string) => {
        // Check for a comma before the triple quotes possibly with spaces in between
        if (string.substring(0, offset).trimEnd().endsWith(',')) {
            return '"<DOUBLE_QUOTE>';
        }
        // Check for a comma after the triple quotes possibly with spaces in between
        else if (
            string
                .substring(offset + 3)
                .trimStart()
                .startsWith(',')
        ) {
            return '<DOUBLE_QUOTE>"';
        } else if (string.substring(0, offset).trim().length === 0) {
            return '"<DOUBLE_QUOTE>';
        }
        return match;
    });

    line = line.replace(/""/g, '<DOUBLE_QUOTE>');

    const lineSplit = line.split(',');

    let lineSplitAccountingForQuotes = [] as string[];
    let currentField = '';

    for (let i = 0; i < lineSplit.length; i++) {
        currentField = lineSplit[i];

        currentField = currentField.trimStart();

        if (currentField.startsWith('"')) {
            let addedTermCount = 0;
            let foundEndQuote = false;
            let addToCurrentField = '';

            let endTrimmedCurrentField = currentField.trimEnd();

            if (endTrimmedCurrentField.endsWith('"')) {
                foundEndQuote = true;
                currentField = currentField.trim();
                currentField = currentField.slice(1, currentField.length - 1); // remove the quotes
                lineSplitAccountingForQuotes.push(currentField);
                continue;
            }

            for (let j = i + 1; j < lineSplit.length; j++) {
                let nextField = lineSplit[j];
                let endTrimmedNextField = nextField.trimEnd();
                if (endTrimmedNextField.endsWith('"')) {
                    foundEndQuote = true;
                    addToCurrentField += `,${endTrimmedNextField}`;
                    addedTermCount++;
                    break;
                } else {
                    addToCurrentField += `,${nextField}`;
                    addedTermCount++;
                }
            }
            if (foundEndQuote) {
                currentField = currentField + addToCurrentField;
                currentField = currentField.trim();
                currentField = currentField.slice(1, currentField.length - 1); // remove the quotes
                i += addedTermCount;
            }

            lineSplitAccountingForQuotes.push(currentField);
        } else {
            lineSplitAccountingForQuotes.push(currentField.trim());
        }
    }

    lineSplitAccountingForQuotes = lineSplitAccountingForQuotes.map((field) => field.replace(/<DOUBLE_QUOTE>/g, '"'));

    return lineSplitAccountingForQuotes;
};
