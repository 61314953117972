import axios from 'axios';

const uploadBlob = async (uploadUrl, blob, contentType, onUploadProgress = null, signal = null) => {
    if (!uploadUrl) {
        throw new Error('uploadUrl is required');
    }

    if (!blob) {
        throw new Error('blob is required');
    }

    if (!contentType) {
        throw new Error('contentType is required');
    }

    // upload doc to stax
    const awsUploadRes = await axios.put(uploadUrl, blob, {
        headers: {
            'Content-Type': contentType,
        },
        onUploadProgress,
        signal,
    });

    return awsUploadRes;
};

export default uploadBlob;
