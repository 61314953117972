import React from 'react';

class DealsIFrame extends React.Component {
    constructor(props) {
        super(props);
        this.iframeRef = React.createRef();
        this.handleTrackingEvent = this.handleTrackingEvent.bind(this);
    }

    componentDidMount() {
        window.addEventListener('message', this.handleTrackingEvent);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleTrackingEvent);
    }

    handleTrackingEvent(event) {
        if (event.data.type === 'linkClick') {
            // Call class method to handle event
            this.props.handleEvent(event.data);
        }
    }

    render() {
        return <iframe ref={this.iframeRef} src="https://www.altexchange.com/bsQUU0jLyon4a3la461HoXNo8ukSGw7h" width={'100%'} height={'100%'} style={{ border: 'none' }} />;
    }
}

export default DealsIFrame;
