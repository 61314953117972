export const getUserStatusColor = (status) => {
    switch (status.toLowerCase()) {
        case 'active':
            return 'var(--color-green)';
        case 'staging':
            return 'var(--color-yellow)';
        case 'pending':
            return 'var(--color-orange)';
        case 'invite pending':
            return 'var(--altx-orange)';
        case 'deactivated':
            return 'var(--color-dark-red)';
        case 'error':
            return 'var(--color-dark-red)';
        default:
            return '#434343';
    }
};
