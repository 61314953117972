import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import '../../styles/widget.css';
import '../../styles/capitalCalledCircle.css';
import formatCurrency from '../../utilities/format/formatCurrency';
import { Currency } from '../../types/Currency';

interface CapitalCalledCircleProps extends RouteComponentProps {
    committedAmount: number;
    calledAmount: number;
    currency: Currency;
    size?: number;
}

const CapitalCalledCircle: React.FC<CapitalCalledCircleProps> = (props) => {
    const renderCircle = () => {
        return (
            <div className="cccCircleContainer">
                <div className="cccCircle" />
                <div className="cccCircleFilled" />
                <div className="cccCircleText" style={{ zIndex: 50000 }}>
                    <div>{formatCurrency(props.committedAmount, props.currency)}</div>
                    <div>Total Committed</div>
                </div>
            </div>
        );
    };

    const renderLabel = () => {
        return (
            <div className="cccLabelContainer">
                <div className="cccLabelLine" />
                <div className="cccLabelText">
                    <div>{formatCurrency(props.calledAmount, props.currency)}</div>
                    <div>total called</div>
                </div>
            </div>
        );
    };

    return (
        <div
            className="widget cccComponent"
            style={
                {
                    '--cccCircle-size': props.size ? `${props.size}px` : '250px',
                    '--cccPercent': `${!props.calledAmount ? 0 : Math.max(0, Math.min((100 * props.calledAmount) / props.committedAmount, 100))}%`,
                } as React.CSSProperties
            }
        >
            {renderCircle()}
            {renderLabel()}
        </div>
    );
};

export default withRouter(CapitalCalledCircle);
