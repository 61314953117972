import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faBars, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
// import _ from 'lodash';

import { VUES } from '../constants/constantStrings';
import { UserPermissions } from '../utilities/AdvisorVue/permissions';
import AddEditAdvisorDrawer from './Drawers/AddEditAdvisorDrawer';
import AddEditUserDrawer from './Drawers/AddEditUserDrawer';
import api from '../api';
import api2 from '../api2';
import { openBulkUploaderManager } from './Uploaders/BulkUploader/BulkUploaderManager/BulkUploaderManager';
import { GroupBase } from 'react-select';

import '../styles/sidebar.css';

interface SidebarProps {
    user: {
        _id: string;
        access: string;
        name: string;
        email: string;
    };
    match: {
        params: {
            account: string;
        };
    };
    accounts: any[];
    advisorClients: any[];
    history: any;
    loading: (time?: number, key?: string) => void;
    loaded: (key?: string) => void;
    setAlert: (alert: any) => void;
    openDrawer: (content: () => JSX.Element) => void;
    closeDrawer: () => void;
    closeNotification: () => void;
    modal: any;
    sidebarOpen: boolean;
    toggleSidebar: () => void;
    forceSidebarAddInvestmentMenuOpen: boolean;
    capitalCallsDue: number;
    hasSyncingConnections: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
    user,
    match,
    accounts,
    advisorClients,
    history,
    loading,
    loaded,
    setAlert,
    openDrawer,
    closeDrawer,
    closeNotification,
    modal,
    sidebarOpen,
    toggleSidebar,
    forceSidebarAddInvestmentMenuOpen,
    capitalCallsDue,
    hasSyncingConnections,
}) => {
    const [state, setState] = useState({
        mobileMenuOpen: false,
        showAddInvestmentOptions: false,
    });

    const loadOptionsTimeout = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (forceSidebarAddInvestmentMenuOpen) {
            setState((prev) => ({
                ...prev,
                showAddInvestmentOptions: true,
                mobileMenuOpen: true,
            }));
        }
    }, [forceSidebarAddInvestmentMenuOpen]);

    const menuLinks = () => {
        switch (user.access) {
            case VUES.INVESTOR:
                return [
                    { title: 'Overview', href: '/accounts/:account' },
                    { title: 'Performance', href: '/accounts/:account/performance' },
                    { title: 'Investments', href: '/accounts/:account/details' },
                    { title: 'Capital Calls', href: '/accounts/:account/calls', num: capitalCallsDue },
                    { title: 'Documents', href: '/accounts/:account/documents' },
                    { title: 'Transactions', href: '/accounts/:account/transactions' },
                    { title: 'Tax Center', href: '/accounts/:account/taxcenter' },
                ];
            case VUES.ADMIN:
                return [
                    { title: 'Integrations', href: '/admin/integrations' },
                    { title: 'Users', href: '/admin/users' },
                    { title: 'Connections', href: '/admin/connections' },
                    { title: 'Advisors', href: '/admin/advisors' },
                    { title: 'Messages', href: '/admin/messages' },
                    { title: 'Investments', href: '/admin/investments' },
                    { title: 'SLA Issue Tracker', href: '/admin/audit' },
                    { title: 'Document Processing', href: '/admin/documentprocessor' },
                    { title: 'Document Jobs', href: '/admin/documentjobs' },
                    { title: 'RAL Requests', href: '/admin/rals' },
                    { title: 'Admin Tabs', href: '/admin/securitymaster' },
                    { title: 'Staging Area', href: '/admin/connections/staging' },
                ];
            case VUES.ADVISOR: {
                const links = [{ title: 'All Clients', href: '/advisor' }];

                if (UserPermissions().canReadDocuments) {
                    links.push({ title: 'Documents', href: '/advisor/documents' });
                }
                if (UserPermissions().canReadTransactions) {
                    links.push({ title: 'Transactions', href: '/advisor/transactions' });
                }
                if (UserPermissions().canReadAnyInvestments) {
                    links.push({ title: 'Investments', href: '/advisor/investments' });
                }
                if (UserPermissions().canReadCapitalCalls) {
                    links.push({ title: 'Capital Calls', href: '/advisor/calls' });
                }
                if (UserPermissions().canReadTransactions) {
                    links.push({ title: 'Distributions', href: '/advisor/distributions' });
                }
                return links;
            }
            default:
                return [];
        }
    };

    const bottomLinks = () => {
        const logout = {
            title: 'Logout',
            href: '/logout',
            faIcon: faArrowRightFromBracket,
            faIconStyle: {
                transform: 'rotate(180deg)',
                color: 'white',
            },
        };

        switch (user.access) {
            case VUES.INVESTOR:
                return [
                    {
                        title: 'Manage Investments',
                        href: '/accounts/:account/manage',
                        syncing: hasSyncingConnections,
                    },
                    {
                        title: 'Manage Account',
                        href: '/profile/:account',
                    },
                    logout,
                ];
            case VUES.ADMIN:
                return [
                    {
                        title: 'Manage Account',
                        href: '/profile',
                        faIcon: faBars,
                        faIconStyle: {
                            color: 'white',
                        },
                    },
                    logout,
                ];
            case VUES.ADVISOR:
                return [logout];
            case VUES.ASSET_MANAGER:
                return [
                    {
                        ...logout,
                        href: `/assetmanager/${user._id}/logout`,
                    },
                ];
            default:
                return [];
        }
    };

    const renderLink = (link: any, idx: number) => {
        let href = link.href;
        if (user.access === VUES.INVESTOR) {
            if (!accounts || !accounts.length) return <React.Fragment key={idx} />;
            let account = match.params.account;
            if (!account) account = 'all';
            href = link.href.replace(':account', account);
        }

        const className = 'link' + (window.location.pathname === href ? ' link-active' : '');

        if (!sidebarOpen) {
            if (link.faIcon) {
                return (
                    <FontAwesomeIcon
                        key={idx}
                        icon={link.faIcon}
                        style={{
                            ...link.faIconStyle,
                            marginTop: '10px',
                        }}
                        className={`${className} sidebar-closed-fa-icon`}
                        onClick={() => {
                            if (window.location.pathname !== href) history.push(href);
                        }}
                    />
                );
            }
            return null;
        }

        return (
            <Link key={idx} className={className} to={href}>
                {link.title}
                {link.syncing && (
                    <div
                        style={{ position: 'relative', top: '3px', left: '75px' }}
                        className={window.location.pathname === href ? 'syncing-spinner syncing-spinner-active' : 'syncing-spinner'}
                    />
                )}
            </Link>
        );
    };

    const openNewAdvisorDrawer = (name: string) => {
        openDrawer(() => <AddEditAdvisorDrawer loaded={loaded} loading={loading} setAlert={setAlert} close={closeDrawer} startName={name} newFirmAndOwner={true} />);
    };

    const renderAddEditUserDrawer = () => (
        <AddEditUserDrawer
            reloadData={() => {}} // TODO: Add loadClients prop
            user={user}
            close={closeDrawer}
            loading={loading}
            loaded={loaded}
            setAlert={setAlert}
        />
    );

    const loadOptions = async (inputValue: string) => {
        try {
            const { data } = await api2.client.UserApi.listUsers({
                search: inputValue !== '' ? inputValue : '',
                limit: 50,
                // minimal: true,
                sort_field: 'name',
                sort_direction: 'asc',
            });

            if (!data || !data.success) {
                console.log('Error Fetching Users, No Response');
                return [];
            }

            const { users } = data;

            return [
                {
                    label: 'Users',
                    options: users
                        ?.filter((user) => user.access === VUES.INVESTOR)
                        .map((user) => ({
                            value: user,
                            label: `${user.name} (${user.email})`,
                        })),
                },
                {
                    label: 'Advisors',
                    options: users
                        ?.filter((user) => user.access === VUES.ADVISOR)
                        .map((user) => ({
                            value: user,
                            label: `${user.name} (${user.email})`,
                        })),
                },
                {
                    label: 'Admins',
                    options: users
                        ?.filter((user) => user.access === VUES.ADMIN)
                        .map((user) => ({
                            value: user,
                            label: `${user.name} (${user.email})`,
                        })),
                },
            ];
        } catch (error) {
            console.log('Error Fetching Users', error);
            return [];
        }
    };

    const loginAs = async (user: any) => {
        const res = await api.post(`/users/${user._id}/session`, {});
        if (res && res.token) {
            api.override(res.token);
            window.location.href = '/';
        }
    };

    const formatGroupLabel = (data: GroupBase<any>) => (
        <div>
            <span>{data.label}</span>
        </div>
    );

    const renderSidebarToggle = () => (
        <div className={`toggle-sidebar-btn ${sidebarOpen ? 'opened' : ''}`} onClick={toggleSidebar} data-testid="toggle-sidebar">
            <FontAwesomeIcon icon={sidebarOpen ? faChevronLeft : faChevronRight} size="xl" />
        </div>
    );

    const renderClosedSidebar = () => (
        <div
            className="closed-sidebar"
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            {renderSidebarToggle()}
            <div className="sidebar-bottom">{bottomLinks().map(renderLink)}</div>
        </div>
    );

    if (!user) return null;

    if (!sidebarOpen) {
        return renderClosedSidebar();
    }

    const advisorJumpOptions = advisorClients
        ? [
              {
                  label: 'Clients',
                  options: advisorClients.map((client) => ({
                      value: client,
                      label: `${client.name} (${client.email})`,
                  })),
              },
          ]
        : [];

    const showSponsors = user.access === VUES.ADVISOR;

    return (
        <div className="sidebar" onClick={closeNotification}>
            {renderSidebarToggle()}
            <img src="/images/logo.png" alt="AltExchange" className="logo" />

            {(match.params.account || user.access === VUES.ADMIN || user.access === VUES.ADVISOR || accounts.length > 0) && (
                <>
                    {menuLinks().map(renderLink)}

                    {(match.params.account || accounts.length > 0) && ![VUES.ADMIN, VUES.ADVISOR].includes(user.access) && (
                        <>
                            <div className="sidebar-separator" />
                            <div
                                className="sidebar-new-investment"
                                data-testid="add-investment-button"
                                onClick={() => setState((prev) => ({ ...prev, showAddInvestmentOptions: !prev.showAddInvestmentOptions }))}
                            >
                                Add New Investment
                                <div className={`${state.showAddInvestmentOptions ? 'minus' : 'plus'}-icon`} />
                            </div>

                            {state.showAddInvestmentOptions && (
                                <div className="sidebar-new-investment-options">
                                    <div
                                        onClick={() => {
                                            const path = `/accounts/${match.params.account}/add`;
                                            if (window.location.pathname !== path) {
                                                setState((prev) => ({ ...prev, showAddInvestmentOptions: false }));
                                                history.push(path);
                                            }
                                        }}
                                    >
                                        Connect a Portal
                                    </div>
                                    <div
                                        onClick={() => {
                                            const path = `/accounts/${match.params.account}/addManual`;
                                            if (window.location.pathname !== path) {
                                                setState((prev) => ({ ...prev, showAddInvestmentOptions: false }));
                                                history.push(path);
                                            }
                                        }}
                                    >
                                        Add Details Manually
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}

            {user.access === VUES.ADMIN && (
                <>
                    <div className="sidebar-separator" />
                    <AsyncCreatableSelect
                        placeholder="Jump to User"
                        value={null}
                        defaultOptions={true}
                        loadOptions={(inputValue, callback) => {
                            clearTimeout(loadOptionsTimeout.current);
                            loadOptionsTimeout.current = setTimeout(() => {
                                loadOptions(inputValue).then((options) => {
                                    const formattedOptions = options.map((option) => ({
                                        label: option.label,
                                        options: option.options || [],
                                    }));
                                    callback(formattedOptions);
                                });
                            }, 700);
                        }}
                        onChange={async (u) => {
                            if (!u) return;
                            const userValue = u as { value: { access: string; _id: string } };
                            if (userValue.value.access === VUES.INVESTOR) {
                                history.push(`/admin/users/${userValue.value._id}`);
                            } else {
                                await loginAs(userValue.value);
                            }
                        }}
                        formatGroupLabel={formatGroupLabel}
                        onCreateOption={openNewAdvisorDrawer}
                        formatCreateLabel={(inputValue) => `Add New Advisor: ${inputValue}`}
                    />
                </>
            )}

            {user.access === VUES.ADVISOR && (
                <>
                    <div className="sidebar-separator" />
                    <div style={{ margin: '5px 0' }}>
                        <Select
                            placeholder="Jump to Client"
                            options={advisorJumpOptions}
                            onChange={async (u) => {
                                if (!u) return;
                                const userValue = u as unknown as { value: { _id: string } };
                                history.push(`/advisor/clients/${userValue.value._id}`);
                            }}
                            formatGroupLabel={formatGroupLabel}
                        />
                    </div>
                    <div className="sidebar-new-investment" onClick={() => openDrawer(renderAddEditUserDrawer)}>
                        Add New Client
                    </div>
                </>
            )}

            <div className="sidebar-bottom">
                {user.access === VUES.ADMIN && (
                    <>
                        <Link
                            to="#"
                            onClick={() => {
                                openBulkUploaderManager(modal, undefined, undefined, true);
                            }}
                            className="mobile-menu-new-investment link"
                        >
                            Bulk Upload
                        </Link>
                        <Link className="sidebar-new-investment link" to="/admin/users?newAdmin=true">
                            Add New Admin
                        </Link>
                        <div className="sidebar-separator" />
                    </>
                )}

                {user.access === VUES.ADVISOR && (
                    <>
                        {showSponsors &&
                            renderLink(
                                {
                                    title: 'Digital Custodian Platform',
                                    href: '/advisor/sponsors',
                                },
                                -1
                            )}
                        {UserPermissions().canExport &&
                            renderLink(
                                {
                                    title: 'Exports',
                                    href: '/advisor/exports',
                                },
                                -2
                            )}
                        {(UserPermissions().canExport || showSponsors) && <div className="sidebar-separator" />}
                        {renderLink(
                            {
                                title: 'Manage Account',
                                href: '/advisor/manage',
                            },
                            -3
                        )}
                    </>
                )}
                {bottomLinks().map(renderLink)}
            </div>
        </div>
    );
};

export default Sidebar;
