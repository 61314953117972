const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber.length === 11) {
        return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
    } else if (phoneNumber.length === 10) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
    } else if (phoneNumber.length === 7) {
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 7)}`;
    } else {
        return phoneNumber;
    }
};

export default formatPhoneNumber;
