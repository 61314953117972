// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import React from 'react';

export default {
    emptyText: (addInvestmentFunc) => (
        <div style={{ width: '400px', color: 'white' }}>
            Get a consolidated view of all your investments.
            <button
                style={{
                    fontSize: '18px',
                    width: '265px',
                    height: '46px',
                    marginTop: '30px',
                }}
                onClick={addInvestmentFunc}
            >
                Add First Investment
            </button>
        </div>
    ),
    textStyle: {
        fontSize: '24px',
    },
    containerStyle: {
        left: 0,
        width: '100%',
        height: '100%',
        top: '-40px',
    },
    props: {
        joinData: [
            {
                type: '2020 Tax K-1 Final',
                date: '2021-03-20T21:00:00',
                investment: { name: 'Leazar Capital IV' },
                account: { name: 'Aldinger Family Trust' },
            },
            {
                type: '2020 Tax K-1 Final',
                date: '2021-03-18T21:00:00',
                investment: { name: 'Oak Hill Capital II' },
                account: { name: 'Aldinger Family Trust' },
            },
            {
                type: 'Quarterly Capital Account Statement',
                date: '2021-01-15T21:00:00',
                investment: { name: 'Oak Hill Capital II' },
                account: { name: 'Aldinger Family Trust' },
            },
        ],
    },
};
