import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import '../../styles/widget.css';
import '../../styles/widget-AllocationTable.css';
import formatCurrency from '../../utilities/format/formatCurrency';
import { isFiniteNonzero } from '../../utilities/validation/numericalValidation';
import { EM_DASH } from '../../constants/constantStrings';
import { calcTotalValue } from '../../utilities/calculate/financeCalculations';

interface Investment {
    type: string;
    valuation: number;
}

interface AllocationTableProps extends RouteComponentProps {
    investments: Investment[];
    loading: (duration: number, key?: string) => void;
    loaded: (key?: string) => void;
}

const AllocationTable: React.FC<AllocationTableProps> = (props) => {
    const [types, setTypes] = useState<{ type: string; invested: string; percent: string }[]>([]);

    const loadData = useCallback(() => {
        const investments = props.investments;
        if (!investments?.length) {
            setTypes([]);
            return;
        }

        props.loading(320, 'allocationTable');

        const investmentTypes = new Set(investments.map((i) => i.type));

        const totalInvested = calcTotalValue(investments);

        const typesInvested: { [key: string]: { invested: string; percent: string } } = {};

        for (const type of Array.from(investmentTypes)) {
            let typeInvestments = investments.filter((i) => i.type === type);
            let typeInvested = calcTotalValue(typeInvestments);

            let accountPercentage = typeInvested / totalInvested;
            let displayInvested = formatCurrency(typeInvested);
            let displayPercentage = isFiniteNonzero(accountPercentage) ? (100 * accountPercentage).toFixed(1) + '%' : EM_DASH;

            typesInvested[type] = {
                invested: displayInvested,
                percent: displayPercentage,
            };
        }

        const typeDataArray = [];
        for (const type in typesInvested) {
            typeDataArray.push({
                type: type,
                invested: typesInvested[type].invested,
                percent: typesInvested[type].percent,
            });
        }

        setTypes(typeDataArray);

        props.loaded('allocationTable');
    }, [props]);

    useEffect(() => {
        loadData();
    }, [props.investments]);

    return (
        <div className="widget allocTblComponent">
            <h1 className="widget-header">Invested by Type</h1>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Invested</th>
                        <th>% of Account</th>
                    </tr>
                </thead>
                <tbody>
                    {types.map((d, idx) => (
                        <tr key={`tr_${idx}`}>
                            <td>{d.type}</td>
                            <td>{d.invested}</td>
                            <td>{d.percent}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default withRouter(AllocationTable);
