export const generateInvestmentMasterTicker = (name: string, filler = 'X') => {
    let words = name.split(' ');
    let ticker = words
        .map((word) => word.charAt(0))
        .join('')
        .toUpperCase();
    ticker = ticker.replace(/[^a-zA-Z0-9]/g, '');

    // If ticker is too short, add filler characters
    while (ticker.length < 3) {
        ticker += filler;
    }

    // If ticker is too long, truncate it
    ticker = ticker.substring(0, 8);

    return ticker;
};
