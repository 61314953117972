import React from 'react';
import { setDismissableAlert } from '../../utilities/alert/setDismissableAlert';
import Multitoggle from './Multitoggle';
import { UserPermissions } from '../../utilities/AdvisorVue/permissions';
import api2 from '../../api2';

const OPTIONS = ['No', 'Yes'];

interface InvestmentHiddenToggleProps {
    investment: {
        _id: string;
        hidden: boolean;
    };
    loading?: (progress: number, key: string) => void;
    loaded?: (key: string) => void;
    onChange?: (newValue: boolean) => void;
    onChangeDate?: (date: Date) => void;
    setAlert?: (message: string, isError?: boolean) => void;
    containerStyle?: React.CSSProperties;
}

const InvestmentHiddenToggle: React.FC<InvestmentHiddenToggleProps> = ({ investment, loading, loaded, onChange, setAlert, containerStyle }) => {
    const curOption = OPTIONS[Number(investment.hidden)];

    const handleSelect = async (opt: string) => {
        if (!UserPermissions().canUpdateAnyInvestments) return;
        const newValue = !!OPTIONS.indexOf(opt);
        if (newValue === investment.hidden) return;

        loading?.(0, 'hiddenToggleUpdate');
        try {
            await api2.client.InvestmentApi.updateInvestment({
                investment_id: investment._id,
                UpdateInvestmentRequest: { hidden: newValue },
            });
            if (setAlert) {
                setDismissableAlert(setAlert, `Investment set to ${newValue ? '' : 'Not '}Hidden.`);
            }
            await onChange?.(newValue);
        } catch (err) {
            setDismissableAlert(setAlert, `Error setting investment to ${newValue ? '' : 'Not '}Hidden.`, true);
        }
        loaded?.('hiddenToggleUpdate');
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', ...containerStyle }} className="pdfIgnore">
            <div>Hidden Investment</div>
            <Multitoggle
                containerStyle={{ width: '70px', marginLeft: '10px' }}
                options={OPTIONS}
                selection={curOption}
                onSelect={handleSelect}
                optionStyle={{ cursor: UserPermissions().canUpdateAnyInvestments ? 'pointer' : 'default' }}
            />
        </div>
    );
};

export default InvestmentHiddenToggle;
