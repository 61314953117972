import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

// makes separate tables assuming each table is separated by an empty row
// const createTables = (data) => {
//     if (data.length === 0) return [];
//     const tables = [];

//     let nCols = data[0].length;
//     let table = [];

//     for (let i = 0; i < data.length; i++) {
//         const row = data[i]
//         // if row is empty, start a new table
//         if (row.length === 0) {
//             if (table.length > 0) {
//                 tables.push(table);
//             }
//             table = [];
//             continue;
//         }
//         // if row has the same number of columns as the first row, add it to the table
//         if (row.length === nCols) {
//             table.push(row);
//             continue;
//         }
//         // if row has a different number of columns, start a new table
//         else {
//             if (table.length > 0) {
//                 tables.push(table);
//             }
//             table = [row];
//             nCols = row.length;
//         }
//     }

//     if (table.length > 0) {
//         tables.push(table);
//     }

//     return tables;
// };
const createTables = (data) => {
    if (data.length === 0) return [];
    const tables = [];
    let table = [];
    let nCols = data[0].length;

    data.forEach((row) => {
        // const isNewTable = row.length === 0 || row.length !== nCols;
        const isNewTable = row.length === 0;

        if (isNewTable) {
            if (table.length > 0) tables.push(table);
            table = row.length === 0 ? [] : [row];
            nCols = row.length;
        } else {
            table.push(row);
        }
    });

    if (table.length > 0) tables.push(table);
    return tables;
};

function ExcelViewer({ fileBlob }) {
    const [sheetNames, setSheetNames] = useState([]);
    const [pages, setPages] = useState([]);
    const [activeSheetIndex, setActiveSheetIndex] = useState(0);

    const readExcelFile = (blob) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const buffer = e.target.result;
            const workbook = XLSX.read(buffer, { type: 'buffer' });
            const pages = [];
            workbook.SheetNames.forEach((sheetName) => {
                const worksheet = workbook.Sheets[sheetName];
                const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                if (data.length) {
                    pages.push({
                        name: sheetName,
                        data: data,
                        tables: createTables(data),
                    });
                }
            });
            setPages(pages);
            setSheetNames(workbook.SheetNames);
        };
        reader.readAsArrayBuffer(blob);
    };

    useEffect(() => {
        if (fileBlob) {
            readExcelFile(fileBlob);
        }
    }, [fileBlob]);

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        backgroundColor: '#f9f9f9',
    };

    const headerStyle = {
        background: '#f2f2f2',
        color: '#000',
        padding: '10px',
        border: '1px solid #ddd',
        backgroundColor: 'var(--color-accent)',
    };

    const cellStyle = {
        padding: '8px',
        border: '1px solid #ddd',
    };

    const tabStyle = {
        cursor: 'pointer',
        padding: '10px',
        marginRight: '4px',
        backgroundColor: '#f0f0f0',
        borderBottom: 'none',
        display: 'inline-block',
        color: 'var(--color-primary)',
    };

    const activeTabStyle = {
        ...tabStyle,
        backgroundColor: 'var(--color-primary)',
        color: 'white',
        fontWeight: 'bold',
    };

    const rowStyle = {
        borderBottom: '2px solid #ddd',
    };

    const renderTable = (data) => {
        return (
            <>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            {data[0]?.map((header, index) => (
                                <th key={index} style={headerStyle}>
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data.slice(1)?.map((row, rowIndex) => (
                            <tr key={rowIndex} style={rowStyle}>
                                {row.map((cell, cellIndex) => (
                                    <td
                                        key={cellIndex}
                                        style={{
                                            ...cellStyle,
                                            // ...rowIndex % 2 === 0 ? {background: '#f0f0f0'} : {},
                                        }}
                                    >
                                        {cell}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <br />
            </>
        );
    };

    return (
        <div
            style={{
                height: '100%',
                overflow: 'auto',
            }}
        >
            <div
                style={{
                    position: 'fixed',
                    width: '100%',
                    backgroundColor: 'white',
                    borderBottom: '2px solid var(--color-primary)',
                }}
            >
                {sheetNames.map((name, index) => (
                    <span key={index} style={index === activeSheetIndex ? activeTabStyle : tabStyle} onClick={() => setActiveSheetIndex(index)}>
                        {name}
                    </span>
                ))}
            </div>
            <div
                style={{
                    marginTop: '40px',
                }}
            >
                {pages.length > 0 && (
                    <div>
                        {/* <h3>{pages[activeSheetIndex].name}</h3> */}
                        {/* {renderTable(pages[activeSheetIndex])} */}
                        {pages[activeSheetIndex]?.tables.map((table, index) => {
                            return renderTable(table);
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}

export default ExcelViewer;
