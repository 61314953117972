import Multitoggle from '../Toggles/Multitoggle';

export const HIDDEN_INVESTMENT_FILTER_OPTIONS = {
    all: 'All',
    hidden: 'Hidden',
    visible: 'Visible',
};

const HiddenInvestmentFilter = ({ value, onChange }: { value: string; onChange: (newValue: string) => void }) => {
    const normalizedValue = value ? value.toLowerCase() : HIDDEN_INVESTMENT_FILTER_OPTIONS.all.toLowerCase();
    const selectedOption = Object.values(HIDDEN_INVESTMENT_FILTER_OPTIONS).find((option) => option.toLowerCase() === normalizedValue) || HIDDEN_INVESTMENT_FILTER_OPTIONS.all;

    return (
        <Multitoggle
            containerStyle={{ width: '250px', height: '37px' }}
            keyStr={'hiddenToggle'}
            options={Object.values(HIDDEN_INVESTMENT_FILTER_OPTIONS)}
            selection={selectedOption}
            onSelect={onChange}
        />
    );
};

export default HiddenInvestmentFilter;
