// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory

import CapitalCallsWidget from './fakeData/capitalCallsWidgetFakeData';
import AccountPerformanceWidget from './fakeData/accountPerformanceWidgetFakeData';
import MonthlyChartWidget from './fakeData/monthlyChartWidgetFakeData';
import PerformancePage from './fakeData/performancePageFakeData';
import DocumentsPage from './fakeData/documentsPageFakeData';
import InvestmentsPage from './fakeData/investmentsPageFakeData';
import DocumentsWidget from './fakeData/documentsWidgetFakeData';
import TotalValue from './fakeData/totalValueFakeData';

export default {
    CapitalCallsWidget,
    AccountPerformanceWidget,
    MonthlyChartWidget,
    TotalValue,
    PerformancePage,
    DocumentsPage,
    InvestmentsPage,
    DocumentsWidget,
};
