// const getInvestorPageBasePath = (
//     userAccess: UserAccess,
//     relevantUserId: string | null, // only required for investors
//     accountId: string | null, // only required for investors
// ): string => {
//     const pathMap = {
//         admin: `/admin/users/${relevantUserId}`,
//         advisor: `/advisor/clients/${relevantUserId}`,
//         investor: `/accounts/${accountId ?? 'all'}`,
//     }
//     return pathMap[userAccess];
// }

const ACCOUNTS = 'accounts';
const strOrAll = (str: string | null | undefined): string => str || 'all';

const overview = (accountId: string, investmentId: string) => `/${ACCOUNTS}/${strOrAll(accountId)}${investmentId ? `/investments/${investmentId}` : ''}`;
const performance = (accountId: string, investmentId: string) => `/${ACCOUNTS}/${strOrAll(accountId)}${investmentId ? `/investments/${investmentId}` : ''}/performance`;
const investments = (accountId: string, investmentId: string) => `/${ACCOUNTS}/${strOrAll(accountId)}${investmentId ? `/investments/${investmentId}` : ''}/details`;
const capitalCalls = (accountId: string, investmentId: string) => `/${ACCOUNTS}/${strOrAll(accountId)}${investmentId ? `/investments/${investmentId}` : ''}/calls`;
const documents = (accountId: string, investmentId: string) => `/${ACCOUNTS}/${strOrAll(accountId)}${investmentId ? `/investments/${investmentId}` : ''}/documents`;
const transactions = (accountId: string, investmentId: string) => `/${ACCOUNTS}/${strOrAll(accountId)}${investmentId ? `/investments/${investmentId}` : ''}/transactions`;
const taxCenter = (accountId: string, investmentId: string) => `/${ACCOUNTS}/${strOrAll(accountId)}${investmentId ? `/investments/${investmentId}` : ''}/taxcenter`;
const manageAccount = (accountId: string, investmentId: string) => `/${ACCOUNTS}/${strOrAll(accountId)}${investmentId ? `/investments/${investmentId}` : ''}/profile`;
const manageInvestments = (accountId: string, investmentId: string) => `/${ACCOUNTS}/${strOrAll(accountId)}${investmentId ? `/investments/${investmentId}` : ''}/manage`;

const investmentDetails = (accountId: string, investmentId: string) => `/${ACCOUNTS}/${strOrAll(accountId)}/investments/${investmentId}/details`;

const viewDocument = (accountId: string, investmentId: string, documentId: string) =>
    `/${ACCOUNTS}/${strOrAll(accountId)}${investmentId ? `/investments/${investmentId}` : ''}/documents/${documentId}`;

const routes = {
    overview,

    investments,
    capitalCalls,
    transactions,
    documents,
    taxCenter,
    performance,
    manageAccount,
    manageInvestments,

    investmentDetails,

    viewDocument,
};

export default routes;
